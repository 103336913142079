import {
    GET_CONTRACTS_BY_FLAT,
    CREATE_CONTRACT,
    UPDATE_CONTRACT,
    DELETE_CONTRACT,
    GET_CONTRACT_LEGACY_BY_FLAT,
    CREATE_LEGACY_CONTRACT,
    UPDATE_LEGACY_CONTRACT,
    DELETE_LEGACY_CONTRACT
} from "@/store/actions/contract";

import {ALERT_SHOW} from "@/store/actions/alert";

import contractAPI from "@/utils/axios/contracts"
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = { contracts: [] }

const getters = {
    getContracts: state => state.contracts,
    getLegacyContracts: state => state.contracts,
}

const actions = {
    [GET_CONTRACTS_BY_FLAT]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            contractAPI.get_by_flat(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_CONTRACTS_BY_FLAT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_CONTRACT]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            contractAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_CONTRACT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_CONTRACT]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            contractAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_CONTRACT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_CONTRACT]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            contractAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(DELETE_CONTRACT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },

    [GET_CONTRACT_LEGACY_BY_FLAT]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            contractAPI.get_by_flat_legacy(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_CONTRACT_LEGACY_BY_FLAT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_LEGACY_CONTRACT]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            contractAPI.create_legacy(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_LEGACY_CONTRACT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_LEGACY_CONTRACT]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            contractAPI.update_legacy(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_LEGACY_CONTRACT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_LEGACY_CONTRACT]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            contractAPI.remove_legacy(payload)
                .then(response => response.data)
                .then(data => {
                    commit(DELETE_LEGACY_CONTRACT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
}

const mutations = {
    [GET_CONTRACTS_BY_FLAT]: (state, payload) => {
        state.contracts = payload
    },
    [CREATE_CONTRACT]: (state, payload) => {
        state.contracts.push(payload)
    },
    [UPDATE_CONTRACT]: (state, payload) => {
        let obj = state.contracts.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [DELETE_CONTRACT]: (state, payload) => {
        const obg = state.contracts.find(item => item.id === payload.id)
        const obj_id = state.contracts.indexOf(obg)
        if (obg) {
            state.contracts.splice(obj_id, 1)
        }
    },

    [GET_CONTRACT_LEGACY_BY_FLAT]: (state, payload) => {
        state.contracts = payload
    },
    [CREATE_LEGACY_CONTRACT]: (state, payload) => {
        state.contracts.push(payload)
    },
    [UPDATE_LEGACY_CONTRACT]: (state, payload) => {
        let obj = state.contracts.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [DELETE_LEGACY_CONTRACT]: (state, payload) => {
        const obg = state.contracts.find(item => item.id === payload.id)
        const obj_id = state.contracts.indexOf(obg)
        if (obg) {
            state.contracts.splice(obj_id, 1)
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}