import {cloneDeep} from "lodash";
import {
    GET_WAREHOUSE_LIST_ELEMENTS,
    GET_CURRENCY_LIST_ELEMENTS,
    GET_NOMENCLATURE_LIST_GROUP,
    GET_NOMENCLATURE_LIST_ELEMENTS,
    GET_DISPATCHER_PROBLEM_LIST_GROUP,
    GET_DISPATCHER_PROBLEM_LIST_ELEMENTS,
    GET_CONTRACTOR_LIST_ELEMENTS,
    GET_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS,
    GET_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS,
    GET_POSITION_LIST_ELEMENTS,
    GET_NATURAL_PERSON_LIST_ELEMENTS,
    GET_UNIT_LIST_ELEMENTS,
    GET_SUBDIVISION_LIST_ELEMENTS,
    GET_PERSONAL_LIST_ELEMENTS,
    GET_CHART_OF_ACCOUNTS_LIST_ELEMENTS,
    GET_CASH_FLOW_ITEM_LIST_ELEMENTS,
    GET_RELEASE_REASON_LIST_ELEMENTS,
    GET_BANK_ACCOUNT_LIST_ELEMENTS,
    GET_DOCUMENT_BANK_INCOME_ELEMENTS,
    GET_DOCUMENT_BANK_OUTCOME_ELEMENTS,
    GET_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS,
    GET_DOCUMENT_WORK_TRANSFER_ELEMENTS,
    GET_FIXED_ASSETS_LIST_ELEMENTS,
    GET_INTANGIBLE_ASSETS_LIST_ELEMENTS,
    GET_BIOLOGICAL_ASSETS_LIST_ELEMENTS,
    GET_COST_ITEMS_LIST_ELEMENTS,
    GET_WORK_SCHEDULE_LIST_ELEMENTS,
    GET_ACCRUAL_LIST_ELEMENTS,
    GET_TIMESHEET_LETTER_ELEMENTS,
    GET_INCOME_TYPE_PIX_ELEMENTS,
    GET_DOCUMENT_EMPLOYMENT_ELEMENTS,
    GET_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS,
    GET_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS,
    GET_DOCUMENT_SALARY_CHARGE_ELEMENTS,
    GET_DOCUMENT_SALARY_TO_PAY_ELEMENTS,
    GET_TAXES_ELEMENTS,
    GET_MIN_AMOUNT_ELEMENTS,
    GET_INFLATION_INDEX_ELEMENTS,
    GET_TAX_TARIFF_ELEMENTS,
    GET_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS,
    GET_DOCUMENT_VOCATION_CHARGE_ELEMENTS,
    GET_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS,
    GET_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS,
    GET_CONTRACTOR_CONTRACT_LIST_ELEMENTS,
    GET_INCOME_ITEMS_LIST_ELEMENTS,
    GET_NOMENCLATURE_GROUPS_LIST_ELEMENTS,
    GET_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS,
    GET_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS,
    GET_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS,
    GET_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS,
    GET_DOCUMENT_BILL_OUTCOME_ELEMENTS,
    GET_DOCUMENT_ADVANCE_REPORT_ELEMENTS,
    GET_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS,
    GET_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS,
    GET_AMORTISATION_CALC_WAY_LIST_ELEMENTS,
    GET_DOCUMENT_COMMISSIONING_ELEMENTS,
    GET_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS,
    GET_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS,
    GET_DOCUMENT_ENTERING_BALANCES_ELEMENTS,
    GET_DOCUMENT_MANUAL_OPERATION_ELEMENTS,
    GET_DOCUMENT_TAX_BILL_INCOME_ELEMENTS,
    GET_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS,
    GET_TAX_INSPECTION_LIST_ELEMENTS,
    GET_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS,
    GET_HOUSE_ACCOUNTING_CHARGE_SETTINGS_LIST_ELEMENTS,
    GET_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS,
    GET_DOCUMENT_RELEASE_WORK_ELEMENTS, GET_COST_ITEMS_LIST_GROUP
} from "@/store/actions/accounting/list";

const warehouse_list_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Код', value: 'code', width: 140},
    {text: 'Найменування', value: 'short_name'},
]

const cash_flow_item_list_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Код', value: 'code', width: 140},
    {text: 'Найменування', value: 'short_name'},
]

const release_reason_list_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Код', value: 'code', width: 140},
    {text: 'Найменування', value: 'short_name'},
    {text: 'Стаття закону', value: 'low_article'},
]

const work_schedule_list_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Код', value: 'code', width: 140},
    {text: 'Найменування', value: 'short_name'},
    {text: 'Рік', value: 'year'},
]

const currency_list_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Код', value: 'code', width: 140},
    {text: 'Код (міжнар.)', value: 'code_internationale', width: 140},
    {text: 'Найменування', value: 'short_name'},
]

const house_accounting_payments_settings_list_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Код', value: 'code', width: 90},
    {text: 'Контрагент', value: 'contractor_name'},
    {text: 'Договір', value: 'contract_name'},
    {text: 'Стаття рух.гр.кош.', value: 'cash_flow_item_name'},
    {text: 'Бух.рах. Дт', value: 'chart_of_account_code'},
    {text: 'Бух рах. Кт', value: 'pay_chart_of_account_code'},
    {text: 'Послуга (осн.)', value: 'service_main_name'},
    {text: 'Послуга (згор.)', value: 'service_base_name'},
]

const house_accounting_charge_settings_list_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Код', value: 'code', width: 90},
    {text: 'Контрагент', value: 'contractor_name'},
    {text: 'Договір', value: 'contract_name'},
    {text: 'Бух.рах. Дт', value: 'contractor_chart_of_account_code'},
    {text: 'Бух рах. Кт', value: 'table_chart_of_account_code'},
    {text: 'Послуга (осн.)', value: 'service_main_name'},
    {text: 'Послуга (згор.)', value: 'service_base_name'},
    {text: 'Номенклатура', value: 'nomenclature_name'},
]

const amortisation_calc_way_list_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Код', value: 'code', width: 140},
    {text: 'Найменування', value: 'short_name'},
    {text: 'Повне найменування', value: 'full_name'},
    {text: 'Дата створення', value: 'create_date', width: 140, date: 'DD.MM.YYYY'},
]

const taxes_list_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Код', value: 'code', width: 140},
    {text: 'Найменування', value: 'short_name', width: 180},
    {text: 'Повна назва', value: 'full_name'},
    {text: 'Інвалід', value: 'invalid', width: 90, checkbox: true},
    {text: 'Група податку', value: '', manual: true, manual_id: 'tax_group'},
]

const min_amount_list_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Місяць', value: 'month', width: 140, date: 'MMMM YYYY'},
    {text: 'Мінімальна заробітна плата', value: 'min_salary', number_format: true},
    {text: 'Мінімальний прожит. мінімум', value: 'cost_of_living', number_format: true},
]

const inflation_index_list_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Місяць', value: 'month', width: 140, date: 'MMMM YYYY'},
    {text: 'Індекс інфляції', value: 'value', width: 260, number_format: true},
]

const tax_tariff_list_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Місяць', value: 'month', width: 140, date: 'MMMM YYYY'},
    {text: 'Податок', value: 'tax_name'},
    {text: 'Розмір', value: 'tariff', number_format: true},
]

const personal_list_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: '', column_true: 'is_released', column_true_color: 'error'}},
    {text: 'Код', value: 'code', width: 70},
    {text: 'Найменування', value: 'full_name', manual: true, manual_id: 'person_full_name',},
    {text: 'Код за ДРФО', value: 'ipn', width: 110},
    {text: 'Посада', value: 'position_name', width: 140},
    {text: 'Підрозділ', value: 'subdivision_name', width: 106},
    {text: 'Вид договору', value: '', manual: true, manual_id: 'person_type', width: 140},
    {text: 'Вид зайнятості', value: '', manual: true, manual_id: 'full_time_type', width: 130},
    {text: 'Дата народж.', value: 'date_of_birth', width: 120, date: 'DD.MM.YYYY'},
]

const nomenclature_list_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Код', value: 'code', width: 140},
    {text: 'Найменування', value: 'short_name'},
    {text: 'ПДВ', value: 'tax_name', width: 120},
    {text: 'Од.вим.', value: 'unit_type_name', width: 120},
    {text: 'Послуга', value: 'is_service', width: 90, checkbox: true},
    {text: 'Залишок', value: 'numeric_value', additionalField: true}
]

const dispatcher_problem_list_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Код', value: 'code', width: 140},
    {text: 'Найменування', value: 'short_name'},
    {text: 'Платна', value: 'pay_service', width: 90, checkbox: true},
    {text: 'Ціна', value: 'price', width: 140, number_format: true},
]

const subdivision_list_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Код', value: 'code', width: 140},
    {text: 'Найменування', value: 'full_name'},
]

const unit_list_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Код', value: 'code', width: 140},
    {text: 'Найменування', value: 'name'},
    {text: 'Скорочено', value: 'short_name'},
]

const timesheet_letter_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Код', value: 'code', width: 140},
    {text: 'Найменування', value: 'full_name'},
    {text: 'Позначка у таб.', value: 'letter_string_code'},
    {text: 'Роб.час', value: 'is_work_time', width: 90, checkbox: true},
    {text: 'Поважна прич.для хвор.', value: 'is_good_reason_for_sick', width: 160, checkbox: true},
]

const income_type_pix_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Код', value: 'code', width: 140},
    {text: 'Найменування', value: 'full_name'},
    {text: 'Скорочено', value: 'short_name'},
]

const income_items_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Код', value: 'code', width: 140},
    {text: 'Найменування', value: 'full_name'},
    {text: 'Скорочено', value: 'short_name'},
]


const contractor_contact_person_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Код', value: 'code', width: 140},
    {text: 'Контрагент', value: 'contractor_name'},
    {text: 'Прізвище', value: 'first_name'},
    {text: 'Ім’я', value: 'middle_name'},
    {text: 'По батькові', value: 'last_name'},
    {text: 'Представлення', value: 'represent_name'},
]

const contractor_response_person_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Дата початку', value: 'date_start', date: 'DD.MM.YYYY'},
    {text: 'Контрагент', value: 'contractor_name'},
    {text: 'Відповідальний', value: 'contact_person_name'},
    {text: 'Категорія', value: 'category'},
    {text: 'Посада', value: 'position_name'},
]


const contractor_bank_account_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Контрагент', value: 'contractor_name'},
    {text: 'Найменування', value: 'print_name'},
    {text: '№ рахунку', value: 'account'},
    {text: 'Банк', value: 'bank_name'},
    {text: 'Основний', value: 'main'}
]

const nomenclature_groups_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Код', value: 'code', width: 140},
    {text: 'Найменування', value: 'full_name'},
    {text: 'Скорочено', value: 'short_name'},
]

const natural_person_list_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Код', value: 'code', width: 90},
    {text: 'Найменування', value: 'full_name'},
    {text: 'Дата народження', value: 'date_of_birth', width: 130, date: 'DD.MM.YYYY'},
    {text: 'Код ДРФО', value: 'ipn', width: 130},
    {text: 'Паспорт', value: 'passport', width: 130},
    {text: 'Інвалідність', value: '', width: 160, manual: true, manual_id: 'cripple_category'},

]


const natural_person_list_elements_header_ = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Код', value: 'code', width: 90},
    {text: 'Найменування', value: 'full_name'},
    {text: 'Посада', value: 'position_name'},
]

const position_list_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Код', value: 'code', width: 90},
    {text: 'Найменування', value: 'name', width: 180},
    {text: 'Код КП', value: 'code_kp', width: 90},
    {text: 'Код ЗКППТР', value: 'code_zkpptr', width: 90},
    {text: 'Назва за КП', value: 'name_kp'},
]

const tax_inspection_list_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Код', value: 'code', width: 90},
    {text: 'Найменування', value: 'full_name'},
    {text: 'Номер податкової', value: 'inspection_number', width: 160},
]

const contractor_list_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Код', value: 'code', width: 140},
    {text: 'Найменування', value: 'short_name'},
    {text: 'ЄДРПОУ/ДРФО', value: 'ident_code', width: 140},
    {text: 'Покуп.', value: 'is_buyer', width: 90, checkbox: true},
    {text: 'Постач.', value: 'is_provider', width: 90, checkbox: true},
    {text: 'Кінц. покуп', value: 'is_end_buyer', width: 90, checkbox: true},
    {text: 'Сальдо (пок.)', value: 'purchase_sum', width: 80, additionalField: true},
    {text: 'Сальдо (пост.)', value: 'sell_sum', width: 80, additionalField: true},
]


const contractor_contract_list_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Код', value: 'code', width: 140},
    {text: 'Найменування', value: 'short_name'},
    {text: 'Дата', value: 'date', date: 'DD.MM.YYYY'},
    {text: 'Номер', value: 'number'},
    {text: 'Покуп.', value: 'is_buyer', width: 90, checkbox: true},
    {text: 'Основний', value: 'is_main', width: 90, checkbox: true},
]

const bank_account_list_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Код', value: 'code', width: 140},
    {text: 'Найменування', value: 'name'},
    {text: 'Рахунок', value: 'account'},
    {text: 'Банк', value: 'bank_name'},
    {text: 'МФО', value: 'bank_mfo', width: 110},
    {text: 'Основ.', value: 'main', width: 90, checkbox: true},
]

const accrual_list_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Код', value: 'code', width: 140},
    {text: 'Найменування', value: 'short_name'},
    {text: 'Тип нарахування', value: 'accrual_type', manual: true, manual_id: 'accrual_type',},
    {text: 'Облік роб.часу', value: 'timesheet_letter_name', width: 130},
    {text: 'Код дох. ПДФО', value: 'income_type_pix_name', width: 130},
    {text: 'Група податків', value: 'tax_group', manual: true, manual_id: 'tax_group', width: 130},
]

const chart_of_accounts_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Код', value: 'code', width: 140},
    {text: 'Найменування', value: 'name', width: 350},
    {text: 'Акт', value: '', width: 60, manual: true, manual_id: 'actual'},
    {text: 'Кіл.', value: 'counted', width: 60, checkbox: true},
    {text: 'Субконто 1', value: '', manual: true, manual_id: 'subconto_1'},
    {text: 'Субконто 2', value: '', manual: true, manual_id: 'subconto_2'},
    {text: 'Субконто 3', value: '', manual: true, manual_id: 'subconto_3'},
]

const biological_assets_list_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Код', value: 'code', width: 140},
    {text: 'Найменування', value: 'short_name'},
]

const cost_items_list_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Код', value: 'code', width: 140},
    {text: 'Найменування', value: 'short_name'},
]

const fixed_assets_list_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Код', value: 'code', width: 140},
    {text: 'Найменування', value: 'short_name'},
    {text: 'Група', value: 'asset_group', manual: true, manual_id: 'asset_group'},
]

const intangible_assets_list_elements_header = [
    {text: '', value: 'icon', width: 60, icon: {icon: 'mdi-square-rounded-outline', size: 16, class: '', color: ''}},
    {text: 'Код', value: 'code', width: 140},
    {text: 'Найменування', value: 'short_name'},
]

const document_bank_income_header = [
    {
        text: '',
        value: '',
        width: 60,
        icon: {icon: 'mdi-scatter-plot-outline', size: 22, class: '', color: '', conducted: true}
    },
    {text: 'Дата', value: 'date', width: 120, date: 'DD.MM.YYYY'},
    {text: 'Номер', value: 'number', width: 140},
    {text: 'Операція', value: 'operation', width: 200, manual: true, manual_id: 'bank_income_operation'},
    {text: 'Сума', value: 'sum', width: 140, number_format: true},
    {text: 'Платник', value: 'contractor_name'},
]

const document_close_financial_month_header = [
    {
        text: '',
        value: '',
        width: 60,
        icon: {icon: 'mdi-scatter-plot-outline', size: 22, class: '', color: '', conducted: true}
    },
    {text: 'Дата', value: 'date', width: 120, date: 'DD.MM.YYYY'},
    {text: 'Номер', value: 'number', width: 140},
    {text: 'Амортизація', value: 'calc_amortisation', checkbox: true},
    {text: 'Фін.результати', value: 'calc_financial_result', checkbox: true},
    {text: 'Прибуток/Збиток', value: 'calc_financial_profit', checkbox: true},
]


const document_close_home_accounting_header = [
    {
        text: '',
        value: '',
        width: 60,
        icon: {icon: 'mdi-scatter-plot-outline', size: 22, class: '', color: '', conducted: true}
    },
    {text: 'Дата', value: 'date', width: 120, date: 'DD.MM.YYYY'},
    {text: 'Номер', value: 'number', width: 140},
    {text: 'Місяць', value: 'month', width: 150, date: 'MMMM YYYY'},
    {text: 'Оплати', value: 'close_pays', checkbox: true},
    {text: 'По касі', value: 'close_pays_by_cash_order', checkbox: true},
    {text: 'Оплата (заг. сума)', value: 'close_pays_general', checkbox: true},
    {text: 'Нарахування', value: 'close_charge', checkbox: true},
    {text: 'Нарахування (заг. сума)', value: 'close_charge_general', checkbox: true},
]

const document_entering_balances_header = [
    {
        text: '',
        value: '',
        width: 60,
        icon: {icon: 'mdi-scatter-plot-outline', size: 22, class: '', color: '', conducted: true}
    },
    {text: 'Дата', value: 'date', width: 120, date: 'DD.MM.YYYY'},
    {text: 'Номер', value: 'number', width: 140},
    {text: 'Дата залишку', value: 'balance_date', width: 180, date: 'DD.MM.YYYY'},
    {text: 'Розділ обліку', value: 'operation', manual: true, manual_id: 'entering_balances_operation'},
    {text: 'Дебет', value: 'total_debit', number_format: true},
    {text: 'Кредит', value: 'total_credit', number_format: true},
]

const document_manual_operation_header = [
    {
        text: '',
        value: '',
        width: 60,
        icon: {icon: 'mdi-scatter-plot-outline', size: 22, class: '', color: '', conducted: true}
    },
    {text: 'Дата', value: 'date', width: 120, date: 'DD.MM.YYYY'},
    {text: 'Номер', value: 'number', width: 140},
    {text: 'Валюта', value: 'currency_name'},
    {text: 'Сума операції', value: 'total_result', number_format: true, width: 180},
    {text: 'Коментар', value: 'comment'},
]

const document_release_work_header = [
    {
        text: '',
        value: '',
        width: 60,
        icon: {icon: 'mdi-scatter-plot-outline', size: 22, class: '', color: '', conducted: true}
    },
    {text: 'Дата', value: 'date', width: 120, date: 'DD.MM.YYYY'},
    {text: 'Номер', value: 'number', width: 140},
    {text: 'Працівник', value: 'full_name'},
    {text: 'Дата звільнення', value: 'release_date', width: 180, date: 'DD.MM.YYYY'},
    {text: 'Підстава звільнення', value: 'release_reason_name'},
    {text: 'Коментар', value: 'comments'},
]

const document_commissioning_header = [
    {
        text: '',
        value: '',
        width: 60,
        icon: {icon: 'mdi-scatter-plot-outline', size: 22, class: '', color: '', conducted: true}
    },
    {text: 'Дата', value: 'date', width: 120, date: 'DD.MM.YYYY'},
    {text: 'Номер', value: 'number', width: 140},
    {text: 'Операція', value: 'operation', width: 310, manual: true, manual_id: 'commissioning_document_operation'},
    {text: 'Основний засіб', value: 'fixed_asset_name'},
    {text: 'Відповідальний', value: 'response_person_name'},
    {text: 'Сума', value: 'total_result', number_format: true, width: 160},
]

const document_tax_bill_income_header = [
    {
        text: '',
        value: '',
        width: 60,
        icon: {icon: 'mdi-scatter-plot-outline', size: 22, class: '', color: '', conducted: true}
    },
    {text: 'Дата', value: 'date', width: 120, date: 'DD.MM.YYYY'},
    {text: 'Номер', value: 'number', width: 140},
    {text: 'Операція', value: 'operation', manual: true, manual_id: 'tax_bill_income_operation', width: 180},
    {text: 'Контрагент', value: 'contractor_name', width: 240},
    {text: 'Договір', value: 'contract_name', width: 160},
    {text: 'Сума без ПДВ', value: 'sum_without_tax', number_format: true},
    {text: 'Сума ПДВ', value: 'tax_sum', number_format: true},
    {text: 'Всього', value: 'sum_with_tax', number_format: true},
]


const document_tax_bill_outcome_header = [
    {
        text: '',
        value: '',
        width: 60,
        icon: {icon: 'mdi-scatter-plot-outline', size: 22, class: '', color: '', conducted: true}
    },
    {text: 'Дата', value: 'date', width: 120, date: 'DD.MM.YYYY'},
    {text: 'Номер', value: 'number', width: 140},
    {text: 'Операція', value: 'operation', manual: true, manual_id: 'tax_bill_outcome_operation', width: 180},
    {text: 'Контрагент', value: 'contractor_name', width: 240},
    {text: 'Договір', value: 'contract_name', width: 160},
    {text: 'Сума без ПДВ', value: 'sum_without_tax', number_format: true},
    {text: 'Сума ПДВ', value: 'tax_sum', number_format: true},
    {text: 'Всього', value: 'sum_with_tax', number_format: true},
]


const document_low_cost_item_commissioning_header = [
    {
        text: '',
        value: '',
        width: 60,
        icon: {icon: 'mdi-scatter-plot-outline', size: 22, class: '', color: '', conducted: true}
    },
    {text: 'Дата', value: 'date', width: 120, date: 'DD.MM.YYYY'},
    {text: 'Номер', value: 'number', width: 140},
    {text: 'Склад', value: 'warehouse_name'},
    {text: 'Підрозділ', value: 'subdivision_name'},
    {text: 'Сума', value: 'total_result', number_format: true, width: 160},
]


const document_bank_outcome_header = [
    {
        text: '',
        value: '',
        width: 60,
        icon: {icon: 'mdi-scatter-plot-outline', size: 22, class: '', color: '', conducted: true}
    },
    {text: 'Дата', value: 'date', width: 120, date: 'DD.MM.YYYY'},
    {text: 'Номер', value: 'number', width: 140},
    {text: 'Операція', value: 'operation', width: 310, manual: true, manual_id: 'bank_outcome_operation'},
    {text: 'Сума', value: 'sum', width: 140, number_format: true},
    {text: 'Платник', value: 'contractor_name'},
]


const document_cash_order_income_header = [
    {
        text: '',
        value: '',
        width: 60,
        icon: {icon: 'mdi-scatter-plot-outline', size: 22, class: '', color: '', conducted: true}
    },
    {text: 'Дата', value: 'date', width: 120, date: 'DD.MM.YYYY'},
    {text: 'Номер', value: 'number', width: 140},
    {text: '№ ордеру', value: 'order_number', width: 140},
    {text: 'Операція', value: 'operation', width: 200, manual: true, manual_id: 'cash_order_income_operation'},
    {text: 'Сума', value: 'sum', width: 140, number_format: true},
    {text: 'Платник', value: 'contractor_name'},
]

const document_cash_order_outcome_header = [
    {
        text: '',
        value: '',
        width: 60,
        icon: {icon: 'mdi-scatter-plot-outline', size: 22, class: '', color: '', conducted: true}
    },
    {text: 'Дата', value: 'date', width: 120, date: 'DD.MM.YYYY'},
    {text: 'Номер', value: 'number', width: 140},
    {text: '№ ордеру', value: 'order_number', width: 140},
    {text: 'Операція', value: 'operation', width: 310, manual: true, manual_id: 'cash_order_outcome_operation'},
    {text: 'Сума', value: 'sum', width: 140, number_format: true},
    {text: 'Платник', value: 'contractor_name'},
]

const document_act_wok_performed_outcome = [
    {
        text: '',
        value: '',
        width: 60,
        icon: {icon: 'mdi-scatter-plot-outline', size: 22, class: '', color: '', conducted: true}
    },
    {text: 'Дата', value: 'date', width: 120, date: 'DD.MM.YYYY'},
    {text: 'Номер', value: 'number', width: 120},
    {text: 'Контрагент', value: 'contractor_name'},
    {text: 'Договір', value: 'contract_name', width: 140},
    {text: 'ПДВ', value: 'total_tax', number_format: true, width: 140},
    {text: 'Всього', value: 'total_result', number_format: true, width: 140},
]

const document_act_wok_performed_income = [
    {
        text: '',
        value: '',
        width: 60,
        icon: {icon: 'mdi-scatter-plot-outline', size: 22, class: '', color: '', conducted: true}
    },
    {text: 'Дата', value: 'date', width: 120, date: 'DD.MM.YYYY'},
    {text: 'Номер', value: 'number', width: 120},
    {text: 'Контрагент', value: 'contractor_name'},
    {text: 'Договір', value: 'contract_name', width: 140},
    {text: 'ПДВ', value: 'total_tax', number_format: true, width: 140},
    {text: 'Всього', value: 'total_result', number_format: true, width: 140},
]


const document_invoice_warehouse_income = [
    {
        text: '',
        value: '',
        width: 60,
        icon: {icon: 'mdi-scatter-plot-outline', size: 22, class: '', color: '', conducted: true}
    },
    {text: 'Дата', value: 'date', width: 120, date: 'DD.MM.YYYY'},
    {text: 'Номер', value: 'number', width: 120},
    {text: 'Контрагент', value: 'contractor_name'},
    {text: 'Договір', value: 'contract_name', width: 140},
    {text: 'Склад', value: 'warehouse_name', width: 140},
    {text: 'ПДВ', value: 'total_tax', number_format: true, width: 140},
    {text: 'Всього', value: 'total_result', number_format: true, width: 140},
]

const document_invoice_warehouse_outcome = [
    {
        text: '',
        value: '',
        width: 60,
        icon: {icon: 'mdi-scatter-plot-outline', size: 22, class: '', color: '', conducted: true}
    },
    {text: 'Дата', value: 'date', width: 120, date: 'DD.MM.YYYY'},
    {text: 'Номер', value: 'number', width: 120},
    {text: 'Контрагент', value: 'contractor_name'},
    {text: 'Договір', value: 'contract_name', width: 140},
    {text: 'Склад', value: 'warehouse_name', width: 140},
    {text: 'ПДВ', value: 'total_tax', number_format: true, width: 140},
    {text: 'Всього', value: 'total_result', number_format: true, width: 140},
]

const document_advance_report = [
    {
        text: '',
        value: '',
        width: 60,
        icon: {icon: 'mdi-scatter-plot-outline', size: 22, class: '', color: '', conducted: true}
    },
    {text: 'Дата', value: 'date', width: 120, date: 'DD.MM.YYYY'},
    {text: 'Номер', value: 'number', width: 120},
    {text: 'Працівник', value: 'person_name'},
    {text: 'Склад', value: 'warehouse_name', width: 140},
    {text: 'Видано', value: 'total_result', number_format: true, width: 140},
    {text: 'Оплачено', value: 'total_contractor_pays', number_format: true, width: 140},
    {text: 'Товари', value: 'total_goods', number_format: true, width: 140},
    {text: 'Послуги', value: 'total_services', number_format: true, width: 140},
]
//
// const document_tax_bill_income = [
//     {
//         text: '',
//         value: '',
//         width: 60,
//         icon: {icon: 'mdi-scatter-plot-outline', size: 22, class: '', color: '', conducted: true}
//     },
//     {text: 'Дата', value: 'date', width: 120, date: 'DD.MM.YYYY'},
//     {text: 'Номер', value: 'number', width: 120},
// ]

const document_bill_outcome = [
    {
        text: '',
        value: '',
        width: 60,
        icon: {icon: 'mdi-scatter-plot-outline', size: 22, class: '', color: '', conducted: true}
    },
    {text: 'Дата', value: 'date', width: 120, date: 'DD.MM.YYYY'},
    {text: 'Номер', value: 'number', width: 120},
    {text: 'Контрагент', value: 'contractor_name'},
    {text: 'Договір', value: 'contract_name', width: 140},
    {text: 'Склад', value: 'warehouse_name', width: 140},
    {text: 'ПДВ', value: 'total_tax', number_format: true, width: 140},
    {text: 'Всього', value: 'total_result', number_format: true, width: 140},
]

const document_warehouse_inventory_outcome = [
    {
        text: '',
        value: '',
        width: 60,
        icon: {icon: 'mdi-scatter-plot-outline', size: 22, class: '', color: '', conducted: true}
    },
    {text: 'Дата', value: 'date', width: 120, date: 'DD.MM.YYYY'},
    {text: 'Номер', value: 'number', width: 120},
    {text: 'Склад', value: 'warehouse_name'},
    {text: 'Сума', value: 'total_result', number_format: true, width: 140},
]

const document_personal_timesheet_header = [
    {
        text: '',
        value: '',
        width: 60,
        icon: {icon: 'mdi-scatter-plot-outline', size: 22, class: '', color: '', conducted: true}
    },
    {text: 'Дата', value: 'date', width: 120, date: 'DD.MM.YYYY'},
    {text: 'Номер', value: 'number', width: 140},
    {text: 'Місяць', value: 'month', width: 140, date: 'MMMM YYYY'},
    {text: 'Підрозділ', value: 'subdivision_name', width: 160},
    {text: 'Працівники', value: 'persons'},
]

const document_work_transfer_header = [
    {
        text: '',
        value: '',
        width: 60,
        icon: {icon: 'mdi-scatter-plot-outline', size: 22, class: '', color: '', conducted: true}
    },
    {text: 'Дата', value: 'date', width: 120, date: 'DD.MM.YYYY'},
    {text: 'Номер', value: 'number', width: 140},
    {text: 'Працівник', value: 'full_name', width: 220},
    {text: 'Підрозділ', value: 'subdivision_name', width: 160},
    {text: 'Посада', value: 'position_name', width: 160},
    {text: 'Ставок', value: 'new_rate_count', width: 100},
    {text: 'Графік', value: 'work_schedule_name'},
]

const document_employment_header = [
    {
        text: '',
        value: '',
        width: 60,
        icon: {icon: 'mdi-scatter-plot-outline', size: 22, class: '', color: '', conducted: true}
    },
    {text: 'Дата', value: 'date', width: 120, date: 'DD.MM.YYYY'},
    {text: 'Номер', value: 'number', width: 140},
    {text: 'Дата прийняття', value: 'hire_date', width: 180, date: 'DD.MM.YYYY'},
    {text: 'Працівник', value: 'person_name', width: 220},
    {text: 'Підрозділ', value: 'subdivision_name', width: 160},
    {text: 'Посада', value: 'position_name'},
]

const document_contract_by_cln_header = [
    {
        text: '',
        value: '',
        width: 60,
        icon: {icon: 'mdi-scatter-plot-outline', size: 22, class: '', color: '', conducted: true}
    },
    {text: 'Дата', value: 'date', width: 120, date: 'DD.MM.YYYY'},
    {text: 'Номер', value: 'number', width: 140},
    {text: 'Працівник', value: 'person_name', width: 220},
    {text: 'Підрозділ', value: 'subdivision_name', width: 160},
    {text: 'Початок', value: 'date_start', width: 120, date: 'DD.MM.YYYY'},
    {text: 'Закінчення', value: 'date_end', width: 120, date: 'DD.MM.YYYY'},
    {text: 'Вид оплати', value: 'pay_type', width: 170, manual: true, manual_id: 'pay_type'},
    {text: 'Сума', value: 'sum', number_format: true},
]

const document_social_privilege_header = [
    {
        text: '',
        value: '',
        width: 60,
        icon: {icon: 'mdi-scatter-plot-outline', size: 22, class: '', color: '', conducted: true}
    },
    {text: 'Дата', value: 'date', width: 120, date: 'DD.MM.YYYY'},
    {text: 'Номер', value: 'number', width: 140},
    {text: 'Працівник', value: 'person_name', width: 220},
    {text: 'Початок', value: 'date_start', width: 100, date: 'DD.MM.YYYY'},
    {text: 'Закінчення', value: 'date_end', width: 100, date: 'DD.MM.YYYY'},
    {text: 'Пільга', value: '', manual: true, manual_id: 'privilege_type'},
    {text: 'Відсоток', value: '', width: 80, manual: true, manual_id: 'privilege_percent'},
]

const document_salary_charge_header = [
    {
        text: '',
        value: '',
        width: 60,
        icon: {icon: 'mdi-scatter-plot-outline', size: 22, class: '', color: '', conducted: true}
    },
    {text: 'Дата', value: 'date', width: 120, date: 'DD.MM.YYYY'},
    {text: 'Номер', value: 'number', width: 120},
    {text: 'Аванс', value: 'is_advance', width: 60, checkbox: true},
    {text: 'Місяць', value: 'month', width: 100, date: 'MMMM YYYY'},
    {text: 'Підрозділ', value: 'subdivision_name', width: 180},
    {text: 'Працівники', value: 'people', width: 300, no_wrap: true},
    {text: 'Нараховано', value: 'total_charge', width: 120, number_format: true},
]

const document_hospital_charge_header = [
    {
        text: '',
        value: '',
        width: 60,
        icon: {icon: 'mdi-scatter-plot-outline', size: 22, class: '', color: '', conducted: true}
    },
    {text: 'Дата', value: 'date', width: 120, date: 'DD.MM.YYYY'},
    {text: 'Номер', value: 'number', width: 140},
    {text: 'Місяць', value: 'month', width: 110, date: 'MMMM YYYY'},
    {text: 'Період з', value: 'date_start', width: 105, date: 'DD.MM.YYYY'},
    {text: 'Період по', value: 'date_end', width: 105, date: 'DD.MM.YYYY'},
    {text: 'Працівник', value: 'person_name', width: 160, no_wrap: true},
    {text: 'Сума', value: 'total_sum', width: 120, number_format: true},
]

const document_vocation_charge_header = [
    {
        text: '',
        value: '',
        width: 60,
        icon: {icon: 'mdi-scatter-plot-outline', size: 22, class: '', color: '', conducted: true}
    },
    {text: 'Дата', value: 'date', width: 120, date: 'DD.MM.YYYY'},
    {text: 'Номер', value: 'number', width: 140},
    {text: 'Місяць', value: 'month', width: 110, date: 'MMMM YYYY'},
    {text: 'Період з', value: 'date_start', width: 105, date: 'DD.MM.YYYY'},
    {text: 'Період по', value: 'date_end', width: 105, date: 'DD.MM.YYYY'},
    {text: 'Працівник', value: 'person_name', width: 160, no_wrap: true},
    {text: 'Сума', value: 'total_sum', width: 120, number_format: true},
]

const document_salary_to_pay_header = [
    {
        text: '',
        value: '',
        width: 60,
        icon: {icon: 'mdi-scatter-plot-outline', size: 22, class: '', color: '', conducted: true}
    },
    {text: 'Дата', value: 'date', width: 120, date: 'DD.MM.YYYY'},
    {text: 'Номер', value: 'number', width: 140},
    {text: 'По банку', value: 'by_bank', width: 80, checkbox: true},
    {text: 'Операція', value: 'operation', width: 180, manual: true, manual_id: 'operation_to_pay'},
    {text: 'Підрозділ', value: 'subdivision_name', width: 180},
    {text: 'Працівники', value: 'people', width: 220, no_wrap: true},
    {text: 'Сума', value: 'total_sum', width: 120, number_format: true}
]

const subconto_type_select_setting = {
    'nomenclature': {
        computed_action: 'SELECT_NOMENCLATURE_LIST_ELEMENTS',
        computed_getter: 'get_nomenclature_list_select',
        label: 'Номенклатура',
        select_button_object: {
            name: 'NOMENCLATURE_ELEMENTS',
            group: 'NOMENCLATURE_GROUP',
            hierarchy: true,
            only_groups: false,
            title: 'Список номенклатури',
            element_title: 'Номенклатура',
            show: true
        },
    },
    'contractor': {
        computed_action: 'SELECT_CONTRACTOR_LIST_ELEMENTS',
        computed_getter: 'get_contractor_list_select',
        label: 'Контрагент',
        select_button_object: {
            name: 'CONTRACTOR_ELEMENTS',
            group: '',
            hierarchy: false,
            only_groups: false,
            title: 'Список контрагентів',
            element_title: 'Контрагент',
            show: true
        },
    },
    'contract': {
        computed_action: 'SELECT_CONTRACTOR_CONTRACT_LIST_ELEMENTS',
        computed_getter: 'get_contractor_contract_list_select',
        label: 'Договір контрагента',
        select_button_object: {
            name: 'CONTRACTOR_CONTRACT_ELEMENTS',
            group: '',
            hierarchy: false,
            only_groups: false,
            title: 'Список договорів контрагента',
            element_title: 'Договір контрагента',
            show: true
        },
    },
    'natural_person': {
        computed_action: 'SELECT_NATURAL_PERSON_LIST_ELEMENTS',
        computed_getter: 'get_natural_person_list_select',
        label: 'Працівник',
        select_button_object: {
            name: 'NATURAL_PERSON_ELEMENTS',
            group: '',
            hierarchy: false,
            only_groups: false,
            title: 'Список працівників',
            element_title: 'Працівник',
            show: true
        },
    },
    'warehouse': {
        computed_action: 'SELECT_WAREHOUSE_LIST_ELEMENTS',
        computed_getter: 'get_warehouse_list_select',
        label: 'Склад',
        select_button_object: {
            name: 'WAREHOUSE_ELEMENTS',
            group: '',
            hierarchy: false,
            only_groups: false,
            title: 'Список складів',
            element_title: 'Склад',
            show: true
        },
    },
    'subdivision': {
        computed_action: 'SELECT_SUBDIVISION_LIST_ELEMENTS',
        computed_getter: 'get_subdivision_list_select',
        label: 'Підрозділ',
        select_button_object: {
            name: 'SUBDIVISION_ELEMENTS',
            group: '',
            hierarchy: false,
            only_groups: false,
            title: 'Список підрозділів',
            element_title: 'Підрозділ',
            show: true
        },
    },
    'person': {
        computed_action: 'SELECT_PERSONAL_LIST_ELEMENTS',
        computed_getter: 'get_personal_list_select',
        label: 'Працівник',
        select_button_object: {
            name: 'PERSONAL_ELEMENTS',
            group: '',
            hierarchy: false,
            only_groups: false,
            title: 'Список працівників',
            element_title: 'Працівник',
            show: true
        },
    },
    'currency': {
        computed_action: 'SELECT_CURRENCY_LIST_ELEMENTS',
        computed_getter: 'get_currency_list_select',
        label: 'Валюта',
        select_button_object: {
            name: 'CURRENCY_ELEMENTS',
            group: '',
            hierarchy: false,
            only_groups: false,
            title: 'Список валют',
            element_title: 'Валюта',
            show: true
        },
    },
    'cash_flow_item': {
        computed_action: 'SELECT_CASH_FLOW_ITEM_LIST_ELEMENTS',
        computed_getter: 'get_cash_flow_item_list_select',
        label: 'Стаття руху грош. коштів',
        select_button_object: {
            name: 'CASH_FLOW_ITEM_ELEMENTS',
            group: '',
            hierarchy: false,
            only_groups: false,
            title: 'Список статей руху грошових коштів',
            element_title: 'Стаття руху грошових коштів',
            show: true
        },
    },
    'bank_account': {
        computed_action: 'SELECT_BANK_ACCOUNT_LIST_ELEMENTS',
        computed_getter: 'get_bank_account_list_select',
        label: 'Банківські рахунки',
        select_button_object: {
            name: 'BANK_ACCOUNT_ELEMENTS',
            group: '',
            hierarchy: false,
            only_groups: false,
            title: 'Список банківських рахунків',
            element_title: 'Банківський рахунок',
            show: true
        },
    },
    'fixed_assets': {
        computed_action: 'SELECT_FIXED_ASSETS_LIST_ELEMENTS',
        computed_getter: 'get_fixed_assets_list_select',
        label: 'Основні засоби',
        select_button_object: {
            name: 'FIXED_ASSETS_ELEMENTS',
            group: '',
            hierarchy: false,
            only_groups: false,
            title: 'Список основних засобів',
            element_title: 'Основні засоби',
            show: true
        },
    },
    'cost_items': {
        computed_action: 'SELECT_COST_ITEMS_LIST_ELEMENTS',
        computed_getter: 'get_cost_items_list_select',
        label: 'Статті витрат',
        select_button_object: {
            name: 'COST_ITEMS_ELEMENTS',
            group: 'COST_ITEMS_GROUP',
            hierarchy: true,
            only_groups: false,
            title: 'Список статей витрат',
            element_title: 'Статті витрат',
            show: true
        },
    },
    'intangible_assets': {
        computed_action: 'SELECT_INTANGIBLE_ASSETS_LIST_ELEMENTS',
        computed_getter: 'get_intangible_assets_list_select',
        label: 'Нематеріальні активи',
        select_button_object: {
            name: 'INTANGIBLE_ASSETS_ELEMENTS',
            group: '',
            hierarchy: false,
            only_groups: false,
            title: 'Список нематеріальні активів',
            element_title: 'Нематеріальні активи',
            show: true
        },
    },
    'biological_assets': {
        computed_action: 'SELECT_BIOLOGICAL_ASSETS_LIST_ELEMENTS',
        computed_getter: 'get_biological_assets_list_select',
        label: 'Біологічні активи',
        select_button_object: {
            name: 'BIOLOGICAL_ASSETS_ELEMENTS',
            group: '',
            hierarchy: false,
            only_groups: false,
            title: 'Список біологічних активів',
            element_title: 'Біологічні активи',
            show: true
        },
    },
    'income_items': {
        computed_action: 'SELECT_INCOME_ITEMS_LIST_ELEMENTS',
        computed_getter: 'get_income_items_list_select',
        label: 'Статті доходу',
        select_button_object: {
            name: 'INCOME_ITEMS_ELEMENTS',
            group: '',
            hierarchy: false,
            only_groups: false,
            title: 'Список статей доходу',
            element_title: 'Стаття доходу',
            show: true
        },
    },
    'nomenclature_groups': {
        computed_action: 'SELECT_NOMENCLATURE_GROUPS_LIST_ELEMENTS',
        computed_getter: 'get_nomenclature_groups_list_select',
        label: 'Номенклатурні групи',
        select_button_object: {
            name: 'NOMENCLATURE_GROUPS_ELEMENTS',
            group: '',
            hierarchy: false,
            only_groups: false,
            title: 'Список номенклатурних груп',
            element_title: 'Номенклатурна група',
            show: true
        },
    },
    // 'unit': {
    //     computed_action: 'SELECT_UNIT_LIST_ELEMENTS',
    //     computed_getter: 'get_unit_list_select',
    //     label: 'Одиниця виміру',
    //     select_button_object: {
    //         name: 'UNIT_ELEMENTS',
    //         group: '',
    //         hierarchy: false,
    //         only_groups: false,
    //         title: 'Список одиниць виміру',
    //         element_title: 'Одиниця виміру',
    //         show: true
    //     },
    // },
    'unit_type': {
        computed_action: 'SELECT_UNIT_LIST_ELEMENTS',
        computed_getter: 'get_unit_list_select',
        label: 'Одиниця виміру',
        select_button_object: {
            name: 'UNIT_ELEMENTS',
            group: '',
            hierarchy: false,
            only_groups: false,
            title: 'Список одиниць виміру',
            element_title: 'Одиниця виміру',
            show: true
        },
    },
    'taxes': {
        computed_action: 'SELECT_TAXES_LIST_ELEMENTS',
        computed_getter: 'get_taxes_list_select',
        label: 'Податки/Внески',
        select_button_object: {
            name: 'TAXES_ELEMENTS',
            group: '',
            hierarchy: false,
            only_groups: false,
            title: 'Список податків/внесків',
            element_title: 'Податок/Внесок',
            show: true
        },
    },
}



const headers = {
    'WAREHOUSE_ELEMENTS': warehouse_list_elements_header,
    'CURRENCY_ELEMENTS': currency_list_elements_header,
    'HOME_ACCOUNTING_PAYMENTS_SETTINGS_ELEMENTS': house_accounting_payments_settings_list_elements_header,
    'HOME_ACCOUNTING_CHARGE_SETTINGS_ELEMENTS': house_accounting_charge_settings_list_elements_header,
    'AMORTISATION_CALC_WAY_ELEMENTS': amortisation_calc_way_list_elements_header,
    'CONTRACTOR_ELEMENTS': contractor_list_elements_header,
    'CONTRACTOR_CONTRACT_ELEMENTS': contractor_contract_list_elements_header,
    'POSITION_ELEMENTS': position_list_elements_header,
    'TAX_INSPECTION_ELEMENTS': tax_inspection_list_elements_header,
    'UNIT_ELEMENTS': unit_list_elements_header,
    'SUBDIVISION_ELEMENTS': subdivision_list_elements_header,
    'PERSONAL_ELEMENTS': personal_list_elements_header,
    'NATURAL_PERSON_ELEMENTS': natural_person_list_elements_header,
    'NATURAL_PERSON_FOR_DISPATCHER_ELEMENTS': natural_person_list_elements_header_,
    'NOMENCLATURE_ELEMENTS': nomenclature_list_elements_header,
    'DISPATCHER_PROBLEM_ELEMENTS': dispatcher_problem_list_elements_header,
    'CHART_OF_ACCOUNTS_ELEMENTS': chart_of_accounts_elements_header,
    'CASH_FLOW_ITEM_ELEMENTS': cash_flow_item_list_elements_header,
    'RELEASE_REASON_ELEMENTS': release_reason_list_elements_header,
    'BANK_ACCOUNT_ELEMENTS': bank_account_list_elements_header,
    'BIOLOGICAL_ASSETS_ELEMENTS': biological_assets_list_elements_header,
    'COST_ITEMS_ELEMENTS': cost_items_list_elements_header,
    'INTANGIBLE_ASSETS_ELEMENTS': intangible_assets_list_elements_header,
    'FIXED_ASSETS_ELEMENTS': fixed_assets_list_elements_header,
    'WORK_SCHEDULE_ELEMENTS': work_schedule_list_elements_header,
    'ACCRUAL_ELEMENTS': accrual_list_elements_header,
    'TIMESHEET_LETTER_ELEMENTS': timesheet_letter_elements_header,
    'INCOME_TYPE_PIX_ELEMENTS': income_type_pix_elements_header,
    'INCOME_ITEMS_ELEMENTS': income_items_elements_header,
    'NOMENCLATURE_GROUPS_ELEMENTS': nomenclature_groups_elements_header,
    'TAXES_ELEMENTS': taxes_list_elements_header,
    'MIN_AMOUNT_ELEMENTS': min_amount_list_elements_header,
    'INFLATION_INDEX_ELEMENTS': inflation_index_list_elements_header,
    'TAX_TARIFF_ELEMENTS': tax_tariff_list_elements_header,
    'CONTRACTOR_CONTACT_PERSON_ELEMENTS': contractor_contact_person_elements_header,
    'CONTRACTOR_RESPONSE_PERSON_ELEMENTS': contractor_response_person_elements_header,
    'CONTRACTOR_BANK_ACCOUNT_ELEMENTS': contractor_bank_account_elements_header,
    'DOCUMENT_BANK_INCOME': document_bank_income_header,
    'DOCUMENT_BANK_OUTCOME': document_bank_outcome_header,
    'DOCUMENT_PERSON_TIMESHEET': document_personal_timesheet_header,
    'DOCUMENT_WORK_TRANSFER': document_work_transfer_header,
    'DOCUMENT_EMPLOYMENT': document_employment_header,
    'DOCUMENT_CONTRACT_BY_CLN': document_contract_by_cln_header,
    'DOCUMENT_SOCIAL_PRIVILEGE': document_social_privilege_header,
    'DOCUMENT_SALARY_CHARGE': document_salary_charge_header,
    'DOCUMENT_SALARY_TO_PAY': document_salary_to_pay_header,
    'DOCUMENT_HOSPITAL_CHARGE': document_hospital_charge_header,
    'DOCUMENT_VOCATION_CHARGE': document_vocation_charge_header,
    'DOCUMENT_ACT_WORK_PERFORMED_OUTCOME': document_act_wok_performed_outcome,
    'DOCUMENT_ACT_WORK_PERFORMED_INCOME': document_act_wok_performed_income,
    'DOCUMENT_INVOICE_WAREHOUSE_INCOME': document_invoice_warehouse_income,
    'DOCUMENT_INVOICE_WAREHOUSE_OUTCOME': document_invoice_warehouse_outcome,
    'DOCUMENT_BILL_OUTCOME': document_bill_outcome,
    'DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME': document_warehouse_inventory_outcome,
    'DOCUMENT_ADVANCE_REPORT': document_advance_report,
    'DOCUMENT_CASH_ORDER_OUTCOME': document_cash_order_outcome_header,
    'DOCUMENT_CASH_ORDER_INCOME': document_cash_order_income_header,
    'DOCUMENT_COMMISSIONING': document_commissioning_header,
    'DOCUMENT_LOW_COST_COMMISSIONING': document_low_cost_item_commissioning_header,
    'DOCUMENT_ACCOUNTING_CLOSE_FINANCIAL_MONTH': document_close_financial_month_header,
    'DOCUMENT_ACCOUNTING_CLOSE_HOME_ACCOUNTING':document_close_home_accounting_header,
    'DOCUMENT_ENTERING_BALANCES': document_entering_balances_header,
    'DOCUMENT_MANUAL_OPERATION': document_manual_operation_header,
    'DOCUMENT_TAX_BILL_INCOME': document_tax_bill_income_header,
    'DOCUMENT_TAX_BILL_OUTCOME': document_tax_bill_outcome_header,
    'DOCUMENT_RELEASE_WORK': document_release_work_header
}

const getters = {
    'WAREHOUSE_ELEMENTS': 'get_warehouse_list',
    'CURRENCY_ELEMENTS': 'get_currency_list',
    'HOME_ACCOUNTING_PAYMENTS_SETTINGS_ELEMENTS': 'get_house_accounting_payments_settings_list',
    'HOME_ACCOUNTING_CHARGE_SETTINGS_ELEMENTS': 'get_house_accounting_charge_settings_list',
    'AMORTISATION_CALC_WAY_ELEMENTS': 'get_amortisation_calc_way_list',
    'CONTRACTOR_ELEMENTS': 'get_contractor_list',
    'CONTRACTOR_CONTRACT_ELEMENTS': 'get_contractor_contract_list',
    'POSITION_ELEMENTS': 'get_position_list',
    'TAX_INSPECTION_ELEMENTS': 'get_tax_inspection_list',
    'UNIT_ELEMENTS': 'get_unit_list',
    'SUBDIVISION_ELEMENTS': 'get_subdivision_list',
    'PERSONAL_ELEMENTS': 'get_personal_list',
    'NATURAL_PERSON_ELEMENTS': 'get_natural_person_list',
    'NOMENCLATURE_GROUP': 'get_nomenclature_groups',
    'NOMENCLATURE_ELEMENTS': 'get_nomenclature_list',
    'DISPATCHER_PROBLEM_GROUP': 'get_dispatcher_problem_groups',
    'DISPATCHER_PROBLEM_ELEMENTS': 'get_dispatcher_problem_list',
    'CHART_OF_ACCOUNTS_ELEMENTS': 'get_chart_of_accounts_list',
    'CASH_FLOW_ITEM_ELEMENTS': 'get_cash_flow_item_list',
    'RELEASE_REASON_ELEMENTS': 'get_release_reason_list',
    'BANK_ACCOUNT_ELEMENTS': 'get_bank_account_list',
    'BIOLOGICAL_ASSETS_ELEMENTS': 'get_biological_assets_list',
    'COST_ITEMS_GROUP': 'get_cost_items_groups',
    'COST_ITEMS_ELEMENTS': 'get_cost_items_list',
    'INTANGIBLE_ASSETS_ELEMENTS': 'get_intangible_assets_list',
    'FIXED_ASSETS_ELEMENTS': 'get_fixed_assets_list',
    'WORK_SCHEDULE_ELEMENTS': 'get_work_schedule_list',
    'ACCRUAL_ELEMENTS': 'get_accrual_list',
    'TIMESHEET_LETTER_ELEMENTS': 'get_timesheet_letter_list',
    'INCOME_TYPE_PIX_ELEMENTS': 'get_income_type_pix_list',
    'INCOME_ITEMS_ELEMENTS': 'get_income_items_list',
    'NOMENCLATURE_GROUPS_ELEMENTS': 'get_nomenclature_groups_list',
    'TAXES_ELEMENTS': 'get_taxes_list',
    'MIN_AMOUNT_ELEMENTS': 'get_min_amount_list',
    'INFLATION_INDEX_ELEMENTS': 'get_inflation_index_list',
    'TAX_TARIFF_ELEMENTS': 'get_tax_tariff_list',
    'CONTRACTOR_CONTACT_PERSON_ELEMENTS': 'get_contractor_contact_person_list',
    'CONTRACTOR_RESPONSE_PERSON_ELEMENTS': 'get_contractor_response_person_list',
    'CONTRACTOR_BANK_ACCOUNT_ELEMENTS': 'get_contractor_bank_account_list',
    'DOCUMENT_BANK_INCOME': 'get_document_bank_income_list',
    'DOCUMENT_BANK_OUTCOME': 'get_document_bank_outcome_list',
    'DOCUMENT_PERSON_TIMESHEET': 'get_document_personal_timesheet_list',
    'DOCUMENT_WORK_TRANSFER': 'get_document_work_transfer_list',
    'DOCUMENT_EMPLOYMENT': 'get_document_employment_list',
    'DOCUMENT_CONTRACT_BY_CLN': 'get_document_contract_by_cln_list',
    'DOCUMENT_SOCIAL_PRIVILEGE': 'get_document_social_privilege_list',
    'DOCUMENT_SALARY_CHARGE': 'get_document_salary_charge_list',
    'DOCUMENT_SALARY_TO_PAY': 'get_document_salary_to_pay_list',
    'DOCUMENT_HOSPITAL_CHARGE': 'get_document_hospital_charge_list',
    'DOCUMENT_VOCATION_CHARGE': 'get_document_vocation_charge_list',
    'DOCUMENT_ACT_WORK_PERFORMED_OUTCOME': 'get_document_act_work_performed_outcome_list',
    'DOCUMENT_ACT_WORK_PERFORMED_INCOME': 'get_document_act_work_performed_income_list',
    'DOCUMENT_INVOICE_WAREHOUSE_INCOME': 'get_document_invoice_warehouse_income_list',
    'DOCUMENT_INVOICE_WAREHOUSE_OUTCOME': 'get_document_invoice_warehouse_outcome_list',
    'DOCUMENT_BILL_OUTCOME': 'get_document_bill_outcome_list',
    'DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME': 'get_document_warehouse_inventory_outcome_list',
    'DOCUMENT_ADVANCE_REPORT': 'get_document_advance_report_list',
    'DOCUMENT_CASH_ORDER_OUTCOME': 'get_document_cash_order_outcome_list',
    'DOCUMENT_CASH_ORDER_INCOME': 'get_document_cash_order_income_list',
    'DOCUMENT_COMMISSIONING': 'get_document_commissioning_list',
    'DOCUMENT_LOW_COST_COMMISSIONING': 'get_document_low_cost_commissioning_list',
    'DOCUMENT_ACCOUNTING_CLOSE_FINANCIAL_MONTH': 'get_document_close_financial_month_list',
    'DOCUMENT_ACCOUNTING_CLOSE_HOME_ACCOUNTING': 'get_document_close_home_accounting_list',
    'DOCUMENT_ENTERING_BALANCES': 'get_document_entering_balance_list',
    'DOCUMENT_MANUAL_OPERATION': 'get_document_manual_operation_list',
    'DOCUMENT_TAX_BILL_INCOME': 'get_document_tax_bill_income_list',
    'DOCUMENT_TAX_BILL_OUTCOME': 'get_document_tax_bill_outcome_list',
    'DOCUMENT_RELEASE_WORK': 'get_document_release_work_list',
}

const create_on_the_basis = [
    {
        document: 'DOCUMENT_BANK_INCOME',
        items: [
            {
                component: 'DOCUMENT_TAX_BILL_OUTCOME',
                name: 'Вихідна податкова накладна'
            }
        ]
    },
    {
        document: 'DOCUMENT_CASH_ORDER_INCOME',
        items: [
            {
                component: 'DOCUMENT_TAX_BILL_OUTCOME',
                name: 'Вихідна податкова накладна'
            }
        ]
    },
    {
        document: 'DOCUMENT_BANK_OUTCOME',
        items: [
            {
                component: 'DOCUMENT_TAX_BILL_INCOME',
                name: 'Вхідна податкова накладна'
            }
        ]
    },
    {
        document: 'DOCUMENT_CASH_ORDER_OUTCOME',
        items: [
            {
                component: 'DOCUMENT_TAX_BILL_INCOME',
                name: 'Вхідна податкова накладна'
            }
        ]
    },
    {
        document: 'DOCUMENT_ACT_WORK_PERFORMED_INCOME',
        items: [
            {
                component: 'DOCUMENT_BANK_OUTCOME',
                name: 'Платіжне доручення (вихідне)'
            },
            {
                component: 'DOCUMENT_CASH_ORDER_OUTCOME',
                name: 'Видатковий касовий ордер'
            },
            {
                component: 'DOCUMENT_TAX_BILL_INCOME',
                name: 'Вхідна податкова накладна'
            }
        ]
    },
    {
        document: 'DOCUMENT_INVOICE_WAREHOUSE_INCOME',
        items: [
            {
                component: 'DOCUMENT_BANK_OUTCOME',
                name: 'Платіжне доручення (вихідне)'
            },
            {
                component: 'DOCUMENT_CASH_ORDER_OUTCOME',
                name: 'Видатковий касовий ордер'
            },
            {
                component: 'DOCUMENT_TAX_BILL_INCOME',
                name: 'Вхідна податкова накладна'
            }
        ]
    },
    {
        document: 'DOCUMENT_ACT_WORK_PERFORMED_OUTCOME',
        items: [
            {
                component: 'DOCUMENT_BANK_INCOME',
                name: 'Платіжне доручення (вхідне)'
            },
            {
                component: 'DOCUMENT_CASH_ORDER_INCOME',
                name: 'Прибутковий касовий ордер'
            },
            {
                component: 'DOCUMENT_TAX_BILL_OUTCOME',
                name: 'Вихідна податкова накладна'
            }
        ]
    },
    {
        document: 'DOCUMENT_INVOICE_WAREHOUSE_OUTCOME',
        items: [
            {
                component: 'DOCUMENT_BANK_INCOME',
                name: 'Платіжне доручення (вхідне)'
            },
            {
                component: 'DOCUMENT_CASH_ORDER_INCOME',
                name: 'Прибутковий касовий ордер'
            },
            {
                component: 'DOCUMENT_TAX_BILL_OUTCOME',
                name: 'Вихідна податкова накладна'
            }
        ]
    },
    {
        document: 'DOCUMENT_BILL_OUTCOME',
        items: [
            {
                component: 'DOCUMENT_BANK_INCOME',
                name: 'Платіжне доручення (вхідне)'
            },
            {
                component: 'DOCUMENT_CASH_ORDER_INCOME',
                name: 'Прибутковий касовий ордер'
            },
            {
                component: 'DOCUMENT_TAX_BILL_OUTCOME',
                name: 'Вихідна податкова накладна'
            },
            {
                component: 'DOCUMENT_ACT_WORK_PERFORMED_OUTCOME',
                name: 'Акт виконаних робіт/наданих послуг'
            }
        ]
    },
    {
        document: 'DOCUMENT_SALARY_CHARGE',
        items: [
            {
                component: 'DOCUMENT_SALARY_TO_PAY',
                name: 'Зарплата до виплати'
            },
        ]
    },
]

const actions = {
    'WAREHOUSE_ELEMENTS': GET_WAREHOUSE_LIST_ELEMENTS,
    'CURRENCY_ELEMENTS': GET_CURRENCY_LIST_ELEMENTS,
    'HOME_ACCOUNTING_PAYMENTS_SETTINGS_ELEMENTS': GET_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS,
    'HOME_ACCOUNTING_CHARGE_SETTINGS_ELEMENTS': GET_HOUSE_ACCOUNTING_CHARGE_SETTINGS_LIST_ELEMENTS,
    'AMORTISATION_CALC_WAY_ELEMENTS': GET_AMORTISATION_CALC_WAY_LIST_ELEMENTS,
    'CONTRACTOR_ELEMENTS': GET_CONTRACTOR_LIST_ELEMENTS,
    'CONTRACTOR_CONTRACT_ELEMENTS': GET_CONTRACTOR_CONTRACT_LIST_ELEMENTS,
    'POSITION_ELEMENTS': GET_POSITION_LIST_ELEMENTS,
    'TAX_INSPECTION_ELEMENTS': GET_TAX_INSPECTION_LIST_ELEMENTS,
    'UNIT_ELEMENTS': GET_UNIT_LIST_ELEMENTS,
    'SUBDIVISION_ELEMENTS': GET_SUBDIVISION_LIST_ELEMENTS,
    'PERSONAL_ELEMENTS': GET_PERSONAL_LIST_ELEMENTS,
    'NATURAL_PERSON_ELEMENTS': GET_NATURAL_PERSON_LIST_ELEMENTS,
    'NOMENCLATURE_GROUP': GET_NOMENCLATURE_LIST_GROUP,
    'NOMENCLATURE_ELEMENTS': GET_NOMENCLATURE_LIST_ELEMENTS,
    'DISPATCHER_PROBLEM_GROUP': GET_DISPATCHER_PROBLEM_LIST_GROUP,
    'DISPATCHER_PROBLEM_ELEMENTS': GET_DISPATCHER_PROBLEM_LIST_ELEMENTS,
    'CHART_OF_ACCOUNTS_ELEMENTS': GET_CHART_OF_ACCOUNTS_LIST_ELEMENTS,
    'CASH_FLOW_ITEM_ELEMENTS': GET_CASH_FLOW_ITEM_LIST_ELEMENTS,
    'RELEASE_REASON_ELEMENTS': GET_RELEASE_REASON_LIST_ELEMENTS,
    'BIOLOGICAL_ASSETS_ELEMENTS': GET_BIOLOGICAL_ASSETS_LIST_ELEMENTS,
    'COST_ITEMS_GROUP': GET_COST_ITEMS_LIST_GROUP,
    'COST_ITEMS_ELEMENTS': GET_COST_ITEMS_LIST_ELEMENTS,
    'INTANGIBLE_ASSETS_ELEMENTS': GET_INTANGIBLE_ASSETS_LIST_ELEMENTS,
    'FIXED_ASSETS_ELEMENTS': GET_FIXED_ASSETS_LIST_ELEMENTS,
    'BANK_ACCOUNT_ELEMENTS': GET_BANK_ACCOUNT_LIST_ELEMENTS,
    'WORK_SCHEDULE_ELEMENTS': GET_WORK_SCHEDULE_LIST_ELEMENTS,
    'ACCRUAL_ELEMENTS': GET_ACCRUAL_LIST_ELEMENTS,
    'TIMESHEET_LETTER_ELEMENTS': GET_TIMESHEET_LETTER_ELEMENTS,
    'INCOME_TYPE_PIX_ELEMENTS': GET_INCOME_TYPE_PIX_ELEMENTS,
    'INCOME_ITEMS_ELEMENTS': GET_INCOME_ITEMS_LIST_ELEMENTS,
    'NOMENCLATURE_GROUPS_ELEMENTS': GET_NOMENCLATURE_GROUPS_LIST_ELEMENTS,
    'TAXES_ELEMENTS': GET_TAXES_ELEMENTS,
    'MIN_AMOUNT_ELEMENTS': GET_MIN_AMOUNT_ELEMENTS,
    'INFLATION_INDEX_ELEMENTS': GET_INFLATION_INDEX_ELEMENTS,
    'TAX_TARIFF_ELEMENTS': GET_TAX_TARIFF_ELEMENTS,
    'CONTRACTOR_CONTACT_PERSON_ELEMENTS': GET_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS,
    'CONTRACTOR_RESPONSE_PERSON_ELEMENTS': GET_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS,
    'CONTRACTOR_BANK_ACCOUNT_ELEMENTS': GET_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS,
    'DOCUMENT_BANK_INCOME': GET_DOCUMENT_BANK_INCOME_ELEMENTS,
    'DOCUMENT_BANK_OUTCOME': GET_DOCUMENT_BANK_OUTCOME_ELEMENTS,
    'DOCUMENT_PERSON_TIMESHEET': GET_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS,
    'DOCUMENT_WORK_TRANSFER': GET_DOCUMENT_WORK_TRANSFER_ELEMENTS,
    'DOCUMENT_EMPLOYMENT': GET_DOCUMENT_EMPLOYMENT_ELEMENTS,
    'DOCUMENT_CONTRACT_BY_CLN': GET_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS,
    'DOCUMENT_SOCIAL_PRIVILEGE': GET_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS,
    'DOCUMENT_SALARY_CHARGE': GET_DOCUMENT_SALARY_CHARGE_ELEMENTS,
    'DOCUMENT_SALARY_TO_PAY': GET_DOCUMENT_SALARY_TO_PAY_ELEMENTS,
    'DOCUMENT_HOSPITAL_CHARGE': GET_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS,
    'DOCUMENT_VOCATION_CHARGE': GET_DOCUMENT_VOCATION_CHARGE_ELEMENTS,
    'DOCUMENT_ACT_WORK_PERFORMED_OUTCOME': GET_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS,
    'DOCUMENT_ACT_WORK_PERFORMED_INCOME': GET_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS,
    'DOCUMENT_INVOICE_WAREHOUSE_INCOME': GET_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS,
    'DOCUMENT_INVOICE_WAREHOUSE_OUTCOME': GET_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS,
    'DOCUMENT_BILL_OUTCOME': GET_DOCUMENT_BILL_OUTCOME_ELEMENTS,
    'DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME': GET_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS,
    'DOCUMENT_ADVANCE_REPORT': GET_DOCUMENT_ADVANCE_REPORT_ELEMENTS,
    'DOCUMENT_CASH_ORDER_OUTCOME': GET_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS,
    'DOCUMENT_CASH_ORDER_INCOME': GET_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS,
    'DOCUMENT_COMMISSIONING': GET_DOCUMENT_COMMISSIONING_ELEMENTS,
    'DOCUMENT_LOW_COST_COMMISSIONING': GET_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS,
    'DOCUMENT_ACCOUNTING_CLOSE_FINANCIAL_MONTH': GET_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS,
    'DOCUMENT_ACCOUNTING_CLOSE_HOME_ACCOUNTING': GET_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS,
    'DOCUMENT_ENTERING_BALANCES': GET_DOCUMENT_ENTERING_BALANCES_ELEMENTS,
    'DOCUMENT_MANUAL_OPERATION': GET_DOCUMENT_MANUAL_OPERATION_ELEMENTS,
    'DOCUMENT_TAX_BILL_INCOME': GET_DOCUMENT_TAX_BILL_INCOME_ELEMENTS,
    'DOCUMENT_TAX_BILL_OUTCOME': GET_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS,
    'DOCUMENT_RELEASE_WORK': GET_DOCUMENT_RELEASE_WORK_ELEMENTS
}

const directories = [
    {
        group: 'selling|purchase',
        icon: 'mdi-file-document-outline',
        list_name: 'NOMENCLATURE_ELEMENTS',
        list_group_name: 'NOMENCLATURE_GROUP',
        hierarchy: true,
        list_title: 'Список номенклатури',
        element_title: 'Номенклатура',
        documents: false
    },
    {
        group: 'fixed_assets',
        icon: 'mdi-file-document-outline',
        list_name: 'AMORTISATION_CALC_WAY_ELEMENTS',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список способів нарахування амортизації',
        element_title: 'Спосіб нарахування амортизації',
        documents: false
    },
    {
        group: 'selling|purchase',
        icon: 'mdi-file-document-outline',
        list_name: 'NOMENCLATURE_GROUPS_ELEMENTS',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список номенклатурних груп',
        element_title: 'Номенклатурна група',
        documents: false
    },
    {
        group: 'selling|purchase',
        icon: 'mdi-warehouse',
        list_name: 'WAREHOUSE_ELEMENTS',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список складів',
        element_title: 'Склад',
        documents: false
    },
    {
        group: 'bank|cash',
        icon: 'mdi-currency-eur',
        list_name: 'CURRENCY_ELEMENTS',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список валют',
        element_title: 'Валюта',
        documents: false
    },
    {
        group: 'selling|purchase',
        icon: 'mdi-account-box-multiple',
        list_name: 'CONTRACTOR_ELEMENTS',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список контрагентів',
        element_title: 'Контрагент',
        documents: false
    },
    {
        group: 'staff',
        icon: 'mdi-redhat',
        list_name: 'POSITION_ELEMENTS',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список посад',
        element_title: 'Посада',
        documents: false
    },
    {
        group: 'staff|salary',
        icon: 'mdi-account-group',
        list_name: 'NATURAL_PERSON_ELEMENTS',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список фізичних осіб',
        element_title: 'Фізична особа',
        documents: false
    },
    {
        group: 'staff',
        icon: 'mdi-account-group',
        list_name: 'RELEASE_REASON_ELEMENTS',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список підстав для звільнення',
        element_title: 'Підстава для звільнення',
        documents: false
    },
    {
        group: 'staff',
        icon: 'mdi-account-switch',
        list_name: 'SUBDIVISION_ELEMENTS',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список підрозділів',
        element_title: 'Підрозділ',
        documents: false
    },
    {
        group: 'selling|purchase',
        icon: 'mdi-ruler',
        list_name: 'UNIT_ELEMENTS',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список одиниць виміру',
        element_title: 'Одиниця виміру',
        documents: false
    },
    {
        group: 'staff|salary',
        icon: 'mdi-account-tie',
        list_name: 'PERSONAL_ELEMENTS',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список працівників',
        element_title: 'Працівник',
        documents: false
    },
    {
        group: 'staff',
        icon: 'mdi-calendar-month',
        list_name: '',
        list_group_name: '',
        hierarchy: false,
        list_title: '',
        element_title: 'Виробничий календар',
        documents: false,
        route: '/calendar',
    },
    {
        group: 'staff',
        icon: 'mdi-clock-outline',
        list_name: 'WORK_SCHEDULE_ELEMENTS',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список графіків роботи',
        element_title: 'Графік роботи',
        documents: false,
        full_screen: true
    },
    {
        group: 'staff',
        icon: 'mdi-redhat',
        list_name: 'TIMESHEET_LETTER_ELEMENTS',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список позначок табеля роб.часу',
        element_title: 'Позначка табеля роб.часу',
        documents: false
    },
    {
        group: 'staff',
        icon: 'mdi-redhat',
        list_name: 'INCOME_TYPE_PIX_ELEMENTS',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список кодів доходу ПДФО',
        element_title: 'Код доходу ПДФО',
        documents: false
    },
    {
        group: 'staff|salary|bank|cash|selling|purchase',
        icon: 'mdi-text-box-multiple-outline',
        list_name: 'CHART_OF_ACCOUNTS_ELEMENTS',
        list_group_name: '',
        hierarchy: false,
        list_title: 'План рахунків бух.обліку',
        element_title: 'Рахунок бух.обліку',
        documents: false
    },
    {
        group: 'bank|cash',
        icon: 'mdi-slope-uphill',
        list_name: 'CASH_FLOW_ITEM_ELEMENTS',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список статтей руху грош.коштів',
        element_title: 'Стаття руху грошових коштів',
        documents: false
    },
    {
        group: 'bank|cash',
        icon: 'mdi-bank-transfer',
        list_name: 'BANK_ACCOUNT_ELEMENTS',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список банківських рахунків орг.',
        element_title: 'Банківський рахунок орг.',
        documents: false
    },
    {
        group: 'fixed_assets',
        icon: 'mdi-truck-outline',
        list_name: 'FIXED_ASSETS_ELEMENTS',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список основних засіб',
        element_title: 'Основний засіб',
        documents: false
    },
    {
        group: 'fixed_assets',
        icon: 'mdi-library-shelves',
        list_name: 'INTANGIBLE_ASSETS_ELEMENTS',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список нематеріальних активів',
        element_title: 'Нематеріальний актив',
        documents: false
    },
    {
        group: 'fixed_assets',
        icon: 'mdi-flower-outline',
        list_name: 'BIOLOGICAL_ASSETS_ELEMENTS',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список біологічних активів',
        element_title: 'Біологічний актив',
        documents: false
    },
    {
        group: 'salary',
        icon: 'mdi-flower-outline',
        list_name: 'ACCRUAL_ELEMENTS',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список нарахувань',
        element_title: 'Нарахування',
        documents: false
    },
    {
        group: 'home_accounting',
        icon: 'mdi-flower-outline',
        list_name: 'HOME_ACCOUNTING_PAYMENTS_SETTINGS_ELEMENTS',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список налаштувань для створення платіжок (поквартирний облік)',
        element_title: 'Налаштування для створення платіжок (поквартирний облік)',
        documents: false
    },
    {
        group: 'home_accounting',
        icon: 'mdi-flower-outline',
        list_name: 'HOME_ACCOUNTING_CHARGE_SETTINGS_ELEMENTS',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список налаштувань для створення актів (поквартирний облік)',
        element_title: 'Налаштування для створення актів (поквартирний облік)',
        documents: false
    },
    {
        group: 'admin',
        icon: 'mdi-cog-outline',
        list_name: '',
        list_group_name: '',
        hierarchy: false,
        list_title: '',
        element_title: 'Налаштування',
        documents: false,
        route: '/accounting-general-settings',
    },
    {
        group: 'admin',
        icon: 'mdi-cog-outline',
        list_name: '',
        list_group_name: '',
        hierarchy: false,
        list_title: '',
        element_title: 'Профіль організації',
        documents: false,
        route: '/organization',
    },
    {
        group: 'admin',
        icon: 'mdi-flower-outline',
        list_name: 'TAXES_ELEMENTS',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список податків та внесків',
        element_title: 'Податки та внески',
        documents: false
    },
    {
        group: 'admin',
        icon: 'mdi-flower-outline',
        list_name: 'MIN_AMOUNT_ELEMENTS',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Мінімальна зарп. та прож.мінімум',
        element_title: 'Мінімальна зарп. та прож.мінімум',
        documents: false
    },
    {
        group: 'admin',
        icon: 'mdi-flower-outline',
        list_name: 'INFLATION_INDEX_ELEMENTS',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список індексів інфляції',
        element_title: 'Індекс інфляції',
        documents: false
    },
    {
        group: 'admin',
        icon: 'mdi-flower-outline',
        list_name: 'TAX_TARIFF_ELEMENTS',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Ставки податків',
        element_title: 'Ставка податку',
        documents: false
    },
    {
        group: 'admin',
        icon: 'mdi-flower-outline',
        list_name: 'CONTRACTOR_CONTACT_PERSON_ELEMENTS',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список контактних осбі контрагентів',
        element_title: 'Контактна особа контрагента',
        documents: false
    },
    {
        group: 'admin',
        icon: 'mdi-flower-outline',
        list_name: 'CONTRACTOR_RESPONSE_PERSON_ELEMENTS',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список відповідальних осіб контрагентів',
        element_title: 'Відповідальна особа контрагента',
        documents: false
    },
    {
        group: 'admin',
        icon: 'mdi-flower-outline',
        list_name: 'CONTRACTOR_BANK_ACCOUNT_ELEMENTS',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список банківських рахунків контрагентів',
        element_title: 'Банківський рахунок контрагента',
        documents: false
    },
    {
        group: 'admin',
        icon: 'mdi-flower-outline',
        list_name: 'TAX_INSPECTION_ELEMENTS',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список податкових інспекцій',
        element_title: 'Податкова інспекція',
        documents: false
    },
    {
        group: 'admin',
        icon: 'mdi-flower-outline',
        list_name: 'COST_ITEMS_ELEMENTS',
        list_group_name: 'COST_ITEMS_GROUP',
        hierarchy: true,
        list_title: 'Список статей витрат',
        element_title: 'Статті витрат',
        documents: false
    },

]

const getDocumentsObject = () => {
    const obj = {}
    documents.forEach(doc => {
        obj[doc.list_name] = Object.assign({}, doc)
        obj[doc.list_name].action = actions[doc.list_name]
        obj[doc.list_name].action_select = actions[doc.list_name].replace('GET_', 'SELECT_')
        obj[doc.list_name].getter = getters[doc.list_name]
        obj[doc.list_name].getter_select = getters[doc.list_name].replace('_list', '_list_select')
    })
    return obj
}

const documents = [
    {
        group: 'fixed_assets',
        icon: 'mdi-text-box-outline',
        list_name: 'DOCUMENT_COMMISSIONING',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список введення в експлуатацію ОС',
        element_title: 'Введення в експлуатацію ОС',
        documents: true,
        full_screen: false
    },
    {
        group: 'fixed_assets',
        icon: 'mdi-text-box-outline',
        list_name: 'DOCUMENT_LOW_COST_COMMISSIONING',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список передач в експлуатацію МШП',
        element_title: 'Передача в експлуатацію МШП',
        documents: true,
        full_screen: true
    },
    {
        group: 'bank',
        icon: 'mdi-text-box-outline',
        list_name: 'DOCUMENT_BANK_INCOME',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список платіжних доручень (вхідних)',
        element_title: 'Платіжне доручення (вхідне)',
        documents: true
    },
    {
        group: 'bank',
        icon: 'mdi-text-box-outline',
        list_name: 'DOCUMENT_BANK_OUTCOME',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список платіжних доручень (вихідних)',
        element_title: 'Платіжне доручення (вихідне)',
        documents: true
    },
    {
        group: 'cash',
        icon: 'mdi-text-box-outline',
        list_name: 'DOCUMENT_CASH_ORDER_INCOME',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список прибуткових касових ордерів',
        element_title: 'Прибутковий касовий ордер',
        documents: true
    },
    {
        group: 'cash',
        icon: 'mdi-text-box-outline',
        list_name: 'DOCUMENT_CASH_ORDER_OUTCOME',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список видаткових касових ордерів',
        element_title: 'Видатковий касовий ордер',
        documents: true
    },
    {
        group: 'salary',
        icon: 'mdi-text-box-outline',
        list_name: 'DOCUMENT_PERSON_TIMESHEET',
        list_group_name: '',
        hide_accounting: true,
        hierarchy: false,
        list_title: 'Список табелів обліку робочого часу',
        element_title: 'Табель обліку робочого часу',
        documents: true,
        full_screen: true
    },
    {
        group: 'salary|staff',
        icon: 'mdi-text-box-outline',
        list_name: 'DOCUMENT_EMPLOYMENT',
        list_group_name: '',
        hide_accounting: true,
        hierarchy: false,
        list_title: 'Список документів пройому на роботу',
        element_title: 'Прийом на роботу',
        documents: true,
    },
    {
        group: 'salary|staff',
        icon: 'mdi-text-box-outline',
        list_name: 'DOCUMENT_WORK_TRANSFER',
        list_group_name: '',
        hide_accounting: true,
        hierarchy: false,
        list_title: 'Список кадрових переміщень',
        element_title: 'Кадрове переміщення',
        documents: true,
    },
    {
        group: 'staff',
        icon: 'mdi-text-box-outline',
        list_name: 'DOCUMENT_RELEASE_WORK',
        list_group_name: '',
        hide_accounting: true,
        hierarchy: false,
        list_title: 'Список звільнень працівників',
        element_title: 'Звільнення працівника',
        documents: true,
    },
    {
        group: 'salary',
        icon: 'mdi-text-box-outline',
        list_name: 'DOCUMENT_CONTRACT_BY_CLN',
        list_group_name: '',
        hierarchy: false,
        hide_accounting: true,
        list_title: 'Список договорів по ЦПХ',
        element_title: 'Договір ЦПХ',
        documents: true,
    },
    {
        group: 'salary',
        icon: 'mdi-text-box-outline',
        list_name: 'DOCUMENT_SOCIAL_PRIVILEGE',
        list_group_name: '',
        hierarchy: false,
        hide_accounting: true,
        list_title: 'Список заяв на застосування пільги ПДФО',
        element_title: 'Заява на застосування пільги ПДФО',
        documents: true,
    },
    {
        group: 'salary',
        icon: 'mdi-text-box-outline',
        list_name: 'DOCUMENT_SALARY_CHARGE',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список нарахування заробітної плати',
        element_title: 'Нарахування заробітної плати',
        documents: true,
        full_screen: true
    },
    {
        group: 'salary',
        icon: 'mdi-text-box-outline',
        list_name: 'DOCUMENT_HOSPITAL_CHARGE',
        list_group_name: '',
        hierarchy: false,
        hide_accounting: true,
        list_title: 'Список лікарняних листів',
        element_title: 'Лікарняний лист',
        documents: true,
        full_screen: true
    },
    {
        group: 'salary',
        icon: 'mdi-text-box-outline',
        list_name: 'DOCUMENT_VOCATION_CHARGE',
        list_group_name: '',
        hierarchy: false,
        hide_accounting: true,
        list_title: 'Список відпускних',
        element_title: 'Відпускні',
        documents: true,
        full_screen: true
    },
    {
        group: 'salary',
        icon: 'mdi-text-box-outline',
        list_name: 'DOCUMENT_SALARY_TO_PAY',
        list_group_name: '',
        hierarchy: false,
        hide_accounting: true,
        list_title: 'Відомості на виплату заробітної плати',
        element_title: 'Виплата заробітної плати',
        documents: true,
        full_screen: true
    },
    {
        group: 'selling',
        icon: 'mdi-text-box-outline',
        list_name: 'DOCUMENT_ACT_WORK_PERFORMED_OUTCOME',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список актів виконаних робіт/наданих послуг',
        element_title: 'Акт виконаних робіт/наданих послуг',
        documents: true,
        full_screen: true,
        icon_color: 'teal'
    },
    {
        group: 'purchase',
        icon: 'mdi-text-box-outline',
        list_name: 'DOCUMENT_ACT_WORK_PERFORMED_INCOME',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список актів виконаних робіт/наданих послуг (вхідний)',
        element_title: 'Акт виконаних робіт/наданих послуг (вхідний)',
        documents: true,
        full_screen: true
    },
    {
        group: 'purchase',
        icon: 'mdi-text-box-outline',
        list_name: 'DOCUMENT_INVOICE_WAREHOUSE_INCOME',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список прихідних накладних (покупка товарів)',
        element_title: 'Прихідна накладна (покупка товарів)',
        documents: true,
        full_screen: true
    },
    {
        group: 'purchase',
        icon: 'mdi-text-box-outline',
        list_name: 'DOCUMENT_TAX_BILL_INCOME',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список вхідних податкових накладних',
        element_title: 'Вхідна податкова накладна',
        documents: true,
        full_screen: true
    },
    {
        group: 'selling',
        icon: 'mdi-text-box-outline',
        list_name: 'DOCUMENT_INVOICE_WAREHOUSE_OUTCOME',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список розхідних накладних (продаж товарів)',
        element_title: 'Розхідна накладна (продаж товарів)',
        documents: true,
        full_screen: true
    },
    {
        group: 'selling',
        icon: 'mdi-text-box-outline',
        list_name: 'DOCUMENT_BILL_OUTCOME',
        list_group_name: '',
        hierarchy: false,
        hide_accounting: true,
        list_title: 'Список рахунків за виконані роботи/надані послуги',
        element_title: 'Рахунок за виконані роботи/надані послуги',
        documents: true,
        full_screen: true,
        icon_color: 'secondary lighten-1'
    },
    {
        group: 'selling',
        icon: 'mdi-text-box-outline',
        list_name: 'DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список актів списання товарів',
        element_title: 'Акт списання товарів',
        documents: true,
        full_screen: true
    },
    {
        group: 'selling',
        icon: 'mdi-text-box-outline',
        list_name: 'DOCUMENT_TAX_BILL_OUTCOME',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список вихідних податкових накладних',
        element_title: 'Вихідна податкова накладна',
        documents: true,
        full_screen: true
    },
    {
        group: 'cash|bank',
        icon: 'mdi-text-box-outline',
        list_name: 'DOCUMENT_ADVANCE_REPORT',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список авансових звітів',
        element_title: 'Авансовий звіт',
        documents: true,
        full_screen: true
    },
    {
        group: 'home_accounting',
        icon: 'mdi-text-box-outline',
        list_name: 'DOCUMENT_ACCOUNTING_CLOSE_HOME_ACCOUNTING',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список документів закриття місяця (поквартиний облік)',
        element_title: 'Закриття місяця (поквартиний облік)',
        documents: true,
        full_screen: false
    },
]

const reports = [
    {
        group: 'bank|cash|selling|purchase|fixed_assets',
        component_name: 'accounting_main_report',
        report_title: 'Оборотно-сальдова відомість',
        icon: 'mdi-chart-donut-variant',
    },
    {
        group: 'bank|cash|selling|purchase|fixed_assets',
        component_name: 'accounting_main_report_by_code',
        report_title: 'Оборотно-сальдова відомість по рахунку',
        icon: 'mdi-chart-donut-variant',
    },
    {
        group: 'bank|cash|selling|purchase|fixed_assets',
        component_name: 'accounting_card_report_by_code',
        report_title: 'Картка рахунку',
        icon: 'mdi-chart-donut-variant',
    },
    {
        group: 'salary',
        component_name: 'salary_main_report',
        report_title: 'Розрахункова відомість',
        icon: 'mdi-chart-donut-variant',
    },
    {
        group: 'salary',
        component_name: 'salary_contribution_and_taxes',
        report_title: 'Відомість внесків та податків',
        icon: 'mdi-chart-donut-variant',
    },
    {
        group: 'salary',
        component_name: 'salary_total_contribution_and_taxes',
        report_title: 'Підсумкова відомість внесків та податків',
        icon: 'mdi-chart-donut-variant',
    },
    {
        group: 'salary',
        component_name: 'salary_account_list',
        report_title: 'Розрахункові листки',
        icon: 'mdi-chart-donut-variant',
        color: 'error lighten-1'
    },
    {
        group: 'bank|cash|selling|purchase|fixed_assets|salary',
        component_name: '',
        report_title: 'Регламентовані звіти',
        icon: 'mdi-chart-box-outline',
        color: 'secondary lighten-1',
        route: '/accounting-regulatory-reports'
    },
]

const operations = [
    {
        group: 'bank|cash|selling|purchase|fixed_assets',
        icon: 'mdi-text-box-outline',
        list_name: 'DOCUMENT_ACCOUNTING_CLOSE_FINANCIAL_MONTH',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список закриття місяця',
        element_title: 'Закриття місяця',
        documents: true
    },
    {
        group: 'bank|cash|selling|purchase|fixed_assets',
        icon: 'mdi-text-box-outline',
        list_name: 'DOCUMENT_ENTERING_BALANCES',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список внесених залишків',
        element_title: 'Внесення залишків',
        documents: true,
        full_screen: true
    },
    {
        group: 'bank|cash|selling|purchase|fixed_assets',
        icon: 'mdi-text-box-outline',
        list_name: 'DOCUMENT_MANUAL_OPERATION',
        list_group_name: '',
        hierarchy: false,
        list_title: 'Список операцій, внесених вручну',
        element_title: 'Операції, внесені вручну',
        documents: true,
        full_screen: true
    },
]

const accounting_pages = [
    {name: 'bank', title: 'Банківські операції'},
    {name: 'cash', title: 'Касові операції'},
    {name: 'purchase', title: 'Операції по закупівлі'},
    {name: 'fixed_assets', title: 'Операції по основних засобах'},
    {name: 'selling', title: 'Операції по продажу'},
    {name: 'staff', title: 'Кадрові операції'},
    {name: 'salary', title: 'Операції по заробітній платі'},
    {name: 'home_accounting', title: 'Поквартирний облік'},
    {name: 'admin', title: 'Адміністрування'}
]

const accounting_links = [
    {
        title: 'Банківські операції',
        short_title: 'Банк',
        icon: 'mdi-bank',
        route: {name: 'AccountingDesktopView', params: {page_name: 'bank'}}
    },
    {
        title: 'Касові операції',
        short_title: 'Каса',
        icon: 'mdi-cash-register',
        route: {name: 'AccountingDesktopView', params: {page_name: 'cash'}}
    },
    {
        title: 'Операції по закупівлі',
        short_title: 'Закупівля',
        icon: 'mdi-cart-arrow-down',
        route: {name: 'AccountingDesktopView', params: {page_name: 'purchase'}}
    },
    {
        title: 'Операції по продажу',
        short_title: 'Продаж',
        icon: 'mdi-cart-arrow-up',
        route: {name: 'AccountingDesktopView', params: {page_name: 'selling'}}
    },
    {
        title: 'Операції по основних засобах',
        short_title: 'Основні засоби',
        icon: 'mdi-truck',
        route: {name: 'AccountingDesktopView', params: {page_name: 'fixed_assets'}}
    },
    {
        title: 'Кадрові операції',
        short_title: 'Кадри',
        icon: 'mdi-account-supervisor',
        route: {name: 'AccountingDesktopView', params: {page_name: 'staff'}}
    },
    {
        title: 'Операції по заробітній платі',
        short_title: 'Зарплата',
        icon: 'mdi-account-tie',
        route: {name: 'AccountingDesktopView', params: {page_name: 'salary'}}
    },
    {
        title: 'Поквартирні операції',
        short_title: 'Поквартирний облік',
        icon: 'mdi-home-group',
        route: {name: 'AccountingDesktopView', params: {page_name: 'home_accounting'}}
    },
    {
        title: 'Адміністрування',
        short_title: 'Адміністрування',
        icon: 'mdi-newspaper-variant',
        route: {name: 'AccountingDesktopView', params: {page_name: 'admin'}}
    },
]

const getDocumentObject = (document_type) => {
    const doc = documents.find(i => i.list_name === document_type)
    return {
        list_name: doc.list_name,
        document: doc.documents,
        list_title: doc.list_title,
        full_screen: doc.full_screen
    }
}

function translateNumberGroup(group, group_idx) {
    const numbers = [
        {simple: 'нуль', special_1: 'нуль'},
        {simple: 'один', special_1: 'одна'},
        {simple: 'два', special_1: 'дві'},
        {simple: 'три', special_1: 'три'},
        {simple: 'чотири', special_1: 'чотири'},
        {simple: 'п’ять', special_1: 'п’ять'},
        {simple: 'шість', special_1: 'шість'},
        {simple: 'сім', special_1: 'сім'},
        {simple: 'вісім', special_1: 'вісім'},
        {simple: 'дев’ять', special_1: 'дев’ять'},
        {simple: 'десять', special_1: 'десять'},
        {simple: 'одинадцять', special_1: 'одинадцять'},
        {simple: 'дванадцять', special_1: 'дванадцять'},
        {simple: 'тринадцять', special_1: 'тринадцять'},
        {simple: 'чотирнадцять', special_1: 'чотирнадцять'},
        {simple: 'п’ятнадцять', special_1: 'п’ятнадцять'},
        {simple: 'шістнадцять', special_1: 'шістнадцять'},
        {simple: 'сімнадцять', special_1: 'сімнадцять'},
        {simple: 'вісімнадцять', special_1: 'вісімнадцять'},
        {simple: 'дев’ятнадцять', special_1: 'дев’ятнадцять'},
    ]

    const decimals = [
        'Нуль',
        'Десять',
        'Двадцять',
        'Тридцять',
        'Сорок',
        'П’ятдесят',
        'Шістдесят',
        'Сімдесят',
        'Вісімдесят',
        'Дев’яносто',
    ]

    const hundreds = [
        'Нуль',
        'Сто',
        'Двісті',
        'Триста',
        'Чотириста',
        'П’ятсот',
        'Шістсот',
        'Сімсот',
        'Вісімсот',
        'Дев’ятсот',
    ]

    const group_names = [
        {
            names: [],
        },
        {
            names: [
                'тисяч',
                'тисяча',
                'тисячі',
                'тисячі',
                'тисячі',
                'тисяч',
                'тисяч',
                'тисяч',
                'тисяч',
                'тисяч',
                'тисяч',
                'тисяч',
                'тисяч',
                'тисяч',
                'тисяч',
                'тисяч',
                'тисяч',
                'тисяч',
                'тисяч',
                'тисяч',
            ]
        },
        {
            names: [
                'мільйонів',
                'мільйон',
                'мільйони',
                'мільйони',
                'мільйони',
                'мільйонів',
                'мільйонів',
                'мільйонів',
                'мільйонів',
                'мільйонів',
                'мільйонів',
                'мільйонів',
                'мільйонів',
                'мільйонів',
                'мільйонів',
                'мільйонів',
                'мільйонів',
                'мільйонів',
                'мільйонів',
                'мільйонів',
                'мільйонів',
            ]
        },
        {
            names: [
                'мільярдів',
                'мільярд',
                'мільярди',
                'мільярди',
                'мільярди',
                'мільярдів',
                'мільярдів',
                'мільярдів',
                'мільярдів',
                'мільярдів',
                'мільярдів',
                'мільярдів',
                'мільярдів',
                'мільярдів',
                'мільярдів',
                'мільярдів',
                'мільярдів',
                'мільярдів',
                'мільярдів',
            ]
        },
    ]

    const text = []

    const l1 = +(group[0] || '')
    const l2 = +(group[1] || '')
    const l3 = +(group[2] || '')
    const l23 = +('' + (group[0] || '') + '' + (group[1] || '') + '' + (group[2] || ''))
    const l23_only = +('' + (group[1] || '') + '' + (group[2] || ''))
    const prev_group_idx = (group_idx + 1) || -1

    let group_name = ''

    if (l23 > 0 && l23 < 20) {
        if (group_idx === 1 || prev_group_idx === 1) {
            text.push(numbers[l23].special_1)
        } else {
            text.push(numbers[l23].simple)
        }

        group_name = group_names[group_idx].names[l23]
        if (group_name) {
            text.push(group_name)
        }
    }

    if (l23 >= 20 && l23 < 100) {
        if (l1 !== 0) {
            text.push(decimals[l1])
        }
        if (l2 !== 0) {
            text.push(numbers[l2].special_1)
        }

        group_name = group_names[group_idx].names[l2]
        if (group_name) {
            text.push(group_name)
        }
    }

    if (l23 >= 100) {
        if (l1 !== 0) {
            text.push(hundreds[l1])
        }

        if (l23_only > 0 && l23_only < 20) {
            if (group_idx === 1 || prev_group_idx === 1) {
                text.push(numbers[l23_only].special_1)
            } else {
                text.push(numbers[l23_only].simple)
            }
        } else {
            if (l2 !== 0) {
                text.push(decimals[l2])
            }
            if (l3 !== 0) {
                text.push(numbers[l3].special_1)
            }
        }

        group_name = group_names[group_idx].names[l3]
        if (group_name) {
            text.push(group_name)
        }
    }


    // if (group.length === 3) {
    //   const l1 = +group[0]
    //   const l2 = +group[1]
    //   const l3 = +group[2]
    //   const l23 = +('' + group[1] + '' + group[2])
    //
    //   if (l1 !== 0) {
    //     text.push(hundreds[l1])
    //   }
    //
    //   if (l23 > 0 && l23 < 20) {
    //     if (group_idx === 1) {
    //       text.push(numbers[l23].special_1)
    //     } else {
    //       text.push(numbers[l23].simple)
    //     }
    //   }
    //
    //   if (l23 >= 20) {
    //     if (l2 !== 0) {
    //       text.push(decimals[l2])
    //     }
    //     if (l3 !== 0) {
    //       text.push(numbers[l3].simple)
    //     }
    //   }
    //
    //
    //   const group_name = group_names[group_idx].names[l3]
    //   if (group_name) {
    //     text.push(group_name)
    //   }
    // }
    // if (group.length === 2) {
    //   const l1 = +group[0]
    //   const l2 = +group[1]
    //   const ll = +group
    //
    //   if (ll < 9) {
    //     text.push(numbers[ll].simple)
    //   }
    //   if (ll > 9 && ll < 20) {
    //     if (group_idx === 1) {
    //       text.push(numbers[ll].special_1)
    //     } else {
    //       text.push(numbers[ll].simple)
    //     }
    //   }
    //   if (ll >= 20) {
    //     if (l1 !== 0) {
    //       text.push(decimals[ll])
    //     }
    //     if (l2 !== 0) {
    //       text.push(numbers[l2].simple)
    //     }
    //   }
    //
    //   const group_name = group_names[group_idx].names[ll]
    //   if (group_name) {
    //     text.push(group_name)
    //   }
    // }
    // if (group.length === 1) {
    //   const l1 = +group[0]
    //
    //   if (l1 !== 0) {
    //     if (group_idx === 1) {
    //       text.push(numbers[l1].special_1)
    //     } else {
    //       text.push(numbers[l1].simple)
    //     }
    //   }
    //
    //   const group_name = group_names[group_idx].names[l1]
    //   if (group_name) {
    //     text.push(group_name)
    //   }
    // }
    return text.join(' ').toLowerCase()
}

function addCurrency(payload, coins=false) {
    if (!payload) return

    const number = [
        { currency: 'гривень', coin: 'копійок' },
        { currency: 'гривня', coin: 'копійка' },
        { currency: 'гривні', coin: 'копійки' },
        { currency: 'гривні', coin: 'копійки' },
        { currency: 'гривні', coin: 'копійки' },
        { currency: 'гривень', coin: 'копійок' },
        { currency: 'гривень', coin: 'копійок' },
        { currency: 'гривень', coin: 'копійок' },
        { currency: 'гривень', coin: 'копійок' },
        { currency: 'гривень', coin: 'копійок' },
    ]

    return ' ' + number[+(payload[payload.length - 1])][coins ? 'coin' : 'currency']
}

const convertNumberToLetterString = (payload) => {
    if (!payload) return '0 гривень 00 копійок'
    let str = payload.toString()
    let left_string = str.split('.')[0] || '0'
    let right_string = str.split('.')[1] || '00'
    if (right_string.length === 1) {
        right_string = '' + right_string + '0'
    }
    const groups = (+left_string).toLocaleString('en-US')
    const group_list = groups.split(',')
    let last = ''

    const result = []
    let text = ''

    for (let i = group_list.length; i > 0; i--) {
        const group_idx = Math.abs(i - group_list.length)
        result.push(translateNumberGroup(group_list[group_idx], (i - 1)))
        last = group_list[group_idx]
    }

    text = result.join(' ')
    text += addCurrency(last)
    text += ' ' + right_string + '' + addCurrency(right_string, true)
    return text[0].toUpperCase() + '' + text.substring(1).toLowerCase()
}

const getDocumentNameByType = (document_type, field='element_title') => {
    const defaults = {
        element_title: 'Тип документа відсутній',
        icon_color: 'success'
    }
    const doc = documents.find(i => i.list_name === document_type)
    return (doc || {})[field] || defaults[field]
}

const getDirectoryByGroup = (group) => {
    return directories.filter(i => i.group.indexOf(group) !== -1)
}

const getDocumentByGroup = (group) => {
    return documents.filter(i => i.group.indexOf(group) !== -1)
}

const getOperationByGroup = (group) => {
    return operations.filter(i => i.group.indexOf(group) !== -1)
}

const getReportByGroup = (group) => {
    return reports.filter(i => i.group.indexOf(group) !== -1)
}

const document_filters = {
    'DOCUMENT_BANK_INCOME': {
        date: {
            name: 'Дата: ',
            props: {
                date_from: {name: 'Дата з: ', field: 'date_from', type: 'date'},
                date_to: {name: 'Дата по: ', field: 'date_to', type: 'date'}
            }
        },
        operation: {
            name: 'Операція',
            props: {
                operation: {
                    name: 'Операція: ',
                    field: 'operation',
                    import_getter: 'bank_document_income_operation'
                }
            }
        },
        contractor: {
            name: 'Платник: ',
            props: {
                contractor: {
                    name: 'Платник: ',
                    field: 'contractor_id',
                    computed_action: 'SELECT_CONTRACTOR_LIST_ELEMENTS',
                    computed_getter: 'get_contractor_list_select',
                    select_button_object: {
                        name: 'CONTRACTOR_ELEMENTS',
                        group: '',
                        hierarchy: false,
                        only_groups: false,
                        title: 'Список контрагентів',
                        element_title: 'Контрагент',
                        show: true
                    }
                }
            }
        }
    },
    'DOCUMENT_BANK_OUTCOME': {
        date: {
            name: 'Дата: ',
            props: {
                date_from: {name: 'Дата з: ', field: 'date_from', type: 'date'},
                date_to: {name: 'Дата по: ', field: 'date_to', type: 'date'}
            }
        },
        operation: {
            name: 'Операція',
            props: {
                operation: {
                    name: 'Операція: ',
                    field: 'operation',
                    import_getter: 'bank_document_outcome_operation'
                }
            }
        },
        contractor: {
            name: 'Платник: ',
            props: {
                contractor: {
                    name: 'Платник: ',
                    field: 'contractor_id',
                    computed_action: 'SELECT_CONTRACTOR_LIST_ELEMENTS',
                    computed_getter: 'get_contractor_list_select',
                    select_button_object: {
                        name: 'CONTRACTOR_ELEMENTS',
                        group: '',
                        hierarchy: false,
                        only_groups: false,
                        title: 'Список контрагентів',
                        element_title: 'Контрагент',
                        show: true
                    }
                }
            }
        }
    },
    'DOCUMENT_ADVANCE_REPORT': {
        date: {
            name: 'Дата: ',
            props: {
                date_from: {name: 'Дата з: ', field: 'date_from', type: 'date'},
                date_to: {name: 'Дата по: ', field: 'date_to', type: 'date'}
            }
        },
        person_id: {
            name: 'Працівник: ',
            props: {
                person_id: {
                    name: 'Працівник: ',
                    field: 'person_id',
                    computed_action: 'SELECT_PERSONAL_LIST_ELEMENTS',
                    computed_getter: 'get_personal_list_select',
                    select_button_object: {
                        name: 'PERSONAL_ELEMENTS',
                        group: '',
                        hierarchy: false,
                        only_groups: false,
                        title: 'Список працівників',
                        element_title: 'Працівник',
                        show: true
                    }
                }
            }
        },
        warehouse_id: {
            name: 'Склад: ',
            props: {
                contractor_id: {
                    name: 'Склад: ',
                    field: 'warehouse_id',
                    computed_action: 'SELECT_WAREHOUSE_LIST_ELEMENTS',
                    computed_getter: 'get_warehouse_list_select',
                    select_button_object: {
                        name: 'WAREHOUSE_ELEMENTS',
                        group: '',
                        hierarchy: false,
                        only_groups: false,
                        title: 'Список складів',
                        element_title: 'Склад',
                        show: true
                    }
                }
            }
        },
    },
    'DOCUMENT_PERSON_TIMESHEET': {
        date: {
            name: 'Дата: ',
            props: {
                date_from: {name: 'Дата з: ', field: 'date_from', type: 'date'},
                date_to: {name: 'Дата по: ', field: 'date_to', type: 'date'}
            }
        },
    },
    'DOCUMENT_WORK_TRANSFER': {
        date: {
            name: 'Дата: ',
            props: {
                date_from: {name: 'Дата з: ', field: 'date_from', type: 'date'},
                date_to: {name: 'Дата по: ', field: 'date_to', type: 'date'}
            }
        },
        personal: {
            name: 'Працівник: ',
            props: {
                person_id: {
                    name: 'Працівник: ',
                    field: 'person_id',
                    computed_action: 'SELECT_PERSONAL_LIST_ELEMENTS',
                    computed_getter: 'get_personal_list_select',
                    select_button_object: {
                        name: 'PERSONAL_ELEMENTS',
                        group: '',
                        hierarchy: false,
                        only_groups: false,
                        title: 'Список працівників',
                        element_title: 'Працівник',
                        show: true
                    }
                }
            }
        },
    },
    'DOCUMENT_RELEASE_WORK': {
        date: {
            name: 'Дата: ',
            props: {
                date_from: {name: 'Дата з: ', field: 'date_from', type: 'date'},
                date_to: {name: 'Дата по: ', field: 'date_to', type: 'date'}
            }
        },
        personal: {
            name: 'Працівник: ',
            props: {
                person_id: {
                    name: 'Працівник: ',
                    field: 'person_id',
                    computed_action: 'SELECT_PERSONAL_LIST_ELEMENTS',
                    computed_getter: 'get_personal_list_select',
                    select_button_object: {
                        name: 'PERSONAL_ELEMENTS',
                        group: '',
                        hierarchy: false,
                        only_groups: false,
                        title: 'Список працівників',
                        element_title: 'Працівник',
                        show: true
                    }
                }
            }
        },
    },
    'DOCUMENT_EMPLOYMENT': {
        date: {
            name: 'Дата: ',
            props: {
                date_from: {name: 'Дата з: ', field: 'date_from', type: 'date'},
                date_to: {name: 'Дата по: ', field: 'date_to', type: 'date'}
            }
        },
        person_id: {
            name: 'Працівник: ',
            props: {
                person_id: {
                    name: 'Працівник: ',
                    field: 'person_id',
                    computed_action: 'SELECT_PERSONAL_LIST_ELEMENTS',
                    computed_getter: 'get_personal_list_select',
                    select_button_object: {
                        name: 'PERSONAL_ELEMENTS',
                        group: '',
                        hierarchy: false,
                        only_groups: false,
                        title: 'Список працівників',
                        element_title: 'Працівник',
                        show: true
                    }
                }
            }
        },
    },
    'DOCUMENT_CONTRACT_BY_CLN': {
        date: {
            name: 'Дата: ',
            props: {
                date_from: {name: 'Дата з: ', field: 'date_from', type: 'date'},
                date_to: {name: 'Дата по: ', field: 'date_to', type: 'date'}
            }
        },
        person_id: {
            name: 'Працівник: ',
            props: {
                person_id: {
                    name: 'Працівник: ',
                    field: 'person_id',
                    computed_action: 'SELECT_PERSONAL_LIST_ELEMENTS',
                    computed_getter: 'get_personal_list_select',
                    select_button_object: {
                        name: 'PERSONAL_ELEMENTS',
                        group: '',
                        hierarchy: false,
                        only_groups: false,
                        title: 'Список працівників',
                        element_title: 'Працівник',
                        show: true
                    }
                }
            }
        },
    },
    'DOCUMENT_SOCIAL_PRIVILEGE': {
        date: {
            name: 'Дата: ',
            props: {
                date_from: {name: 'Дата з: ', field: 'date_from', type: 'date'},
                date_to: {name: 'Дата по: ', field: 'date_to', type: 'date'}
            }
        },
        person_id: {
            name: 'Працівник: ',
            props: {
                person_id: {
                    name: 'Працівник: ',
                    field: 'person_id',
                    computed_action: 'SELECT_PERSONAL_LIST_ELEMENTS',
                    computed_getter: 'get_personal_list_select',
                    select_button_object: {
                        name: 'PERSONAL_ELEMENTS',
                        group: '',
                        hierarchy: false,
                        only_groups: false,
                        title: 'Список працівників',
                        element_title: 'Працівник',
                        show: true
                    }
                }
            }
        },
    },
    'DOCUMENT_SALARY_CHARGE': {
        date: {
            name: 'Дата: ',
            props: {
                date_from: {name: 'Дата з: ', field: 'date_from', type: 'date'},
                date_to: {name: 'Дата по: ', field: 'date_to', type: 'date'}
            }
        },
        subdivision_id: {
            name: 'Підрозділ: ',
            props: {
                contractor: {
                    name: 'Підрозділ: ',
                    field: 'subdivision_id',
                    computed_action: 'SELECT_SUBDIVISION_LIST_ELEMENTS',
                    computed_getter: 'get_subdivision_list_select',
                    select_button_object: {
                        name: 'SUBDIVISION_ELEMENTS',
                        group: '',
                        hierarchy: false,
                        only_groups: false,
                        title: 'Список підрозділів',
                        element_title: 'Підрозділ',
                        show: true
                    }
                }
            }
        },
        is_advance: {
            name: 'Це аванс',
            props: {
                is_advance: {name: 'Це аванс', field: 'is_advance', type: 'bool'}
            }
        },

    },
    'DOCUMENT_SALARY_TO_PAY': {
        date: {
            name: 'Дата: ',
            props: {
                date_from: {name: 'Дата з: ', field: 'date_from', type: 'date'},
                date_to: {name: 'Дата по: ', field: 'date_to', type: 'date'}
            }
        },
        subdivision_id: {
            name: 'Підрозділ: ',
            props: {
                contractor: {
                    name: 'Підрозділ: ',
                    field: 'subdivision_id',
                    computed_action: 'SELECT_SUBDIVISION_LIST_ELEMENTS',
                    computed_getter: 'get_subdivision_list_select',
                    select_button_object: {
                        name: 'SUBDIVISION_ELEMENTS',
                        group: '',
                        hierarchy: false,
                        only_groups: false,
                        title: 'Список підрозділів',
                        element_title: 'Підрозділ',
                        show: true
                    }
                }
            }
        },
        by_bank: {
            name: 'По банку',
            props: {
                by_bank: {name: 'По банку', field: 'by_bank', type: 'bool'}
            }
        },

    },
    'DOCUMENT_HOSPITAL_CHARGE': {
        date: {
            name: 'Дата: ',
            props: {
                date_from: {name: 'Дата з: ', field: 'date_from', type: 'date'},
                date_to: {name: 'Дата по: ', field: 'date_to', type: 'date'}
            }
        },
        hospital_type: {
            name: 'Вид лікарняного',
            props: {
                operation: {
                    name: 'Вид лікарняного: ',
                    field: 'hospital_type',
                    import_getter: 'hospital_type'
                }
            }
        },
        person_id: {
            name: 'Працівник: ',
            props: {
                person_id: {
                    name: 'Працівник: ',
                    field: 'person_id',
                    computed_action: 'SELECT_PERSONAL_LIST_ELEMENTS',
                    computed_getter: 'get_personal_list_select',
                    select_button_object: {
                        name: 'PERSONAL_ELEMENTS',
                        group: '',
                        hierarchy: false,
                        only_groups: false,
                        title: 'Список працівників',
                        element_title: 'Працівник',
                        show: true
                    }
                }
            }
        },
    },
    'DOCUMENT_VOCATION_CHARGE': {
        date: {
            name: 'Дата: ',
            props: {
                date_from: {name: 'Дата з: ', field: 'date_from', type: 'date'},
                date_to: {name: 'Дата по: ', field: 'date_to', type: 'date'}
            }
        },
        hospital_type: {
            name: 'Вид відпустки',
            props: {
                operation: {
                    name: 'Вид відпустки: ',
                    field: 'vocation_type',
                    import_getter: 'vocation_type'
                }
            }
        },
        person_id: {
            name: 'Працівник: ',
            props: {
                person_id: {
                    name: 'Працівник: ',
                    field: 'person_id',
                    computed_action: 'SELECT_PERSONAL_LIST_ELEMENTS',
                    computed_getter: 'get_personal_list_select',
                    select_button_object: {
                        name: 'PERSONAL_ELEMENTS',
                        group: '',
                        hierarchy: false,
                        only_groups: false,
                        title: 'Список працівників',
                        element_title: 'Працівник',
                        show: true
                    }
                }
            }
        },
    },
    'DOCUMENT_ACT_WORK_PERFORMED_OUTCOME': {
        date: {
            name: 'Дата: ',
            props: {
                date_from: {name: 'Дата з: ', field: 'date_from', type: 'date'},
                date_to: {name: 'Дата по: ', field: 'date_to', type: 'date'}
            }
        },
        contractor_id: {
            name: 'Платник: ',
            props: {
                contractor_id: {
                    name: 'Платник: ',
                    field: 'contractor_id',
                    computed_action: 'SELECT_CONTRACTOR_LIST_ELEMENTS',
                    computed_getter: 'get_contractor_list_select',
                    select_button_object: {
                        name: 'CONTRACTOR_ELEMENTS',
                        group: '',
                        hierarchy: false,
                        only_groups: false,
                        title: 'Список контрагентів',
                        element_title: 'Контрагент',
                        show: true
                    }
                }
            }
        }
    },
    'DOCUMENT_ACT_WORK_PERFORMED_INCOME': {
        date: {
            name: 'Дата: ',
            props: {
                date_from: {name: 'Дата з: ', field: 'date_from', type: 'date'},
                date_to: {name: 'Дата по: ', field: 'date_to', type: 'date'}
            }
        },
        contractor_id: {
            name: 'Платник: ',
            props: {
                contractor_id: {
                    name: 'Платник: ',
                    field: 'contractor_id',
                    computed_action: 'SELECT_CONTRACTOR_LIST_ELEMENTS',
                    computed_getter: 'get_contractor_list_select',
                    select_button_object: {
                        name: 'CONTRACTOR_ELEMENTS',
                        group: '',
                        hierarchy: false,
                        only_groups: false,
                        title: 'Список контрагентів',
                        element_title: 'Контрагент',
                        show: true
                    }
                }
            }
        }
    },
    'DOCUMENT_INVOICE_WAREHOUSE_INCOME': {
        date: {
            name: 'Дата: ',
            props: {
                date_from: {name: 'Дата з: ', field: 'date_from', type: 'date'},
                date_to: {name: 'Дата по: ', field: 'date_to', type: 'date'}
            }
        },
        contractor_id: {
            name: 'Платник: ',
            props: {
                contractor_id: {
                    name: 'Платник: ',
                    field: 'contractor_id',
                    computed_action: 'SELECT_CONTRACTOR_LIST_ELEMENTS',
                    computed_getter: 'get_contractor_list_select',
                    select_button_object: {
                        name: 'CONTRACTOR_ELEMENTS',
                        group: '',
                        hierarchy: false,
                        only_groups: false,
                        title: 'Список контрагентів',
                        element_title: 'Контрагент',
                        show: true
                    }
                }
            }
        },
        warehouse_id: {
            name: 'Склад: ',
            props: {
                warehouse_id: {
                    name: 'Склад: ',
                    field: 'warehouse_id',
                    computed_action: 'SELECT_WAREHOUSE_LIST_ELEMENTS',
                    computed_getter: 'get_warehouse_list_select',
                    select_button_object: {
                        name: 'WAREHOUSE_ELEMENTS',
                        group: '',
                        hierarchy: false,
                        only_groups: false,
                        title: 'Список складів',
                        element_title: 'Склад',
                        show: true
                    }
                }
            }
        },
    },
    'DOCUMENT_INVOICE_WAREHOUSE_OUTCOME': {
        date: {
            name: 'Дата: ',
            props: {
                date_from: {name: 'Дата з: ', field: 'date_from', type: 'date'},
                date_to: {name: 'Дата по: ', field: 'date_to', type: 'date'}
            }
        },
        contractor_id: {
            name: 'Платник: ',
            props: {
                contractor_id: {
                    name: 'Платник: ',
                    field: 'contractor_id',
                    computed_action: 'SELECT_CONTRACTOR_LIST_ELEMENTS',
                    computed_getter: 'get_contractor_list_select',
                    select_button_object: {
                        name: 'CONTRACTOR_ELEMENTS',
                        group: '',
                        hierarchy: false,
                        only_groups: false,
                        title: 'Список контрагентів',
                        element_title: 'Контрагент',
                        show: true
                    }
                }
            }
        },
        warehouse_id: {
            name: 'Склад: ',
            props: {
                warehouse_id: {
                    name: 'Склад: ',
                    field: 'warehouse_id',
                    computed_action: 'SELECT_WAREHOUSE_LIST_ELEMENTS',
                    computed_getter: 'get_warehouse_list_select',
                    select_button_object: {
                        name: 'WAREHOUSE_ELEMENTS',
                        group: '',
                        hierarchy: false,
                        only_groups: false,
                        title: 'Список складів',
                        element_title: 'Склад',
                        show: true
                    }
                }
            }
        },
    },
    'DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME': {
        date: {
            name: 'Дата: ',
            props: {
                date_from: {name: 'Дата з: ', field: 'date_from', type: 'date'},
                date_to: {name: 'Дата по: ', field: 'date_to', type: 'date'}
            }
        },
        warehouse_id: {
            name: 'Склад: ',
            props: {
                warehouse_id: {
                    name: 'Склад: ',
                    field: 'warehouse_id',
                    computed_action: 'SELECT_WAREHOUSE_LIST_ELEMENTS',
                    computed_getter: 'get_warehouse_list_select',
                    select_button_object: {
                        name: 'WAREHOUSE_ELEMENTS',
                        group: '',
                        hierarchy: false,
                        only_groups: false,
                        title: 'Список складів',
                        element_title: 'Склад',
                        show: true
                    }
                }
            }
        },
    },
    'DOCUMENT_TAX_BILL_INCOME': {
        date: {
            name: 'Дата: ',
            props: {
                date_from: {name: 'Дата з: ', field: 'date_from', type: 'date'},
                date_to: {name: 'Дата по: ', field: 'date_to', type: 'date'}
            }
        },
        operation: {
            name: 'Вид документа підстави: ',
            props: {
                operation: {
                    name: 'Вид документа підстави: ',
                    field: 'operation',
                    import_getter: 'tax_bill_document_income_operation'
                }
            }
        },
        contractor_id: {
            name: 'Контрагент: ',
            props: {
                contractor_id: {
                    name: 'Контрагент: ',
                    field: 'contractor_id',
                    computed_action: 'SELECT_CONTRACTOR_LIST_ELEMENTS',
                    computed_getter: 'get_contractor_list_select',
                    select_button_object: {
                        name: 'CONTRACTOR_ELEMENTS',
                        group: '',
                        hierarchy: false,
                        only_groups: false,
                        title: 'Список контрагентів',
                        element_title: 'Контрагент',
                        show: true
                    }
                }
            }
        }
    },
    'DOCUMENT_TAX_BILL_OUTCOME': {
        date: {
            name: 'Дата: ',
            props: {
                date_from: {name: 'Дата з: ', field: 'date_from', type: 'date'},
                date_to: {name: 'Дата по: ', field: 'date_to', type: 'date'}
            }
        },
        operation: {
            name: 'Вид документа підстави: ',
            props: {
                operation: {
                    name: 'Вид документа підстави: ',
                    field: 'operation',
                    import_getter: 'tax_bill_document_outcome_operation'
                }
            }
        },
        contractor_id: {
            name: 'Контрагент: ',
            props: {
                contractor_id: {
                    name: 'Контрагент: ',
                    field: 'contractor_id',
                    computed_action: 'SELECT_CONTRACTOR_LIST_ELEMENTS',
                    computed_getter: 'get_contractor_list_select',
                    select_button_object: {
                        name: 'CONTRACTOR_ELEMENTS',
                        group: '',
                        hierarchy: false,
                        only_groups: false,
                        title: 'Список контрагентів',
                        element_title: 'Контрагент',
                        show: true
                    }
                }
            }
        }
    },
    'DOCUMENT_BILL_OUTCOME': {
        date: {
            name: 'Дата: ',
            props: {
                date_from: {name: 'Дата з: ', field: 'date_from', type: 'date'},
                date_to: {name: 'Дата по: ', field: 'date_to', type: 'date'}
            }
        },
        contractor_id: {
            name: 'Платник: ',
            props: {
                contractor_id: {
                    name: 'Платник: ',
                    field: 'contractor_id',
                    computed_action: 'SELECT_CONTRACTOR_LIST_ELEMENTS',
                    computed_getter: 'get_contractor_list_select',
                    select_button_object: {
                        name: 'CONTRACTOR_ELEMENTS',
                        group: '',
                        hierarchy: false,
                        only_groups: false,
                        title: 'Список контрагентів',
                        element_title: 'Контрагент',
                        show: true
                    }
                }
            }
        }
    },
    'DOCUMENT_COMMISSIONING': {
        date: {
            name: 'Дата: ',
            props: {
                date_from: {name: 'Дата з: ', field: 'date_from', type: 'date'},
                date_to: {name: 'Дата по: ', field: 'date_to', type: 'date'}
            }
        },
        operation: {
            name: 'Операція: ',
            props: {
                operation: {
                    name: 'Операція: ',
                    field: 'operation',
                    import_getter: 'commissioning_document_operation'
                }
            }
        },
        fixed_asset_id: {
            name: 'Основний засіб: ',
            props: {
                fixed_asset_id: {
                    name: 'Основний засіб: ',
                    field: 'fixed_asset_id',
                    computed_action: 'SELECT_FIXED_ASSETS_LIST_ELEMENTS',
                    computed_getter: 'get_fixed_assets_list_select',
                    select_button_object: {
                        name: 'FIXED_ASSETS_ELEMENTS',
                        group: '',
                        hierarchy: false,
                        only_groups: false,
                        title: 'Список основних засіб',
                        element_title: 'Основний засіб',
                        show: true
                    }
                }
            }
        },
    },
    'DOCUMENT_LOW_COST_COMMISSIONING': {
        date: {
            name: 'Дата: ',
            props: {
                date_from: {name: 'Дата з: ', field: 'date_from', type: 'date'},
                date_to: {name: 'Дата по: ', field: 'date_to', type: 'date'}
            }
        },
        subdivision_id: {
            name: 'Підрозділ: ',
            props: {
                contractor: {
                    name: 'Підрозділ: ',
                    field: 'subdivision_id',
                    computed_action: 'SELECT_SUBDIVISION_LIST_ELEMENTS',
                    computed_getter: 'get_subdivision_list_select',
                    select_button_object: {
                        name: 'SUBDIVISION_ELEMENTS',
                        group: '',
                        hierarchy: false,
                        only_groups: false,
                        title: 'Список підрозділів',
                        element_title: 'Підрозділ',
                        show: true
                    }
                }
            }
        },
        warehouse_id: {
            name: 'Склад: ',
            props: {
                warehouse_id: {
                    name: 'Склад: ',
                    field: 'warehouse_id',
                    computed_action: 'SELECT_WAREHOUSE_LIST_ELEMENTS',
                    computed_getter: 'get_warehouse_list_select',
                    select_button_object: {
                        name: 'WAREHOUSE_ELEMENTS',
                        group: '',
                        hierarchy: false,
                        only_groups: false,
                        title: 'Список складів',
                        element_title: 'Склад',
                        show: true
                    }
                }
            }
        },
    },
    'DOCUMENT_ACCOUNTING_CLOSE_HOME_ACCOUNTING': {
        date: {
            name: 'Дата: ',
            props: {
                date_from: {name: 'Дата з: ', field: 'date_from', type: 'date'},
                date_to: {name: 'Дата по: ', field: 'date_to', type: 'date'}
            }
        },
    },
    'DOCUMENT_ACCOUNTING_CLOSE_FINANCIAL_MONTH': {
        date: {
            name: 'Дата: ',
            props: {
                date_from: {name: 'Дата з: ', field: 'date_from', type: 'date'},
                date_to: {name: 'Дата по: ', field: 'date_to', type: 'date'}
            }
        },
    },
    'DOCUMENT_ENTERING_BALANCES': {
        date: {
            name: 'Дата: ',
            props: {
                date_from: {name: 'Дата з: ', field: 'date_from', type: 'date'},
                date_to: {name: 'Дата по: ', field: 'date_to', type: 'date'}
            }
        },
    },
    'DOCUMENT_MANUAL_OPERATION': {
        date: {
            name: 'Дата: ',
            props: {
                date_from: {name: 'Дата з: ', field: 'date_from', type: 'date'},
                date_to: {name: 'Дата по: ', field: 'date_to', type: 'date'}
            }
        },
    },
}

function sort_by(items, props, except_number='') {
    return cloneDeep(items).sort((a, b) => {

        const a_path = props.map(item => {
            if (a[item.key] !== undefined) {
                const ret = item.desc ? b[item.key] : a[item.key]
                if (item.key === except_number) {
                    return ret
                } else {
                    return isNumber(ret) ? pad(ret, 10) : ret
                }
            }
        }).join('|');

        const b_path = props.map(item => {
            if (b[item.key] !== undefined) {
                const ret = item.desc ? a[item.key] : b[item.key]
                if (item.key === except_number) {
                    return ret
                } else {
                    return isNumber(ret) ? pad(ret, 10) : ret
                }
            }
        }).join('|')

        return a_path > b_path ? 1 : (a_path === b_path ? 0 : -1)
    });
}

function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

function pad(n, width) {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
}

const getDocumentFilter = (payload) => {
    return document_filters[payload] || {}
}

const getComponentType = (payload) => {
    if (payload.type === 'date') {
        return 'date_input'
    }
    if (payload.type === 'string') {
        return 'string_input'
    }
    if (payload.type === 'textarea') {
        return 'text_area_input'
    }
    if (payload.type === 'number') {
        return 'number_input'
    }
    if (payload.type === 'bool') {
        return 'v-checkbox'
    }
    if ('import_getter' in payload) {
        return 'select_input'
    }
    if ('computed_action' in payload) {
        return 'select_input'
    }
    if ('computed_getter' in payload) {
        return 'select_input'
    }
    if ('select_button_object' in payload) {
        return 'select_input'
    }
    return 'string_input'
}

const buildDocumentFilterForm = (payload) => {
    const config = cloneDeep(payload)
    const data = {}

    if (!Object.keys(payload).length) return null

    Object.keys(config).forEach(key => {
        const props = (config[key].props || {})

        if (Object.keys(props).length) {
            Object.keys(props).forEach(prop => {
                const el = props[prop]
                data[prop] = {
                    label: el.name,
                    component: getComponentType(el),
                    value: null,
                    field: el.field,
                    cols: 12 / (Object.keys(props).length || 1),
                    import_getter: el.import_getter || null,
                    computed_action: el.computed_action || null,
                    computed_getter: el.computed_getter || null,
                    key: prop
                }
                if ('select_button_object' in el) {
                    data[prop]['select_button_object'] = el.select_button_object
                }
            })
        }
    })
    return data
}

const buildPayloadForRequest = (payload) => {
    const new_payload = {}
    Object.keys(payload).forEach(key => {
        const el = payload[key]
        new_payload[el.field] = el.value || null
    })
    return new_payload
}

const buildFilterItems = (payload) => {
    if (!payload) return []
    const filters = []

    Object.keys(payload).forEach(key => {
        let el = payload[key]

        if (el.value !== null) {
            const filter_item = {
                name: el.label,
                field: el.key,
                text: el.represent || ''
            }
            filters.push(filter_item)
        }
    })

    return filters
}

const getHeaders = (payload) => {
    return headers[payload] || []
}

const getGetter = (payload) => {
    return getters[payload] || ''
}

const getAction = (payload) => {
    return actions[payload] || ''
}

const getActionFilter = (payload) => {
    if (!payload) return null

    const filters_arr = payload.split('@@@;')

    if (!filters_arr.length) {
        filters_arr.push(payload)
    }

    const query = []

    filters_arr.forEach(item => {
        const filter_split = item.split('@;')
        let field = filter_split[0].replace('@field=', '')
        let operator_string = filter_split[1].substr(1)
        const equal_position = operator_string.indexOf('=')
        let operator = operator_string.substr(0, equal_position)
        let value_type = operator_string.substr(equal_position + 1, 1)
        let value = operator_string.substr(equal_position + 3)

        if (value_type === 'S') value = value.trim()
        if (value_type === 'N') value = +value.trim()

        query.push(
            {
                field,
                operator,
                value
            }
        )
    })

    return (item) => {
        let r = []
        for (let i in query) {
            const q = query[i]
            if (q.operator === 'start-with') {
                const qr = item[q.field].startsWith(q.value)
                if (qr) r.push(1)
            }
        }
        return r.length
    }
}

const tax_type_select = [
    {text: 'Без ПДВ', value: 'pdv_without'},
    {text: 'ПДВ 0%', value: 'pdv_0'},
    {text: 'ПДВ 5%', value: 'pdv_5'},
    {text: 'ПДВ 7%', value: 'pdv_7'},
    {text: 'ПДВ 20%', value: 'pdv_20'},
]


const tax_group_select = [
    {value: 'main', text: 'ЄСВ працівники'},
    {value: 'hospital', text: 'ЄВС лікарняні'},
    {value: 'decree', text: 'ЄСВ дикретні'},
    {value: 'by_contract', text: 'ЄСВ по дог. ЦПХ'},
    {value: 'military', text: 'Військовий збір'},
    {value: 'pdfo', text: 'ПДФО'},
    {value: 'pdv', text: 'ПДВ'},
    {value: 'single_tax', text: 'Єдиний податок'},
    {value: 'other', text: 'Інші'},
]

const sex_type_select = [
    {text: 'чол.', value: 'male'},
    {text: 'жін.', value: 'female'},
]

const get_sex_type_select = (value) => {
    return (sex_type_select.find(i => i.value === value) || {}).text || ''
}

const cripple_select = [
    {text: 'Перша група', value: 'first_category'},
    {text: 'Друга група', value: 'second_category'},
    {text: 'Третя група', value: 'third_category'}
]


const get_cripple_select = (value) => {
    return (cripple_select.find(i => i.value === value) || {}).text || ''
}

const person_type_select = [
    {text: 'Трудовий дог.', value: 'full_time'},
    {text: 'Дог. ЦПХ', value: 'by_contract'},
]

const get_person_type_select = (value) => {
    return (person_type_select.find(i => i.value === value) || {}).text || ''
}


const get_tax_group_select = (value) => {
    return (tax_group_select.find(i => i.value === value) || {}).text || ''
}

const contract_pay_type_select = [
    {text: 'Щомісячно', value: 'every_month'},
    {text: 'В кінці періоду', value: 'end_of_period'},
]

const get_contract_pay_type_select = (value) => {
    return (contract_pay_type_select.find(i => i.value === value) || {}).text || ''
}

const full_time_type_select = [
    {text: 'Основ.місце', value: 'main_work_place'},
    {text: 'Сумісництво', value: 'combination'},
]

const get_full_time_type_select = (value) => {
    return (full_time_type_select.find(i => i.value === value) || {}).text || 'Дог. ЦПХ'
}

const timesheet_letter_list = {
    'work': {name: 'р', color: ''},
    'work_non_full_time': {name: 'рс', color: ''},
    'work_evening': {name: 'вч', color: ''},
    'overtime': {name: 'ну', color: ''},
    'work_opening': {name: 'рв', color: ''},
    'assignment': {name: 'вд', color: ''},
    'vocation_main': {name: 'в', color: ''},
    'vocation_additional': {name: 'д', color: ''},
    'vocation_chornobel': {name: 'ч', color: ''},
    'vocation_creative': {name: 'тв', color: ''},
    'vocation_study': {name: 'н', color: ''},
    'vocation_study_without': {name: 'нб', color: ''},
    'vocation_additional_without': {name: 'дб', color: ''},
    'vocation_children': {name: 'до', color: ''},
    'vocation_pregnancy': {name: 'вп', color: ''},
    'vocation_babysitting': {name: 'дд', color: ''},
    'vocation_without': {name: 'на', color: ''},
    'vocation_without_other': {name: 'бз', color: ''},
    'not_full_day': {name: 'нд', color: ''},
    'transfer_to_other': {name: 'нп', color: ''},
    'unworked_time': {name: 'ін', color: ''},
    'downtime': {name: 'п', color: ''},
    'absenteeism': {name: 'пр', color: ''},
    'strike': {name: 'с', color: ''},
    'disease': {name: 'тн', color: ''},
    'disease_without': {name: 'нн', color: ''},
    'non_appearance': {name: 'нз', color: ''},
    'non_appearance_by_col_contract': {name: 'нз', color: ''},
    'non_appearance_other': {name: 'і', color: ''},
}


const timesheet_letter_array = [
    {name: 'Робота', color: '', value: 'work', main: true},
    {name: 'Відпуск', color: '', value: 'vocation_main', main: true},
    {name: 'Хвороба', color: '', value: 'disease', main: true},
    {name: 'Прогул', color: '', value: 'absenteeism', main: true},
    {name: 'Неявка', color: '', value: 'non_appearance', main: true},
    {name: 'рс', color: '', value: 'work_non_full_time'},
    {name: 'вч', color: '', value: 'work_evening'},
    {name: 'ну', color: '', value: 'overtime'},
    {name: 'рв', color: '', value: 'work_opening'},
    {name: 'вд', color: '', value: 'assignment'},
    {name: 'д', color: '', value: 'vocation_additional'},
    {name: 'ч', color: '', value: 'vocation_chornobel'},
    {name: 'тв', color: '', value: 'vocation_creative'},
    {name: 'н', color: '', value: 'vocation_study'},
    {name: 'нб', color: '', value: 'vocation_study_without'},
    {name: 'дб', color: '', value: 'vocation_additional_without'},
    {name: 'до', color: '', value: 'vocation_children'},
    {name: 'вп', color: '', value: 'vocation_pregnancy'},
    {name: 'дд', color: '', value: 'vocation_babysitting'},
    {name: 'на', color: '', value: 'vocation_without'},
    {name: 'бз', color: '', value: 'vocation_without_other'},
    {name: 'нд', color: '', value: 'not_full_day'},
    {name: 'нп', color: '', value: 'transfer_to_other'},
    {name: 'ін', color: '', value: 'unworked_time'},
    {name: 'п', color: '', value: 'downtime'},
    {name: 'с', color: '', value: 'strike'},
    {name: 'нн', color: '', value: 'disease_without'},
    {name: 'нз', color: '', value: 'non_appearance_by_col_contract'},
    {name: 'і', color: '', value: 'non_appearance_other'},
]

const roundUp = (x) => {
    return x % 10 === 0 ? x : x + 10 - x % 10
}

const round_number = (value, precision) => {
    return Math.round((value + Number.EPSILON) * Math.pow(10, precision)) / Math.pow(10, precision)
}

const getSumColor = (result) => {
    if ((result || 0) < 0) {
        return 'color: var(--v-error-base)'
    }
    if ((result || 0) > 0) {
        return 'color: var(--v-success-darken1)'
    }
    return 'color: inherit'
}

export {
    tax_type_select,
    sex_type_select,
    subconto_type_select_setting,
    directories,
    documents,
    accounting_pages,
    accounting_links,
    person_type_select,
    full_time_type_select,
    timesheet_letter_list,
    timesheet_letter_array,
    tax_group_select,
    cripple_select,
    reports,
    operations,
    create_on_the_basis,
    getDocumentObject,
    roundUp,
    round_number,
    getSumColor,
    get_cripple_select,
    get_tax_group_select,
    getDirectoryByGroup,
    getDocumentByGroup,
    getReportByGroup,
    get_sex_type_select,
    getHeaders,
    getGetter,
    getAction,
    getActionFilter,
    getDocumentFilter,
    buildDocumentFilterForm,
    buildPayloadForRequest,
    buildFilterItems,
    sort_by,
    get_full_time_type_select,
    get_person_type_select,
    get_contract_pay_type_select,
    getDocumentNameByType,
    convertNumberToLetterString,
    getDocumentsObject,
    getOperationByGroup
}