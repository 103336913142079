import {
    FETCH_REGULAR_BY_ORGANIZATION,
    CREATE_REGULAR_BY_ORGANIZATION,
    UPDATE_REGULAR_BY_ORGANIZATION,
    DELETE_REGULAR_BY_ORGANIZATION
} from "@/store/actions/regular_by_organization";

import tarByOrgAPI from "@/utils/axios/regular_by_organization"

import {ALERT_SHOW} from "@/store/actions/alert";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = { reg_by_org: [] }

const getters = {
    getRegByOrg: state => state.reg_by_org,
}

const actions = {
    [FETCH_REGULAR_BY_ORGANIZATION]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            tarByOrgAPI.get(payload)
                .then(response => response.data)
                .then(data => {
                    commit(FETCH_REGULAR_BY_ORGANIZATION, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_REGULAR_BY_ORGANIZATION]: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            tarByOrgAPI.create(payload)
                .then(response => { return response.data })
                .then(data => {
                    commit(CREATE_REGULAR_BY_ORGANIZATION, data)
                    dispatch(FETCH_REGULAR_BY_ORGANIZATION, data?.is_legacy || false)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_REGULAR_BY_ORGANIZATION]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            tarByOrgAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_REGULAR_BY_ORGANIZATION, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_REGULAR_BY_ORGANIZATION]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            tarByOrgAPI.remove(payload)
                .then(resolve => resolve.data)
                .then(() => {
                    commit(DELETE_REGULAR_BY_ORGANIZATION, payload)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    }
}

const mutations = {
    [FETCH_REGULAR_BY_ORGANIZATION]: (state, payload) => {
        state.reg_by_org = payload
    },
    [CREATE_REGULAR_BY_ORGANIZATION]: (state, payload) => {
        state.reg_by_org.push(payload)
    },
    [UPDATE_REGULAR_BY_ORGANIZATION]: (state, payload) => {
        let obj = state.reg_by_org.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [DELETE_REGULAR_BY_ORGANIZATION]: (state, payload) => {
        const obg = state.reg_by_org.find(item => item.id === payload)
        const obj_id = state.reg_by_org.indexOf(obg)
        if (obg) {
            state.reg_by_org.splice(obj_id, 1)
        }
    },
}


export default {
    state,
    getters,
    actions,
    mutations
}