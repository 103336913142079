import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/legacy-accounting-documents';

export default {
    get_by_flat(payload) {
        return axios.get(`${RESOURCE_NAME}/get-by-flat`, {params: payload})
    },
    create_documents(payload) {
        return axios.post(`${RESOURCE_NAME}/create-documents`, payload)
    },
    remove_documents(payload) {
        return axios.post(`${RESOURCE_NAME}/remove-documents`, payload)
    },
    document_crud(payload) {
        return axios.post(`${RESOURCE_NAME}/documents-crud`, payload)
    },
    document_tax_bill_export_to_xml(payload) {
        return axios.post(`${RESOURCE_NAME}/document-tax-bill--export-to-xml`, payload, {responseType: 'blob'})
    },
    get_setting_by_document(payload) {
        return axios.get(`${RESOURCE_NAME}/get-settings`, {params: payload})
    },
    get_all_setting() {
        return axios.get(`${RESOURCE_NAME}/get-all-settings`)
    },
    save_settings(payload) {
        return axios.post(`${RESOURCE_NAME}/save-settings`, payload)
    },
    remove_settings(payload) {
        return axios.post(`${RESOURCE_NAME}/remove-settings`, payload)
    },
};