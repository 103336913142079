import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/flat-counter-fmc';

export default {
    get_all(payload) {
        return axios.get(`${RESOURCE_NAME}/`, {params: payload})
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    create_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    update_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/update`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { header_id: payload })
    },
    remove_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/remove`, { header_id: payload })
    },
};