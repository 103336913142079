export const GET_BANK = 'GET_BANK'
export const GET_BANK_ACCOUNT = 'GET_BANK_ACCOUNT'
export const CREATE_BANK = 'CREATE_BANK'
export const CREATE_BANK_ACCOUNT = 'CREATE_BANK_ACCOUNT'
export const UPDATE_BANK = 'UPDATE_BANK'
export const UPDATE_BANK_ACCOUNT = 'UPDATE_BANK_ACCOUNT'
export const REMOVE_BANK = 'REMOVE_BANK'
export const REMOVE_BANK_ACCOUNT = 'REMOVE_BANK_ACCOUNT'
export const SELECT_BANK = 'SELECT_BANK'
export const SELECT_BANK_ACCOUNT = 'SELECT_BANK_ACCOUNT'
