import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/restructuring-agreement';

export default {
    get_all(payload) {
        return axios.get(`${RESOURCE_NAME}/`, {params: payload})
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { "restructuring_agreement_id": payload })
    },
    get_debt(payload) {
        return axios.get(`${RESOURCE_NAME}/get-debt`, {params: payload})
    },
};