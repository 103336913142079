import {
    GET_FLAT_MODEL_COUNTER,
    UPDATE_FLAT_MODEL_COUNTER,
    CREATE_FLAT_MODEL_COUNTER,
    REMOVE_FLAT_MODEL_COUNTER,
    SELECT_FLAT_MODEL_COUNTER,
    GET_FLAT_COUNTER,
    GET_BUILDING_COUNTER,
    GET_FLAT_COUNTER_BY_ID,
    CREATE_FLAT_COUNTER,
    UPDATE_FLAT_COUNTER,
    REMOVE_FLAT_COUNTER,
    GET_FLAT_COUNTER_READING,
    CREATE_FLAT_COUNTER_READING,
    COUNTER_SELECT,
    REMOVE_FLAT_COUNTER_READING,
    GET_BUILDING_COUNTER_BY_ID,
    CREATE_BUILDING_COUNTER,
    UPDATE_BUILDING_COUNTER,
    REMOVE_BUILDING_COUNTER,
    BUILDING_COUNTER_SELECT,
    GET_BUILDING_COUNTER_READING,
    CREATE_BUILDING_COUNTER_READING,
    REMOVE_BUILDING_COUNTER_READING,
    RECALCULATION_BUILDING_COUNTER,
    CREATE_FLAT_COUNTER_LEGACY,
    CREATE_FLAT_COUNTER_LEGACY_READING,
    UPDATE_FLAT_COUNTER_LEGACY,
    REMOVE_FLAT_COUNTER_LEGACY_READING,
    REMOVE_FLAT_COUNTER_LEGACY,

    GET_ALL_FLAT_COUNTER_OPERATION,
    CREATE_FLAT_COUNTER_OPERATION,
    UPDATE_FLAT_COUNTER_OPERATION,
    DELETE_FLAT_COUNTER_OPERATION,

    GET_ALL_FLAT_COUNTER_SUB_ACCOUNT,
    CREATE_FLAT_COUNTER_SUB_ACCOUNT,
    UPDATE_FLAT_COUNTER_SUB_ACCOUNT,
    DELETE_FLAT_COUNTER_SUB_ACCOUNT
} from "@/store/actions/counter";
import flatCounterAPI from "@/utils/axios/flat_counter";
import buildingCounterAPI from "@/utils/axios/building_counter"
import {ALERT_SHOW} from "@/store/actions/alert";
import {maxBy} from "lodash";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = {
    selectedCounter: {},
    selectedBuildingCounter: {},
    counter_model: [],
    counter_model_is_building: false,
    flat_counter: [],
    building_counter: [],
    counter_model_select: [],
    counter_model_select_loaded: false,
    counter_readings: [],
    building_counter_readings: [],
    counter_readings_filtered: [],
    building_counter_readings_filtered: [],
    flat_counter_operations: [],
    flat_counter_operations_filtered: [],
    flat_counter_sub_accounts: []
};

const getters = {
    getSelectedCounter: state => state.selectedCounter,
    getSelectedBuildingCounter: state => state.selectedBuildingCounter,
    getCounterModel: state => state.counter_model,
    getCounterModelSelect: state => state.counter_model_select,
    getFlatCounter_: state => state.flat_counter,
    getFlatCounterLegacy: state => state.flat_counter,
    getBuildingCounter_: state => state.building_counter,
    getFlatMeterReading: state => state.counter_readings_filtered,
    getFlatMeterReadingLegacy: state => state.counter_readings_filtered,
    getBuildingMeterReading: state => state.building_counter_readings_filtered,
    getFlatCounterOperation: state => state.flat_counter_operations_filtered,
    getFlatCounterSubAccount: state => state.flat_counter_sub_accounts
}

const actions = {
    [GET_FLAT_MODEL_COUNTER]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            flatCounterAPI.get_counter_model(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_FLAT_MODEL_COUNTER, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [GET_FLAT_COUNTER]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            flatCounterAPI.get_flat_counter(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_FLAT_COUNTER, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [GET_BUILDING_COUNTER]: ({commit}) => {
        return new Promise((resolve, reject) => {
            buildingCounterAPI.get_counter()
                .then(response => response.data)
                .then(data => {
                    commit(GET_BUILDING_COUNTER, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [GET_FLAT_COUNTER_BY_ID]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            flatCounterAPI.get_flat_counter_by_id(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_FLAT_COUNTER_BY_ID, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [GET_BUILDING_COUNTER_BY_ID]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            buildingCounterAPI.get_counter_by_id(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_BUILDING_COUNTER_BY_ID, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [GET_FLAT_COUNTER_READING]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            flatCounterAPI.get_flat_counter_reading(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_FLAT_COUNTER_READING, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [GET_ALL_FLAT_COUNTER_OPERATION]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            flatCounterAPI.get_flat_counter_operation(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_ALL_FLAT_COUNTER_OPERATION, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [GET_ALL_FLAT_COUNTER_SUB_ACCOUNT]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            flatCounterAPI.get_flat_counter_sub_account(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_ALL_FLAT_COUNTER_SUB_ACCOUNT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [GET_BUILDING_COUNTER_READING]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            buildingCounterAPI.get_counter_reading(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_BUILDING_COUNTER_READING, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [CREATE_FLAT_MODEL_COUNTER]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatCounterAPI.create_counter_model(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_FLAT_MODEL_COUNTER, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CREATE_FLAT_COUNTER]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatCounterAPI.create_flat_counter(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_FLAT_COUNTER, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CREATE_FLAT_COUNTER_OPERATION]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatCounterAPI.create_counter_operation(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_FLAT_COUNTER_OPERATION, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CREATE_FLAT_COUNTER_SUB_ACCOUNT]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatCounterAPI.create_counter_sub_account(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_FLAT_COUNTER_SUB_ACCOUNT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CREATE_FLAT_COUNTER_LEGACY]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatCounterAPI.create_flat_counter_legacy(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_FLAT_COUNTER_LEGACY, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CREATE_BUILDING_COUNTER]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            buildingCounterAPI.create_counter(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_BUILDING_COUNTER, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CREATE_FLAT_COUNTER_READING]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatCounterAPI.create_flat_counter_reading(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_FLAT_COUNTER_READING, data)

                    const local_payload = {
                        flat_id: payload.flat_id,
                        flat_counter_id: payload.flat_counter_id,
                        month: payload.month
                    }
                    flatCounterAPI.get_flat_counter_by_id(local_payload)
                        .then(response => response.data)
                        .then(data => {
                            commit(GET_FLAT_COUNTER_BY_ID, data)
                            resolve(true)
                        })
                        .catch(err => {
                            const error = err.response.data.detail;
                            commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                            reject(false)
                        })
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CREATE_FLAT_COUNTER_LEGACY_READING]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatCounterAPI.create_flat_counter_reading_legacy(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_FLAT_COUNTER_LEGACY_READING, data)

                    const local_payload = {
                        flat_id: payload.flat_id,
                        flat_counter_id: payload.flat_counter_id,
                        month: payload.month
                    }
                    flatCounterAPI.get_flat_counter_by_id(local_payload)
                        .then(response => response.data)
                        .then(data => {
                            commit(GET_FLAT_COUNTER_BY_ID, data)
                            resolve(true)
                        })
                        .catch(err => {
                            const error = err.response.data.detail;
                            commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                            reject(false)
                        })
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CREATE_BUILDING_COUNTER_READING]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            buildingCounterAPI.create_counter_reading(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_BUILDING_COUNTER_READING, data)

                    const local_payload = {
                        building_counter_id: payload.building_counter_id,
                        month: payload.month
                    }
                    buildingCounterAPI.get_counter_by_id(local_payload)
                        .then(response => response.data)
                        .then(data => {
                            commit(GET_BUILDING_COUNTER_BY_ID, data)
                            resolve(true)
                        })
                        .catch(err => {
                            const error = err.response.data.detail;
                            commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                            reject(false)
                        })
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_FLAT_MODEL_COUNTER]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatCounterAPI.update_counter_model(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_FLAT_MODEL_COUNTER, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_FLAT_COUNTER]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatCounterAPI.update_flat_counter(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_FLAT_COUNTER, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_FLAT_COUNTER_OPERATION]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatCounterAPI.update_counter_operation(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_FLAT_COUNTER_OPERATION, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_FLAT_COUNTER_SUB_ACCOUNT]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatCounterAPI.update_counter_sub_account(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_FLAT_COUNTER_SUB_ACCOUNT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_FLAT_COUNTER_LEGACY]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatCounterAPI.update_flat_counter_legacy(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_FLAT_COUNTER_LEGACY, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_BUILDING_COUNTER]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            buildingCounterAPI.update_counter(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_BUILDING_COUNTER, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_FLAT_MODEL_COUNTER]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatCounterAPI.remove_counter_model(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_FLAT_MODEL_COUNTER, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_FLAT_COUNTER]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatCounterAPI.remove_flat_counter(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_FLAT_COUNTER, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_FLAT_COUNTER_OPERATION]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatCounterAPI.remove_counter_operation(payload)
                .then(response => response.data)
                .then(data => {
                    commit(DELETE_FLAT_COUNTER_OPERATION, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_FLAT_COUNTER_SUB_ACCOUNT]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatCounterAPI.remove_counter_sub_account(payload)
                .then(response => response.data)
                .then(data => {
                    commit(DELETE_FLAT_COUNTER_SUB_ACCOUNT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_FLAT_COUNTER_LEGACY]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatCounterAPI.remove_flat_counter_legacy(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_FLAT_COUNTER_LEGACY, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_BUILDING_COUNTER]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            buildingCounterAPI.remove_counter(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_BUILDING_COUNTER, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_FLAT_COUNTER_READING]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatCounterAPI.remove_flat_counter_reading(payload.id, payload.calc || true, payload.cabinet || false)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_FLAT_COUNTER_READING, data)
                    flatCounterAPI.get_flat_counter_by_id(payload)
                        .then(response => response.data)
                        .then(data => {
                            commit(GET_FLAT_COUNTER_BY_ID, data)
                            resolve(true)
                        })
                        .catch(err => {
                            const error = err.response.data.detail;
                            commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                            reject(false)
                        })
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_FLAT_COUNTER_LEGACY_READING]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatCounterAPI.remove_flat_counter_reading_legacy(payload.id, payload.calc || true)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_FLAT_COUNTER_LEGACY_READING, data)
                    flatCounterAPI.get_flat_counter_by_id(payload)
                        .then(response => response.data)
                        .then(data => {
                            commit(GET_FLAT_COUNTER_BY_ID, data)
                            resolve(true)
                        })
                        .catch(err => {
                            const error = err.response.data.detail;
                            commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                            reject(false)
                        })
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_BUILDING_COUNTER_READING]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            buildingCounterAPI.remove_counter_reading(payload.id)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_BUILDING_COUNTER_READING, data)
                    buildingCounterAPI.get_counter_by_id(payload)
                        .then(response => response.data)
                        .then(data => {
                            commit(GET_BUILDING_COUNTER_BY_ID, data)
                            resolve(true)
                        })
                        .catch(err => {
                            const error = err.response.data.detail;
                            commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                            reject(false)
                        })
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [RECALCULATION_BUILDING_COUNTER]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            buildingCounterAPI.recalculation_counter_reading(payload)
                .then(response => response.data)
                .then(() => {
                    buildingCounterAPI.get_counter_by_id(payload)
                        .then(response => response.data)
                        .then(data => {
                            commit(GET_BUILDING_COUNTER_BY_ID, data)
                            resolve(true)
                        })
                        .catch(err => {
                            const error = err.response.data.detail;
                            commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                            reject(false)
                        })
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [SELECT_FLAT_MODEL_COUNTER]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            flatCounterAPI.select_counter_model(payload)
                .then(response => response.data)
                .then(data => {
                    commit("COUNTER_MODEL_IS_BUILDING", payload)
                    commit(SELECT_FLAT_MODEL_COUNTER, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [COUNTER_SELECT]: ({commit}, payload) => {
        commit(COUNTER_SELECT, payload)
    },
    [BUILDING_COUNTER_SELECT]: ({commit}, payload) => {
        commit(BUILDING_COUNTER_SELECT, payload)
    }
}

const mutations = {
    [GET_FLAT_MODEL_COUNTER]: (state, payload) => {
        state.counter_model = payload
    },
    [GET_FLAT_COUNTER]: (state, payload) => {
        const local_payload = payload.slice()
        local_payload.forEach((item, idx) => {
            Object.assign(item, {row_num: idx + 1})
        })
        state.flat_counter = local_payload

        const arr = state.flat_counter.filter(i => i.counter_minus_id).map(item => {
            return item.counter_minus_id
        })
        state.flat_counter.forEach(i => {
            if (!i.last_operation_type) {
                i.last_operation_type = 'installation'
            }
            if (arr.includes(i.id)) {
                i.counter_minus_from_id = i.id
            }
        })
        // if (state.flat_counter.length > 0) {
        //     state.selectedCounter = state.flat_counter[0]
        // } else {
        //     state.selectedCounter = {}
        // }
    },
    [GET_BUILDING_COUNTER]: (state, payload) => {
        const local_payload = payload.slice()
        local_payload.forEach((item, idx) => {
            Object.assign(item, {row_num: idx + 1})
        })
        state.building_counter = local_payload
    },
    [GET_FLAT_COUNTER_BY_ID]: (state, payload) => {
        const flat_obj = state.flat_counter.find(item => item.id === payload.flat_counter_id)
        if (flat_obj) {
            flat_obj.current_meter_reading_first = payload.current_meter_reading_first
            flat_obj.current_meter_reading_second = payload.current_meter_reading_second
            flat_obj.current_meter_reading_third = payload.current_meter_reading_third
        }
    },
    [GET_BUILDING_COUNTER_BY_ID]: (state, payload) => {
        const building_obj = state.building_counter.find(item => item.id === payload.building_counter_id)
        if (building_obj) {
            building_obj.current_meter_reading_first = payload.current_meter_reading_first
            building_obj.current_meter_reading_second = payload.current_meter_reading_second
            building_obj.current_meter_reading_third = payload.current_meter_reading_third
        }
    },
    [GET_FLAT_COUNTER_READING]: (state, payload) => {
        // state.counter_readings = payload
        const local_payload = []
        payload.forEach(item => {
            if (item.flat_counter.zone === null || item.flat_counter.zone === 1 || item.flat_counter.zone === 0) {
                local_payload.push(Object.assign({}, item, {
                    zone_name: 'Без зони',
                    contract_name: item.flat_counter.contract_name
                }))
            }
            if (item.flat_counter.zone === 2) {
                local_payload.push(Object.assign({}, item, {
                    zone_name: 'День',
                    contract_name: item.flat_counter.contract_name,
                    color: 'success'
                }))
                local_payload.push(
                    Object.assign({}, item, {
                        prev_value_first: item.prev_value_second,
                        current_value_first: item.current_value_second,
                        value_difference_first: item.value_difference_second,
                        zone_name: 'Ніч',
                        contract_name: item.flat_counter.contract_name,
                        color: 'secondary'
                    })
                )
            }
            if (item.flat_counter.zone === 3) {
                local_payload.push(Object.assign({}, item, {
                    zone_name: 'День',
                    contract_name: item.flat_counter.contract_name,
                    color: 'success'
                }))
                local_payload.push(
                    Object.assign({}, item, {
                        prev_value_first: item.prev_value_second,
                        current_value_first: item.current_value_second,
                        value_difference_first: item.value_difference_second,
                        zone_name: 'Пік',
                        contract_name: item.flat_counter.contract_name,
                        color: 'error lighten-1'
                    })
                )
                local_payload.push(
                    Object.assign({}, item, {
                        prev_value_first: item.prev_value_third,
                        current_value_first: item.current_value_third,
                        value_difference_first: item.value_difference_third,
                        zone_name: 'Ніч',
                        contract_name: item.flat_counter.contract_name,
                        color: 'secondary'
                    })
                )
            }

        })
        state.counter_readings = local_payload
    },
    [GET_ALL_FLAT_COUNTER_OPERATION]: (state, payload) => {
        state.flat_counter_operations = payload

        if (state.selectedCounter.id) {
            state.flat_counter_operations_filtered = state.flat_counter_operations.filter(
                item => item.flat_counter_id === state.selectedCounter.id)
        } else {
            state.flat_counter_operations_filtered = []
        }
    },
    [GET_ALL_FLAT_COUNTER_SUB_ACCOUNT]: (state, payload) => {
        state.flat_counter_sub_accounts = payload
    },
    [GET_BUILDING_COUNTER_READING]: (state, payload) => {
        // state.counter_readings = payload
        const local_payload = []
        payload.forEach(item => {
            if (item.value_results.length) {
                item.result = 'Відкрити'
            }
            if (item.building_counter.zone === null || item.building_counter.zone === 1 || item.building_counter.zone === 0) {
                local_payload.push(Object.assign({}, item, {zone_name: 'Без зони'}))
            }
            if (item.building_counter.zone === 2) {
                local_payload.push(Object.assign({}, item, {zone_name: 'День'}))
                local_payload.push(
                    Object.assign({}, item, {
                        prev_value_first: item.prev_value_second,
                        current_value_first: item.current_value_second,
                        value_difference_first: item.value_difference_second,
                        zone_name: 'Ніч'
                    })
                )
            }
            if (item.building_counter.zone === 3) {
                local_payload.push(Object.assign({}, item, {zone_name: 'Напівпік'}))
                local_payload.push(
                    Object.assign({}, item, {
                        prev_value_first: item.prev_value_second,
                        current_value_first: item.current_value_second,
                        value_difference_first: item.value_difference_second,
                        zone_name: 'Пік'
                    })
                )
                local_payload.push(
                    Object.assign({}, item, {
                        prev_value_first: item.prev_value_third,
                        current_value_first: item.current_value_third,
                        value_difference_first: item.value_difference_third,
                        zone_name: 'Ніч'
                    })
                )
            }
        })
        state.building_counter_readings = local_payload
        if (state.selectedBuildingCounter) {
            state.building_counter_readings_filtered = state.building_counter_readings.filter(item => item.building_counter_id === state.selectedBuildingCounter.id)
        } else {
            state.building_counter_readings_filtered = []
        }
    },
    [CREATE_FLAT_MODEL_COUNTER]: (state, payload) => {
        state.counter_model.push(payload)
        if (state.counter_model_select_loaded) {
            state.counter_model_select.push({
                value: payload.id, text: payload.name
            })
        }
    },
    [UPDATE_FLAT_MODEL_COUNTER]: (state, payload) => {
        let obj = state.counter_model.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })

        if (state.counter_model_select_loaded) {
            let obj_select = state.counter_model_select.find(item => item.value === payload.id)
            obj_select.text = payload.name
            obj_select.value = payload.id
        }

    },
    [REMOVE_FLAT_MODEL_COUNTER]: (state, payload) => {
        const obg = state.counter_model.find(item => item.id === payload.id)
        const obj_id = state.counter_model.indexOf(obg)
        if (obg) {
            state.counter_model.splice(obj_id, 1)
        }
        if (state.counter_model_select_loaded) {
            const obj_select = state.counter_model_select.find(item => item.value === payload.id)
            const obj_select_id = state.counter_model_select.indexOf(obj_select)
            if (obj_select) {
                state.counter_model_select.splice(obj_select_id, 1)
            }
        }
    },
    [SELECT_FLAT_MODEL_COUNTER]: (state, payload) => {
        state.counter_model_select = payload
        state.counter_model_select_loaded = true
    },
    [CREATE_FLAT_COUNTER]: (state, payload) => {
        const max_row_item = maxBy(payload, 'row_num')
        let max_row = 0
        if (max_row_item) {
            max_row = max_row_item.row_num
        }
        state.flat_counter.push(Object.assign({}, payload, {row_num: max_row + 1}))
    },
    [CREATE_FLAT_COUNTER_LEGACY]: (state, payload) => {
        const max_row_item = maxBy(payload, 'row_num')
        let max_row = 0
        if (max_row_item) {
            max_row = max_row_item.row_num
        }
        state.flat_counter.push(Object.assign({}, payload, {row_num: max_row + 1}))
    },
    [CREATE_BUILDING_COUNTER]: (state, payload) => {
        const max_row_item = maxBy(payload, 'row_num')
        let max_row = 0
        if (max_row_item) {
            max_row = max_row_item.row_num
        }
        state.building_counter.push(Object.assign({}, payload, {row_num: max_row + 1}))
    },
    [UPDATE_FLAT_COUNTER]: (state, payload) => {
        let obj = state.flat_counter.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [UPDATE_FLAT_COUNTER_LEGACY]: (state, payload) => {
        let obj = state.flat_counter.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [UPDATE_BUILDING_COUNTER]: (state, payload) => {
        let obj = state.building_counter.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [REMOVE_FLAT_COUNTER]: (state, payload) => {
        const obg = state.flat_counter.find(item => item.id === payload.id)
        const obj_id = state.flat_counter.indexOf(obg)
        if (obg) {
            state.flat_counter.splice(obj_id, 1)
        }
        state.counter_readings_filtered = []
        state.flat_counter_operations_filtered = []
    },
    [REMOVE_FLAT_COUNTER_LEGACY]: (state, payload) => {
        const obg = state.flat_counter.find(item => item.id === payload.id)
        const obj_id = state.flat_counter.indexOf(obg)
        if (obg) {
            state.flat_counter.splice(obj_id, 1)
        }
    },
    [REMOVE_BUILDING_COUNTER]: (state, payload) => {
        const obg = state.building_counter.find(item => item.id === payload.id)
        const obj_id = state.building_counter.indexOf(obg)
        if (obg) {
            state.building_counter.splice(obj_id, 1)
        }
    },
    [CREATE_FLAT_COUNTER_READING]: (state, payload) => {
        let local_payload = []

        if (payload.flat_counter.zone === null || payload.flat_counter.zone === 1 || payload.flat_counter.zone === 0) {
            local_payload.push(Object.assign({}, payload, {zone_name: 'Без зони'}))
        }
        if (payload.flat_counter.zone === 2) {
            local_payload.push(Object.assign({}, payload, {zone_name: 'День'}))
            local_payload.push(
                Object.assign({}, payload, {
                    prev_value_first: payload.prev_value_second,
                    current_value_first: payload.current_value_second,
                    value_difference_first: payload.value_difference_second,
                    zone_name: 'Ніч'
                })
            )
        }
        if (payload.flat_counter.zone === 3) {
            local_payload.push(Object.assign({}, payload, {zone_name: 'Напівпік'}))
            local_payload.push(
                Object.assign({}, payload, {
                    prev_value_first: payload.prev_value_second,
                    current_value_first: payload.current_value_second,
                    value_difference_first: payload.value_difference_second,
                    zone_name: 'Пік'
                })
            )
            local_payload.push(
                Object.assign({}, payload, {
                    prev_value_first: payload.prev_value_third,
                    current_value_first: payload.current_value_third,
                    value_difference_first: payload.value_difference_third,
                    zone_name: 'Ніч'
                })
            )
        }

        local_payload.forEach(item => {
            state.counter_readings.unshift(item)
        })

        if (state.selectedCounter.id) {
            state.counter_readings_filtered = state.counter_readings.filter(item => item.flat_counter_id === state.selectedCounter.id)
        } else {
            state.counter_readings_filtered = []
        }
    },
    [CREATE_FLAT_COUNTER_OPERATION]: (state, payload) => {
        state.flat_counter_operations.push(payload)

        state.flat_counter.filter(item => item.id === payload.flat_counter_id).forEach(i => {
            i.last_operation_date = payload.last_operation_date
            i.last_operation_type = payload.last_operation_type
            i.date_start = payload.date_start
            i.date_end = payload.date_end
        })

        if (state.selectedCounter.id) {
            state.flat_counter_operations_filtered = state.flat_counter_operations.filter(
                item => item.flat_counter_id === state.selectedCounter.id)
        } else {
            state.flat_counter_operations_filtered = []
        }
    },
    [CREATE_FLAT_COUNTER_SUB_ACCOUNT]: (state, payload) => {
        state.flat_counter_sub_accounts.push(payload)
    },
    [UPDATE_FLAT_COUNTER_OPERATION]: (state, payload) => {
        let obj = state.flat_counter_operations.find(item => item.id === payload.id)

        state.flat_counter.filter(item => item.id === payload.flat_counter_id).forEach(i => {
            i.last_operation_date = payload.last_operation_date
            i.last_operation_type = payload.last_operation_type
            i.date_start = payload.date_start
            i.date_end = payload.date_end
        })

        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [UPDATE_FLAT_COUNTER_SUB_ACCOUNT]: (state, payload) => {
        let obj = state.flat_counter_sub_accounts.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [CREATE_FLAT_COUNTER_LEGACY_READING]: (state, payload) => {
        let local_payload = []

        if (payload.flat_counter.zone === null || payload.flat_counter.zone === 1 || payload.flat_counter.zone === 0) {
            local_payload.push(Object.assign({}, payload, {
                zone_name: 'Без зони',
                contract_name: payload.flat_counter.contract_name
            }))
        }
        if (payload.flat_counter.zone === 2) {
            local_payload.push(Object.assign({}, payload, {zone_name: 'День'}))
            local_payload.push(
                Object.assign({}, payload, {
                    prev_value_first: payload.prev_value_second,
                    current_value_first: payload.current_value_second,
                    value_difference_first: payload.value_difference_second,
                    zone_name: 'Ніч',
                    contract_name: payload.flat_counter.contract_name
                })
            )
        }
        if (payload.flat_counter.zone === 3) {
            local_payload.push(Object.assign({}, payload, {zone_name: 'Напівпік'}))
            local_payload.push(
                Object.assign({}, payload, {
                    prev_value_first: payload.prev_value_second,
                    current_value_first: payload.current_value_second,
                    value_difference_first: payload.value_difference_second,
                    zone_name: 'Пік',
                    contract_name: payload.flat_counter.contract_name
                })
            )
            local_payload.push(
                Object.assign({}, payload, {
                    prev_value_first: payload.prev_value_third,
                    current_value_first: payload.current_value_third,
                    value_difference_first: payload.value_difference_third,
                    zone_name: 'Ніч',
                    contract_name: payload.flat_counter.contract_name
                })
            )
        }

        local_payload.forEach(item => {
            state.counter_readings.unshift(item)
        })

        if (state.selectedCounter.id) {
            state.counter_readings_filtered = state.counter_readings.filter(item => item.flat_counter_id === state.selectedCounter.id)
        } else {
            state.counter_readings_filtered = []
        }
    },
    [CREATE_BUILDING_COUNTER_READING]: (state, payload) => {
        let local_payload = []

        if (payload.value_results.length) {
            payload.result = 'Відкрити'
        }

        const prev_ = state.building_counter_readings.filter(item => item.month === payload.month) || []
        if (prev_) {
            prev_.forEach(item => {
                item.result = ''
                item.value_results = []
            })
        }


        if (payload.building_counter.zone === null || payload.building_counter.zone === 1 || payload.building_counter.zone === 0) {
            local_payload.push(Object.assign({}, payload, {zone_name: 'Без зони'}))
        }
        if (payload.building_counter.zone === 2) {
            local_payload.push(Object.assign({}, payload, {zone_name: 'День'}))
            local_payload.push(
                Object.assign({}, payload, {
                    prev_value_first: payload.prev_value_second,
                    current_value_first: payload.current_value_second,
                    value_difference_first: payload.value_difference_second,
                    zone_name: 'Ніч'
                })
            )
        }
        if (payload.building_counter.zone === 3) {
            local_payload.push(Object.assign({}, payload, {zone_name: 'Напівпік'}))
            local_payload.push(
                Object.assign({}, payload, {
                    prev_value_first: payload.prev_value_second,
                    current_value_first: payload.current_value_second,
                    value_difference_first: payload.value_difference_second,
                    zone_name: 'Пік'
                })
            )
            local_payload.push(
                Object.assign({}, payload, {
                    prev_value_first: payload.prev_value_third,
                    current_value_first: payload.current_value_third,
                    value_difference_first: payload.value_difference_third,
                    zone_name: 'Ніч'
                })
            )
        }

        local_payload.forEach(item => {
            state.building_counter_readings.push(item)
        })

        if (state.selectedBuildingCounter.id) {
            state.building_counter_readings_filtered = state.building_counter_readings.filter(item => item.building_counter_id === state.selectedBuildingCounter.id)
        } else {
            state.building_counter_readings_filtered = []
        }
    },
    [REMOVE_FLAT_COUNTER_READING]: (state, payload) => {
        state.counter_readings = state.counter_readings.filter(item => item.id !== payload.id)

        if (state.selectedCounter.id) {
            state.counter_readings_filtered = state.counter_readings.filter(item => item.flat_counter_id === state.selectedCounter.id)
        } else {
            state.counter_readings_filtered = []
        }

    },
    [DELETE_FLAT_COUNTER_OPERATION]: (state, payload) => {
        state.flat_counter_operations = state.flat_counter_operations.filter(item => item.id !== payload.id)

        if (state.selectedCounter.id) {
            state.flat_counter_operations_filtered = state.flat_counter_operations.filter(
                item => item.flat_counter_id === state.selectedCounter.id)
        } else {
            state.flat_counter_operations_filtered = []
        }

    },
    [DELETE_FLAT_COUNTER_SUB_ACCOUNT]: (state, payload) => {
        const obg = state.flat_counter_sub_accounts.find(item => item.id === payload.id)
        const obj_id = state.flat_counter_sub_accounts.indexOf(obg)
        if (obg) {
            state.flat_counter_sub_accounts.splice(obj_id, 1)
        }
    },
    [REMOVE_FLAT_COUNTER_LEGACY_READING]: (state, payload) => {
        state.counter_readings = state.counter_readings.filter(item => item.id !== payload.id)

        if (state.selectedCounter.id) {
            state.counter_readings_filtered = state.counter_readings.filter(item => item.flat_counter_id === state.selectedCounter.id)
        } else {
            state.counter_readings_filtered = []
        }

    },
    [REMOVE_BUILDING_COUNTER_READING]: (state, payload) => {
        state.building_counter_readings = state.building_counter_readings.filter(item => item.id !== payload.id)

        if (state.selectedBuildingCounter.id) {
            state.building_counter_readings_filtered = state.building_counter_readings.filter(item => item.building_counter_id === state.selectedBuildingCounter.id)
        } else {
            state.building_counter_readings_filtered = []
        }

    },
    [COUNTER_SELECT]: (state, payload) => {
        state.selectedCounter = payload
        if (!payload) {
            state.counter_readings_filtered = []
            state.flat_counter_operations_filtered = []
        }
        if (payload.id) {
            state.counter_readings_filtered = state.counter_readings.filter(item => item.flat_counter_id === payload.id)
            state.flat_counter_operations_filtered = state.flat_counter_operations.filter(
                item => item.flat_counter_id === payload.id)
        } else {
            state.counter_readings_filtered = []
            state.flat_counter_operations_filtered = []
        }
    },
    [BUILDING_COUNTER_SELECT]: (state, payload) => {
        state.selectedBuildingCounter = payload
        if (payload.id) {
            state.building_counter_readings_filtered = state.building_counter_readings.filter(item => item.building_counter_id === payload.id)
        } else {
            state.building_counter_readings_filtered = []
        }
    },
    ["COUNTER_MODEL_IS_BUILDING"] : (state, payload) => {
        state.counter_model_is_building = payload
    }
}

export default {
    state,
    getters,
    actions,
    mutations
};