import {
    GET_BANK,
    GET_BANK_ACCOUNT,
    CREATE_BANK,
    CREATE_BANK_ACCOUNT,
    UPDATE_BANK,
    UPDATE_BANK_ACCOUNT,
    REMOVE_BANK,
    REMOVE_BANK_ACCOUNT,
    SELECT_BANK,
    SELECT_BANK_ACCOUNT
} from "@/store/actions/bank";

import {ALERT_SHOW} from "@/store/actions/alert";
import bankAPI from "@/utils/axios/bank"
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = { banks: [], banks_select: [], bank_accounts: [], bank_accounts_select: [], bank_account_default: {}}

const getters = {
    getBanks: state => state.banks,
    getBankSelect: state => state.banks_select,
    getBankAccounts: state => state.bank_accounts,
    getBankAccountsByOrganization: state => state.bank_accounts.filter(i => !i.building_id),
    getBankAccountsSelect: state => state.bank_accounts_select,
    getDefaultBankAccount: state => state.bank_account_default
}

const actions = {
    [GET_BANK]: ({commit}) => {
        return new Promise((resolve, reject) => {
            bankAPI.get_banks()
                .then(response => response.data)
                .then(data => {
                    commit(GET_BANK, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [GET_BANK_ACCOUNT]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            bankAPI.get_bank_accounts(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_BANK_ACCOUNT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_BANK]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            bankAPI.create_bank(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_BANK, data)
                    dispatch(SELECT_BANK)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CREATE_BANK_ACCOUNT]: ({ dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            bankAPI.create_bank_account(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_BANK_ACCOUNT, data)
                    dispatch(SELECT_BANK_ACCOUNT)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_BANK]: ({ dispatch, commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            bankAPI.update_bank(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_BANK, data)
                    dispatch(SELECT_BANK)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_BANK_ACCOUNT]: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            bankAPI.update_bank_account(payload)
                .then(response => response.data)
                .then(() => {
                    dispatch(GET_BANK_ACCOUNT)
                    dispatch(SELECT_BANK_ACCOUNT)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_BANK]: ({ dispatch, commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            bankAPI.remove_bank(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_BANK, data)
                    dispatch(SELECT_BANK)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_BANK_ACCOUNT]: ({ dispatch, commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            bankAPI.remove_bank_account(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_BANK_ACCOUNT, data)
                    dispatch(SELECT_BANK_ACCOUNT)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_BANK]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            bankAPI.select_bank()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_BANK, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [SELECT_BANK_ACCOUNT]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            bankAPI.select_bank_accounts()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_BANK_ACCOUNT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    }
}

const mutations = {
    [GET_BANK]: (state, payload) => {
        state.banks = payload
    },
    [GET_BANK_ACCOUNT]: (state, payload) => {
        state.bank_accounts = payload
        state.bank_accounts.forEach(i => {
            if (i.main) {
                state.bank_account_default = Object.assign({}, i)
            }
        })
    },
    [SELECT_BANK]: (state, payload) => {
        state.banks_select = payload
    },
    [SELECT_BANK_ACCOUNT]: (state, payload) => {
        state.bank_accounts_select = payload
    },
    [CREATE_BANK]: (state, payload) => {
        state.banks.push(payload)
    },
    [CREATE_BANK_ACCOUNT]: (state, payload) => {
        state.bank_accounts.push(payload)
        state.bank_accounts.forEach(i => {
            if (i.main) {
                state.bank_account_default = Object.assign({}, i)
            }
        })
    },
    [UPDATE_BANK]: (state, payload) => {
        const obj = state.banks.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_BANK]: (state, payload) => {
        const obg = state.banks.find(item => item.id === payload.id)
        const obj_id = state.banks.indexOf(obg)
        if (obg) {
            state.banks.splice(obj_id, 1)
        }
    },
    [REMOVE_BANK_ACCOUNT]: (state, payload) => {
        const obg = state.bank_accounts.find(item => item.id === payload.id)
        const obj_id = state.bank_accounts.indexOf(obg)
        if (obg) {
            state.bank_accounts.splice(obj_id, 1)
        }
        state.bank_accounts.forEach(i => {
            if (i.main) {
                state.bank_account_default = Object.assign({}, i)
            }
        })
    },
}


export default {
    state,
    getters,
    actions,
    mutations
}