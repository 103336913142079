export const FETCH_FLATS = "FETCH_FLATS"
export const CLEAR_FLATS = "CLEAR_FLATS"
export const GET_FLAT = "GET_FLAT"
export const CREATE_FLAT = "CREATE_FLAT"
export const UPDATE_FLAT = "UPDATE_FLAT"
export const SEARCH_FLAT_BY_TEXT = "SEARCH_FLAT_BY_TEXT"
export const NEXT_ITEM = "NEXT_ITEM"
export const SELECT_FLATS = "SELECT_FLATS"


export const FETCH_LEGACY_FLATS = "FETCH_LEGACY_FLATS"
export const GET_LEGACY_FLAT = "GET_LEGACY_FLAT"
export const CREATE_LEGACY_FLAT = "CREATE_LEGACY_FLAT"
export const UPDATE_LEGACY_FLAT = "UPDATE_LEGACY_FLAT"
export const SEARCH_LEGACY_FLAT_BY_TEXT = "SEARCH_LEGACY_FLAT_BY_TEXT"
export const NEXT_LEGACY_ITEM = "NEXT_LEGACY_ITEM"
export const SELECT_LEGACY_FLATS = "SELECT_LEGACY_FLATS"