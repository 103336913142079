import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/accounting/chart-of-account';

export default {
    get_all(payload) {
        return axios.get(`${RESOURCE_NAME}/`, { params: payload || {} })
    },
    get_by_id(payload) {
        return axios.get(`${RESOURCE_NAME}/by_id`, { params: payload || {} })
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { "remove_id": payload })
    },
    select(payload) {
        return axios.post(`${RESOURCE_NAME}/select`, payload || {})
    },
};