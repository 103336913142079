import {
    CREATE_SITE_GALLERY,
    CREATE_SITE_NEWS, CREATE_SITE_NOTIFY, GET_SITE_GALLERY,
    GET_SITE_NEWS, GET_SITE_NOTIFY, REMOVE_SITE_GALLERY, REMOVE_SITE_NEWS, REMOVE_SITE_NOTIFY, UPDATE_SITE_GALLERY,
    UPDATE_SITE_NEWS, UPDATE_SITE_NOTIFY,
} from "@/store/actions/personalSite/site";

import SiteTableAPI from "@/utils/axios/site_table"
import {ALERT_SHOW} from "@/store/actions/alert";


const state = {site_table_notify: [], site_table_news: [], site_table_gallery: []}

const getters = {
    getNews: state => state.site_table_news,
    getNotification: state => state.site_table_notify,
    getGallery: state => state.site_table_gallery
}

const actions = {
    [GET_SITE_NEWS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            SiteTableAPI.get_news(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_SITE_NEWS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [GET_SITE_NOTIFY]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            SiteTableAPI.get_notify(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_SITE_NOTIFY, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [GET_SITE_GALLERY]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            SiteTableAPI.get_gallery(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_SITE_GALLERY, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [CREATE_SITE_NEWS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            SiteTableAPI.create_news(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_SITE_NEWS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [CREATE_SITE_NOTIFY]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            SiteTableAPI.create_notify(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_SITE_NOTIFY, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [CREATE_SITE_GALLERY]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            SiteTableAPI.create_gallery(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_SITE_GALLERY, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [UPDATE_SITE_NEWS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            SiteTableAPI.update_news(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_SITE_NEWS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [UPDATE_SITE_NOTIFY]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            SiteTableAPI.update_notify(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_SITE_NOTIFY, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [UPDATE_SITE_GALLERY]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            SiteTableAPI.update_gallery(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_SITE_GALLERY, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [REMOVE_SITE_NEWS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            SiteTableAPI.remove_news(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_SITE_NEWS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [REMOVE_SITE_NOTIFY]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            SiteTableAPI.remove_notify(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_SITE_NOTIFY, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [REMOVE_SITE_GALLERY]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            SiteTableAPI.remove_gallery(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_SITE_GALLERY, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
}

const mutations = {
    [GET_SITE_NEWS]: (state, payload) => {
        state.site_table_news = payload
    },
    [GET_SITE_NOTIFY]: (state, payload) => {
        state.site_table_notify = payload
    },
    [GET_SITE_GALLERY]: (state, payload) => {
        state.site_table_gallery = payload
    },
    [CREATE_SITE_NEWS]: (state, payload) => {
        state.site_table_news.push(payload)
    },
    [CREATE_SITE_NOTIFY]: (state, payload) => {
        state.site_table_notify.push(payload)
    },
    [CREATE_SITE_GALLERY]: (state, payload) => {
        state.site_table_gallery.push(payload)
    },
    [UPDATE_SITE_NEWS]: (state, payload) => {
        const doc_item = state.site_table_news.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            doc_item[item] = payload[item]
        })
    },
    [UPDATE_SITE_NOTIFY]: (state, payload) => {
        const doc_item = state.site_table_notify.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            doc_item[item] = payload[item]
        })
    },
    [UPDATE_SITE_GALLERY]: (state, payload) => {
        const doc_item = state.site_table_gallery.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            doc_item[item] = payload[item]
        })
    },
    [REMOVE_SITE_NEWS]: (state, payload) => {
        const obg = state.site_table_news.find(item => item.id === payload)
        const obj_id = state.site_table_news.indexOf(obg)
        if (obg) {
            state.site_table_news.splice(obj_id, 1)
        }
    },
    [REMOVE_SITE_NOTIFY]: (state, payload) => {
        const obg = state.site_table_notify.find(item => item.id === payload)
        const obj_id = state.site_table_notify.indexOf(obg)
        if (obg) {
            state.site_table_notify.splice(obj_id, 1)
        }
    },
    [REMOVE_SITE_GALLERY]: (state, payload) => {
        const obg = state.site_table_gallery.find(item => item.id === payload)
        const obj_id = state.site_table_gallery.indexOf(obg)
        if (obg) {
            state.site_table_gallery.splice(obj_id, 1)
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}