export const VOTE_ITEM_GET_ALL = "VOTE_ITEM_GET_ALL"
export const VOTE_ITEM_CREATE = "VOTE_ITEM_CREATE"
export const VOTE_ITEM_UPDATE = "VOTE_ITEM_UPDATE"
export const VOTE_ITEM_REMOVE = "VOTE_ITEM_REMOVE"
export const VOTE_ITEM_READ = "VOTE_ITEM_READ"
export const VOTE_ITEM_SHOW_FULL_TEXT = "VOTE_ITEM_SHOW_FULL_TEXT"

export const VOTE_ADD = "VOTE_ADD"
export const VOTE_UPDATE_OPTION = "VOTE_UPDATE_OPTION"
export const VOTE_UPDATE_USER_VOTED = "VOTE_UPDATE_USER_VOTED"

export const VOTE_COMMENT_GET_ALL = "VOTE_COMMENT_GET_ALL"
export const VOTE_COMMENT_CREATE = "VOTE_COMMENT_CREATE"
export const VOTE_COMMENT_UPDATE_LAST = "VOTE_COMMENT_UPDATE_LAST"

export const VOTE_GET_STATS = "VOTE_GET_STATS"
export const VOTE_SET_LAST_DATE = "VOTE_SET_LAST_DATE"