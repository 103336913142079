import Axios from 'axios';
import store from '@/store'
import router from "@/router";
import { START_LOADING, STOP_LOADING } from "@/store/actions/loading";
import { ALERT_SHOW } from "@/store/actions/alert";

const PROTOCOL = process.env.NODE_ENV === 'production' ? 'https://' : 'http://'
const URL_PRODUCTION = 'wetoo-api.vinksoft.com.ua'
const URL_DEVELOPMENT = '127.0.0.1:9044'

const AXIOS_BASE_URL = `${PROTOCOL}${process.env.NODE_ENV === 'production' ? URL_PRODUCTION : URL_DEVELOPMENT}`
const URL_WITHOUT_PROTOCOL = `${process.env.NODE_ENV === 'production' ? URL_PRODUCTION : URL_DEVELOPMENT}`

Axios.defaults.baseURL = AXIOS_BASE_URL

Axios.interceptors.request.use(req => {
    store.commit(START_LOADING)
    // store.commit(ALERT_HIDE)
    return req;
});

Axios.interceptors.response.use(
    res => {
        store.commit(STOP_LOADING)
        return res
    },
    err => {
        const err_detail = err?.response?.data?.detail

        store.commit(STOP_LOADING)
        if (err.response === undefined) {
            const alert = { text: "Сервіс недоступний. Перевірте з’єднання...", color: 'error' }
            store.commit(ALERT_SHOW, alert)
            throw err;
        } else {
            if (err_detail === 'Could not validate credentials') {
                store.commit(ALERT_SHOW, { text: 'Час сесії вичерпано. Авторизуйтесь знову', color: 'secondary' })
                router.push('/login')
            }
            else if (err_detail === 'Not authenticated') {
                store.commit(ALERT_SHOW, { text: 'Будь ласка авторизуйтесь знову у платформі', color: 'error' })
                router.push('/login')
            }
            else if (err_detail === 'Not enough permissions') {
                store.commit(ALERT_SHOW, {
                    text: 'Нажаль у Вас недостатньо прав для виконання цієї операції!',
                    color: 'teal'
                })
            } else {
                throw err;
            }
        }
    }
);

export const axios = Axios;
export const BASE_URL_AXIOS = AXIOS_BASE_URL;
export const BASE_URL_AXIOS_LOCAL = AXIOS_BASE_URL.replace('127.0.0.1', 'localhost')
export const BASE_URL_WITHOUT_PROTOCOL = URL_WITHOUT_PROTOCOL;
