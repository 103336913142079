import {ALERT_SHOW} from "@/store/actions/alert";

import checkerAPI from "@/utils/axios/checker"
import {
    CREATE_CHECKER,
    DELETE_CHECKER,
    GET_ALL_CHECKERS,
    SELECT_CHECKER,
    UPDATE_CHECKER
} from "@/store/actions/checker";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = { checkers: [], select_checkers: [], select_loaded: false }

const getters = {
    getCheckers: state => state.checkers,
    getSelectCheckers: state => state.select_checkers,
}

const actions = {
    [GET_ALL_CHECKERS]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            checkerAPI.get_all(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_ALL_CHECKERS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_CHECKER]: ({ dispatch, commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            checkerAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_CHECKER, data)
                    dispatch(SELECT_CHECKER)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_CHECKER]: ({ commit }, payload) => {
        commit(START_MODAL_LOADING)
        return new Promise((resolve, reject) => {
            checkerAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_CHECKER, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_CHECKER]: ({ dispatch, commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            checkerAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(DELETE_CHECKER, data)
                    dispatch(SELECT_CHECKER)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_CHECKER]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            checkerAPI.select(payload)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_CHECKER, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
}

const mutations = {
    [GET_ALL_CHECKERS]: (state, payload) => {
        state.checkers = payload
    },
    [CREATE_CHECKER]: (state, payload) => {
        state.checkers.push(payload)
    },
    [UPDATE_CHECKER]: (state, payload) => {
        let obj = state.checkers.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [DELETE_CHECKER]: (state, payload) => {
        const obg = state.checkers.find(item => item.id === payload.id)
        const obj_id = state.checkers.indexOf(obg)
        if (obg) {
            state.checkers.splice(obj_id, 1)
        }
    },
    [SELECT_CHECKER]: (state, payload) => {
        state.select_checkers = payload
        state.select_loaded = true
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}