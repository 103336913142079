<template v-cloak>
  <v-app>
    <nav-drawer/>
    <v-main class="grey lighten-4" v-cloak>
      <slot/>
      <v-dialog v-model="show_month_change" persistent max-width="700">
        <v-card min-width="350" class="banner px-6 pt-6 pb-5 transition-ease-in-out" shaped>
          <div>
            <div class="text-center" style="font-size: 1.34rem; font-weight: bold">Увага змінився розрахунковий місяць!!!</div>
            <div class="d-flex pt-5 pb-9 align-center">
              <v-avatar
                  color="error"
                  size="52"
                  class="mr-8"
              >
                <v-icon icon="mdi-lock" color="white">
                  mdi-lock
                </v-icon>
              </v-avatar>
              <div style="flex: 1; font-size: .98rem; color: #505050">
                Тепер поточний місяць:
                <span style="font-weight: 500">
                  {{` ${formatDate(month, "MMMM YYYY")}. `}}
                </span>
                Для коректної роботи - слід оновити/перезавантажити сторінку.
                Для операцій в яких зафіксований поточний місяць (відомість оплат або показів), слід зберегти цей документ та оновити/перезавантажити сторінку.
              </div>
            </div>
            <div class="d-flex flex-wrap">
              <v-spacer></v-spacer>
              <v-btn
                  small
                  text
                  class="mr-2"
                  @click="closePage"
              >
                Закрити
              </v-btn>
              <v-btn
                  small
                  depressed
                  color="grey lighten-2"
                  @click="reloadPage"
              >
                Перезавантажити
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-model="show_balance" persistent max-width="700">
        <v-card min-width="350" class="banner px-6 pt-6 pb-5 transition-ease-in-out" shaped>
          <div>
            <div class="text-center" style="font-size: 1.34rem; font-weight: bold">
              Попередження про наявність заборгованості за абонентське обслуговування платформи
            </div>
            <div class="d-flex pt-5 pb-9 align-center">
              <v-avatar
                  color="error"
                  size="52"
                  class="mr-8"
              >
                <v-icon icon="mdi-lock" color="white">
                  mdi-lock
                </v-icon>
              </v-avatar>
              <div style="flex: 1; font-size: .98rem; color: #505050">
                Термін заборгованості:
                <span style="font-weight: 500">
                  {{` ${balance_conf.debt_period || 0} днів. `}}
                </span>
                Сума заборгованості:
                <span style="font-weight: 500">
                  {{` ${formatToFixed(balance_conf.sum || 0)} грн. `}}
                </span>
                Задля запобігання обмеження функціоналу платформи - погасіть вказану заборгованість, аби отримати детальнішу інформацію,
                або отримати акт-звірки зателефонуйте за тел. 0965051535. Для завантаження актів виконаних робіт
                <span
                    style="color: #2f8232; text-decoration: underline; cursor: pointer"
                    @click.stop="closeBalancePage(true)"
                > перейдіть за посиланням</span>
              </div>
            </div>
            <div class="d-flex flex-wrap">
              <v-spacer></v-spacer>
              <v-btn
                  small
                  text
                  class="mr-2"
                  @click="closeBalancePage"
              >
                Закрити
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="updateExists" max-width="480" >
        <v-card class="pa-3" rounded>
          <v-card-text
              class="align-center text-center pt-4 pb-6"
              style="font-size: 1.46rem;"
          >
            <v-icon size="110"
                    color="teal">
              mdi-rocket-launch-outline
            </v-icon>
          </v-card-text>
          <v-card-text
              class="py-4"
              style="font-size: 1.4rem; font-weight: bold; text-align: center; color: #2d2d2d">
            Доступне оновлення програми
          </v-card-text>
          <v-card-text style="text-align: center; font-size: .9rem">
            Щоб використовувати нові можливості та мати доступ до нових функції - натисніть на Оновити. <br>
            В наступних оновленнях буде реалізована технологія web-push, тому команда оновити може тимчасово не працювати). Для примусового оновлення слід виконати комбінацію клавіш: <strong>CTRL+SHIFT+R</strong>
          </v-card-text>
          <v-card-actions class="pb-3">
            <v-btn
                color="grey lighten-3"
                @click.stop="refreshApp"
                style="margin: 0 auto;"
                width="320"
                rounded
            >
              Оновити
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar
          :timeout="4000"
          :color="alert_conf.color"
          :value="show_alert"
          @input="hide_alert"
      >
        {{ alert_conf.text }}

        <template v-slot:action="{ attrs }">
          <v-btn
              dark
              icon
              v-bind="attrs"
              @click="hide_alert"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <GeneralQuestion/>
      <GeneralRegistrationOfChanges />
      <GeneralUserMessage v-if="isAuthenticated && !personal_cab && !updateExists" />
    </v-main>
  </v-app>
</template>

<script>
import {ALERT_HIDE} from "@/store/actions/alert";
import { AUTO_LOGIN, AUTH_LOGOUT } from "@/store/actions/auth";
import NavDrawer from "@/components/NavDrawer";
import GeneralQuestion from "@/components/modal/GeneralQuestion";
import GeneralRegistrationOfChanges from "@/components/modal/GeneralRegistrationOfChanges";
import GeneralUserMessage from "@/components/modal/GeneralUserMessage";
import {mapGetters} from "vuex";
import {formatDate, formatToFixed} from "@/filters";
import clockAPI from "@/utils/axios/clock"
import update from "@/mixins/update";
import {BALANCE_CLEAN, BALANCE_HIDE} from "@/store/actions/balance_soap";
// import pushNotification from '@/utils/axios/puhs_notification';
// import {SERVER_PUBLIC_KEY, subscribeToPush, requestPermission} from "@/utils/icons"

export default {
  name: 'AppLayout',
  components: { NavDrawer, GeneralQuestion, GeneralRegistrationOfChanges, GeneralUserMessage },
  data: () => ({
    loading: false,
    show_month_change: false,
    month: null
  }),
  mixins: [update],
  computed: {
    ...mapGetters(
        {
          personal_cab: 'getPersonalCabinetHome',
          isAuthenticated: 'isAuthenticated',
          organization: 'getCurrentOrganization',
          socket_data: 'get_socket',
          userId: 'getUserId'
        }
    ),
    show_balance() {
      return this.$store.getters.balance_status
    },
    balance_conf() {
      return this.$store.getters.balance_conf
    },
    show_alert() {
      return this.$store.getters.alert_status
    },
    alert_conf() {
      return this.$store.getters.alert_conf
    },
  },
  methods: {
    formatDate,
    formatToFixed,
    reloadPage() {
      window.location.reload()
    },
    closePage() {
      this.show_month_change = false
      this.month = null
    },
    closeBalancePage(redirect=false) {
      this.$store.commit(BALANCE_CLEAN)
      this.$store.dispatch(BALANCE_HIDE)
      if (redirect) {
        if (this.$route.name !== 'BalanceDetail') {
          this.$router.push({name: 'BalanceDetail'})
        }
      }
    },
    logout() {
      this.$store.dispatch(AUTH_LOGOUT);
      this.$router.push({ name: 'Login' })
    },
    hide_alert() {
      this.$store.commit(ALERT_HIDE, this.alert_conf);
    },
    // afterServiceWorkerRegistered() {
    //   if (!this.isAuthenticated || this.personal_cab) return
    //   navigator.serviceWorker.getRegistration()
    //       .then(registration => {
    //         requestPermission()
    //             .then(permission => {
    //               if (permission === 'granted') {
    //                 subscribeToPush(registration, SERVER_PUBLIC_KEY, pushNotification);
    //               } else {
    //                 console.log('permission not granted')
    //               }
    //             })
    //       })
    //
    //
    // },
  },
  created() {
    this.$store.dispatch(AUTO_LOGIN)
      .then(() => {
        if (this.isAuthenticated) {
            this.$ws.start()
          }

        if (this.personal_cab) {
          if (this.$route.name !== 'privacyPolicy') {
            this.$router.push({ name: 'PersonalWorkPlace' })
                // .then(() => {
                //   this.afterServiceWorkerRegistered()
                // })
          }
        } else {
          console.log('this.afterServiceWorkerRegistered')
          if (this.isAuthenticated) {
            clockAPI.unblock_all()
            // this.afterServiceWorkerRegistered()
          }
        }
      })
  },
  watch: {
    socket_data: {
      immediate: true,
      handler(payload) {
        if (payload && this.isAuthenticated) {
          if (payload.data.ws_type === 'month_change'
              && payload.data.owner_id !== this.userId
              && payload.data.organization_id === this.organization.id) {
            this.month = payload.data.month
            this.show_month_change = true
          }
        }
      }
    }
  },
  beforeDestroy() {
    this.$ws.close()
  },
};
</script>

<style lang="scss">
  .banner {

  }
</style>
