import {
    APPEAL_ITEM_GET_ALL,
    APPEAL_ITEM_UPDATE,
    APPEAL_ITEM_CREATE,
    APPEAL_ITEM_REMOVE,
    APPEAL_ADD,
    APPEAL_COMMENT_GET_ALL,
    APPEAL_COMMENT_CREATE,
    APPEAL_UPDATE_OPTION,
    APPEAL_ITEM_READ,
    APPEAL_CLEAR_COMMENTS,
    APPEAL_UPDATE_COMMENT_COUNT, APPEAL_ITEM_RATE, APPEAL_FILE_UPDATE
} from "@/store/actions/appeal";

import appealAPI from "@/utils/axios/appeal"

import {ALERT_SHOW} from "@/store/actions/alert";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = {
    appeals: [],
    appeals_comments: [],
}

const getters = {
    getAppeals: state => state.appeals,
    getAppealComments: state => state.appeals_comments
}

const actions = {
    [APPEAL_ITEM_GET_ALL]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            appealAPI.get_all(payload)
                .then(response => response.data)
                .then(data => {
                    commit(APPEAL_ITEM_GET_ALL, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [APPEAL_COMMENT_GET_ALL]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            appealAPI.appeal_comment(payload)
                .then(response => response.data)
                .then(data => {
                    commit(APPEAL_COMMENT_GET_ALL, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [APPEAL_ITEM_CREATE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            appealAPI.create(payload)
                .then(response => { return response.data })
                .then(data => {
                    commit(APPEAL_ITEM_CREATE, data)
                    resolve(data)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [APPEAL_COMMENT_CREATE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            appealAPI.appeal_comment_create(payload)
                .then(response => { return response.data })
                .then(data => {
                    commit(APPEAL_COMMENT_CREATE, data)
                    resolve(data)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [APPEAL_ITEM_UPDATE]: ({ commit }, payload) => {
        return new Promise(((resolve, reject) => {
            commit(START_MODAL_LOADING)
            appealAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(APPEAL_ITEM_UPDATE, data)
                    resolve(data)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        }))
    },
    [APPEAL_FILE_UPDATE]: ({ commit }, payload) => {
        return new Promise(((resolve, reject) => {
            commit(START_MODAL_LOADING)
            appealAPI.update_file(payload)
                .then(response => response.data)
                .then(data => {
                    commit(APPEAL_FILE_UPDATE, data)
                    resolve(data)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        }))
    },
    [APPEAL_ITEM_READ]: ({ commit }, payload) => {
        return new Promise(((resolve, reject) => {
            appealAPI.update_read(payload)
                .then(response => response.data)
                .then(data => {
                    commit(APPEAL_ITEM_READ, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        }))
    },
    [APPEAL_ITEM_RATE]: ({ commit }, payload) => {
        return new Promise(((resolve, reject) => {
            appealAPI.rate(payload)
                .then(response => response.data)
                .then(data => {
                    commit(APPEAL_ITEM_RATE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        }))
    },
    [APPEAL_ITEM_REMOVE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            appealAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(APPEAL_ITEM_REMOVE, data)
                    resolve(payload)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [APPEAL_ADD]: ({ commit }, payload) => {
        return new Promise(((resolve, reject) => {
            appealAPI.appeal_add(payload)
                .then(response => response.data)
                .then(data => {
                    commit(APPEAL_ADD, data)
                    resolve(data)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        }))
    },
}

const mutations = {
    [APPEAL_ITEM_GET_ALL]: (state, payload) => {
        state.appeals = payload
    },
    [APPEAL_COMMENT_GET_ALL]: (state, payload) => {
        state.appeals_comments = payload
    },
    [APPEAL_ITEM_CREATE]: (state, payload) => {
        state.appeals.push(payload)
        state.appeals.sort((firstItem, secondItem) => secondItem.create_date.localeCompare(firstItem.create_date))
    },
    [APPEAL_COMMENT_CREATE]: (state, payload) => {
        state.appeals_comments.push(payload)
    },
    [APPEAL_CLEAR_COMMENTS]: (state) => {
      state.appeal_comments = []
    },
    [APPEAL_UPDATE_COMMENT_COUNT]: (state, payload) => {
      const item = state.appeals.find(i => i.id === payload.id)
      if (item) {
          item.comments_count = payload.count
      }
    },
    [APPEAL_ITEM_UPDATE]: (state, payload) => {
        let obj = state.appeals.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [APPEAL_FILE_UPDATE]: (state, payload) => {
        let obj = state.appeals.find(item => item.id === payload.id)
        if (obj) {
            obj.files = payload.files
            obj.files_count = payload.files.length
        }
    },
    [APPEAL_ITEM_READ]: (state, payload) => {
        let obj = state.appeals.find(item => item.id === payload.id)
        if (obj) {
            obj.read = true
        }
    },
    [APPEAL_ITEM_RATE]: (state, payload) => {
        let obj = state.appeals.find(item => item.id === payload.id)
        if (obj) {
            obj.rating = payload.rating
        }
    },
    [APPEAL_ITEM_REMOVE]: (state, payload) => {
        const obg = state.appeals.find(item => item.id === payload.id)
        if (obg) {
            state.appeals.splice(state.appeals.indexOf(obg), 1)
        }
        state.appeals.sort((firstItem, secondItem) => secondItem.create_date.localeCompare(firstItem.create_date))
    },
    [APPEAL_ADD]: (state, payload) => {
        let obj = state.appeals.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [APPEAL_UPDATE_OPTION]: (state, payload) => {
        let obj = state.appeals.find(item => item.id === payload.id)
        if (obj) {
            obj.options = payload.options
        }
    }
}


export default {
    state,
    getters,
    actions,
    mutations
}