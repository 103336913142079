import {ALERT_SHOW} from "@/store/actions/alert";
import {
    GET_ORGANIZATION,
    UPDATE_ORGANIZATION,
    GET_ORGANIZATION_PRINT_DATA,
    GET_GENERAL_SETTINGS,
    CREATE_ORGANIZATION,
    SET_CURRENT_ORGANIZATION,
    SET_CURRENT_FLAT,
    FETCH_BASES_BY_SERVICE,
    CREATE_BASES_BY_SERVICE,
    UPDATE_BASES_BY_SERVICE,
    REMOVE_BASES_BY_SERVICE,
    GET_ACCOUNTING_GENERAL_SETTINGS,
    FETCH_PAYMENTS_BY_PRIORITY,
    CREATE_PAYMENTS_BY_PRIORITY,
    UPDATE_PAYMENTS_BY_PRIORITY, REMOVE_PAYMENTS_BY_PRIORITY, UPDATE_INFORMATION_BY_ORGANIZATION
} from "@/store/actions/organization";
import organizationAPI from "@/utils/axios/organization"
import userAPI from "@/utils/axios/user"

const state = { general_settings: {}, accounting_general_settings: {}, bases_by_service: [], payments_by_priority: [] }

const getters = {
    getGeneralSettings: state => state.general_settings,
    getAccountingGeneralSettings: state => state.accounting_general_settings,
    getBasesByService: state => state.bases_by_service,
    getPaymentsByPriority: state => state.payments_by_priority
}

const actions = {
    [GET_ORGANIZATION]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            organizationAPI.get()
                .then(response => response.data)
                .then(data => {
                    resolve(data)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [FETCH_BASES_BY_SERVICE]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            organizationAPI.get_bases_by_service()
                .then(response => response.data)
                .then(data => {
                    commit(FETCH_BASES_BY_SERVICE, data)
                    resolve(data)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [FETCH_PAYMENTS_BY_PRIORITY]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            organizationAPI.get_payments_by_priority()
                .then(response => response.data)
                .then(data => {
                    commit(FETCH_PAYMENTS_BY_PRIORITY, data)
                    resolve(data)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [SET_CURRENT_ORGANIZATION]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            userAPI.set_current(payload)
                .then(response => response.data)
                .then(data => {
                    resolve(data)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_BASES_BY_SERVICE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            organizationAPI.create_bases_by_service(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_BASES_BY_SERVICE, data)
                    resolve(data)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_PAYMENTS_BY_PRIORITY]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            organizationAPI.create_payments_by_priority(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_PAYMENTS_BY_PRIORITY, data)
                    resolve(data)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [UPDATE_BASES_BY_SERVICE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            organizationAPI.update_bases_by_service(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_BASES_BY_SERVICE, data)
                    resolve(data)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [UPDATE_PAYMENTS_BY_PRIORITY]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            organizationAPI.update_payments_by_priority(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_PAYMENTS_BY_PRIORITY, data)
                    resolve(data)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [REMOVE_BASES_BY_SERVICE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            organizationAPI.remove_bases_by_service(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_BASES_BY_SERVICE, data)
                    resolve(data)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [REMOVE_PAYMENTS_BY_PRIORITY]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            organizationAPI.remove_payments_by_priority(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_PAYMENTS_BY_PRIORITY, data)
                    resolve(data)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [SET_CURRENT_FLAT]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            userAPI.set_current_flat(payload)
                .then(response => response.data)
                .then(data => {
                    resolve(data)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [GET_ORGANIZATION_PRINT_DATA]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            organizationAPI.get_print_data()
                .then(response => response.data)
                .then(data => {
                    resolve(data)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [GET_GENERAL_SETTINGS]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            organizationAPI.get_general_settings()
                .then(response => response.data)
                .then(data => {
                    commit(GET_GENERAL_SETTINGS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [GET_ACCOUNTING_GENERAL_SETTINGS]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            organizationAPI.get_accounting_general_settings()
                .then(response => response.data)
                .then(data => {
                    commit(GET_ACCOUNTING_GENERAL_SETTINGS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [UPDATE_INFORMATION_BY_ORGANIZATION]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            organizationAPI.update_accounting_information_by_organization()
                .then(response => response.data)
                .then(() => {
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [UPDATE_ORGANIZATION]: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            organizationAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    if (!payload.id) {
                        dispatch(GET_ORGANIZATION)
                            .then(res_data => {
                                resolve(res_data)
                            })
                    } else {
                        resolve(data)
                    }
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_ORGANIZATION]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            organizationAPI.create(payload)
                .then(response => response.data)
                .then(data => resolve(data)
                )
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
}

const mutations = {
    [GET_GENERAL_SETTINGS]: (state, payload) => {
        state.general_settings = payload
    },
    [GET_ACCOUNTING_GENERAL_SETTINGS]: (state, payload) => {
        state.accounting_general_settings = payload
    },
    [FETCH_BASES_BY_SERVICE]: (state, payload) => {
        state.bases_by_service = payload
    },
    [CREATE_BASES_BY_SERVICE]: (state, payload) => {
        state.bases_by_service.push(payload)
    },
    [UPDATE_BASES_BY_SERVICE]: (state, payload) => {
        const obj = state.bases_by_service.find(item => item.id === payload.id)

        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_BASES_BY_SERVICE]: (state, payload) => {
        const obg = state.bases_by_service.find(item => item.id === payload)
        const obj_id = state.bases_by_service.indexOf(obg)
        if (obg) {
            state.bases_by_service.splice(obj_id, 1)
        }
    },
    [FETCH_PAYMENTS_BY_PRIORITY]: (state, payload) => {
        state.payments_by_priority = payload
    },
    [CREATE_PAYMENTS_BY_PRIORITY]: (state, payload) => {
        state.payments_by_priority.push(payload)
    },
    [UPDATE_PAYMENTS_BY_PRIORITY]: (state, payload) => {
        const obj = state.payments_by_priority.find(item => item.id === payload.id)

        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_PAYMENTS_BY_PRIORITY]: (state, payload) => {
        const obg = state.payments_by_priority.find(item => item.id === payload)
        const obj_id = state.payments_by_priority.indexOf(obg)
        if (obg) {
            state.payments_by_priority.splice(obj_id, 1)
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}