import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/spreed-sheet';

export default {
    get_all() {
        return axios.get(`${RESOURCE_NAME}/`)
    },
    get_config(spreed_sheet_id) {
        return axios.get(`${RESOURCE_NAME}/get-config`, {params: {spreed_sheet_id: spreed_sheet_id}})
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { "spreed_sheet_id": payload })
    },
    select(payload) {
        return axios.post(`${RESOURCE_NAME}/select`, {show_in_flat: payload || null})
    },
    create_image(payload) {
        return axios.post(`${RESOURCE_NAME}/create-image`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
    select_images() {
        return axios.post(`${RESOURCE_NAME}/select-images`)
    },
};