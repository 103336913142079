import {axios} from '@/utils/axios';

const RESOURCE_NAME = '/flat_service';

export default {
    all(person_hash) {
        return axios.get(`${RESOURCE_NAME}/`, {params: {person_hash: person_hash}})
    },
    all_legacy(person_hash) {
        return axios.get(`${RESOURCE_NAME}/legacy`, {params: {person_hash: person_hash}})
    },
    all_tariff(person_hash) {
        return axios.get(`${RESOURCE_NAME}/all-tariff`, {params: {person_hash: person_hash}})
    },
    all_tariff_legacy(person_hash) {
        return axios.get(`${RESOURCE_NAME}/legacy/all-tariff`, {params: {person_hash: person_hash}})
    },
    all_with_balance(person_hash) {
        return axios.get(`${RESOURCE_NAME}/with_balance`, {params: {person_hash: person_hash}})
    },
    all_with_balance_legacy(person_hash) {
        return axios.get(`${RESOURCE_NAME}/with_balance_legacy`, {params: {person_hash: person_hash}})
    },
    get_volume_archive(payload) {
        return axios.get(`${RESOURCE_NAME}/get_volume_archive`,
            {
                params:
                    {
                        person_hash: payload.person_hash,
                        date_start: payload.date_start,
                        service_id: payload.service_id
                    }
            }
        )
    },
    get_archive(person_hash) {
        return axios.get(`${RESOURCE_NAME}/get_archive`, {params: {person_hash: person_hash}})
    },
    get_archive_legacy(person_hash) {
        return axios.get(`${RESOURCE_NAME}/get_archive_legacy`, {params: {person_hash: person_hash}})
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    create_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    update_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/update`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, {"flat_service_id": payload})
    },
    remove_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/remove`, {"flat_service_id": payload})
    },
    select() {
        return axios.post(`${RESOURCE_NAME}/select`)
    },
};