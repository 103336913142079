import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/family';

export default {
    by_flat(person_hash) {
        return axios.get(`${RESOURCE_NAME}/persons/${person_hash}`)
    },
    by_flat_select(payload) {
        return axios.get(`${RESOURCE_NAME}/select`, { params: { flat_id: payload } })
    },
    by_flat_hash_select(payload) {
        return axios.get(`${RESOURCE_NAME}/select-by-hash`, { params: { person_hash: payload } })
    },
    by_one(person_hash) {
        return axios.get(`${RESOURCE_NAME}/one/${person_hash}`)
    },
    get_owner_changes(payload) {
        return axios.post(`${RESOURCE_NAME}/owner_changes`, { "person_hash": payload })
    },
    relation_select() {
        return axios.get(`${RESOURCE_NAME}/relation_types/select`)
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { "person_hash": payload })
    },

};
