import {
    GET_DOCUMENT_BANK_INCOME_ELEMENTS,
    CREATE_DOCUMENT_BANK_INCOME_ELEMENTS,
    UPDATE_DOCUMENT_BANK_INCOME_ELEMENTS,
    REMOVE_DOCUMENT_BANK_INCOME_ELEMENTS,
    SELECT_DOCUMENT_BANK_INCOME_ELEMENTS,
    GET_DOCUMENT_BANK_OUTCOME_ELEMENTS,
    CREATE_DOCUMENT_BANK_OUTCOME_ELEMENTS,
    UPDATE_DOCUMENT_BANK_OUTCOME_ELEMENTS,
    REMOVE_DOCUMENT_BANK_OUTCOME_ELEMENTS,
    SELECT_DOCUMENT_BANK_OUTCOME_ELEMENTS,
    GET_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS,
    CREATE_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS,
    UPDATE_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS,
    REMOVE_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS,
    SELECT_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS,
    GET_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS,
    CREATE_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS,
    UPDATE_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS,
    REMOVE_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS,
    SELECT_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS,
    GET_DOCUMENT_WORK_TRANSFER_ELEMENTS,
    CREATE_DOCUMENT_WORK_TRANSFER_ELEMENTS,
    UPDATE_DOCUMENT_WORK_TRANSFER_ELEMENTS,
    REMOVE_DOCUMENT_WORK_TRANSFER_ELEMENTS,
    SELECT_DOCUMENT_WORK_TRANSFER_ELEMENTS,
    CONDUCTED_DOCUMENT_WORK_TRANSFER_ELEMENTS,
    GET_DOCUMENT_EMPLOYMENT_ELEMENTS,
    CREATE_DOCUMENT_EMPLOYMENT_ELEMENTS,
    UPDATE_DOCUMENT_EMPLOYMENT_ELEMENTS,
    REMOVE_DOCUMENT_EMPLOYMENT_ELEMENTS,
    SELECT_DOCUMENT_EMPLOYMENT_ELEMENTS,
    CONDUCTED_DOCUMENT_EMPLOYMENT_ELEMENTS,
    GET_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS,
    CREATE_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS,
    UPDATE_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS,
    REMOVE_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS,
    SELECT_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS,
    CONDUCTED_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS,
    GET_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS,
    CREATE_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS,
    UPDATE_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS,
    REMOVE_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS,
    SELECT_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS,
    GET_DOCUMENT_SALARY_CHARGE_ELEMENTS,
    CREATE_DOCUMENT_SALARY_CHARGE_ELEMENTS,
    UPDATE_DOCUMENT_SALARY_CHARGE_ELEMENTS,
    REMOVE_DOCUMENT_SALARY_CHARGE_ELEMENTS,
    GET_DOCUMENT_SALARY_TO_PAY_ELEMENTS,
    CREATE_DOCUMENT_SALARY_TO_PAY_ELEMENTS,
    UPDATE_DOCUMENT_SALARY_TO_PAY_ELEMENTS,
    REMOVE_DOCUMENT_SALARY_TO_PAY_ELEMENTS,
    SELECT_DOCUMENT_SALARY_TO_PAY_ELEMENTS,
    GET_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS,
    CREATE_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS,
    UPDATE_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS,
    REMOVE_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS,
    SELECT_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS,
    GET_DOCUMENT_VOCATION_CHARGE_ELEMENTS,
    CREATE_DOCUMENT_VOCATION_CHARGE_ELEMENTS,
    UPDATE_DOCUMENT_VOCATION_CHARGE_ELEMENTS,
    REMOVE_DOCUMENT_VOCATION_CHARGE_ELEMENTS,
    SELECT_DOCUMENT_VOCATION_CHARGE_ELEMENTS,
    CONDUCTED_DOCUMENT_BANK_OUTCOME_ELEMENTS,
    CONDUCTED_DOCUMENT_BANK_INCOME_ELEMENTS,
    CONDUCTED_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS,
    CONDUCTED_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS,
    CONDUCTED_DOCUMENT_SALARY_CHARGE_ELEMENTS,
    CONDUCTED_DOCUMENT_SALARY_TO_PAY_ELEMENTS,
    CONDUCTED_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS,
    CONDUCTED_DOCUMENT_VOCATION_CHARGE_ELEMENTS,
    GET_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS,
    CREATE_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS,
    UPDATE_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS,
    REMOVE_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS,
    CONDUCTED_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS,
    SELECT_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS,
    GET_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS,
    CREATE_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS,
    UPDATE_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS,
    CONDUCTED_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS,
    REMOVE_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS,
    SELECT_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS,
    GET_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS,
    CREATE_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS,
    UPDATE_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS,
    CONDUCTED_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS,
    REMOVE_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS,
    SELECT_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS,
    GET_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS,
    CREATE_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS,
    UPDATE_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS,
    CONDUCTED_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS,
    REMOVE_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS,
    SELECT_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS,
    GET_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS,
    CREATE_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS,
    UPDATE_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS,
    CONDUCTED_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS,
    REMOVE_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS,
    SELECT_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS,
    GET_DOCUMENT_BILL_OUTCOME_ELEMENTS,
    CREATE_DOCUMENT_BILL_OUTCOME_ELEMENTS,
    UPDATE_DOCUMENT_BILL_OUTCOME_ELEMENTS,
    CONDUCTED_DOCUMENT_BILL_OUTCOME_ELEMENTS,
    REMOVE_DOCUMENT_BILL_OUTCOME_ELEMENTS,
    SELECT_DOCUMENT_BILL_OUTCOME_ELEMENTS,
    GET_DOCUMENT_ADVANCE_REPORT_ELEMENTS,
    CREATE_DOCUMENT_ADVANCE_REPORT_ELEMENTS,
    UPDATE_DOCUMENT_ADVANCE_REPORT_ELEMENTS,
    CONDUCTED_DOCUMENT_ADVANCE_REPORT_ELEMENTS,
    REMOVE_DOCUMENT_ADVANCE_REPORT_ELEMENTS,
    SELECT_DOCUMENT_ADVANCE_REPORT_ELEMENTS,
    CONDUCTED_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS,
    GET_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS,
    CREATE_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS,
    UPDATE_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS,
    CONDUCTED_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS,
    REMOVE_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS,
    SELECT_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS,
    GET_DOCUMENT_COMMISSIONING_ELEMENTS,
    CREATE_DOCUMENT_COMMISSIONING_ELEMENTS,
    UPDATE_DOCUMENT_COMMISSIONING_ELEMENTS,
    CONDUCTED_DOCUMENT_COMMISSIONING_ELEMENTS,
    REMOVE_DOCUMENT_COMMISSIONING_ELEMENTS,
    SELECT_DOCUMENT_COMMISSIONING_ELEMENTS,
    GET_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS,
    CREATE_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS,
    UPDATE_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS,
    REMOVE_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS,
    SELECT_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS,
    CONDUCTED_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS,
    GET_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS,
    CREATE_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS,
    UPDATE_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS,
    REMOVE_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS,
    SELECT_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS,
    CONDUCTED_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS,
    GET_DOCUMENT_ENTERING_BALANCES_ELEMENTS,
    CREATE_DOCUMENT_ENTERING_BALANCES_ELEMENTS,
    UPDATE_DOCUMENT_ENTERING_BALANCES_ELEMENTS,
    CONDUCTED_DOCUMENT_ENTERING_BALANCES_ELEMENTS,
    REMOVE_DOCUMENT_ENTERING_BALANCES_ELEMENTS,
    SELECT_DOCUMENT_ENTERING_BALANCES_ELEMENTS,
    GET_DOCUMENT_MANUAL_OPERATION_ELEMENTS,
    CREATE_DOCUMENT_MANUAL_OPERATION_ELEMENTS,
    UPDATE_DOCUMENT_MANUAL_OPERATION_ELEMENTS,
    CONDUCTED_DOCUMENT_MANUAL_OPERATION_ELEMENTS,
    REMOVE_DOCUMENT_MANUAL_OPERATION_ELEMENTS,
    SELECT_DOCUMENT_MANUAL_OPERATION_ELEMENTS,
    GET_DOCUMENT_TAX_BILL_INCOME_ELEMENTS,
    CREATE_DOCUMENT_TAX_BILL_INCOME_ELEMENTS,
    UPDATE_DOCUMENT_TAX_BILL_INCOME_ELEMENTS,
    CONDUCTED_DOCUMENT_TAX_BILL_INCOME_ELEMENTS,
    REMOVE_DOCUMENT_TAX_BILL_INCOME_ELEMENTS,
    SELECT_DOCUMENT_TAX_BILL_INCOME_ELEMENTS,
    GET_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS,
    CREATE_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS,
    UPDATE_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS,
    CONDUCTED_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS,
    REMOVE_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS,
    SELECT_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS,
    GET_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS,
    CREATE_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS,
    UPDATE_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS,
    REMOVE_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS,
    SELECT_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS,
    CONDUCTED_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS,
    GET_DOCUMENT_RELEASE_WORK_ELEMENTS,
    CREATE_DOCUMENT_RELEASE_WORK_ELEMENTS,
    UPDATE_DOCUMENT_RELEASE_WORK_ELEMENTS,
    REMOVE_DOCUMENT_RELEASE_WORK_ELEMENTS,
    CONDUCTED_DOCUMENT_RELEASE_WORK_ELEMENTS,
    SELECT_DOCUMENT_RELEASE_WORK_ELEMENTS
} from "@/store/actions/accounting/list";

import accountingDocumentsBankIncomeAPI from "@/utils/axios/accounting/documents/bank_income"
import accountingDocumentsBankOutcomeAPI from "@/utils/axios/accounting/documents/bank_outcome"
import accountingDocumentsCashOrderIncomeAPI from "@/utils/axios/accounting/documents/cash_order_income"
import accountingDocumentsCashOrderOutcomeAPI from "@/utils/axios/accounting/documents/cash_order_outcome"
import accountingDocumentsPersonalTimesheetAPI from "@/utils/axios/accounting/documents/personal_timesheet"
import accountingDocumentsWorkTransferAPI from "@/utils/axios/accounting/documents/work_transfer"
import accountingDocumentsEmploymentAPI from "@/utils/axios/accounting/documents/employment"
import accountingDocumentsContractByClntAPI from "@/utils/axios/accounting/documents/contract_by_cln"
import accountingDocumentsSocialPrivilegetAPI from "@/utils/axios/accounting/documents/social_privilege"
import accountingDocumentsSalaryChargetAPI from "@/utils/axios/accounting/documents/salary_charge"
import accountingDocumentsSalaryToPayAPI from "@/utils/axios/accounting/documents/salary_to_pay"
import accountingDocumentsHospitalChargeAPI from "@/utils/axios/accounting/documents/hospital_charge"
import accountingDocumentsVocationChargeAPI from "@/utils/axios/accounting/documents/vocation_charge"
import accountingDocumentsActWorkPerformedOutcomeAPI from "@/utils/axios/accounting/documents/act_work_performed_outcome"
import accountingDocumentsActWorkPerformedIncomeAPI from "@/utils/axios/accounting/documents/act_work_performed_income"
import accountingDocumentsInvoiceWarehouseIncomeAPI from "@/utils/axios/accounting/documents/invoice_warehouse_income"
import accountingDocumentsInvoiceWarehouseOutcomeAPI from "@/utils/axios/accounting/documents/invoice_warehouse_outcome"
import accountingDocumentsBillOutcomeAPI from "@/utils/axios/accounting/documents/bill_outcome"
import accountingDocumentsWarehouseInventoryOutcomeAPI from "@/utils/axios/accounting/documents/warehouse_inventory_outcome"
import accountingDocumentsAdvanceReportAPI from "@/utils/axios/accounting/documents/advance_report"
import accountingDocumentsCommissioningAPI from "@/utils/axios/accounting/documents/commissioning"
import accountingDocumentsLowCostCommissioningAPI from "@/utils/axios/accounting/documents/low_cost_commissioning"
import accountingDocumentsCloseFinancialMonthAPI from "@/utils/axios/accounting/documents/close_financial_month"
import accountingDocumentsCloseHomeAccountingAPI from "@/utils/axios/accounting/documents/close_home_accounting"
import accountingDocumentsEnteringBalanceAPI from "@/utils/axios/accounting/documents/entering_balance"
import accountingDocumentsManualOperationAPI from "@/utils/axios/accounting/documents/manual_operation"
import accountingDocumentsTaxBillIncomeAPI from "@/utils/axios/accounting/documents/tax_bill_income"
import accountingDocumentsTaxBillOutcomeAPI from "@/utils/axios/accounting/documents/tax_bill_outcome"
import accountingDocumentsReleaseWorkAPI from "@/utils/axios/accounting/documents/release_work"
import accountingListAPI from "@/utils/axios/accounting/documents/list_select";
import {ALERT_SHOW} from "@/store/actions/alert";
import {RELOAD_LIST} from "@/store/actions/question";
import {GET_LEGACY_ACCOUNTING_DOCUMENTS_BY_FLAT} from "@/store/actions/legacy_documents";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = {
    document_bank_income_list_elements: [],
    document_bank_income_list_elements_select: [],
    document_commissioning_list_elements: [],
    document_commissioning_list_elements_select: [],
    document_salary_charge_list_elements: [],
    document_salary_charge_list_elements_select: [],
    document_salary_to_pay_list_elements: [],
    document_salary_to_pay_list_elements_select: [],
    document_hospital_list_elements: [],
    document_hospital_list_elements_select: [],
    document_vocation_list_elements: [],
    document_vocation_list_elements_select: [],
    document_bank_outcome_list_elements: [],
    document_bank_outcome_list_elements_select: [],
    document_cash_order_income_list_elements: [],
    document_cash_order_income_list_elements_select: [],
    document_cash_order_outcome_list_elements: [],
    document_cash_order_outcome_list_elements_select: [],
    document_personal_timesheet_list_elements: [],
    document_personal_timesheet_list_elements_select: [],
    document_work_transfer_list_elements: [],
    document_work_transfer_list_elements_select: [],
    document_employment_list_elements: [],
    document_employment_list_elements_select: [],
    document_contract_by_cln_list_elements: [],
    document_contract_by_cln_list_elements_select: [],
    document_social_privilege_list_elements: [],
    document_social_privilege_list_elements_select: [],
    document_act_work_performed_outcome_list_elements: [],
    document_act_work_performed_outcome_list_elements_select: [],
    document_act_work_performed_income_list_elements: [],
    document_act_work_performed_income_list_elements_select: [],
    document_invoice_warehouse_income_list_elements: [],
    document_invoice_warehouse_income_list_elements_select: [],
    document_invoice_warehouse_outcome_list_elements: [],
    document_invoice_warehouse_outcome_list_elements_select: [],
    document_bill_outcome_list_elements: [],
    document_bill_outcome_list_elements_select: [],
    document_warehouse_inventory_outcome_list_elements: [],
    document_warehouse_inventory_outcome_list_elements_select: [],
    document_advance_report_list_elements: [],
    document_advance_report_list_elements_select: [],
    document_low_cost_commissioning_list_elements: [],
    document_low_cost_commissioning_list_elements_select: [],
    document_close_financial_month_list_elements: [],
    document_close_financial_month_list_elements_select: [],
    document_close_home_accounting_list_elements: [],
    document_close_home_accounting_list_elements_select: [],
    document_entering_balance_list_elements: [],
    document_entering_balance_list_elements_select: [],
    document_manual_operation_list_elements: [],
    document_manual_operation_list_elements_select: [],
    document_tax_bill_income_list_elements: [],
    document_tax_bill_income_list_elements_select: [],
    document_tax_bill_outcome_list_elements: [],
    document_tax_bill_outcome_list_elements_select: [],
    document_release_work_list_elements: [],
    document_release_work_list_elements_select: []
}

const getters = {
    get_document_bank_income_list: state => state.document_bank_income_list_elements,
    get_document_bank_income_list_select: state => state.document_bank_income_list_elements_select,
    get_document_commissioning_list: state => state.document_commissioning_list_elements,
    get_document_commissioning_list_select: state => state.document_commissioning_list_elements_select,
    get_document_bank_outcome_list: state => state.document_bank_outcome_list_elements,
    get_document_bank_outcome_list_select: state => state.document_bank_outcome_list_elements_select,
    get_document_cash_order_income_list: state => state.document_cash_order_income_list_elements,
    get_document_cash_order_income_list_select: state => state.document_cash_order_income_list_elements_select,
    get_document_cash_order_outcome_list: state => state.document_cash_order_outcome_list_elements,
    get_document_cash_order_outcome_list_select: state => state.document_cash_order_outcome_list_elements_select,
    get_document_salary_charge_list: state => state.document_salary_charge_list_elements,
    get_document_salary_charge_list_select: state => state.document_salary_charge_list_elements_select,
    get_document_hospital_charge_list: state => state.document_hospital_list_elements,
    get_document_hospital_charge_list_select: state => state.document_hospital_list_elements_select,
    get_document_vocation_charge_list: state => state.document_vocation_list_elements,
    get_document_vocation_charge_list_select: state => state.document_vocation_list_elements_select,
    get_document_salary_to_pay_list: state => state.document_salary_to_pay_list_elements,
    get_document_salary_to_pay_list_select: state => state.document_salary_to_pay_list_elements_select,
    get_document_personal_timesheet_list: state => state.document_personal_timesheet_list_elements,
    get_document_personal_timesheet_list_select: state => state.document_personal_timesheet_list_elements_select,
    get_document_work_transfer_list: state => state.document_work_transfer_list_elements,
    get_document_work_transfer_list_select: state => state.document_work_transfer_list_elements_select,
    get_document_employment_list: state => state.document_employment_list_elements,
    get_document_employment_list_select: state => state.document_employment_list_elements_select,
    get_document_contract_by_cln_list: state => state.document_contract_by_cln_list_elements,
    get_document_contract_by_cln_list_select: state => state.document_contract_by_cln_list_elements_select,
    get_document_social_privilege_list: state => state.document_social_privilege_list_elements,
    get_document_social_privilege_list_select: state => state.document_social_privilege_list_elements_select,
    get_document_act_work_performed_outcome_list: state => state.document_act_work_performed_outcome_list_elements,
    get_document_act_work_performed_outcome_list_select: state => state.document_act_work_performed_outcome_list_elements_select,
    get_document_act_work_performed_income_list: state => state.document_act_work_performed_income_list_elements,
    get_document_act_work_performed_income_list_select: state => state.document_act_work_performed_income_list_elements_select,
    get_document_invoice_warehouse_income_list: state => state.document_invoice_warehouse_income_list_elements,
    get_document_invoice_warehouse_income_list_select: state => state.document_invoice_warehouse_income_list_elements_select,
    get_document_invoice_warehouse_outcome_list: state => state.document_invoice_warehouse_outcome_list_elements,
    get_document_invoice_warehouse_outcome_list_select: state => state.document_invoice_warehouse_outcome_list_elements_select,
    get_document_bill_outcome_list: state => state.document_bill_outcome_list_elements,
    get_document_bill_outcome_list_select: state => state.document_bill_outcome_list_elements_select,
    get_document_warehouse_inventory_outcome_list: state => state.document_warehouse_inventory_outcome_list_elements,
    get_document_warehouse_inventory_outcome_list_select: state => state.document_warehouse_inventory_outcome_list_elements_select,
    get_document_advance_report_list: state => state.document_advance_report_list_elements,
    get_document_advance_report_list_select: state => state.document_advance_report_list_elements_select,
    get_document_low_cost_commissioning_list: state => state.document_low_cost_commissioning_list_elements,
    get_document_low_cost_commissioning_list_select: state => state.document_low_cost_commissioning_list_elements_select,
    get_document_close_financial_month_list: state => state.document_close_financial_month_list_elements,
    get_document_close_financial_month_list_select: state => state.document_close_financial_month_list_elements_select,
    get_document_close_home_accounting_list: state => state.document_close_home_accounting_list_elements,
    get_document_close_home_accounting_list_select: state => state.document_close_home_accounting_list_elements_select,
    get_document_entering_balance_list: state => state.document_entering_balance_list_elements,
    get_document_entering_balance_list_select: state => state.document_entering_balance_list_elements_select,
    get_document_manual_operation_list: state => state.document_manual_operation_list_elements,
    get_document_manual_operation_list_select: state => state.document_manual_operation_list_elements_select,
    get_document_tax_bill_income_list: state => state.document_tax_bill_income_list_elements,
    get_document_tax_bill_income_list_select: state => state.document_tax_bill_income_list_elements_select,
    get_document_tax_bill_outcome_list: state => state.document_tax_bill_outcome_list_elements,
    get_document_tax_bill_outcome_list_select: state => state.document_tax_bill_outcome_list_elements_select,
    get_document_release_work_list: state => state.document_release_work_list_elements,
    get_document_realease_work_list_select: state => state.document_release_work_list_elements_select
}

const actions = {
    [GET_DOCUMENT_BANK_INCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_doc_bank_income_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_DOCUMENT_BANK_INCOME_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                        reject(false)
                    }
                )
        })
    },
    [CREATE_DOCUMENT_BANK_INCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsBankIncomeAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DOCUMENT_BANK_INCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_bank_income_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DOCUMENT_BANK_INCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsBankIncomeAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DOCUMENT_BANK_INCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_bank_income_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CONDUCTED_DOCUMENT_BANK_INCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsBankIncomeAPI.conducted(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CONDUCTED_DOCUMENT_BANK_INCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_bank_income_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DOCUMENT_BANK_INCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsBankIncomeAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_DOCUMENT_BANK_INCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_bank_income_list', id: data.id  })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_DOCUMENT_BANK_INCOME_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingDocumentsBankIncomeAPI.select()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_DOCUMENT_BANK_INCOME_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },

    [GET_DOCUMENT_COMMISSIONING_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_doc_commissioning_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_DOCUMENT_COMMISSIONING_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                        reject(false)
                    }
                )
        })
    },
    [CREATE_DOCUMENT_COMMISSIONING_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsCommissioningAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DOCUMENT_COMMISSIONING_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_commissioning_list', id: data.id })
                    if (data?.restore_text) {
                        commit(ALERT_SHOW, {text: data.restore_text, color: 'secondary'})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DOCUMENT_COMMISSIONING_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsCommissioningAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DOCUMENT_COMMISSIONING_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_commissioning_list', id: data.id })
                    if (data?.restore_text) {
                        commit(ALERT_SHOW, {text: data.restore_text, color: 'secondary'})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CONDUCTED_DOCUMENT_COMMISSIONING_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsCommissioningAPI.conducted(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CONDUCTED_DOCUMENT_COMMISSIONING_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_commissioning_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DOCUMENT_COMMISSIONING_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsCommissioningAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_DOCUMENT_COMMISSIONING_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_commissioning_list', id: data.id  })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_DOCUMENT_COMMISSIONING_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingDocumentsCommissioningAPI.select()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_DOCUMENT_COMMISSIONING_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },

    [GET_DOCUMENT_BANK_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_doc_bank_outcome_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_DOCUMENT_BANK_OUTCOME_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                        reject(false)
                    }
                )
        })
    },
    [CREATE_DOCUMENT_BANK_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsBankOutcomeAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DOCUMENT_BANK_OUTCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_bank_outcome_list', id: data.id  })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DOCUMENT_BANK_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsBankOutcomeAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DOCUMENT_BANK_OUTCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_bank_outcome_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CONDUCTED_DOCUMENT_BANK_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsBankOutcomeAPI.conducted(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CONDUCTED_DOCUMENT_BANK_OUTCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_bank_outcome_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DOCUMENT_BANK_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsBankOutcomeAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_DOCUMENT_BANK_OUTCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_bank_outcome_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_DOCUMENT_BANK_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingDocumentsBankOutcomeAPI.select(payload || {})
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_DOCUMENT_BANK_OUTCOME_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },

    [GET_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_doc_cash_order_income_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                        reject(false)
                    }
                )
        })
    },
    [CREATE_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsCashOrderIncomeAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_cash_order_income_list', id: data.id, all: true  })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsCashOrderIncomeAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_cash_order_income_list', id: data.id, all: true  })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CONDUCTED_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsCashOrderIncomeAPI.conducted(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CONDUCTED_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_cash_order_income_list', id: data.id, all: true  })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsCashOrderIncomeAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_cash_order_income_list', id: data.id, all: true  })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingDocumentsCashOrderIncomeAPI.select(payload || {})
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },

    [GET_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_doc_cash_order_outcome_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                        reject(false)
                    }
                )
        })
    },
    [CREATE_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsCashOrderOutcomeAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_cash_order_outcome_list', id: data.id, all: true })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsCashOrderOutcomeAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_cash_order_outcome_list', id: data.id, all: true  })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CONDUCTED_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsCashOrderOutcomeAPI.conducted(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CONDUCTED_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_cash_order_outcome_list', id: data.id, all: true  })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsCashOrderOutcomeAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_cash_order_outcome_list', id: data.id, all: true  })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingDocumentsCashOrderOutcomeAPI.select(payload || {})
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },

    [GET_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_doc_personal_timesheet_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                        reject(false)
                    }
                )
        })
    },
    [CREATE_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsPersonalTimesheetAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_personal_timesheet_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsPersonalTimesheetAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_personal_timesheet_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CONDUCTED_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsPersonalTimesheetAPI.conducted(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CONDUCTED_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_personal_timesheet_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsPersonalTimesheetAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_personal_timesheet_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingDocumentsPersonalTimesheetAPI.select()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },

    [GET_DOCUMENT_WORK_TRANSFER_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_doc_work_transfer_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_DOCUMENT_WORK_TRANSFER_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                        reject(false)
                    }
                )
        })
    },
    [CREATE_DOCUMENT_WORK_TRANSFER_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsWorkTransferAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DOCUMENT_WORK_TRANSFER_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_work_transfer_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DOCUMENT_WORK_TRANSFER_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsWorkTransferAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DOCUMENT_WORK_TRANSFER_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_work_transfer_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CONDUCTED_DOCUMENT_WORK_TRANSFER_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsWorkTransferAPI.conducted(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CONDUCTED_DOCUMENT_WORK_TRANSFER_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_work_transfer_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DOCUMENT_WORK_TRANSFER_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsWorkTransferAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_DOCUMENT_WORK_TRANSFER_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_work_transfer_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_DOCUMENT_WORK_TRANSFER_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingDocumentsWorkTransferAPI.select()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_DOCUMENT_WORK_TRANSFER_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },

    [GET_DOCUMENT_EMPLOYMENT_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_doc_employment_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_DOCUMENT_EMPLOYMENT_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                        reject(false)
                    }
                )
        })
    },
    [CREATE_DOCUMENT_EMPLOYMENT_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsEmploymentAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DOCUMENT_EMPLOYMENT_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_employment_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DOCUMENT_EMPLOYMENT_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsEmploymentAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DOCUMENT_EMPLOYMENT_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_employment_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CONDUCTED_DOCUMENT_EMPLOYMENT_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsEmploymentAPI.conducted(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CONDUCTED_DOCUMENT_EMPLOYMENT_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_employment_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DOCUMENT_EMPLOYMENT_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsEmploymentAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_DOCUMENT_EMPLOYMENT_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_employment_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_DOCUMENT_EMPLOYMENT_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingDocumentsEmploymentAPI.select()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_DOCUMENT_EMPLOYMENT_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },

    [GET_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_doc_contract_by_cln_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                        reject(false)
                    }
                )
        })
    },
    [CREATE_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsContractByClntAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_contract_by_cln_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsContractByClntAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_contract_by_cln_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CONDUCTED_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsContractByClntAPI.conducted(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CONDUCTED_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_contract_by_cln_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsContractByClntAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_contract_by_cln_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingDocumentsContractByClntAPI.select()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },

    [GET_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_doc_social_privilege_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                        reject(false)
                    }
                )
        })
    },
    [CREATE_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsSocialPrivilegetAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_social_privilege_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsSocialPrivilegetAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_social_privilege_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CONDUCTED_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsSocialPrivilegetAPI.conducted(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CONDUCTED_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_social_privilege_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsSocialPrivilegetAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_social_privilege_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingDocumentsSocialPrivilegetAPI.select()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },

    [GET_DOCUMENT_SALARY_CHARGE_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_doc_salary_charge_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_DOCUMENT_SALARY_CHARGE_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                        reject(false)
                    }
                )
        })
    },
    [CREATE_DOCUMENT_SALARY_CHARGE_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsSalaryChargetAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DOCUMENT_SALARY_CHARGE_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_salary_charge_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DOCUMENT_SALARY_CHARGE_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsSalaryChargetAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DOCUMENT_SALARY_CHARGE_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_salary_charge_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CONDUCTED_DOCUMENT_SALARY_CHARGE_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsSalaryChargetAPI.conducted(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CONDUCTED_DOCUMENT_SALARY_CHARGE_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_salary_charge_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DOCUMENT_SALARY_CHARGE_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsSalaryChargetAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_DOCUMENT_SALARY_CHARGE_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_salary_charge_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },

    [GET_DOCUMENT_SALARY_TO_PAY_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_doc_salary_to_pay_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_DOCUMENT_SALARY_TO_PAY_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                        reject(false)
                    }
                )
        })
    },
    [CREATE_DOCUMENT_SALARY_TO_PAY_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsSalaryToPayAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DOCUMENT_SALARY_TO_PAY_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_salary_to_pay_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DOCUMENT_SALARY_TO_PAY_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsSalaryToPayAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DOCUMENT_SALARY_TO_PAY_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_salary_to_pay_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CONDUCTED_DOCUMENT_SALARY_TO_PAY_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsSalaryToPayAPI.conducted(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CONDUCTED_DOCUMENT_SALARY_TO_PAY_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_salary_to_pay_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DOCUMENT_SALARY_TO_PAY_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsSalaryToPayAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_DOCUMENT_SALARY_TO_PAY_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_salary_to_pay_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_DOCUMENT_SALARY_TO_PAY_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingDocumentsSalaryToPayAPI.select(payload)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_DOCUMENT_SALARY_TO_PAY_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },

    [GET_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_doc_hospital_charge_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                        reject(false)
                    }
                )
        })
    },
    [CREATE_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsHospitalChargeAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_hospital_charge_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsHospitalChargeAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_hospital_charge_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CONDUCTED_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsHospitalChargeAPI.conducted(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CONDUCTED_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_hospital_charge_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsHospitalChargeAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_hospital_charge_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingDocumentsHospitalChargeAPI.select(payload)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },

    [GET_DOCUMENT_VOCATION_CHARGE_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_doc_vocation_charge_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_DOCUMENT_VOCATION_CHARGE_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                        reject(false)
                    }
                )
        })
    },
    [CREATE_DOCUMENT_VOCATION_CHARGE_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsVocationChargeAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DOCUMENT_VOCATION_CHARGE_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_vocation_charge_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DOCUMENT_VOCATION_CHARGE_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsVocationChargeAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DOCUMENT_VOCATION_CHARGE_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_vocation_charge_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CONDUCTED_DOCUMENT_VOCATION_CHARGE_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsVocationChargeAPI.conducted(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CONDUCTED_DOCUMENT_VOCATION_CHARGE_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_vocation_charge_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DOCUMENT_VOCATION_CHARGE_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsVocationChargeAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_DOCUMENT_VOCATION_CHARGE_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_vocation_charge_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_DOCUMENT_VOCATION_CHARGE_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingDocumentsVocationChargeAPI.select(payload)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_DOCUMENT_VOCATION_CHARGE_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },

    [GET_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_doc_act_work_performed_outcome_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                        reject(false)
                    }
                )
        })
    },
    [CREATE_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsActWorkPerformedOutcomeAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_act_work_performed_outcome_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsActWorkPerformedOutcomeAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_act_work_performed_outcome_list', id: data.id })
                    if (payload.flat_id) {
                        dispatch(GET_LEGACY_ACCOUNTING_DOCUMENTS_BY_FLAT, {flat_id: payload.flat_id})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CONDUCTED_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsActWorkPerformedOutcomeAPI.conducted(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CONDUCTED_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_act_work_performed_outcome_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsActWorkPerformedOutcomeAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_act_work_performed_outcome_list', id: data.id })
                    if (payload.flat_id) {
                        dispatch(GET_LEGACY_ACCOUNTING_DOCUMENTS_BY_FLAT, {flat_id: payload.flat_id})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingDocumentsActWorkPerformedOutcomeAPI.select(payload)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },

    [GET_DOCUMENT_BILL_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_doc_bill_outcome_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_DOCUMENT_BILL_OUTCOME_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                        reject(false)
                    }
                )
        })
    },
    [CREATE_DOCUMENT_BILL_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsBillOutcomeAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DOCUMENT_BILL_OUTCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_bill_outcome_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DOCUMENT_BILL_OUTCOME_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsBillOutcomeAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DOCUMENT_BILL_OUTCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_bill_outcome_list', id: data.id })
                    if (payload.flat_id) {
                        dispatch(GET_LEGACY_ACCOUNTING_DOCUMENTS_BY_FLAT, {flat_id: payload.flat_id})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CONDUCTED_DOCUMENT_BILL_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsBillOutcomeAPI.conducted(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CONDUCTED_DOCUMENT_BILL_OUTCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_bill_outcome_list' })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DOCUMENT_BILL_OUTCOME_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsBillOutcomeAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_DOCUMENT_BILL_OUTCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_bill_outcome_list' })
                    if (payload.flat_id) {
                        dispatch(GET_LEGACY_ACCOUNTING_DOCUMENTS_BY_FLAT, {flat_id: payload.flat_id})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_DOCUMENT_BILL_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingDocumentsBillOutcomeAPI.select(payload)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_DOCUMENT_BILL_OUTCOME_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },

    [GET_DOCUMENT_ADVANCE_REPORT_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_doc_advance_report_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_DOCUMENT_ADVANCE_REPORT_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                        reject(false)
                    }
                )
        })
    },
    [CREATE_DOCUMENT_ADVANCE_REPORT_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsAdvanceReportAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DOCUMENT_ADVANCE_REPORT_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_advance_report_list' })
                    if (data?.restore_text) {
                        commit(ALERT_SHOW, {text: data.restore_text, color: 'secondary'})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DOCUMENT_ADVANCE_REPORT_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsAdvanceReportAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DOCUMENT_ADVANCE_REPORT_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_advance_report_list' })
                    if (data?.restore_text) {
                        commit(ALERT_SHOW, {text: data.restore_text, color: 'secondary'})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CONDUCTED_DOCUMENT_ADVANCE_REPORT_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsAdvanceReportAPI.conducted(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CONDUCTED_DOCUMENT_ADVANCE_REPORT_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_advance_report_list' })
                    if (data?.restore_text) {
                        commit(ALERT_SHOW, {text: data.restore_text, color: 'secondary'})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DOCUMENT_ADVANCE_REPORT_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsAdvanceReportAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_DOCUMENT_ADVANCE_REPORT_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_advance_report_list' })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_DOCUMENT_ADVANCE_REPORT_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingDocumentsAdvanceReportAPI.select(payload)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_DOCUMENT_ADVANCE_REPORT_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },

    [GET_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_doc_act_work_performed_income_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                        reject(false)
                    }
                )
        })
    },
    [CREATE_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsActWorkPerformedIncomeAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_act_work_performed_income_list' })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsActWorkPerformedIncomeAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_act_work_performed_income_list' })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CONDUCTED_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsActWorkPerformedIncomeAPI.conducted(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CONDUCTED_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_act_work_performed_income_list' })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsActWorkPerformedIncomeAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_act_work_performed_income_list' })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingDocumentsActWorkPerformedIncomeAPI.select(payload)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },

    [GET_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_doc_invoice_warehouse_income_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                        reject(false)
                    }
                )
        })
    },
    [CREATE_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsInvoiceWarehouseIncomeAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_invoice_warehouse_income_list' })
                    if (data?.restore_text) {
                        commit(ALERT_SHOW, {text: data.restore_text, color: 'secondary'})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsInvoiceWarehouseIncomeAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_invoice_warehouse_income_list' })
                    if (data?.restore_text) {
                        commit(ALERT_SHOW, {text: data.restore_text, color: 'secondary'})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CONDUCTED_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsInvoiceWarehouseIncomeAPI.conducted(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CONDUCTED_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_invoice_warehouse_income_list' })
                    if (data?.restore_text) {
                        commit(ALERT_SHOW, {text: data.restore_text, color: 'secondary'})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsInvoiceWarehouseIncomeAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_invoice_warehouse_income_list' })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingDocumentsInvoiceWarehouseIncomeAPI.select(payload)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },

    [GET_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_doc_invoice_warehouse_outcome_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                        reject(false)
                    }
                )
        })
    },
    [CREATE_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsInvoiceWarehouseOutcomeAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_invoice_warehouse_outcome_list' })
                    if (data?.restore_text) {
                        commit(ALERT_SHOW, {text: data.restore_text, color: 'secondary'})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsInvoiceWarehouseOutcomeAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_invoice_warehouse_outcome_list' })
                    if (data?.restore_text) {
                        commit(ALERT_SHOW, {text: data.restore_text, color: 'secondary'})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CONDUCTED_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsInvoiceWarehouseOutcomeAPI.conducted(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CONDUCTED_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_invoice_warehouse_outcome_list' })
                    if (data?.restore_text) {
                        commit(ALERT_SHOW, {text: data.restore_text, color: 'secondary'})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsInvoiceWarehouseOutcomeAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_invoice_warehouse_outcome_list' })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingDocumentsInvoiceWarehouseOutcomeAPI.select(payload)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },

    [GET_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_doc_warehouse_inventory_outcome_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                        reject(false)
                    }
                )
        })
    },
    [CREATE_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsWarehouseInventoryOutcomeAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_warehouse_inventory_outcome_list' })
                    if (data?.restore_text) {
                        commit(ALERT_SHOW, {text: data.restore_text, color: 'secondary'})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsWarehouseInventoryOutcomeAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_warehouse_inventory_outcome_list' })
                    if (data?.restore_text) {
                        commit(ALERT_SHOW, {text: data.restore_text, color: 'secondary'})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CONDUCTED_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsWarehouseInventoryOutcomeAPI.conducted(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CONDUCTED_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_warehouse_inventory_outcome_list' })
                    if (data?.restore_text) {
                        commit(ALERT_SHOW, {text: data.restore_text, color: 'secondary'})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsWarehouseInventoryOutcomeAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_warehouse_inventory_outcome_list' })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingDocumentsWarehouseInventoryOutcomeAPI.select(payload)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },

    [GET_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_doc_low_cost_commissioning_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                        reject(false)
                    }
                )
        })
    },
    [CREATE_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsLowCostCommissioningAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_low_cost_commissioning_list' })
                    if (data?.restore_text) {
                        commit(ALERT_SHOW, {text: data.restore_text, color: 'secondary'})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsLowCostCommissioningAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_low_cost_commissioning_list' })
                    if (data?.restore_text) {
                        commit(ALERT_SHOW, {text: data.restore_text, color: 'secondary'})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CONDUCTED_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsLowCostCommissioningAPI.conducted(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CONDUCTED_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_low_cost_commissioning_list' })
                    if (data?.restore_text) {
                        commit(ALERT_SHOW, {text: data.restore_text, color: 'secondary'})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsLowCostCommissioningAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_low_cost_commissioning_list' })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingDocumentsLowCostCommissioningAPI.select(payload)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },

    [GET_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_doc_close_financial_month_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                        reject(false)
                    }
                )
        })
    },
    [CREATE_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsCloseFinancialMonthAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_close_financial_month_list' })
                    if ((data?.errors || []).length) {
                        commit(ALERT_SHOW, {text: data.errors.join('; '), color: 'secondary'})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsCloseFinancialMonthAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_close_financial_month_list' })
                    if ((data?.errors || []).length) {
                        commit(ALERT_SHOW, {text: data.errors.join('; '), color: 'secondary'})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CONDUCTED_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsCloseFinancialMonthAPI.conducted(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CONDUCTED_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_close_financial_month_list' })
                    if ((data?.errors || []).length) {
                        commit(ALERT_SHOW, {text: data.errors.join('; '), color: 'secondary'})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsCloseFinancialMonthAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_close_financial_month_list' })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingDocumentsCloseFinancialMonthAPI.select(payload)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },

    [GET_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_doc_close_home_accounting_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                        reject(false)
                    }
                )
        })
    },
    [CREATE_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsCloseHomeAccountingAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_close_home_accounting_list' })
                    if ((data?.errors || []).length) {
                        commit(ALERT_SHOW, {text: data.errors.join('; '), color: 'secondary'})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsCloseHomeAccountingAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_close_home_accounting_list' })
                    if ((data?.errors || []).length) {
                        commit(ALERT_SHOW, {text: data.errors.join('; '), color: 'secondary'})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CONDUCTED_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsCloseHomeAccountingAPI.conducted(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CONDUCTED_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_close_home_accounting_list' })
                    if ((data?.errors || []).length) {
                        commit(ALERT_SHOW, {text: data.errors.join('; '), color: 'secondary'})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsCloseHomeAccountingAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_close_home_accounting_list' })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingDocumentsCloseHomeAccountingAPI.select(payload)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },

    [GET_DOCUMENT_ENTERING_BALANCES_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_doc_entering_balance_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_DOCUMENT_ENTERING_BALANCES_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                        reject(false)
                    }
                )
        })
    },
    [CREATE_DOCUMENT_ENTERING_BALANCES_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsEnteringBalanceAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DOCUMENT_ENTERING_BALANCES_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_entering_balance_list' })
                    if (data?.restore_text) {
                        commit(ALERT_SHOW, {text: data.restore_text, color: 'secondary'})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DOCUMENT_ENTERING_BALANCES_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsEnteringBalanceAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DOCUMENT_ENTERING_BALANCES_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_entering_balance_list' })
                    if (data?.restore_text) {
                        commit(ALERT_SHOW, {text: data.restore_text, color: 'secondary'})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CONDUCTED_DOCUMENT_ENTERING_BALANCES_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsEnteringBalanceAPI.conducted(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CONDUCTED_DOCUMENT_ENTERING_BALANCES_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_entering_balance_list' })
                    if (data?.restore_text) {
                        commit(ALERT_SHOW, {text: data.restore_text, color: 'secondary'})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DOCUMENT_ENTERING_BALANCES_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsEnteringBalanceAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_DOCUMENT_ENTERING_BALANCES_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_entering_balance_list' })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_DOCUMENT_ENTERING_BALANCES_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingDocumentsEnteringBalanceAPI.select(payload)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_DOCUMENT_ENTERING_BALANCES_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },

    [GET_DOCUMENT_MANUAL_OPERATION_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_doc_manual_operation_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_DOCUMENT_MANUAL_OPERATION_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                        reject(false)
                    }
                )
        })
    },
    [CREATE_DOCUMENT_MANUAL_OPERATION_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsManualOperationAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DOCUMENT_MANUAL_OPERATION_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_manual_operation_list' })
                    if (data?.restore_text) {
                        commit(ALERT_SHOW, {text: data.restore_text, color: 'secondary'})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DOCUMENT_MANUAL_OPERATION_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsManualOperationAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DOCUMENT_MANUAL_OPERATION_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_manual_operation_list' })
                    if (data?.restore_text) {
                        commit(ALERT_SHOW, {text: data.restore_text, color: 'secondary'})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CONDUCTED_DOCUMENT_MANUAL_OPERATION_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsManualOperationAPI.conducted(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CONDUCTED_DOCUMENT_MANUAL_OPERATION_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_manual_operation_list' })
                    if (data?.restore_text) {
                        commit(ALERT_SHOW, {text: data.restore_text, color: 'secondary'})
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DOCUMENT_MANUAL_OPERATION_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsManualOperationAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_DOCUMENT_MANUAL_OPERATION_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_manual_operation_list' })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_DOCUMENT_MANUAL_OPERATION_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingDocumentsManualOperationAPI.select(payload)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_DOCUMENT_MANUAL_OPERATION_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },

    [GET_DOCUMENT_TAX_BILL_INCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_doc_tax_bill_income_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_DOCUMENT_TAX_BILL_INCOME_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                        reject(false)
                    }
                )
        })
    },
    [CREATE_DOCUMENT_TAX_BILL_INCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsTaxBillIncomeAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DOCUMENT_TAX_BILL_INCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_tax_bill_income_list' })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DOCUMENT_TAX_BILL_INCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsTaxBillIncomeAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DOCUMENT_TAX_BILL_INCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_tax_bill_income_list' })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CONDUCTED_DOCUMENT_TAX_BILL_INCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsTaxBillIncomeAPI.conducted(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CONDUCTED_DOCUMENT_TAX_BILL_INCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_tax_bill_income_list' })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DOCUMENT_TAX_BILL_INCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsTaxBillIncomeAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_DOCUMENT_TAX_BILL_INCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_tax_bill_income_list' })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_DOCUMENT_TAX_BILL_INCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingDocumentsTaxBillIncomeAPI.select(payload)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_DOCUMENT_TAX_BILL_INCOME_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },

    [GET_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_doc_tax_bill_outcome_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                        reject(false)
                    }
                )
        })
    },
    [CREATE_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsTaxBillOutcomeAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_tax_bill_outcome_list' })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsTaxBillOutcomeAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_tax_bill_outcome_list' })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CONDUCTED_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsTaxBillOutcomeAPI.conducted(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CONDUCTED_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_tax_bill_outcome_list' })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsTaxBillOutcomeAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_tax_bill_outcome_list' })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingDocumentsTaxBillOutcomeAPI.select(payload)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },

    [GET_DOCUMENT_RELEASE_WORK_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_doc_release_work_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_DOCUMENT_RELEASE_WORK_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                        reject(false)
                    }
                )
        })
    },
    [CREATE_DOCUMENT_RELEASE_WORK_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsReleaseWorkAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DOCUMENT_RELEASE_WORK_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_release_work_list' })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DOCUMENT_RELEASE_WORK_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsReleaseWorkAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DOCUMENT_RELEASE_WORK_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_release_work_list' })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CONDUCTED_DOCUMENT_RELEASE_WORK_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsReleaseWorkAPI.conducted(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CONDUCTED_DOCUMENT_RELEASE_WORK_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_release_work_list' })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DOCUMENT_RELEASE_WORK_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDocumentsReleaseWorkAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_DOCUMENT_RELEASE_WORK_ELEMENTS, data)
                    commit(RELOAD_LIST, { getter: 'get_document_release_work_list' })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_DOCUMENT_RELEASE_WORK_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingDocumentsReleaseWorkAPI.select(payload)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_DOCUMENT_RELEASE_WORK_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
}

const mutations = {
    [GET_DOCUMENT_BANK_INCOME_ELEMENTS]: (state, payload) => {
        state.document_bank_income_list_elements = payload
    },
    [CREATE_DOCUMENT_BANK_INCOME_ELEMENTS]: (state, payload) => {
        state.document_bank_income_list_elements.push(payload)
    },
    [UPDATE_DOCUMENT_BANK_INCOME_ELEMENTS]: (state, payload) => {
        const obj = state.document_bank_income_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [CONDUCTED_DOCUMENT_BANK_INCOME_ELEMENTS]: (state, payload) => {
        const obj = state.document_bank_income_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_DOCUMENT_BANK_INCOME_ELEMENTS]: (state, payload) => {
        const obg = state.document_bank_income_list_elements.find(item => item.id === payload)
        const obj_id = state.document_bank_income_list_elements.indexOf(obg)
        if (obg) {
            state.document_bank_income_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_DOCUMENT_BANK_INCOME_ELEMENTS]: (state, payload) => {
        state.document_bank_income_list_elements_select = payload
    },

    [GET_DOCUMENT_COMMISSIONING_ELEMENTS]: (state, payload) => {
        state.document_commissioning_list_elements = payload
    },
    [CREATE_DOCUMENT_COMMISSIONING_ELEMENTS]: (state, payload) => {
        state.document_commissioning_list_elements.push(payload)
    },
    [UPDATE_DOCUMENT_COMMISSIONING_ELEMENTS]: (state, payload) => {
        const obj = state.document_commissioning_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [CONDUCTED_DOCUMENT_COMMISSIONING_ELEMENTS]: (state, payload) => {
        const obj = state.document_commissioning_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_DOCUMENT_COMMISSIONING_ELEMENTS]: (state, payload) => {
        const obg = state.document_commissioning_list_elements.find(item => item.id === payload)
        const obj_id = state.document_commissioning_list_elements.indexOf(obg)
        if (obg) {
            state.document_commissioning_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_DOCUMENT_COMMISSIONING_ELEMENTS]: (state, payload) => {
        state.document_commissioning_list_elements_select = payload
    },

    [GET_DOCUMENT_BANK_OUTCOME_ELEMENTS]: (state, payload) => {
        state.document_bank_outcome_list_elements = payload
    },
    [CREATE_DOCUMENT_BANK_OUTCOME_ELEMENTS]: (state, payload) => {
        state.document_bank_outcome_list_elements.push(payload)
    },
    [UPDATE_DOCUMENT_BANK_OUTCOME_ELEMENTS]: (state, payload) => {
        const obj = state.document_bank_outcome_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [CONDUCTED_DOCUMENT_BANK_OUTCOME_ELEMENTS]: (state, payload) => {
        const obj = state.document_bank_outcome_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_DOCUMENT_BANK_OUTCOME_ELEMENTS]: (state, payload) => {
        const obg = state.document_bank_outcome_list_elements.find(item => item.id === payload)
        const obj_id = state.document_bank_outcome_list_elements.indexOf(obg)
        if (obg) {
            state.document_bank_outcome_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_DOCUMENT_BANK_OUTCOME_ELEMENTS]: (state, payload) => {
        state.document_bank_outcome_list_elements_select = payload
    },


    [GET_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS]: (state, payload) => {
        state.document_cash_order_income_list_elements = payload
    },
    [CREATE_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS]: (state, payload) => {
        state.document_cash_order_income_list_elements.push(payload)
    },
    [UPDATE_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS]: (state, payload) => {
        const obj = state.document_cash_order_income_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [CONDUCTED_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS]: (state, payload) => {
        const obj = state.document_cash_order_income_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS]: (state, payload) => {
        const obg = state.document_cash_order_income_list_elements.find(item => item.id === payload)
        const obj_id = state.document_cash_order_income_list_elements.indexOf(obg)
        if (obg) {
            state.document_cash_order_income_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS]: (state, payload) => {
        state.document_cash_order_income_list_elements_select = payload
    },


    [GET_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS]: (state, payload) => {
        state.document_cash_order_outcome_list_elements = payload
    },
    [CREATE_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS]: (state, payload) => {
        state.document_cash_order_outcome_list_elements.push(payload)
    },
    [UPDATE_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS]: (state, payload) => {
        const obj = state.document_cash_order_outcome_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [CONDUCTED_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS]: (state, payload) => {
        const obj = state.document_cash_order_outcome_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS]: (state, payload) => {
        const obg = state.document_cash_order_outcome_list_elements.find(item => item.id === payload)
        const obj_id = state.document_cash_order_outcome_list_elements.indexOf(obg)
        if (obg) {
            state.document_cash_order_outcome_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS]: (state, payload) => {
        state.document_cash_order_outcome_list_elements_select = payload
    },


    [GET_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS]: (state, payload) => {
        state.document_personal_timesheet_list_elements = payload
    },
    [CREATE_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS]: (state, payload) => {
        state.document_personal_timesheet_list_elements.push(payload)
    },
    [UPDATE_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS]: (state, payload) => {
        const obj = state.document_personal_timesheet_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [CONDUCTED_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS]: (state, payload) => {
        const obj = state.document_personal_timesheet_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS]: (state, payload) => {
        const obg = state.document_personal_timesheet_list_elements.find(item => item.id === payload)
        const obj_id = state.document_personal_timesheet_list_elements.indexOf(obg)
        if (obg) {
            state.document_personal_timesheet_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS]: (state, payload) => {
        state.document_personal_timesheet_list_elements_select = payload
    },


    [GET_DOCUMENT_WORK_TRANSFER_ELEMENTS]: (state, payload) => {
        state.document_work_transfer_list_elements = payload
    },
    [CREATE_DOCUMENT_WORK_TRANSFER_ELEMENTS]: (state, payload) => {
        state.document_work_transfer_list_elements.push(payload)
    },
    [UPDATE_DOCUMENT_WORK_TRANSFER_ELEMENTS]: (state, payload) => {
        const obj = state.document_work_transfer_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [CONDUCTED_DOCUMENT_WORK_TRANSFER_ELEMENTS]: (state, payload) => {
        const obj = state.document_work_transfer_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_DOCUMENT_WORK_TRANSFER_ELEMENTS]: (state, payload) => {
        const obg = state.document_work_transfer_list_elements.find(item => item.id === payload)
        const obj_id = state.document_work_transfer_list_elements.indexOf(obg)
        if (obg) {
            state.document_work_transfer_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_DOCUMENT_WORK_TRANSFER_ELEMENTS]: (state, payload) => {
        state.document_work_transfer_list_elements_select = payload
    },


    [GET_DOCUMENT_EMPLOYMENT_ELEMENTS]: (state, payload) => {
        state.document_employment_list_elements = payload
    },
    [CREATE_DOCUMENT_EMPLOYMENT_ELEMENTS]: (state, payload) => {
        state.document_employment_list_elements.push(payload)
    },
    [UPDATE_DOCUMENT_EMPLOYMENT_ELEMENTS]: (state, payload) => {
        const obj = state.document_employment_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [CONDUCTED_DOCUMENT_EMPLOYMENT_ELEMENTS]: (state, payload) => {
        const obj = state.document_employment_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_DOCUMENT_EMPLOYMENT_ELEMENTS]: (state, payload) => {
        const obg = state.document_employment_list_elements.find(item => item.id === payload)
        const obj_id = state.document_employment_list_elements.indexOf(obg)
        if (obg) {
            state.document_employment_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_DOCUMENT_EMPLOYMENT_ELEMENTS]: (state, payload) => {
        state.document_employment_list_elements_select = payload
    },


    [GET_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS]: (state, payload) => {
        state.document_contract_by_cln_list_elements = payload
    },
    [CREATE_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS]: (state, payload) => {
        state.document_contract_by_cln_list_elements.push(payload)
    },
    [UPDATE_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS]: (state, payload) => {
        const obj = state.document_contract_by_cln_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [CONDUCTED_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS]: (state, payload) => {
        const obj = state.document_contract_by_cln_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS]: (state, payload) => {
        const obg = state.document_contract_by_cln_list_elements.find(item => item.id === payload)
        const obj_id = state.document_contract_by_cln_list_elements.indexOf(obg)
        if (obg) {
            state.document_contract_by_cln_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS]: (state, payload) => {
        state.document_contract_by_cln_list_elements_select = payload
    },


    [GET_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS]: (state, payload) => {
        state.document_social_privilege_list_elements = payload
    },
    [CREATE_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS]: (state, payload) => {
        state.document_social_privilege_list_elements.push(payload)
    },
    [UPDATE_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS]: (state, payload) => {
        const obj = state.document_social_privilege_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [CONDUCTED_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS]: (state, payload) => {
        const obj = state.document_social_privilege_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS]: (state, payload) => {
        const obg = state.document_social_privilege_list_elements.find(item => item.id === payload)
        const obj_id = state.document_social_privilege_list_elements.indexOf(obg)
        if (obg) {
            state.document_social_privilege_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS]: (state, payload) => {
        state.document_social_privilege_list_elements_select = payload
    },


    [GET_DOCUMENT_SALARY_CHARGE_ELEMENTS]: (state, payload) => {
        state.document_salary_charge_list_elements = payload
    },
    [CREATE_DOCUMENT_SALARY_CHARGE_ELEMENTS]: (state, payload) => {
        state.document_salary_charge_list_elements.push(payload)
    },
    [UPDATE_DOCUMENT_SALARY_CHARGE_ELEMENTS]: (state, payload) => {
        const obj = state.document_salary_charge_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [CONDUCTED_DOCUMENT_SALARY_CHARGE_ELEMENTS]: (state, payload) => {
        const obj = state.document_salary_charge_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_DOCUMENT_SALARY_CHARGE_ELEMENTS]: (state, payload) => {
        const obg = state.document_salary_charge_list_elements.find(item => item.id === payload)
        const obj_id = state.document_salary_charge_list_elements.indexOf(obg)
        if (obg) {
            state.document_salary_charge_list_elements.splice(obj_id, 1)
        }
    },

    [GET_DOCUMENT_SALARY_TO_PAY_ELEMENTS]: (state, payload) => {
        state.document_salary_to_pay_list_elements = payload
    },
    [CREATE_DOCUMENT_SALARY_TO_PAY_ELEMENTS]: (state, payload) => {
        state.document_salary_to_pay_list_elements.push(payload)
    },
    [UPDATE_DOCUMENT_SALARY_TO_PAY_ELEMENTS]: (state, payload) => {
        const obj = state.document_salary_to_pay_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [CONDUCTED_DOCUMENT_SALARY_TO_PAY_ELEMENTS]: (state, payload) => {
        const obj = state.document_salary_to_pay_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_DOCUMENT_SALARY_TO_PAY_ELEMENTS]: (state, payload) => {
        const obg = state.document_salary_to_pay_list_elements.find(item => item.id === payload)
        const obj_id = state.document_salary_to_pay_list_elements.indexOf(obg)
        if (obg) {
            state.document_salary_to_pay_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_DOCUMENT_SALARY_TO_PAY_ELEMENTS]: (state, payload) => {
        state.document_salary_to_pay_list_elements_select = payload
    },

    [GET_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS]: (state, payload) => {
        state.document_hospital_list_elements = payload
    },
    [CREATE_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS]: (state, payload) => {
        state.document_hospital_list_elements.push(payload)
    },
    [UPDATE_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS]: (state, payload) => {
        const obj = state.document_hospital_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [CONDUCTED_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS]: (state, payload) => {
        const obj = state.document_hospital_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS]: (state, payload) => {
        const obg = state.document_hospital_list_elements.find(item => item.id === payload)
        const obj_id = state.document_hospital_list_elements.indexOf(obg)
        if (obg) {
            state.document_hospital_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS]: (state, payload) => {
        state.document_hospital_list_elements_select = payload
    },

    [GET_DOCUMENT_VOCATION_CHARGE_ELEMENTS]: (state, payload) => {
        state.document_vocation_list_elements = payload
    },
    [CREATE_DOCUMENT_VOCATION_CHARGE_ELEMENTS]: (state, payload) => {
        state.document_vocation_list_elements.push(payload)
    },
    [UPDATE_DOCUMENT_VOCATION_CHARGE_ELEMENTS]: (state, payload) => {
        const obj = state.document_vocation_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [CONDUCTED_DOCUMENT_VOCATION_CHARGE_ELEMENTS]: (state, payload) => {
        const obj = state.document_vocation_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_DOCUMENT_VOCATION_CHARGE_ELEMENTS]: (state, payload) => {
        const obg = state.document_vocation_list_elements.find(item => item.id === payload)
        const obj_id = state.document_vocation_list_elements.indexOf(obg)
        if (obg) {
            state.document_vocation_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_DOCUMENT_VOCATION_CHARGE_ELEMENTS]: (state, payload) => {
        state.document_vocation_list_elements_select = payload
    },

    [GET_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS]: (state, payload) => {
        state.document_act_work_performed_outcome_list_elements = payload
    },
    [CREATE_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS]: (state, payload) => {
        state.document_act_work_performed_outcome_list_elements.push(payload)
    },
    [UPDATE_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS]: (state, payload) => {
        const obj = state.document_act_work_performed_outcome_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [CONDUCTED_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS]: (state, payload) => {
        const obj = state.document_act_work_performed_outcome_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS]: (state, payload) => {
        const obg = state.document_act_work_performed_outcome_list_elements.find(item => item.id === payload)
        const obj_id = state.document_act_work_performed_outcome_list_elements.indexOf(obg)
        if (obg) {
            state.document_act_work_performed_outcome_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS]: (state, payload) => {
        state.document_act_work_performed_outcome_list_elements_select = payload
    },

    [GET_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS]: (state, payload) => {
        state.document_act_work_performed_income_list_elements = payload
    },
    [CREATE_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS]: (state, payload) => {
        state.document_act_work_performed_income_list_elements.push(payload)
    },
    [UPDATE_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS]: (state, payload) => {
        const obj = state.document_act_work_performed_income_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [CONDUCTED_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS]: (state, payload) => {
        const obj = state.document_act_work_performed_income_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS]: (state, payload) => {
        const obg = state.document_act_work_performed_income_list_elements.find(item => item.id === payload)
        const obj_id = state.document_act_work_performed_income_list_elements.indexOf(obg)
        if (obg) {
            state.document_act_work_performed_income_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS]: (state, payload) => {
        state.document_act_work_performed_income_list_elements_select = payload
    },

    [GET_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS]: (state, payload) => {
        state.document_invoice_warehouse_income_list_elements = payload
    },
    [CREATE_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS]: (state, payload) => {
        state.document_invoice_warehouse_income_list_elements.push(payload)
    },
    [UPDATE_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS]: (state, payload) => {
        const obj = state.document_invoice_warehouse_income_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [CONDUCTED_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS]: (state, payload) => {
        const obj = state.document_invoice_warehouse_income_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS]: (state, payload) => {
        const obg = state.document_invoice_warehouse_income_list_elements.find(item => item.id === payload)
        const obj_id = state.document_invoice_warehouse_income_list_elements.indexOf(obg)
        if (obg) {
            state.document_invoice_warehouse_income_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS]: (state, payload) => {
        state.document_invoice_warehouse_income_list_elements_select = payload
    },

    [GET_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS]: (state, payload) => {
        state.document_invoice_warehouse_outcome_list_elements = payload
    },
    [CREATE_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS]: (state, payload) => {
        state.document_invoice_warehouse_outcome_list_elements.push(payload)
    },
    [UPDATE_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS]: (state, payload) => {
        const obj = state.document_invoice_warehouse_outcome_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [CONDUCTED_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS]: (state, payload) => {
        const obj = state.document_invoice_warehouse_outcome_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS]: (state, payload) => {
        const obg = state.document_invoice_warehouse_outcome_list_elements.find(item => item.id === payload)
        const obj_id = state.document_invoice_warehouse_outcome_list_elements.indexOf(obg)
        if (obg) {
            state.document_invoice_warehouse_outcome_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS]: (state, payload) => {
        state.document_invoice_warehouse_outcome_list_elements_select = payload
    },

    [GET_DOCUMENT_BILL_OUTCOME_ELEMENTS]: (state, payload) => {
        state.document_bill_outcome_list_elements = payload
    },
    [CREATE_DOCUMENT_BILL_OUTCOME_ELEMENTS]: (state, payload) => {
        state.document_bill_outcome_list_elements.push(payload)
    },
    [UPDATE_DOCUMENT_BILL_OUTCOME_ELEMENTS]: (state, payload) => {
        const obj = state.document_bill_outcome_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [CONDUCTED_DOCUMENT_BILL_OUTCOME_ELEMENTS]: (state, payload) => {
        const obj = state.document_bill_outcome_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_DOCUMENT_BILL_OUTCOME_ELEMENTS]: (state, payload) => {
        const obg = state.document_bill_outcome_list_elements.find(item => item.id === payload)
        const obj_id = state.document_bill_outcome_list_elements.indexOf(obg)
        if (obg) {
            state.document_bill_outcome_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_DOCUMENT_BILL_OUTCOME_ELEMENTS]: (state, payload) => {
        state.document_bill_outcome_list_elements_select = payload
    },

    [GET_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS]: (state, payload) => {
        state.document_warehouse_inventory_outcome_list_elements = payload
    },
    [CREATE_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS]: (state, payload) => {
        state.document_warehouse_inventory_outcome_list_elements.push(payload)
    },
    [UPDATE_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS]: (state, payload) => {
        const obj = state.document_warehouse_inventory_outcome_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [CONDUCTED_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS]: (state, payload) => {
        const obj = state.document_warehouse_inventory_outcome_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS]: (state, payload) => {
        const obg = state.document_warehouse_inventory_outcome_list_elements.find(item => item.id === payload)
        const obj_id = state.document_warehouse_inventory_outcome_list_elements.indexOf(obg)
        if (obg) {
            state.document_warehouse_inventory_outcome_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS]: (state, payload) => {
        state.document_warehouse_inventory_outcome_list_elements_select = payload
    },

    [GET_DOCUMENT_ADVANCE_REPORT_ELEMENTS]: (state, payload) => {
        state.document_advance_report_list_elements = payload
    },
    [CREATE_DOCUMENT_ADVANCE_REPORT_ELEMENTS]: (state, payload) => {
        state.document_advance_report_list_elements.push(payload)
    },
    [UPDATE_DOCUMENT_ADVANCE_REPORT_ELEMENTS]: (state, payload) => {
        const obj = state.document_advance_report_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [CONDUCTED_DOCUMENT_ADVANCE_REPORT_ELEMENTS]: (state, payload) => {
        const obj = state.document_advance_report_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_DOCUMENT_ADVANCE_REPORT_ELEMENTS]: (state, payload) => {
        const obg = state.document_advance_report_list_elements.find(item => item.id === payload)
        const obj_id = state.document_advance_report_list_elements.indexOf(obg)
        if (obg) {
            state.document_advance_report_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_DOCUMENT_ADVANCE_REPORT_ELEMENTS]: (state, payload) => {
        state.document_advance_report_list_elements_select = payload
    },

    [GET_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS]: (state, payload) => {
        state.document_low_cost_commissioning_list_elements = payload
    },
    [CREATE_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS]: (state, payload) => {
        state.document_low_cost_commissioning_list_elements.push(payload)
    },
    [UPDATE_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS]: (state, payload) => {
        const obj = state.document_low_cost_commissioning_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [CONDUCTED_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS]: (state, payload) => {
        const obj = state.document_low_cost_commissioning_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS]: (state, payload) => {
        const obg = state.document_low_cost_commissioning_list_elements.find(item => item.id === payload)
        const obj_id = state.document_low_cost_commissioning_list_elements.indexOf(obg)
        if (obg) {
            state.document_low_cost_commissioning_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS]: (state, payload) => {
        state.document_low_cost_commissioning_list_elements_select = payload
    },

    [GET_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS]: (state, payload) => {
        state.document_close_financial_month_list_elements = payload
    },
    [CREATE_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS]: (state, payload) => {
        state.document_close_financial_month_list_elements.push(payload)
    },
    [UPDATE_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS]: (state, payload) => {
        const obj = state.document_close_financial_month_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [CONDUCTED_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS]: (state, payload) => {
        const obj = state.document_close_financial_month_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS]: (state, payload) => {
        const obg = state.document_close_financial_month_list_elements.find(item => item.id === payload)
        const obj_id = state.document_close_financial_month_list_elements.indexOf(obg)
        if (obg) {
            state.document_close_financial_month_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS]: (state, payload) => {
        state.document_close_financial_month_list_elements_select = payload
    },

    [GET_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS]: (state, payload) => {
        state.document_close_home_accounting_list_elements = payload
    },
    [CREATE_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS]: (state, payload) => {
        state.document_close_home_accounting_list_elements.push(payload)
    },
    [UPDATE_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS]: (state, payload) => {
        const obj = state.document_close_home_accounting_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [CONDUCTED_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS]: (state, payload) => {
        const obj = state.document_close_home_accounting_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS]: (state, payload) => {
        const obg = state.document_close_home_accounting_list_elements.find(item => item.id === payload)
        const obj_id = state.document_close_home_accounting_list_elements.indexOf(obg)
        if (obg) {
            state.document_close_home_accounting_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS]: (state, payload) => {
        state.document_close_home_accounting_list_elements_select = payload
    },

    [GET_DOCUMENT_ENTERING_BALANCES_ELEMENTS]: (state, payload) => {
        state.document_entering_balance_list_elements = payload
    },
    [CREATE_DOCUMENT_ENTERING_BALANCES_ELEMENTS]: (state, payload) => {
        state.document_entering_balance_list_elements.push(payload)
    },
    [UPDATE_DOCUMENT_ENTERING_BALANCES_ELEMENTS]: (state, payload) => {
        const obj = state.document_entering_balance_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [CONDUCTED_DOCUMENT_ENTERING_BALANCES_ELEMENTS]: (state, payload) => {
        const obj = state.document_entering_balance_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_DOCUMENT_ENTERING_BALANCES_ELEMENTS]: (state, payload) => {
        const obg = state.document_entering_balance_list_elements.find(item => item.id === payload)
        const obj_id = state.document_entering_balance_list_elements.indexOf(obg)
        if (obg) {
            state.document_entering_balance_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_DOCUMENT_ENTERING_BALANCES_ELEMENTS]: (state, payload) => {
        state.document_entering_balance_list_elements_select = payload
    },

    [GET_DOCUMENT_MANUAL_OPERATION_ELEMENTS]: (state, payload) => {
        state.document_manual_operation_list_elements = payload
    },
    [CREATE_DOCUMENT_MANUAL_OPERATION_ELEMENTS]: (state, payload) => {
        state.document_manual_operation_list_elements.push(payload)
    },
    [UPDATE_DOCUMENT_MANUAL_OPERATION_ELEMENTS]: (state, payload) => {
        const obj = state.document_manual_operation_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [CONDUCTED_DOCUMENT_MANUAL_OPERATION_ELEMENTS]: (state, payload) => {
        const obj = state.document_manual_operation_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_DOCUMENT_MANUAL_OPERATION_ELEMENTS]: (state, payload) => {
        const obg = state.document_manual_operation_list_elements.find(item => item.id === payload)
        const obj_id = state.document_manual_operation_list_elements.indexOf(obg)
        if (obg) {
            state.document_manual_operation_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_DOCUMENT_MANUAL_OPERATION_ELEMENTS]: (state, payload) => {
        state.document_manual_operation_list_elements_select = payload
    },

    [GET_DOCUMENT_TAX_BILL_INCOME_ELEMENTS]: (state, payload) => {
        state.document_tax_bill_income_list_elements = payload
    },
    [CREATE_DOCUMENT_TAX_BILL_INCOME_ELEMENTS]: (state, payload) => {
        state.document_tax_bill_income_list_elements.push(payload)
    },
    [UPDATE_DOCUMENT_TAX_BILL_INCOME_ELEMENTS]: (state, payload) => {
        const obj = state.document_tax_bill_income_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [CONDUCTED_DOCUMENT_TAX_BILL_INCOME_ELEMENTS]: (state, payload) => {
        const obj = state.document_tax_bill_income_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_DOCUMENT_TAX_BILL_INCOME_ELEMENTS]: (state, payload) => {
        const obg = state.document_tax_bill_income_list_elements.find(item => item.id === payload)
        const obj_id = state.document_tax_bill_income_list_elements.indexOf(obg)
        if (obg) {
            state.document_tax_bill_income_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_DOCUMENT_TAX_BILL_INCOME_ELEMENTS]: (state, payload) => {
        state.document_tax_bill_income_list_elements_select = payload
    },

    [GET_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS]: (state, payload) => {
        state.document_tax_bill_outcome_list_elements = payload
    },
    [CREATE_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS]: (state, payload) => {
        state.document_tax_bill_outcome_list_elements.push(payload)
    },
    [UPDATE_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS]: (state, payload) => {
        const obj = state.document_tax_bill_outcome_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [CONDUCTED_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS]: (state, payload) => {
        const obj = state.document_tax_bill_outcome_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS]: (state, payload) => {
        const obg = state.document_tax_bill_outcome_list_elements.find(item => item.id === payload)
        const obj_id = state.document_tax_bill_outcome_list_elements.indexOf(obg)
        if (obg) {
            state.document_tax_bill_outcome_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS]: (state, payload) => {
        state.document_tax_bill_outcome_list_elements_select = payload
    },

    [GET_DOCUMENT_RELEASE_WORK_ELEMENTS]: (state, payload) => {
        state.document_release_work_list_elements = payload
    },
    [CREATE_DOCUMENT_RELEASE_WORK_ELEMENTS]: (state, payload) => {
        state.document_release_work_list_elements.push(payload)
    },
    [UPDATE_DOCUMENT_RELEASE_WORK_ELEMENTS]: (state, payload) => {
        const obj = state.document_release_work_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [CONDUCTED_DOCUMENT_RELEASE_WORK_ELEMENTS]: (state, payload) => {
        const obj = state.document_release_work_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_DOCUMENT_RELEASE_WORK_ELEMENTS]: (state, payload) => {
        const obg = state.document_release_work_list_elements.find(item => item.id === payload)
        const obj_id = state.document_release_work_list_elements.indexOf(obg)
        if (obg) {
            state.document_release_work_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_DOCUMENT_RELEASE_WORK_ELEMENTS]: (state, payload) => {
        state.document_release_work_list_elements_select = payload
    },
}


export default {
    state,
    getters,
    actions,
    mutations
}