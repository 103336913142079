import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/city';

export default {
    all(payload) {
        const inner_payload = payload || {}
        return axios.get(`${RESOURCE_NAME}/`, {params: inner_payload})
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { "city_id": payload })
    },
    get_tree() {
        return axios.get(`${RESOURCE_NAME}/tree`)
    },
    search_new({text, parent_id, page}) {
        return axios.post(`${RESOURCE_NAME}/search-new`, { search_text : text, page: page, parent_id: parent_id })
    },
    get_by_id(payload) {
        return axios.post(`${RESOURCE_NAME}/get-by-id`, { elements: payload })
    },
    get_one_by_id(payload) {
        return axios.get(`${RESOURCE_NAME}/one-by-id`, { params: payload })
    },
};