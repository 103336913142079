import {ALERT_SHOW} from "@/store/actions/alert";

import absentAPI from "@/utils/axios/absent"
import {
    CREATE_ABSENT_PERSONS,
    DELETE_ABSENT_PERSONS,
    GET_ALL_ABSENT_PERSONS,
    UPDATE_ABSENT_PERSONS
} from "@/store/actions/absent";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = { absent_persons: [] }

const getters = {
    getAbsentPersons: state => state.absent_persons,
}

const actions = {
    [GET_ALL_ABSENT_PERSONS]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            absentAPI.get_all(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_ALL_ABSENT_PERSONS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_ABSENT_PERSONS]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            absentAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_ABSENT_PERSONS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_ABSENT_PERSONS]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            absentAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_ABSENT_PERSONS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_ABSENT_PERSONS]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            absentAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(DELETE_ABSENT_PERSONS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
}

const mutations = {
    [GET_ALL_ABSENT_PERSONS]: (state, payload) => {
        state.absent_persons = payload
    },
    [CREATE_ABSENT_PERSONS]: (state, payload) => {
        state.absent_persons.push(payload)
    },
    [UPDATE_ABSENT_PERSONS]: (state, payload) => {
        let obj = state.absent_persons.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [DELETE_ABSENT_PERSONS]: (state, payload) => {
        const obg = state.absent_persons.find(item => item.id === payload.id)
        const obj_id = state.absent_persons.indexOf(obg)
        if (obg) {
            state.absent_persons.splice(obj_id, 1)
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}