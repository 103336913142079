import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/flat_indicator';

export default {
    all(person_hash) {
        return axios.get(`${RESOURCE_NAME}/`, { params: { person_hash: person_hash } })
    },
    legacy_all(person_hash) {
        return axios.get(`${RESOURCE_NAME}/legacy`, { params: { person_hash: person_hash } })
    },
    all_actual(person_hash) {
        return axios.get(`${RESOURCE_NAME}/actual`, { params: { person_hash: person_hash } })
    },
    all_types() {
        return axios.get(`${RESOURCE_NAME}/type`)
    },
    all_unit() {
        return axios.get(`${RESOURCE_NAME}/unit`)
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    legacy_create(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy`, payload)
    },
    create_type(payload) {
        return axios.post(`${RESOURCE_NAME}/type`, payload)
    },
    create_unit(payload) {
        return axios.post(`${RESOURCE_NAME}/unit`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    legacy_update(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/update`, payload)
    },
    update_type(payload) {
        return axios.post(`${RESOURCE_NAME}/type/update`, payload)
    },
    update_unit(payload) {
        return axios.post(`${RESOURCE_NAME}/unit/update`, payload)
    },
    select() {
        return axios.post(`${RESOURCE_NAME}/select`)
    },
    select_type() {
        return axios.post(`${RESOURCE_NAME}/type/select`)
    },
    select_unit() {
        return axios.post(`${RESOURCE_NAME}/unit/select`)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { "indicator_id": payload })
    },
    legacy_remove(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/remove`, { "indicator_id": payload })
    },
    remove_type(payload) {
        return axios.post(`${RESOURCE_NAME}/type/remove`, { "indicator_type_id": payload })
    },
    remove_unit(payload) {
        return axios.post(`${RESOURCE_NAME}/type/remove`, { "indicator_type_id": payload })
    },
};