export const FETCH_SERVICES = "FETCH_SERVICES"
export const CREATE_SERVICE = "CREATE_SERVICE"
export const UPDATE_SERVICE = "UPDATE_SERVICE"
export const DELETE_SERVICE = "DELETE_SERVICE"
export const SELECT_SERVICES = "SELECT_SERVICES"
export const SELECT_SERVICES_WITHOUT_ALL_SERVICE = "SELECT_SERVICES_WITHOUT_ALL_SERVICE"
export const SELECT_SERVICES_WITH_COUNTER = "SELECT_SERVICES_WITH_COUNTER"
export const SELECT_SERVICES_ONE_TIME = "SELECT_SERVICES_ONE_TIME"

export const FETCH_REGULARS = "FETCH_REGULARS"
export const CREATE_REGULARS = "CREATE_REGULARS"
export const UPDATE_REGULARS = "UPDATE_REGULARS"
export const DELETE_REGULARS = "DELETE_REGULARS"
export const SELECT_REGULARS = "SELECT_REGULARS"
export const SELECT_SOCIAL_REGULARS = "SELECT_SOCIAL_REGULARS"


