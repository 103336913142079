import {ALERT_SHOW} from "@/store/actions/alert";

import dispatcherCallPAI from "@/utils/axios/dispatcher_call"
import {
    CREATE_DISPATCHER_CALL,
    DELETE_DISPATCHER_CALL,
    GET_ALL_DISPATCHER_CALL, GET_ALL_DISPATCHER_CALL_BY_SEARCH,
    UPDATE_DISPATCHER_CALL
} from "@/store/actions/dispatcher_call";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = { dispatcher_calls: [] }

const getters = {
    getDispatcherCalls: state => state.dispatcher_calls
}

const actions = {
    [GET_ALL_DISPATCHER_CALL]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            dispatcherCallPAI.get_all(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_ALL_DISPATCHER_CALL, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [GET_ALL_DISPATCHER_CALL_BY_SEARCH]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            dispatcherCallPAI.get_all_by_search(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_ALL_DISPATCHER_CALL_BY_SEARCH, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_DISPATCHER_CALL]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            dispatcherCallPAI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DISPATCHER_CALL, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DISPATCHER_CALL]: ({ commit }, payload) => {
        commit(START_MODAL_LOADING)
        return new Promise((resolve, reject) => {
            dispatcherCallPAI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DISPATCHER_CALL, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_DISPATCHER_CALL]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            dispatcherCallPAI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(DELETE_DISPATCHER_CALL, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    }
}

const mutations = {
    [GET_ALL_DISPATCHER_CALL]: (state, payload) => {
        state.dispatcher_calls = payload
    },
    [GET_ALL_DISPATCHER_CALL_BY_SEARCH]: (state, payload) => {
        state.dispatcher_calls = payload
    },
    [CREATE_DISPATCHER_CALL]: (state, payload) => {
        state.dispatcher_calls.push(payload)
    },
    [UPDATE_DISPATCHER_CALL]: (state, payload) => {
        let obj = state.dispatcher_calls.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [DELETE_DISPATCHER_CALL]: (state, payload) => {
        const obg = state.dispatcher_calls.find(item => item.id === payload.id)
        const obj_id = state.dispatcher_calls.indexOf(obg)
        if (obg) {
            state.dispatcher_calls.splice(obj_id, 1)
        }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}