import {
    FETCH_ALL_CONTACTS,
    FETCH_MAIN_CONTACTS,
    CREATE_CONTACT,
    CREATE_MAIN_CONTACT,
    UPDATE_CONTACT,
    DELETE_CONTACT
} from "@/store/actions/contact";

import contactAPI from "@/utils/axios/contact"

import {ALERT_SHOW} from "@/store/actions/alert";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = { contacts: [] }

const getters = {
    getContacts: state => state.contacts,
}

const actions = {
    [FETCH_MAIN_CONTACTS]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            contactAPI.main(payload)
                .then(response => response.data)
                .then(data => {
                    commit(FETCH_MAIN_CONTACTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [FETCH_ALL_CONTACTS]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            contactAPI.all(payload)
                .then(response => response.data)
                .then(data => {
                    commit(FETCH_ALL_CONTACTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_MAIN_CONTACT]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            contactAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_CONTACT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CREATE_CONTACT]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            contactAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_CONTACT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_CONTACT]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            contactAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_CONTACT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_CONTACT]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            contactAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(DELETE_CONTACT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
}

const mutations = {
    [FETCH_MAIN_CONTACTS]: (state, payload) => {
        state.contacts = payload
    },
    [FETCH_ALL_CONTACTS]: (state, payload) => {
        state.contacts = payload
    },
    [CREATE_CONTACT]: (state, payload) => {
        state.contacts.push(payload)
    },
    [UPDATE_CONTACT]: (state, payload) => {
        let obj = state.contacts.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }

    },
    [DELETE_CONTACT]: (state, payload) => {
        const obg = state.contacts.find(item => item.id === payload.id)
        const obj_id = state.contacts.indexOf(obg)
        if (obg) {
            state.contacts.splice(obj_id, 1)
        }
    },
}


export default {
    state,
    getters,
    actions,
    mutations
}