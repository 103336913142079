import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/accounting/documents/bank-outcome';

export default {
    get_document_by_id(id) {
        return axios.post(`${RESOURCE_NAME}/get-document-by-id`, {document_id: id})
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    conducted(payload) {
        return axios.post(`${RESOURCE_NAME}/conducted`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { "delete_id": payload })
    },
    get_salary_sum_to_pay(payload) {
        return axios.post(`${RESOURCE_NAME}/get-salary-to-pay-sum`, payload)
    },
    select(payload) {
        return axios.get(`${RESOURCE_NAME}/select`, {params: payload})
    }
};