export const GET_POSITION = "GET_POSITION"
export const CREATE_POSITION = "CREATE_POSITION"
export const UPDATE_POSITION = "UPDATE_POSITION"
export const REMOVE_POSITION = "REMOVE_POSITION"
export const SELECT_POSITION = "SELECT_POSITION"

export const GET_NATURAL_PERSON = "GET_NATURAL_PERSON"
export const CREATE_NATURAL_PERSON = "CREATE_NATURAL_PERSON"
export const UPDATE_NATURAL_PERSON = "UPDATE_NATURAL_PERSON"
export const REMOVE_NATURAL_PERSON = "REMOVE_NATURAL_PERSON"
export const SELECT_NATURAL_PERSON = "SELECT_NATURAL_PERSON"

export const GET_RESPONSE_PERSON = "GET_RESPONSE_PERSON"
export const CREATE_RESPONSE_PERSON = "CREATE_RESPONSE_PERSON"
export const UPDATE_RESPONSE_PERSON = "UPDATE_RESPONSE_PERSON"
export const REMOVE_RESPONSE_PERSON = "REMOVE_RESPONSE_PERSON"

