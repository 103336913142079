import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/dispatcher-call';

export default {
    get_all(payload) {
        return axios.post(`${RESOURCE_NAME}/get-all`, payload)
    },
    get_all_by_search(payload) {
        return axios.get(`${RESOURCE_NAME}/search`, {params: payload})
    },
    get_tables(dispatcher_call_id) {
        return axios.get(`${RESOURCE_NAME}/get-tables`, {params: {dispatcher_call_id}})
    },
    get_info(flat_id) {
        return axios.get(`${RESOURCE_NAME}/get-info`, {params: {flat_id}})
    },
    get_info_for_change_data(payload) {
        return axios.get(`${RESOURCE_NAME}/get-info-for-change-data`, {params: {...payload}})
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { "dispatcher_call_id": payload })
    },
    select() {
        return axios.post(`${RESOURCE_NAME}/select`)
    },
};