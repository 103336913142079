import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/build_indicator';

export default {
    all(building_id) {
        return axios.get(`${RESOURCE_NAME}/`, { params: { building_id: building_id } })
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { "indicator_id": payload })
    },
    select() {
        return axios.post(`${RESOURCE_NAME}/select`)
    }
};