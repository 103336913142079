export const FETCH_PRIVILEGE_CATEGORIES = "FETCH_PRIVILEGE_CATEGORIES"
export const FETCH_PRIVILEGE_OWNER = "FETCH_PRIVILEGE_OWNER"
export const FETCH_PRIVILEGE_PERSONS = "FETCH_PRIVILEGE_PERSONS"

export const CREATE_PRIVILEGE_CATEGORIES = "CREATE_PRIVILEGE_CATEGORIES"
export const CREATE_PRIVILEGE_OWNER = "CREATE_PRIVILEGE_OWNER"
export const CREATE_PRIVILEGE_PERSONS = "CREATE_PRIVILEGE_PERSONS"

export const UPDATE_PRIVILEGE_CATEGORIES = "UPDATE_PRIVILEGE_CATEGORIES"
export const UPDATE_PRIVILEGE_OWNER = "UPDATE_PRIVILEGE_OWNER"
export const UPDATE_PRIVILEGE_PERSONS = "UPDATE_PRIVILEGE_PERSONS"

export const REMOVE_PRIVILEGE_CATEGORIES = "REMOVE_PRIVILEGE_CATEGORIES"
export const REMOVE_PRIVILEGE_OWNER = "REMOVE_PRIVILEGE_OWNER"
export const REMOVE_PRIVILEGE_PERSONS = "REMOVE_PRIVILEGE_PERSONS"

export const SET_SELECTED_PRIVILEGE = "SET_SELECTED_PRIVILEGE"
export const CLEAR_SELECTED_PRIVILEGE = "CLEAR_SELECTED_PRIVILEGE"

