import { axios } from '@/utils/axios';

const RESOURCE_NAME_SERVICE  = '/service';
const RESOURCE_NAME_REGULAR  = '/regular';

export default {
    get() {
        return axios.get(`${RESOURCE_NAME_SERVICE}/`)
    },
    get_regular(payload) {
        return axios.get(`${RESOURCE_NAME_REGULAR}/`, {params: payload})
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME_SERVICE}/`, payload)
    },
    create_regular(payload) {
        return axios.post(`${RESOURCE_NAME_REGULAR}/`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME_SERVICE}/update`, payload)
    },
    update_regular(payload) {
        return axios.post(`${RESOURCE_NAME_REGULAR}/update`, payload)
    },
    select(payload=true) {
        return axios.post(`${RESOURCE_NAME_SERVICE}/select`, {all_service: payload})
    },
    select_with_counter() {
        return axios.post(`${RESOURCE_NAME_SERVICE}/select-with-counter`)
    },
    select_one_time() {
        return axios.post(`${RESOURCE_NAME_SERVICE}/select-one-time`)
    },
    select_regular(payload) {
        return axios.post(`${RESOURCE_NAME_REGULAR}/select`, payload)
    },
    select_social_regular(payload) {
        return axios.post(`${RESOURCE_NAME_REGULAR}/select-social`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME_SERVICE}/remove`, { service_id: payload })
    },
    remove_regular(payload) {
        return axios.post(`${RESOURCE_NAME_REGULAR}/remove`, { regular_id: payload })
    },
}
