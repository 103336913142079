import {axios} from '@/utils/axios';

const RESOURCE_NAME = '/accounting/documents/act-work-performed-outcome';

export default {
    get_document_by_id(id) {
        return axios.post(`${RESOURCE_NAME}/get-document-by-id`, {document_id: id})
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    conducted(payload) {
        return axios.post(`${RESOURCE_NAME}/conducted`, payload)
    },
    get_contractor_balance(payload) {
        return axios.post(`${RESOURCE_NAME}/get-contract-balance`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`,
            {"delete_id": payload.delete_id, "flat_id": payload.flat_id}
        )
    },
    select(payload) {
        return axios.get(`${RESOURCE_NAME}/select`, {params: payload})
    },
    get_document_for_print(payload) {
        return axios.post(`${RESOURCE_NAME}/get-document-for-print`, {document_id: payload})
    },
    get_all_document_for_print(payload) {
        return axios.post(`${RESOURCE_NAME}/get-all-document-for-print`, payload)
    }
};