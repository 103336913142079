import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/absent-person';

export default {
    get_all(payload) {
        return axios.get(`${RESOURCE_NAME}/`, {params: { person_hash: payload }})
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { "absent_person_id": payload })
    },
};