import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/tariff_by_building';

export default {
    get({building_id, legacy}) {
        return axios.get(`${RESOURCE_NAME}/`, {params: {building_id: building_id, legacy: legacy}})
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { tariff_id: payload })
    },
    get_removal_items(payload) {
        return axios.get(`${RESOURCE_NAME}/get-removal-items`, {params: {tariff_id: payload}})
    },
    fill_removals() {
        return axios.get(`${RESOURCE_NAME}/fill-removals`)
    },
}
