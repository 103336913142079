import {ALERT_SHOW} from "@/store/actions/alert";

import smsSendConfigAPI from "@/utils/axios/sms_send_config"
import {
    CREATE_SMS_SEND_CONFIG,
    DELETE_SMS_SEND_CONFIG,
    GET_ALL_SMS_SEND_CONFIG,
    SELECT_SMS_SEND_CONFIG,
    UPDATE_SMS_SEND_CONFIG
} from "@/store/actions/sms_send_config";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = { sms_send_configs: [], select_sms_send_configs: [], select_loaded: false }

const getters = {
    getSmsSendConfigs: state => state.sms_send_configs,
    getSelectSmsSendConfigs: state => state.select_sms_send_configs,
}

const actions = {
    [GET_ALL_SMS_SEND_CONFIG]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            smsSendConfigAPI.get_all(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_ALL_SMS_SEND_CONFIG, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_SMS_SEND_CONFIG]: ({ dispatch, commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            smsSendConfigAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_SMS_SEND_CONFIG, data)
                    dispatch(SELECT_SMS_SEND_CONFIG)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_SMS_SEND_CONFIG]: ({ commit }, payload) => {
        commit(START_MODAL_LOADING)
        return new Promise((resolve, reject) => {
            smsSendConfigAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_SMS_SEND_CONFIG, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_SMS_SEND_CONFIG]: ({ dispatch, commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            smsSendConfigAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(DELETE_SMS_SEND_CONFIG, data)
                    dispatch(SELECT_SMS_SEND_CONFIG)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_SMS_SEND_CONFIG]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            smsSendConfigAPI.select(payload)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_SMS_SEND_CONFIG, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    }
}

const mutations = {
    [GET_ALL_SMS_SEND_CONFIG]: (state, payload) => {
        state.sms_send_configs = payload
    },
    [CREATE_SMS_SEND_CONFIG]: (state, payload) => {
        state.sms_send_configs.push(payload)
    },
    [UPDATE_SMS_SEND_CONFIG]: (state, payload) => {
        let obj = state.sms_send_configs.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [DELETE_SMS_SEND_CONFIG]: (state, payload) => {
        const obg = state.sms_send_configs.find(item => item.id === payload.id)
        const obj_id = state.sms_send_configs.indexOf(obg)
        if (obg) {
            state.sms_send_configs.splice(obj_id, 1)
        }
    },
    [SELECT_SMS_SEND_CONFIG]: (state, payload) => {
        state.select_sms_send_configs = payload
        state.select_loaded = true
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}