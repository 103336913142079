import {
    GET_FLAT_DOCUMENTS,
    CREATE_FLAT_DOCUMENTS,
    UPDATE_FLAT_DOCUMENTS,
    REMOVE_FLAT_DOCUMENTS
} from "@/store/actions/flat_documents";

import {ALERT_SHOW} from "@/store/actions/alert";
import flatDocumentAPI from "@/utils/axios/flat_douments"
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = {flat_documents: []}

const getters = {
    getFlatDocuments: state => state.flat_documents,
}

const actions = {
    [GET_FLAT_DOCUMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            flatDocumentAPI.get_flat_documents(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_FLAT_DOCUMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_FLAT_DOCUMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatDocumentAPI.create_flat_document(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_FLAT_DOCUMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_FLAT_DOCUMENTS]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatDocumentAPI.update_flat_document(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_FLAT_DOCUMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_FLAT_DOCUMENTS]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatDocumentAPI.remove_flat_document(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_FLAT_DOCUMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
}

const mutations = {
    [GET_FLAT_DOCUMENTS]: (state, payload) => {
        state.flat_documents = payload
    },
    [CREATE_FLAT_DOCUMENTS]: (state, payload) => {
        state.flat_documents.push(payload)
    },
    [UPDATE_FLAT_DOCUMENTS]: (state, payload) => {
        const doc_item = state.flat_documents.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            doc_item[item] = payload[item]
        })
    },
    [REMOVE_FLAT_DOCUMENTS]: (state, payload) => {
        const obg = state.flat_documents.find(item => item.id === payload.id)
        const obj_id = state.flat_documents.indexOf(obg)
        if (obg) {
            state.flat_documents.splice(obj_id, 1)
        }
    },
}


export default {
    state,
    getters,
    actions,
    mutations
}