import {
    FETCH_FLAT_INDICATOR_TYPES,
    FETCH_FLAT_INDICATORS,
    GET_FLAT_INDICATOR_TYPE,
    CREATE_FLAT_INDICATOR,
    CREATE_FLAT_INDICATOR_TYPE,
    UPDATE_FLAT_INDICATOR,
    UPDATE_FLAT_INDICATOR_TYPE,
    DELETE_FLAT_INDICATOR,
    REMOVE_FLAT_INDICATOR_TYPE,
    GET_UNIT_TYPES,
    CREATE_UNIT_TYPE,
    UPDATE_UNIT_TYPE,
    REMOVE_UNIT_TYPE,
    SELECT_UNIT_TYPE,
    FETCH_LEGACY_FLAT_INDICATORS,
    CREATE_LEGACY_FLAT_INDICATOR,
    UPDATE_LEGACY_FLAT_INDICATOR,
    DELETE_LEGACY_FLAT_INDICATOR
} from "@/store/actions/flat_indicators";

import flatIndAPI from "@/utils/axios/flat_indicator"
import {ALERT_SHOW} from "@/store/actions/alert";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = {
    flat_indicators: [],
    flat_indicators_actual: [],
    flat_indicator_types: [],
    indicator_types: [],
    unit_types: [],
    unit_types_select: []
}

const getters = {
    getFLatIndicators: state => state.flat_indicators,
    getFLatIndicatorsActual: state => state.flat_indicators_actual,
    getFlatIndicatorTypes: state => state.flat_indicator_types,
    getIndicatorTypes: state => state.indicator_types,
    getUnitTypes: state => state.unit_types,
    getUnitTypesSelect: state => state.unit_types_select
}

const actions = {
    [FETCH_FLAT_INDICATORS]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            flatIndAPI.all(payload)
                .then(response => response.data)
                .then(data => {
                    commit(FETCH_FLAT_INDICATORS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })

        })
    },
    [FETCH_LEGACY_FLAT_INDICATORS]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            flatIndAPI.legacy_all(payload)
                .then(response => response.data)
                .then(data => {
                    commit(FETCH_LEGACY_FLAT_INDICATORS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })

        })
    },
    [FETCH_FLAT_INDICATOR_TYPES]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            flatIndAPI.select()
                .then(response => response.data)
                .then(data => {
                    commit(FETCH_FLAT_INDICATOR_TYPES, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })

        })
    },
    [GET_FLAT_INDICATOR_TYPE]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            flatIndAPI.all_types()
                .then(response => response.data)
                .then(data => {
                    commit(GET_FLAT_INDICATOR_TYPE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })

        })
    },
    [GET_UNIT_TYPES]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            flatIndAPI.all_unit()
                .then(response => response.data)
                .then(data => {
                    commit(GET_UNIT_TYPES, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })

        })
    },
    [CREATE_FLAT_INDICATOR]: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatIndAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_FLAT_INDICATOR, data)
                    dispatch(FETCH_FLAT_INDICATORS, payload.person_hash)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CREATE_LEGACY_FLAT_INDICATOR]: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatIndAPI.legacy_create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_LEGACY_FLAT_INDICATOR, data)
                    dispatch(FETCH_LEGACY_FLAT_INDICATORS, payload.person_hash)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CREATE_FLAT_INDICATOR_TYPE]: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatIndAPI.create_type(payload)
                .then(response => response.data)
                .then(() => {
                    dispatch(GET_FLAT_INDICATOR_TYPE)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))

        })
    },
    [CREATE_UNIT_TYPE]: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatIndAPI.create_unit(payload)
                .then(response => response.data)
                .then(() => {
                    dispatch(GET_UNIT_TYPES)
                    dispatch(SELECT_UNIT_TYPE)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_FLAT_INDICATOR]: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatIndAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_FLAT_INDICATOR, data)
                    dispatch(FETCH_FLAT_INDICATORS, payload.person_hash)
                    dispatch(SELECT_UNIT_TYPE)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_LEGACY_FLAT_INDICATOR]: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatIndAPI.legacy_update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_LEGACY_FLAT_INDICATOR, data)
                    dispatch(FETCH_LEGACY_FLAT_INDICATORS, payload.person_hash)
                    dispatch(SELECT_UNIT_TYPE)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_FLAT_INDICATOR_TYPE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatIndAPI.update_type(payload)
                .then(response => response.data)
                .then((data) => {
                    commit(UPDATE_FLAT_INDICATOR_TYPE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_UNIT_TYPE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatIndAPI.update_unit(payload)
                .then(response => response.data)
                .then(() => {
                    commit(UPDATE_UNIT_TYPE)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_FLAT_INDICATOR]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatIndAPI.remove(payload)
                .then(response => response.data)
                .then(() => {
                    commit(DELETE_FLAT_INDICATOR, payload)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_LEGACY_FLAT_INDICATOR]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatIndAPI.legacy_remove(payload)
                .then(response => response.data)
                .then(() => {
                    commit(DELETE_LEGACY_FLAT_INDICATOR, payload)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_FLAT_INDICATOR_TYPE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatIndAPI.remove_type(payload)
                .then(response => response.data)
                .then(() => {
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_UNIT_TYPE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatIndAPI.remove_unit(payload)
                .then(response => response.data)
                .then(() => {
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_UNIT_TYPE]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            flatIndAPI.select_unit()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_UNIT_TYPE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })

        })
    },
}

const mutations = {
    [FETCH_FLAT_INDICATORS]: (state, payload) => {
        state.flat_indicators = payload
    },
    [FETCH_LEGACY_FLAT_INDICATORS]: (state, payload) => {
        state.flat_indicators = payload
    },
    [FETCH_FLAT_INDICATOR_TYPES]: (state, payload) => {
        state.flat_indicator_types = payload
    },
    [GET_FLAT_INDICATOR_TYPE]: (state, payload) => {
        state.indicator_types = payload
    },
    [GET_UNIT_TYPES]: (state, payload) => {
        state.unit_types = payload
    },
    [SELECT_UNIT_TYPE]: (state, payload) => {
        state.unit_types_select = payload
    },
    [CREATE_FLAT_INDICATOR]: (state, payload) => {
        state.flat_indicators.push(payload)
    },
    [CREATE_LEGACY_FLAT_INDICATOR]: (state, payload) => {
        state.flat_indicators.push(payload)
    },
    [CREATE_FLAT_INDICATOR_TYPE]: (state, payload) => {
        state.indicator_types.push(payload)
    },
    [CREATE_UNIT_TYPE]: (state, payload) => {
        state.unit_types.push(payload)
    },
    [UPDATE_FLAT_INDICATOR]: (state, payload) => {
        let obj = state.flat_indicators.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [UPDATE_LEGACY_FLAT_INDICATOR]: (state, payload) => {
        let obj = state.flat_indicators.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [UPDATE_FLAT_INDICATOR_TYPE]: (state, payload) => {
        let obj = state.indicator_types.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [UPDATE_UNIT_TYPE]: (state, payload) => {
        let obj = state.unit_types.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [DELETE_FLAT_INDICATOR]: (state, payload) => {
        const obg = state.flat_indicators.find(item => item.id === payload)
        const obj_id = state.flat_indicators.indexOf(obg)
        if (obg) {
            state.flat_indicators.splice(obj_id, 1)
        }
    },
    [DELETE_LEGACY_FLAT_INDICATOR]: (state, payload) => {
        const obg = state.flat_indicators.find(item => item.id === payload)
        const obj_id = state.flat_indicators.indexOf(obg)
        if (obg) {
            state.flat_indicators.splice(obj_id, 1)
        }
    },
    [REMOVE_FLAT_INDICATOR_TYPE]: (state, payload) => {
        const obg = state.indicator_types.find(item => item.id === payload)
        const obj_id = state.indicator_types.indexOf(obg)
        if (obg) {
            state.indicator_types.splice(obj_id, 1)
        }
    },
    [REMOVE_UNIT_TYPE]: (state, payload) => {
        const obg = state.unit_types.find(item => item.id === payload)
        const obj_id = state.unit_types.indexOf(obg)
        if (obg) {
            state.unit_types.splice(obj_id, 1)
        }
    },
}


export default {
    state,
    getters,
    actions,
    mutations
}