import accountingListAPI from "@/utils/axios/accounting/list_select"
import accountingWarehouse from "@/utils/axios/accounting/warehouse"
import accountingCurrency from "@/utils/axios/accounting/currency"
import accountingAmortisationCalcWay from "@/utils/axios/accounting/amortisation_calc_way"
import accountingContractor from "@/utils/axios/accounting/contractor"
import accountingContractorContract from "@/utils/axios/accounting/contractor_contract"
import accountingPosition from "@/utils/axios/accounting/position"
import accountingTaxInspection from "@/utils/axios/accounting/tax_inspection"
import accountingSubdivision from "@/utils/axios/accounting/subdivision"
import accountingUnit from "@/utils/axios/accounting/unit"
import accountingNaturalPerson from "@/utils/axios/accounting/natural_person"
import accountingPersonal from "@/utils/axios/accounting/personal"
import accountingNomenclature from "@/utils/axios/accounting/nomenclature"
import accountingDispatcherProblem from "@/utils/axios/accounting/dispatcher_problem"
import accountingCashFlowItem from "@/utils/axios/accounting/cash_flow_item"
import accountingBankAccount from "@/utils/axios/bank"
import accountingBiologicalAssets from "@/utils/axios/accounting/biological_assets"
import accountingCostItems from "@/utils/axios/accounting/cost_items"
import accountingReleaseReason from "@/utils/axios/accounting/release_reason"
import accountingFixedAssets from "@/utils/axios/accounting/fixed_assets"
import accountingIntangibleAssets from "@/utils/axios/accounting/intangible_assets"
import accountingWorkSchedule from "@/utils/axios/accounting/work_shcedule"
import accountingAccrual from "@/utils/axios/accounting/accrual"
import accountingTimesheetLetter from "@/utils/axios/accounting/timesheet_letter"
import accountingIncomeTypePix from "@/utils/axios/accounting/income_type_pix"
import accountingTaxes from "@/utils/axios/accounting/taxes"
import accountingMinAmount from "@/utils/axios/accounting/min_amount"
import accountingInflationIndex from "@/utils/axios/accounting/inflation_index"
import accountingTaxTariff from "@/utils/axios/accounting/tax_tariff"
import accountingIncomeItems from "@/utils/axios/accounting/income_items"
import accountingNomenclatureGroups from "@/utils/axios/accounting/nomenclature_groups"
import accountingContractorContactPerson from "@/utils/axios/accounting/contractor_contact_person"
import accountingContractorResponsePerson from "@/utils/axios/accounting/contractor_response_person"
import accountingContractorBankAccount from "@/utils/axios/accounting/contractor_bank_account"
import accountingHouseAccountingPaymentsSettings from "@/utils/axios/accounting/home_accounting_payments_settings"
import accountingHouseAccountingChargeSettings from "@/utils/axios/accounting/home_accounting_charge_settings"


import {ALERT_SHOW} from "@/store/actions/alert";

import {
    GET_WAREHOUSE_LIST_ELEMENTS,
    GET_NOMENCLATURE_LIST_GROUP,
    GET_CURRENCY_LIST_ELEMENTS,
    GET_CONTRACTOR_LIST_ELEMENTS,
    GET_CONTRACTOR_CONTRACT_LIST_ELEMENTS,
    GET_POSITION_LIST_ELEMENTS,
    GET_SUBDIVISION_LIST_ELEMENTS,
    GET_UNIT_LIST_ELEMENTS,
    GET_NATURAL_PERSON_LIST_ELEMENTS,
    GET_PERSONAL_LIST_ELEMENTS,

    CREATE_WAREHOUSE_LIST_ELEMENTS,
    UPDATE_WAREHOUSE_LIST_ELEMENTS,
    REMOVE_WAREHOUSE_LIST_ELEMENTS,

    GET_NOMENCLATURE_LIST_ELEMENTS,
    CREATE_NOMENCLATURE_LIST_GROUP,
    CREATE_NOMENCLATURE_LIST_ELEMENTS,
    UPDATE_NOMENCLATURE_LIST_GROUP,
    UPDATE_NOMENCLATURE_LIST_ELEMENTS,
    REMOVE_NOMENCLATURE_LIST_GROUP,
    REMOVE_NOMENCLATURE_LIST_ELEMENTS,

    GET_DISPATCHER_PROBLEM_LIST_GROUP,
    GET_DISPATCHER_PROBLEM_LIST_ELEMENTS,
    CREATE_DISPATCHER_PROBLEM_LIST_GROUP,
    CREATE_DISPATCHER_PROBLEM_LIST_ELEMENTS,
    UPDATE_DISPATCHER_PROBLEM_LIST_GROUP,
    UPDATE_DISPATCHER_PROBLEM_LIST_ELEMENTS,
    REMOVE_DISPATCHER_PROBLEM_LIST_GROUP,
    REMOVE_DISPATCHER_PROBLEM_LIST_ELEMENTS,
    SELECT_DISPATCHER_PROBLEM_LIST_ELEMENTS,

    CREATE_CURRENCY_LIST_ELEMENTS,
    UPDATE_CURRENCY_LIST_ELEMENTS,
    REMOVE_CURRENCY_LIST_ELEMENTS,

    CREATE_CONTRACTOR_LIST_ELEMENTS,
    UPDATE_CONTRACTOR_LIST_ELEMENTS,
    REMOVE_CONTRACTOR_LIST_ELEMENTS,
    SELECT_CONTRACTOR_LIST_ELEMENTS,

    CREATE_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS,
    UPDATE_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS,
    REMOVE_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS,

    CREATE_HOUSE_ACCOUNTING_CHARGE_SETTINGS_LIST_ELEMENTS,
    UPDATE_HOUSE_ACCOUNTING_CHARGE_SETTINGS_LIST_ELEMENTS,
    REMOVE_HOUSE_ACCOUNTING_CHARGE_SETTINGS_LIST_ELEMENTS,

    CREATE_CONTRACTOR_CONTRACT_LIST_ELEMENTS,
    UPDATE_CONTRACTOR_CONTRACT_LIST_ELEMENTS,
    REMOVE_CONTRACTOR_CONTRACT_LIST_ELEMENTS,
    SELECT_CONTRACTOR_CONTRACT_LIST_ELEMENTS,

    CREATE_POSITION_LIST_ELEMENTS,
    UPDATE_POSITION_LIST_ELEMENTS,
    REMOVE_POSITION_LIST_ELEMENTS,

    CREATE_NATURAL_PERSON_LIST_ELEMENTS,
    UPDATE_NATURAL_PERSON_LIST_ELEMENTS,
    REMOVE_NATURAL_PERSON_LIST_ELEMENTS,

    CREATE_SUBDIVISION_LIST_ELEMENTS,
    UPDATE_SUBDIVISION_LIST_ELEMENTS,
    REMOVE_SUBDIVISION_LIST_ELEMENTS,

    CREATE_UNIT_LIST_ELEMENTS,
    UPDATE_UNIT_LIST_ELEMENTS,
    REMOVE_UNIT_LIST_ELEMENTS,

    CREATE_PERSONAL_LIST_ELEMENTS,
    UPDATE_PERSONAL_LIST_ELEMENTS,
    REMOVE_PERSONAL_LIST_ELEMENTS,
    SELECT_NOMENCLATURE_LIST_ELEMENTS,

    CREATE_CHART_OF_ACCOUNTS_LIST_ELEMENTS,
    UPDATE_CHART_OF_ACCOUNTS_LIST_ELEMENTS,
    REMOVE_CHART_OF_ACCOUNTS_LIST_ELEMENTS,
    SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS,
    GET_CHART_OF_ACCOUNTS_LIST_ELEMENTS,

    CREATE_AMORTISATION_CALC_WAY_LIST_ELEMENTS,
    UPDATE_AMORTISATION_CALC_WAY_LIST_ELEMENTS,
    REMOVE_AMORTISATION_CALC_WAY_LIST_ELEMENTS,
    SELECT_AMORTISATION_CALC_WAY_LIST_ELEMENTS,
    GET_AMORTISATION_CALC_WAY_LIST_ELEMENTS,

    GET_CASH_FLOW_ITEM_LIST_ELEMENTS,
    CREATE_CASH_FLOW_ITEM_LIST_ELEMENTS,
    UPDATE_CASH_FLOW_ITEM_LIST_ELEMENTS,
    REMOVE_CASH_FLOW_ITEM_LIST_ELEMENTS,
    SELECT_CASH_FLOW_ITEM_LIST_ELEMENTS,

    GET_BIOLOGICAL_ASSETS_LIST_ELEMENTS,
    CREATE_BIOLOGICAL_ASSETS_LIST_ELEMENTS,
    UPDATE_BIOLOGICAL_ASSETS_LIST_ELEMENTS,
    REMOVE_BIOLOGICAL_ASSETS_LIST_ELEMENTS,
    SELECT_BIOLOGICAL_ASSETS_LIST_ELEMENTS,

    GET_COST_ITEMS_LIST_ELEMENTS,
    CREATE_COST_ITEMS_LIST_ELEMENTS,
    UPDATE_COST_ITEMS_LIST_ELEMENTS,
    REMOVE_COST_ITEMS_LIST_ELEMENTS,
    SELECT_COST_ITEMS_LIST_ELEMENTS,

    GET_RELEASE_REASON_LIST_ELEMENTS,
    CREATE_RELEASE_REASON_LIST_ELEMENTS,
    UPDATE_RELEASE_REASON_LIST_ELEMENTS,
    REMOVE_RELEASE_REASON_LIST_ELEMENTS,
    SELECT_RELEASE_REASON_LIST_ELEMENTS,

    GET_FIXED_ASSETS_LIST_ELEMENTS,
    CREATE_FIXED_ASSETS_LIST_ELEMENTS,
    UPDATE_FIXED_ASSETS_LIST_ELEMENTS,
    REMOVE_FIXED_ASSETS_LIST_ELEMENTS,
    SELECT_FIXED_ASSETS_LIST_ELEMENTS,

    GET_INTANGIBLE_ASSETS_LIST_ELEMENTS,
    CREATE_INTANGIBLE_ASSETS_LIST_ELEMENTS,
    UPDATE_INTANGIBLE_ASSETS_LIST_ELEMENTS,
    REMOVE_INTANGIBLE_ASSETS_LIST_ELEMENTS,
    SELECT_INTANGIBLE_ASSETS_LIST_ELEMENTS,

    GET_BANK_ACCOUNT_LIST_ELEMENTS,
    CREATE_BANK_ACCOUNT_LIST_ELEMENTS,
    UPDATE_BANK_ACCOUNT_LIST_ELEMENTS,
    REMOVE_BANK_ACCOUNT_LIST_ELEMENTS,
    SELECT_BANK_ACCOUNT_LIST_ELEMENTS,

    GET_WORK_SCHEDULE_LIST_ELEMENTS,
    CREATE_WORK_SCHEDULE_LIST_ELEMENTS,
    UPDATE_WORK_SCHEDULE_LIST_ELEMENTS,
    REMOVE_WORK_SCHEDULE_LIST_ELEMENTS,
    SELECT_WORK_SCHEDULE_LIST_ELEMENTS,

    GET_ACCRUAL_LIST_ELEMENTS,
    CREATE_ACCRUAL_LIST_ELEMENTS,
    UPDATE_ACCRUAL_LIST_ELEMENTS,
    REMOVE_ACCRUAL_LIST_ELEMENTS,
    SELECT_ACCRUAL_LIST_ELEMENTS,

    GET_TIMESHEET_LETTER_ELEMENTS,
    CREATE_TIMESHEET_LETTER_LIST_ELEMENTS,
    UPDATE_TIMESHEET_LETTER_LIST_ELEMENTS,
    REMOVE_TIMESHEET_LETTER_LIST_ELEMENTS,
    SELECT_TIMESHEET_LETTER_LIST_ELEMENTS,

    GET_INCOME_TYPE_PIX_ELEMENTS,
    CREATE_INCOME_TYPE_PIX_LIST_ELEMENTS,
    UPDATE_INCOME_TYPE_PIX_LIST_ELEMENTS,
    REMOVE_INCOME_TYPE_PIX_LIST_ELEMENTS,
    SELECT_INCOME_TYPE_PIX_LIST_ELEMENTS,

    GET_NOMENCLATURE_GROUPS_LIST_ELEMENTS,
    CREATE_NOMENCLATURE_GROUPS_LIST_ELEMENTS,
    UPDATE_NOMENCLATURE_GROUPS_LIST_ELEMENTS,
    REMOVE_NOMENCLATURE_GROUPS_LIST_ELEMENTS,
    SELECT_NOMENCLATURE_GROUPS_LIST_ELEMENTS,

    GET_INCOME_ITEMS_LIST_ELEMENTS,
    CREATE_INCOME_ITEMS_LIST_ELEMENTS,
    UPDATE_INCOME_ITEMS_LIST_ELEMENTS,
    REMOVE_INCOME_ITEMS_LIST_ELEMENTS,
    SELECT_INCOME_ITEMS_LIST_ELEMENTS,

    GET_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS,
    CREATE_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS,
    UPDATE_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS,
    REMOVE_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS,
    SELECT_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS,

    GET_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS,
    CREATE_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS,
    UPDATE_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS,
    REMOVE_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS,
    SELECT_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS,

    GET_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS,
    CREATE_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS,
    UPDATE_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS,
    REMOVE_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS,

    GET_TAXES_ELEMENTS,
    CREATE_TAXES_LIST_ELEMENTS,
    UPDATE_TAXES_LIST_ELEMENTS,
    REMOVE_TAXES_LIST_ELEMENTS,
    SELECT_TAXES_LIST_ELEMENTS,

    GET_MIN_AMOUNT_ELEMENTS,
    CREATE_MIN_AMOUNT_LIST_ELEMENTS,
    UPDATE_MIN_AMOUNT_LIST_ELEMENTS,
    REMOVE_MIN_AMOUNT_LIST_ELEMENTS,

    GET_INFLATION_INDEX_ELEMENTS,
    CREATE_INFLATION_INDEX_LIST_ELEMENTS,
    UPDATE_INFLATION_INDEX_LIST_ELEMENTS,
    REMOVE_INFLATION_INDEX_LIST_ELEMENTS,

    GET_TAX_TARIFF_ELEMENTS,
    CREATE_TAX_TARIFF_LIST_ELEMENTS,
    UPDATE_TAX_TARIFF_LIST_ELEMENTS,
    REMOVE_TAX_TARIFF_LIST_ELEMENTS,

    SELECT_WAREHOUSE_LIST_ELEMENTS,
    SELECT_CURRENCY_LIST_ELEMENTS,
    SELECT_POSITION_LIST_ELEMENTS,
    SELECT_SUBDIVISION_LIST_ELEMENTS,
    SELECT_UNIT_LIST_ELEMENTS,
    SELECT_PERSONAL_LIST_ELEMENTS,
    SELECT_NATURAL_PERSON_LIST_ELEMENTS,
    GET_TAX_INSPECTION_LIST_ELEMENTS,
    CREATE_TAX_INSPECTION_LIST_ELEMENTS,
    UPDATE_TAX_INSPECTION_LIST_ELEMENTS,
    REMOVE_TAX_INSPECTION_LIST_ELEMENTS,
    SELECT_TAX_INSPECTION_LIST_ELEMENTS,
    GET_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS,
    GET_HOUSE_ACCOUNTING_CHARGE_SETTINGS_LIST_ELEMENTS,

    CLEAR_ALL_ACCOUNTING_LOADING,
    GET_COST_ITEMS_LIST_GROUP,
    CREATE_COST_ITEMS_LIST_GROUP,
    UPDATE_COST_ITEMS_LIST_GROUP,
    REMOVE_COST_ITEMS_LIST_GROUP
} from "@/store/actions/accounting/list";
import chartOfAccountAPI from "@/utils/axios/accounting/chart_of_account";
import {RELOAD_LIST} from "@/store/actions/question";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = {
    warehouse_list_elements: [],
    warehouse_loading: false,
    warehouse_list_elements_select: [],
    currency_list_elements: [],
    house_accounting_payments_settings_loading: false,
    house_accounting_payments_settings_list_elements: [],
    house_accounting_payments_settings_list_elements_select: [],
    house_accounting_charge_settings_loading: false,
    house_accounting_charge_settings_list_elements: [],
    house_accounting_charge_settings_list_elements_select: [],
    currency_loading: false,
    currency_list_elements_select: [],
    amortisation_calc_way_list_elements: [],
    amortisation_calc_way_loading: false,
    amortisation_calc_way_list_elements_select: [],
    contractor_list_elements: [],
    contractor_loading: false,
    contractor_list_elements_select: [],
    contractor_contract_list_elements: [],
    contractor_contract_loading: false,
    contractor_contract_list_elements_select: [],
    position_list_elements: [],
    position_loading: false,
    position_list_elements_select: [],
    tax_inspection_list_elements: [],
    tax_inspection_loading: false,
    tax_inspection_list_elements_select: [],
    subdivision_list_elements: [],
    subdivision_loading: false,
    subdivision_list_elements_select: [],
    unit_list_elements: [],
    unit_loading: false,
    unit_list_elements_select: [],
    natural_person_list_elements: [],
    natural_person_loading: false,
    natural_person_list_elements_select: [],
    personal_list_elements: [],
    personal_loading: false,
    personal_list_elements_select: [],
    nomenclature_list_groups: [],
    nomenclature_list_elements: [],
    nomenclature_loading: false,
    nomenclature_list_elements_select: [],
    dispatcher_problem_list_groups: [],
    dispatcher_problem_list_elements: [],
    dispatcher_problem_loading: false,
    dispatcher_problem_list_elements_select: [],
    charts_of_account_list_elements: [],
    charts_of_account_loading: false,
    charts_of_account_list_elements_select: [],
    cash_flow_item_list_elements: [],
    cash_flow_item_loading: false,
    cash_flow_item_list_elements_select: [],
    bank_account_list_elements: [],
    bank_account_loading: false,
    bank_account_list_elements_select: [],
    biological_assets_list_elements: [],
    biological_assets_loading: false,
    biological_assets_list_elements_select: [],
    cost_items_list_elements: [],
    cost_items_list_groups: [],
    cost_items_loading: false,
    cost_items_list_elements_select: [],
    release_reason_list_elements: [],
    release_reason_loading: false,
    release_reason_list_elements_select: [],
    fixed_assets_list_elements: [],
    fixed_assets_loading: false,
    fixed_assets_list_elements_select: [],
    intangible_assets_list_elements: [],
    intangible_assets_loading: false,
    intangible_assets_list_elements_select: [],
    work_schedule_list_elements: [],
    work_schedule_loading: false,
    work_schedule_list_elements_select: [],
    accrual_list_elements: [],
    accrual_loading: false,
    accrual_list_elements_select: [],
    timesheet_letter_list_elements: [],
    timesheet_letter_loading: false,
    timesheet_letter_list_elements_select: [],
    income_type_pix_list_elements: [],
    income_type_pix_loading: false,
    income_type_pix_list_elements_select: [],
    income_items_list_elements: [],
    income_items_loading: false,
    income_items_list_elements_select: [],
    nomenclature_groups_list_elements: [],
    nomenclature_groups_loading: false,
    nomenclature_groups_list_elements_select: [],
    taxes_list_elements: [],
    taxes_loading: false,
    taxes_list_elements_select: [],
    min_amount_list_elements: [],
    min_amount_loading: false,
    inflation_index_list_elements: [],
    inflation_index_loading: false,
    tax_tariff_list_elements: [],
    tax_tariff_loading: false,
    contractor_contact_person_list_elements: [],
    contractor_contact_person_list_elements_select: [],
    contractor_contact_person_loading: false,
    contractor_response_person_list_elements: [],
    contractor_response_person_list_elements_select: [],
    contractor_response_person_loading: false,
    contractor_bank_account_list_elements: [],
    contractor_bank_account_list_elements_select: [],
    contractor_bank_account_loading: false,

    reload_nomenclature: false
}

const getters = {
    get_warehouse_list: state => state.warehouse_list_elements,
    get_warehouse_loaded: state => state.warehouse_loading,
    get_warehouse_list_select: state => state.warehouse_list_elements_select,
    get_currency_list: state => state.currency_list_elements,
    get_currency_loaded: state => state.currency_loading,
    get_house_accounting_payments_settings_list: state => state.house_accounting_payments_settings_list_elements,
    get_house_accounting_payments_settings_loaded: state => state.house_accounting_payments_settings_loading,
    get_house_accounting_charge_settings_list: state => state.house_accounting_charge_settings_list_elements,
    get_house_accounting_charge_settings_loaded: state => state.house_accounting_charge_settings_loading,
    get_currency_list_select: state => state.currency_list_elements_select,
    get_amortisation_calc_way_list: state => state.amortisation_calc_way_list_elements,
    get_amortisation_calc_way_loaded: state => state.amortisation_calc_way_loading,
    get_amortisation_calc_way_list_select: state => state.amortisation_calc_way_list_elements_select,
    get_contractor_list: state => state.contractor_list_elements,
    get_contractor_loaded: state => state.contractor_loading,
    get_contractor_list_select: state => state.contractor_list_elements_select,
    get_contractor_contract_list: state => state.contractor_contract_list_elements,
    get_contractor_contract_list_select: state => state.contractor_contract_list_elements_select,
    get_position_list: state => state.position_list_elements,
    get_position_loaded: state => state.position_loading,
    get_position_list_select: state => state.position_list_elements_select,
    get_tax_inspection_list: state => state.tax_inspection_list_elements,
    get_tax_inspection_loaded: state => state.tax_inspection_loading,
    get_tax_inspection_list_select: state => state.tax_inspection_list_elements_select,
    get_subdivision_list: state => state.subdivision_list_elements,
    get_subdivision_loaded: state => state.subdivision_loading,
    get_subdivision_list_select: state => state.subdivision_list_elements_select,
    get_unit_list: state => state.unit_list_elements,
    get_unit_loaded: state => state.unit_loading,
    get_unit_list_select: state => state.unit_list_elements_select,
    get_natural_person_list: state => state.natural_person_list_elements,
    get_natural_person_loaded: state => state.natural_person_loading,
    get_natural_person_list_select: state => state.natural_person_list_elements_select,
    get_personal_list: state => state.personal_list_elements,
    get_personal_loaded: state => state.personal_loading,
    get_personal_list_select: state => state.personal_list_elements_select,

    get_nomenclature_groups: state => state.nomenclature_list_groups,
    get_nomenclature_list: state => state.nomenclature_list_elements,
    get_nomenclature_loaded: state => state.nomenclature_loading,
    get_nomenclature_list_select: state => state.nomenclature_list_elements_select,

    get_dispatcher_problem_groups: state => state.dispatcher_problem_list_groups,
    get_dispatcher_problem_list: state => state.dispatcher_problem_list_elements,
    get_dispatcher_problem_loaded: state => state.dispatcher_problem_loading,
    get_dispatcher_problem_list_select: state => state.dispatcher_problem_list_elements_select,

    get_chart_of_accounts_list: state => state.charts_of_account_list_elements,
    get_chart_of_accounts_loaded: state => state.charts_of_account_loading,
    get_chart_of_accounts_list_select: state => state.charts_of_account_list_elements_select,
    get_cash_flow_item_list: state => state.cash_flow_item_list_elements,
    get_cash_flow_item_loaded: state => state.cash_flow_item_loading,
    get_cash_flow_item_list_select: state => state.cash_flow_item_list_elements_select,
    get_bank_account_list: state => state.bank_account_list_elements,
    get_bank_account_loaded: state => state.bank_account_loading,
    get_bank_account_list_select: state => state.bank_account_list_elements_select,
    get_biological_assets_list: state => state.biological_assets_list_elements,
    get_biological_assets_loaded: state => state.biological_assets_loading,
    get_biological_assets_list_select: state => state.biological_assets_list_elements_select,

    get_cost_items_groups: state => state.cost_items_list_groups,
    get_cost_items_list: state => state.cost_items_list_elements,
    get_cost_items_loaded: state => state.cost_items_loading,
    get_cost_items_list_select: state => state.cost_items_list_elements_select,
    get_release_reason_list: state => state.release_reason_list_elements,
    get_release_reason_loaded: state => state.release_reason_loading,
    get_release_reason_list_select: state => state.release_reason_list_elements_select,
    get_fixed_assets_list: state => state.fixed_assets_list_elements,
    get_fixed_assets_loaded: state => state.fixed_assets_loading,
    get_fixed_assets_list_select: state => state.fixed_assets_list_elements_select,
    get_intangible_assets_list: state => state.intangible_assets_list_elements,
    get_intangible_assets_loaded: state => state.intangible_assets_loading,
    get_intangible_assets_list_select: state => state.intangible_assets_list_elements_select,
    get_work_schedule_list: state => state.work_schedule_list_elements,
    get_work_schedule_loaded: state => state.work_schedule_loading,
    get_work_schedule_list_select: state => state.work_schedule_list_elements_select,
    get_accrual_list: state => state.accrual_list_elements,
    get_accrual_loaded: state => state.accrual_loading,
    get_accrual_list_select: state => state.accrual_list_elements_select,
    get_timesheet_letter_list: state => state.timesheet_letter_list_elements,
    get_timesheet_letter_loaded: state => state.timesheet_letter_loading,
    get_timesheet_letter_list_select: state => state.timesheet_letter_list_elements_select,
    get_income_type_pix_list: state => state.income_type_pix_list_elements,
    get_income_type_pix_loaded: state => state.income_type_pix_loading,
    get_income_type_pix_list_select: state => state.income_type_pix_list_elements_select,
    get_income_items_list: state => state.income_items_list_elements,
    get_income_items_loaded: state => state.income_items_loading,
    get_income_items_list_select: state => state.income_items_list_elements_select,
    get_nomenclature_groups_list: state => state.nomenclature_groups_list_elements,
    get_nomenclature_groups_loaded: state => state.nomenclature_groups_loading,
    get_nomenclature_groups_list_select: state => state.nomenclature_groups_list_elements_select,
    get_taxes_list: state => state.taxes_list_elements,
    get_taxes_loaded: state => state.taxes_loading,
    get_taxes_list_select: state => state.taxes_list_elements_select,
    get_taxes_list_pdv_select: state => state.taxes_list_elements_select.filter(item => item.type.indexOf('pdv') !== -1),
    get_min_amount_list: state => state.min_amount_list_elements,
    get_min_amount_loaded: state => state.min_amount_loading,
    get_inflation_index_list: state => state.inflation_index_list_elements,
    get_inflation_index_loaded: state => state.inflation_index_loading,
    get_tax_tariff_list: state => state.tax_tariff_list_elements,
    get_tax_tariff_loaded: state => state.tax_tariff_loading,
    get_contractor_contact_person_list: state => state.contractor_contact_person_list_elements,
    get_contractor_contact_person_list_select: state => state.contractor_contact_person_list_elements_select,
    get_contractor_contact_person_loaded: state => state.contractor_contact_person_loading,
    get_contractor_response_person_list: state => state.contractor_response_person_list_elements,
    get_contractor_response_person_loaded: state => state.contractor_response_person_loading,
    get_contractor_bank_account_list: state => state.contractor_bank_account_list_elements,
    get_contractor_bank_account_list_select: state => state.contractor_bank_account_list_elements_select,
    get_contractor_bank_account_loaded: state => state.contractor_bank_account_loading,


    get_reload_nomenclature: state => state.reload_nomenclature
}

const actions = {
    [CLEAR_ALL_ACCOUNTING_LOADING]: ({commit}) => {
        commit(CLEAR_ALL_ACCOUNTING_LOADING)
    },
    [GET_WAREHOUSE_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_warehouse_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_WAREHOUSE_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [GET_CURRENCY_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_currency_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_CURRENCY_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [GET_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_house_accounting_payments_settings_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [GET_HOUSE_ACCOUNTING_CHARGE_SETTINGS_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_house_accounting_charge_settings_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_HOUSE_ACCOUNTING_CHARGE_SETTINGS_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [GET_AMORTISATION_CALC_WAY_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_amortisation_calc_way_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_AMORTISATION_CALC_WAY_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [GET_CONTRACTOR_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_contractor_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_CONTRACTOR_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [GET_CONTRACTOR_CONTRACT_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_contractor_contract_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_CONTRACTOR_CONTRACT_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [GET_POSITION_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_position_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_POSITION_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [GET_TAX_INSPECTION_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_tax_inspection_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_TAX_INSPECTION_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [GET_SUBDIVISION_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_subdivision_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_SUBDIVISION_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [GET_UNIT_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_unit_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_UNIT_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [GET_NATURAL_PERSON_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_natural_person_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_NATURAL_PERSON_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [GET_PERSONAL_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_personal_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_PERSONAL_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [GET_NOMENCLATURE_LIST_GROUP]: ({commit}, payload = '') => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_nomenclature_groups()
                .then(response => response.data)
                .then(data => {
                    const new_data = [
                        {
                            id: 0,
                            name: payload || 'Усі',
                            parent_id: null,
                            children: data,
                        }
                    ]
                    commit(GET_NOMENCLATURE_LIST_GROUP, new_data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [GET_NOMENCLATURE_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_nomenclature_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_NOMENCLATURE_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [GET_CHART_OF_ACCOUNTS_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            chartOfAccountAPI.get_all(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_CHART_OF_ACCOUNTS_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [GET_BIOLOGICAL_ASSETS_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_biological_assets_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_BIOLOGICAL_ASSETS_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [GET_COST_ITEMS_LIST_GROUP]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_cost_items_groups(payload)
                .then(response => response.data)
                .then(data => {
                    const new_data = [
                        {
                            id: 0,
                            name: payload || 'Усі',
                            parent_id: null,
                            children: data,
                        }
                    ]
                    commit(GET_COST_ITEMS_LIST_GROUP, new_data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [GET_COST_ITEMS_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_cost_items_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_COST_ITEMS_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [GET_RELEASE_REASON_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_release_reason_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_RELEASE_REASON_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [GET_FIXED_ASSETS_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_fixed_assets_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_FIXED_ASSETS_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [GET_INTANGIBLE_ASSETS_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_intangible_assets_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_INTANGIBLE_ASSETS_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [GET_WORK_SCHEDULE_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_work_schedule_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_WORK_SCHEDULE_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [GET_ACCRUAL_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_accrual_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_ACCRUAL_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [GET_TIMESHEET_LETTER_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_timesheet_letter_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_TIMESHEET_LETTER_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [GET_INCOME_TYPE_PIX_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_income_type_pix_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_INCOME_TYPE_PIX_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [GET_INCOME_ITEMS_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_income_items_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_INCOME_ITEMS_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [GET_NOMENCLATURE_GROUPS_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_nomenclature_groups_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_NOMENCLATURE_GROUPS_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [GET_TAXES_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_taxes_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_TAXES_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [GET_MIN_AMOUNT_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_min_amount_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_MIN_AMOUNT_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [GET_INFLATION_INDEX_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_inflation_index_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_INFLATION_INDEX_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [GET_TAX_TARIFF_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_tax_tariff_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_TAX_TARIFF_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },

    [GET_BANK_ACCOUNT_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_bank_account_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_BANK_ACCOUNT_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [CREATE_BANK_ACCOUNT_LIST_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingBankAccount.create_bank_account(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_BANK_ACCOUNT_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_bank_account_list', id: data.id})
                    dispatch(SELECT_BANK_ACCOUNT_LIST_ELEMENTS)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))

        })
    },
    [UPDATE_BANK_ACCOUNT_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingBankAccount.update_bank_account(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_BANK_ACCOUNT_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_bank_account_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_BANK_ACCOUNT_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingBankAccount.remove_bank_account(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_BANK_ACCOUNT_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_bank_account_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_BANK_ACCOUNT_LIST_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingBankAccount.select_bank_accounts()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_BANK_ACCOUNT_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },

    [GET_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_contractor_contact_person_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [CREATE_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingContractorContactPerson.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_contractor_contact_person_list', id: data.id})
                    dispatch(SELECT_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingContractorContactPerson.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_CONTRACTOR_CONTRACT_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_contractor_contact_person_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingContractorContactPerson.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_contractor_contact_person_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingContractorContactPerson.select()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },

    [GET_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_contractor_response_person_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [CREATE_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingContractorResponsePerson.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_contractor_response_person_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingContractorResponsePerson.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_contractor_response_person_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingContractorResponsePerson.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_contractor_response_person_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },

    [GET_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_contractor_bank_account_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [CREATE_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingContractorBankAccount.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_contractor_bank_account_list', id: data.id})
                    dispatch(SELECT_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingContractorBankAccount.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_contractor_bank_account_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingContractorBankAccount.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_contractor_bank_account_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingContractorBankAccount.select()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },

    [GET_CASH_FLOW_ITEM_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_cash_flow_item_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_CASH_FLOW_ITEM_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [CREATE_CASH_FLOW_ITEM_LIST_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingCashFlowItem.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_CASH_FLOW_ITEM_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_cash_flow_item_list', id: data.id})
                    dispatch(SELECT_CASH_FLOW_ITEM_LIST_ELEMENTS)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_CASH_FLOW_ITEM_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingCashFlowItem.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_CASH_FLOW_ITEM_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_cash_flow_item_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_CASH_FLOW_ITEM_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingCashFlowItem.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_CASH_FLOW_ITEM_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_cash_flow_item_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_CASH_FLOW_ITEM_LIST_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingCashFlowItem.select()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_CASH_FLOW_ITEM_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },

    [CREATE_WAREHOUSE_LIST_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingWarehouse.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_WAREHOUSE_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_warehouse_list', id: data.id})
                    dispatch(SELECT_WAREHOUSE_LIST_ELEMENTS)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_WAREHOUSE_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingWarehouse.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_WAREHOUSE_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_warehouse_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_WAREHOUSE_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingWarehouse.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_WAREHOUSE_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_warehouse_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_WAREHOUSE_LIST_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingWarehouse.select()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_WAREHOUSE_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },

    [CREATE_CHART_OF_ACCOUNTS_LIST_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            chartOfAccountAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_CHART_OF_ACCOUNTS_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_chart_of_accounts_list', id: data.id})
                    dispatch(SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_CHART_OF_ACCOUNTS_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            chartOfAccountAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_CHART_OF_ACCOUNTS_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_chart_of_accounts_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_CHART_OF_ACCOUNTS_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            chartOfAccountAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_CHART_OF_ACCOUNTS_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_chart_of_accounts_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            chartOfAccountAPI.select(payload)
                .then(response => response.data)
                .then(data => {
                    commit("SET_ACCOUNTING_DATA_LOADING", {state: 'charts_of_account_loading', value: true})
                    commit(SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },

    [CREATE_CURRENCY_LIST_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingCurrency.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_CURRENCY_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_currency_list', id: data.id})
                    dispatch(SELECT_CURRENCY_LIST_ELEMENTS)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_CURRENCY_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingCurrency.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_CURRENCY_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_currency_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_CURRENCY_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingCurrency.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_CURRENCY_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_currency_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_CURRENCY_LIST_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingCurrency.select()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_CURRENCY_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },

    [CREATE_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingHouseAccountingPaymentsSettings.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_house_accounting_payments_settings_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingHouseAccountingPaymentsSettings.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_house_accounting_payments_settings_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingHouseAccountingPaymentsSettings.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_house_accounting_payments_settings_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },

    [CREATE_HOUSE_ACCOUNTING_CHARGE_SETTINGS_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingHouseAccountingChargeSettings.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_HOUSE_ACCOUNTING_CHARGE_SETTINGS_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_house_accounting_charge_settings_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_HOUSE_ACCOUNTING_CHARGE_SETTINGS_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingHouseAccountingChargeSettings.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_HOUSE_ACCOUNTING_CHARGE_SETTINGS_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_house_accounting_charge_settings_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_HOUSE_ACCOUNTING_CHARGE_SETTINGS_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingHouseAccountingChargeSettings.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_HOUSE_ACCOUNTING_CHARGE_SETTINGS_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_house_accounting_charge_settings_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },

    [CREATE_AMORTISATION_CALC_WAY_LIST_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingAmortisationCalcWay.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_AMORTISATION_CALC_WAY_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_amortisation_calc_way_list', id: data.id})
                    dispatch(SELECT_AMORTISATION_CALC_WAY_LIST_ELEMENTS)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_AMORTISATION_CALC_WAY_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingAmortisationCalcWay.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_AMORTISATION_CALC_WAY_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_amortisation_calc_way_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_AMORTISATION_CALC_WAY_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingAmortisationCalcWay.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_AMORTISATION_CALC_WAY_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_amortisation_calc_way_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_AMORTISATION_CALC_WAY_LIST_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingAmortisationCalcWay.select()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_AMORTISATION_CALC_WAY_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },

    [CREATE_CONTRACTOR_LIST_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingContractor.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_CONTRACTOR_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_contractor_list', id: data.id})
                    dispatch(SELECT_CONTRACTOR_LIST_ELEMENTS)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_CONTRACTOR_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingContractor.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_CONTRACTOR_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_contractor_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_CONTRACTOR_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingContractor.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_CONTRACTOR_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_contractor_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_CONTRACTOR_LIST_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingContractor.select()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_CONTRACTOR_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },

    [CREATE_CONTRACTOR_CONTRACT_LIST_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingContractorContract.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_CONTRACTOR_CONTRACT_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_contractor_contract_list', id: data.id})
                    dispatch(SELECT_CONTRACTOR_CONTRACT_LIST_ELEMENTS, payload)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_CONTRACTOR_CONTRACT_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingContractorContract.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_CONTRACTOR_CONTRACT_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_contractor_contract_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_CONTRACTOR_CONTRACT_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingContractorContract.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_CONTRACTOR_CONTRACT_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_contractor_contract_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_CONTRACTOR_CONTRACT_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingContractorContract.select(payload)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_CONTRACTOR_CONTRACT_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },

    [CREATE_POSITION_LIST_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingPosition.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_POSITION_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_position_list', id: data.id})
                    dispatch(SELECT_POSITION_LIST_ELEMENTS)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_POSITION_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingPosition.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_POSITION_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_position_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_POSITION_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingPosition.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_POSITION_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_position_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_POSITION_LIST_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingPosition.select()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_POSITION_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },

    [CREATE_TAX_INSPECTION_LIST_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingTaxInspection.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_TAX_INSPECTION_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_tax_inspection_list', id: data.id})
                    dispatch(SELECT_TAX_INSPECTION_LIST_ELEMENTS)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_TAX_INSPECTION_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingTaxInspection.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_TAX_INSPECTION_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_tax_inspection_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_TAX_INSPECTION_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingTaxInspection.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_TAX_INSPECTION_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_tax_inspection_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_TAX_INSPECTION_LIST_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingTaxInspection.select()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_TAX_INSPECTION_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },

    [CREATE_SUBDIVISION_LIST_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingSubdivision.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_SUBDIVISION_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_subdivision_list', id: data.id})
                    dispatch(SELECT_SUBDIVISION_LIST_ELEMENTS)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_SUBDIVISION_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingSubdivision.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_SUBDIVISION_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_subdivision_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_SUBDIVISION_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingSubdivision.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_SUBDIVISION_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_subdivision_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_SUBDIVISION_LIST_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingSubdivision.select()
                .then(response => response.data)
                .then(data => {
                    commit("SET_ACCOUNTING_DATA_LOADING", {state: 'subdivision_loading', value: true})
                    commit(SELECT_SUBDIVISION_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },

    [CREATE_BIOLOGICAL_ASSETS_LIST_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingBiologicalAssets.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_BIOLOGICAL_ASSETS_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_biological_assets_list', id: data.id})
                    dispatch(SELECT_BIOLOGICAL_ASSETS_LIST_ELEMENTS)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_BIOLOGICAL_ASSETS_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingBiologicalAssets.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_BIOLOGICAL_ASSETS_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_biological_assets_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_BIOLOGICAL_ASSETS_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingBiologicalAssets.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_BIOLOGICAL_ASSETS_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_biological_assets_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_BIOLOGICAL_ASSETS_LIST_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingBiologicalAssets.select()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_BIOLOGICAL_ASSETS_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },

    [CREATE_COST_ITEMS_LIST_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingCostItems.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_COST_ITEMS_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_cost_items_list', id: data.id})
                    dispatch(SELECT_COST_ITEMS_LIST_ELEMENTS)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_COST_ITEMS_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingCostItems.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_COST_ITEMS_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_cost_items_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_COST_ITEMS_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingCostItems.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_COST_ITEMS_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_cost_items_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_COST_ITEMS_LIST_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingCostItems.select()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_COST_ITEMS_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },

    [CREATE_RELEASE_REASON_LIST_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingReleaseReason.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_RELEASE_REASON_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_release_reason_list', id: data.id})
                    dispatch(SELECT_RELEASE_REASON_LIST_ELEMENTS)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_RELEASE_REASON_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingReleaseReason.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_RELEASE_REASON_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_release_reason_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_RELEASE_REASON_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingReleaseReason.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_RELEASE_REASON_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_release_reason_list', id: data.id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_RELEASE_REASON_LIST_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingReleaseReason.select()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_RELEASE_REASON_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },

    [CREATE_FIXED_ASSETS_LIST_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingFixedAssets.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_FIXED_ASSETS_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_fixed_assets_list'})
                    dispatch(SELECT_FIXED_ASSETS_LIST_ELEMENTS)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_FIXED_ASSETS_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingFixedAssets.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_FIXED_ASSETS_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_fixed_assets_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_FIXED_ASSETS_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingFixedAssets.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_FIXED_ASSETS_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_fixed_assets_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_FIXED_ASSETS_LIST_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingFixedAssets.select()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_FIXED_ASSETS_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },

    [CREATE_INTANGIBLE_ASSETS_LIST_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingIntangibleAssets.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_INTANGIBLE_ASSETS_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_intangible_assets_list'})
                    dispatch(SELECT_INTANGIBLE_ASSETS_LIST_ELEMENTS)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_INTANGIBLE_ASSETS_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingIntangibleAssets.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_INTANGIBLE_ASSETS_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_intangible_assets_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_INTANGIBLE_ASSETS_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingIntangibleAssets.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_INTANGIBLE_ASSETS_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_intangible_assets_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_INTANGIBLE_ASSETS_LIST_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingIntangibleAssets.select()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_INTANGIBLE_ASSETS_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },

    [CREATE_WORK_SCHEDULE_LIST_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingWorkSchedule.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_WORK_SCHEDULE_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_work_schedule_list'})
                    dispatch(SELECT_WORK_SCHEDULE_LIST_ELEMENTS)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_WORK_SCHEDULE_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingWorkSchedule.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_WORK_SCHEDULE_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_work_schedule_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_WORK_SCHEDULE_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingWorkSchedule.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_WORK_SCHEDULE_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_work_schedule_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_WORK_SCHEDULE_LIST_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingWorkSchedule.select()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_WORK_SCHEDULE_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },

    [CREATE_ACCRUAL_LIST_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingAccrual.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_ACCRUAL_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_accrual_list'})
                    dispatch(SELECT_ACCRUAL_LIST_ELEMENTS)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_ACCRUAL_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingAccrual.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_ACCRUAL_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_accrual_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_ACCRUAL_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingAccrual.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_ACCRUAL_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_accrual_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_ACCRUAL_LIST_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingAccrual.select()
                .then(response => response.data)
                .then(data => {
                    commit("SET_ACCOUNTING_DATA_LOADING", {state: 'accrual_loading', value: true})
                    commit(SELECT_ACCRUAL_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },

    [CREATE_TIMESHEET_LETTER_LIST_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingTimesheetLetter.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_TIMESHEET_LETTER_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_timesheet_letter_list'})
                    dispatch(SELECT_TIMESHEET_LETTER_LIST_ELEMENTS)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_TIMESHEET_LETTER_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingTimesheetLetter.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_TIMESHEET_LETTER_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_timesheet_letter_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_TIMESHEET_LETTER_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingTimesheetLetter.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_TIMESHEET_LETTER_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_timesheet_letter_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_TIMESHEET_LETTER_LIST_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingTimesheetLetter.select()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_TIMESHEET_LETTER_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },

    [CREATE_INCOME_TYPE_PIX_LIST_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingIncomeTypePix.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_INCOME_TYPE_PIX_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_income_type_pix_list'})
                    dispatch(SELECT_INCOME_TYPE_PIX_LIST_ELEMENTS)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_INCOME_TYPE_PIX_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingIncomeTypePix.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_INCOME_TYPE_PIX_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_income_type_pix_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_INCOME_TYPE_PIX_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingIncomeTypePix.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_INCOME_TYPE_PIX_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_income_type_pix_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_INCOME_TYPE_PIX_LIST_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingIncomeTypePix.select()
                .then(response => response.data)
                .then(data => {
                    commit("SET_ACCOUNTING_DATA_LOADING", {state: 'income_type_pix_loading', value: true})
                    commit(SELECT_INCOME_TYPE_PIX_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },

    [CREATE_INCOME_ITEMS_LIST_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingIncomeItems.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_INCOME_ITEMS_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_income_items_list'})
                    dispatch(SELECT_INCOME_ITEMS_LIST_ELEMENTS)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_INCOME_ITEMS_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingIncomeItems.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_INCOME_ITEMS_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_income_items_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_INCOME_ITEMS_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingIncomeItems.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_INCOME_ITEMS_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_income_items_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_INCOME_ITEMS_LIST_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingIncomeItems.select()
                .then(response => response.data)
                .then(data => {
                    commit("SET_ACCOUNTING_DATA_LOADING", {state: 'income_items_loading', value: true})
                    commit(SELECT_INCOME_ITEMS_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },

    [CREATE_NOMENCLATURE_GROUPS_LIST_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingNomenclatureGroups.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_NOMENCLATURE_GROUPS_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_nomenclature_groups_list', id: data.id})
                    dispatch(SELECT_NOMENCLATURE_GROUPS_LIST_ELEMENTS)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_NOMENCLATURE_GROUPS_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingNomenclatureGroups.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_NOMENCLATURE_GROUPS_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_nomenclature_groups_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_NOMENCLATURE_GROUPS_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingNomenclatureGroups.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_NOMENCLATURE_GROUPS_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_nomenclature_groups_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_NOMENCLATURE_GROUPS_LIST_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingNomenclatureGroups.select()
                .then(response => response.data)
                .then(data => {
                    commit("SET_ACCOUNTING_DATA_LOADING", {state: 'nomeclature_groups_loading', value: true})
                    commit(SELECT_NOMENCLATURE_GROUPS_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },

    [CREATE_TAXES_LIST_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingTaxes.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_TAXES_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_taxes_list'})
                    dispatch(SELECT_TAXES_LIST_ELEMENTS)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_TAXES_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingTaxes.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_TAXES_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_taxes_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_TAXES_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingTaxes.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_TAXES_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_taxes_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_TAXES_LIST_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingTaxes.select()
                .then(response => response.data)
                .then(data => {
                    commit("SET_ACCOUNTING_DATA_LOADING", {state: 'taxes_loading', value: true})
                    commit(SELECT_TAXES_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },

    [CREATE_MIN_AMOUNT_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingMinAmount.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_MIN_AMOUNT_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_min_amount_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_MIN_AMOUNT_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingMinAmount.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_MIN_AMOUNT_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_min_amount_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_MIN_AMOUNT_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingMinAmount.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_MIN_AMOUNT_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_min_amount_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },

    [CREATE_INFLATION_INDEX_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingInflationIndex.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_INFLATION_INDEX_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_inflation_index_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_INFLATION_INDEX_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingInflationIndex.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_INFLATION_INDEX_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_inflation_index_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_INFLATION_INDEX_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingInflationIndex.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_INFLATION_INDEX_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_inflation_index_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },

    [CREATE_TAX_TARIFF_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingTaxTariff.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_TAX_TARIFF_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_tax_tariff_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_TAX_TARIFF_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingTaxTariff.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_TAX_TARIFF_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_tax_tariff_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_INFLATION_INDEX_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingTaxTariff.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_INFLATION_INDEX_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_tax_tariff_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },

    [CREATE_UNIT_LIST_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingUnit.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_UNIT_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_unit_list'})
                    dispatch(SELECT_UNIT_LIST_ELEMENTS)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_UNIT_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingUnit.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_UNIT_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_unit_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_UNIT_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingUnit.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_UNIT_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_unit_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_UNIT_LIST_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingUnit.select()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_UNIT_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },

    [CREATE_PERSONAL_LIST_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingPersonal.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_PERSONAL_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_personal_list'})
                    dispatch(SELECT_PERSONAL_LIST_ELEMENTS)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_PERSONAL_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingPersonal.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_PERSONAL_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_personal_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_PERSONAL_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingPersonal.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_PERSONAL_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_personal_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_PERSONAL_LIST_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingPersonal.select()
                .then(response => response.data)
                .then(data => {
                    commit("SET_ACCOUNTING_DATA_LOADING", {state: 'personal_loading', value: true})
                    commit(SELECT_PERSONAL_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },

    [CREATE_NATURAL_PERSON_LIST_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingNaturalPerson.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_NATURAL_PERSON_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_natural_person_list'})
                    dispatch(SELECT_NATURAL_PERSON_LIST_ELEMENTS)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_NATURAL_PERSON_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingNaturalPerson.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_NATURAL_PERSON_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_natural_person_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_NATURAL_PERSON_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingNaturalPerson.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_NATURAL_PERSON_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_natural_person_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_NATURAL_PERSON_LIST_ELEMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            accountingNaturalPerson.select()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_NATURAL_PERSON_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },

    [CREATE_NOMENCLATURE_LIST_GROUP]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingNomenclature.create(payload)
                .then(response => response.data)
                .then(() => {
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_NOMENCLATURE_LIST_GROUP]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingNomenclature.update(payload)
                .then(response => response.data)
                .then(() => {
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_NOMENCLATURE_LIST_GROUP]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingNomenclature.remove(payload)
                .then(response => response.data)
                .then(() => {
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },

    [CREATE_COST_ITEMS_LIST_GROUP]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingCostItems.create(payload)
                .then(response => response.data)
                .then(() => {
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_COST_ITEMS_LIST_GROUP]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingCostItems.update(payload)
                .then(response => response.data)
                .then(() => {
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_COST_ITEMS_LIST_GROUP]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingCostItems.remove(payload)
                .then(response => response.data)
                .then(() => {
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },

    [CREATE_NOMENCLATURE_LIST_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingNomenclature.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_NOMENCLATURE_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_nomenclature_list', id: data.id, all: true})
                    dispatch(SELECT_NOMENCLATURE_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_NOMENCLATURE_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingNomenclature.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_NOMENCLATURE_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_nomenclature_list', id: data.id, all: true})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_NOMENCLATURE_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingNomenclature.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_NOMENCLATURE_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_nomenclature_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_NOMENCLATURE_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingNomenclature.select_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_NOMENCLATURE_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },

    [GET_DISPATCHER_PROBLEM_LIST_GROUP]: ({commit}, payload = '') => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_dispatcher_problem_groups()
                .then(response => response.data)
                .then(data => {
                    const new_data = [
                        {
                            id: 0,
                            name: payload || 'Усі',
                            parent_id: null,
                            children: data,
                        }
                    ]
                    commit(GET_DISPATCHER_PROBLEM_LIST_GROUP, new_data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [CREATE_DISPATCHER_PROBLEM_LIST_GROUP]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDispatcherProblem.create(payload)
                .then(response => response.data)
                .then((data) => {
                    dispatch(SELECT_DISPATCHER_PROBLEM_LIST_ELEMENTS, {is_group: true})
                    commit(RELOAD_LIST, {getter: 'get_dispatcher_problem_list', id: data.id, all: true})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DISPATCHER_PROBLEM_LIST_GROUP]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDispatcherProblem.update(payload)
                .then(response => response.data)
                .then((data) => {
                    dispatch(SELECT_DISPATCHER_PROBLEM_LIST_ELEMENTS, {is_group: true})
                    commit(RELOAD_LIST, {getter: 'get_dispatcher_problem_list', id: data.id, all: true})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DISPATCHER_PROBLEM_LIST_GROUP]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDispatcherProblem.create(payload)
                .then(response => response.data)
                .then(() => {
                    dispatch(SELECT_DISPATCHER_PROBLEM_LIST_ELEMENTS, {is_group: true})
                    commit(RELOAD_LIST, {getter: 'get_dispatcher_problem_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },

    [GET_DISPATCHER_PROBLEM_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingListAPI.fetch_dispatcher_problem_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_DISPATCHER_PROBLEM_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                        const error = err.response.data.detail;
                        commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        reject(false)
                    }
                )
        })
    },
    [CREATE_DISPATCHER_PROBLEM_LIST_ELEMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDispatcherProblem.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DISPATCHER_PROBLEM_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_dispatcher_problem_list', id: data.id, all: true})
                    dispatch(SELECT_DISPATCHER_PROBLEM_LIST_ELEMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DISPATCHER_PROBLEM_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDispatcherProblem.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DISPATCHER_PROBLEM_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_dispatcher_problem_list', id: data.id, all: true})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DISPATCHER_PROBLEM_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            accountingDispatcherProblem.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_DISPATCHER_PROBLEM_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_dispatcher_problem_list'})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_DISPATCHER_PROBLEM_LIST_ELEMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            accountingDispatcherProblem.select_elements(payload)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_DISPATCHER_PROBLEM_LIST_ELEMENTS, data)
                    commit(RELOAD_LIST, {getter: 'get_dispatcher_problem_list', select: true})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
}

const mutations = {
    ["SET_ACCOUNTING_DATA_LOADING"]: (state, payload) => {
        state[payload.state] = payload.value
    },
    [CLEAR_ALL_ACCOUNTING_LOADING]: (state) => {
        state.warehouse_list_elements = false
        state.warehouse_loading = false
        state.house_accounting_payments_settings_loading = false
        state.house_accounting_charge_settings_loading = false
        state.currency_loading = false
        state.contractor_loading = false
        state.amortisation_calc_way_loading = false
        state.contractor_loading = false
        state.contractor_contract_loading = false
        state.position_loading = false
        state.tax_inspection_loading = false
        state.subdivision_loading = false
        state.unit_loading = false
        state.natural_person_loading = false
        state.personal_loading = false
        state.nomenclature_loading = false
        state.charts_of_account_loading = false
        state.cash_flow_item_loading = false
        state.bank_account_loading = false
        state.biological_assets_loading = false
        state.cost_items_loading = false
        state.fixed_assets_loading = false
        state.intangible_assets_loading = false
        state.work_schedule_loading = false
        state.accrual_loading = false
        state.timesheet_letter_loading = false
        state.income_type_pix_loading = false
        state.income_items_loading = false
        state.nomenclature_groups_loading = false
        state.taxes_loading = false
        state.min_amount_loading = false
        state.tax_tariff_loading = false
        state.contractor_contact_person_loading = false
        state.contractor_response_person_loading = false
        state.contractor_bank_account_loading = false
    },
    [GET_WAREHOUSE_LIST_ELEMENTS]: (state, payload) => {
        state.warehouse_list_elements = payload
    },
    [GET_CURRENCY_LIST_ELEMENTS]: (state, payload) => {
        state.currency_list_elements = payload
    },
    [GET_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS]: (state, payload) => {
        state.house_accounting_payments_settings_list_elements = payload
    },
    [GET_HOUSE_ACCOUNTING_CHARGE_SETTINGS_LIST_ELEMENTS]: (state, payload) => {
        state.house_accounting_charge_settings_list_elements = payload
    },
    [GET_AMORTISATION_CALC_WAY_LIST_ELEMENTS]: (state, payload) => {
        state.amortisation_calc_way_list_elements = payload
    },
    [GET_CONTRACTOR_LIST_ELEMENTS]: (state, payload) => {
        state.contractor_list_elements = payload
    },
    [GET_CONTRACTOR_CONTRACT_LIST_ELEMENTS]: (state, payload) => {
        state.contractor_contract_list_elements = payload
    },
    [GET_POSITION_LIST_ELEMENTS]: (state, payload) => {
        state.position_list_elements = payload
    },
    [GET_TAX_INSPECTION_LIST_ELEMENTS]: (state, payload) => {
        state.tax_inspection_list_elements = payload
    },
    [GET_SUBDIVISION_LIST_ELEMENTS]: (state, payload) => {
        state.subdivision_list_elements = payload
    },
    [GET_UNIT_LIST_ELEMENTS]: (state, payload) => {
        state.unit_list_elements = payload
    },
    [GET_NATURAL_PERSON_LIST_ELEMENTS]: (state, payload) => {
        state.natural_person_list_elements = payload
    },
    [GET_PERSONAL_LIST_ELEMENTS]: (state, payload) => {
        state.personal_list_elements = payload
    },
    [GET_NOMENCLATURE_LIST_GROUP]: (state, payload) => {
        state.nomenclature_list_groups = payload
    },
    [GET_NOMENCLATURE_LIST_ELEMENTS]: (state, payload) => {
        state.nomenclature_list_elements = payload
    },
    [GET_CHART_OF_ACCOUNTS_LIST_ELEMENTS]: (state, payload) => {
        state.charts_of_account_list_elements = payload
    },
    [GET_FIXED_ASSETS_LIST_ELEMENTS]: (state, payload) => {
        state.fixed_assets_list_elements = payload
    },
    [GET_COST_ITEMS_LIST_GROUP]: (state, payload) => {
        state.cost_items_list_groups = payload
    },
    [GET_COST_ITEMS_LIST_ELEMENTS]: (state, payload) => {
        state.cost_items_list_elements = payload
    },
    [GET_RELEASE_REASON_LIST_ELEMENTS]: (state, payload) => {
        state.release_reason_list_elements = payload
    },
    [GET_BIOLOGICAL_ASSETS_LIST_ELEMENTS]: (state, payload) => {
        state.biological_assets_list_elements = payload
    },
    [GET_INTANGIBLE_ASSETS_LIST_ELEMENTS]: (state, payload) => {
        state.intangible_assets_list_elements = payload
    },
    [GET_WORK_SCHEDULE_LIST_ELEMENTS]: (state, payload) => {
        state.work_schedule_list_elements = payload
    },
    [GET_ACCRUAL_LIST_ELEMENTS]: (state, payload) => {
        state.accrual_list_elements = payload
    },
    [GET_TIMESHEET_LETTER_ELEMENTS]: (state, payload) => {
        state.timesheet_letter_list_elements = payload
    },
    [GET_INCOME_TYPE_PIX_ELEMENTS]: (state, payload) => {
        state.income_type_pix_list_elements = payload
    },
    [GET_INCOME_ITEMS_LIST_ELEMENTS]: (state, payload) => {
        state.income_items_list_elements = payload
    },
    [GET_NOMENCLATURE_GROUPS_LIST_ELEMENTS]: (state, payload) => {
        state.nomenclature_groups_list_elements = payload
    },
    [GET_TAXES_ELEMENTS]: (state, payload) => {
        state.taxes_list_elements = payload
    },
    [GET_MIN_AMOUNT_ELEMENTS]: (state, payload) => {
        state.min_amount_list_elements = payload
    },
    [GET_INFLATION_INDEX_ELEMENTS]: (state, payload) => {
        state.inflation_index_list_elements = payload
    },
    [GET_TAX_TARIFF_ELEMENTS]: (state, payload) => {
        state.tax_tariff_list_elements = payload
    },
    [GET_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS]: (state, payload) => {
        state.contractor_contact_person_list_elements = payload
    },
    [GET_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS]: (state, payload) => {
        state.contractor_response_person_list_elements = payload
    },
    [GET_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS]: (state, payload) => {
        state.contractor_bank_account_list_elements = payload
    },

    [CREATE_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS]: (state, payload) => {
        state.contractor_contact_person_list_elements.push(payload)
    },
    [UPDATE_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.contractor_contact_person_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.contractor_contact_person_list_elements.find(item => item.id === payload)
        const obj_id = state.contractor_contact_person_list_elements.indexOf(obg)
        if (obg) {
            state.contractor_contact_person_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS]: (state, payload) => {
        state.contractor_contact_person_list_elements_select = payload
    },

    [CREATE_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS]: (state, payload) => {
        state.contractor_bank_account_list_elements.push(payload)
    },
    [UPDATE_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.contractor_bank_account_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.contractor_bank_account_list_elements.find(item => item.id === payload)
        const obj_id = state.contractor_bank_account_list_elements.indexOf(obg)
        if (obg) {
            state.contractor_bank_account_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS]: (state, payload) => {
        state.contractor_bank_account_list_elements_select = payload
    },

    [CREATE_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS]: (state, payload) => {
        state.contractor_response_person_list_elements.push(payload)
    },
    [UPDATE_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.contractor_response_person_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.contractor_response_person_list_elements.find(item => item.id === payload)
        const obj_id = state.contractor_response_person_list_elements.indexOf(obg)
        if (obg) {
            state.contractor_response_person_list_elements.splice(obj_id, 1)
        }
    },

    [CREATE_WAREHOUSE_LIST_ELEMENTS]: (state, payload) => {
        state.warehouse_list_elements.push(payload)
    },
    [UPDATE_WAREHOUSE_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.warehouse_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_WAREHOUSE_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.warehouse_list_elements.find(item => item.id === payload)
        const obj_id = state.warehouse_list_elements.indexOf(obg)
        if (obg) {
            state.warehouse_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_WAREHOUSE_LIST_ELEMENTS]: (state, payload) => {
        state.warehouse_list_elements_select = payload
    },

    [GET_CASH_FLOW_ITEM_LIST_ELEMENTS]: (state, payload) => {
        state.cash_flow_item_list_elements = payload
    },
    [CREATE_CASH_FLOW_ITEM_LIST_ELEMENTS]: (state, payload) => {
        state.cash_flow_item_list_elements.push(payload)
    },
    [UPDATE_CASH_FLOW_ITEM_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.cash_flow_item_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_CASH_FLOW_ITEM_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.cash_flow_item_list_elements.find(item => item.id === payload)
        const obj_id = state.cash_flow_item_list_elements.indexOf(obg)
        if (obg) {
            state.cash_flow_item_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_CASH_FLOW_ITEM_LIST_ELEMENTS]: (state, payload) => {
        state.cash_flow_item_list_elements_select = payload
    },

    [CREATE_CHART_OF_ACCOUNTS_LIST_ELEMENTS]: (state, payload) => {
        state.charts_of_account_list_elements.push(payload)
    },
    [UPDATE_CHART_OF_ACCOUNTS_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.charts_of_account_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_CHART_OF_ACCOUNTS_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.charts_of_account_list_elements.find(item => item.id === payload)
        const obj_id = state.charts_of_account_list_elements.indexOf(obg)
        if (obg) {
            state.charts_of_account_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS]: (state, payload) => {
        state.charts_of_account_list_elements_select = payload
    },

    [GET_BANK_ACCOUNT_LIST_ELEMENTS]: (state, payload) => {
        state.bank_account_list_elements = payload
    },
    [CREATE_BANK_ACCOUNT_LIST_ELEMENTS]: (state, payload) => {
        state.bank_account_list_elements.push(payload)
    },
    [UPDATE_BANK_ACCOUNT_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.bank_account_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_BANK_ACCOUNT_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.bank_account_list_elements.find(item => item.id === payload)
        const obj_id = state.bank_account_list_elements.indexOf(obg)
        if (obg) {
            state.bank_account_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_BANK_ACCOUNT_LIST_ELEMENTS]: (state, payload) => {
        state.bank_account_list_elements_select = payload
    },

    [CREATE_CURRENCY_LIST_ELEMENTS]: (state, payload) => {
        state.currency_list_elements.push(payload)
    },
    [UPDATE_CURRENCY_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.currency_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_CURRENCY_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.currency_list_elements.find(item => item.id === payload)
        const obj_id = state.currency_list_elements.indexOf(obg)
        if (obg) {
            state.currency_list_elements.splice(obj_id, 1)
        }
    },

    [CREATE_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS]: (state, payload) => {
        state.house_accounting_payments_settings_list_elements.push(payload)
    },
    [UPDATE_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.house_accounting_payments_settings_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.house_accounting_payments_settings_list_elements.find(item => item.id === payload)
        const obj_id = state.house_accounting_payments_settings_list_elements.indexOf(obg)
        if (obg) {
            state.house_accounting_payments_settings_list_elements.splice(obj_id, 1)
        }
    },

    [CREATE_HOUSE_ACCOUNTING_CHARGE_SETTINGS_LIST_ELEMENTS]: (state, payload) => {
        state.house_accounting_charge_settings_list_elements.push(payload)
    },
    [UPDATE_HOUSE_ACCOUNTING_CHARGE_SETTINGS_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.house_accounting_charge_settings_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_HOUSE_ACCOUNTING_CHARGE_SETTINGS_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.house_accounting_charge_settings_list_elements.find(item => item.id === payload)
        const obj_id = state.house_accounting_charge_settings_list_elements.indexOf(obg)
        if (obg) {
            state.house_accounting_charge_settings_list_elements.splice(obj_id, 1)
        }
    },

    [SELECT_CURRENCY_LIST_ELEMENTS]: (state, payload) => {
        state.currency_list_elements_select = payload
    },

    [CREATE_AMORTISATION_CALC_WAY_LIST_ELEMENTS]: (state, payload) => {
        state.amortisation_calc_way_list_elements.push(payload)
    },
    [UPDATE_AMORTISATION_CALC_WAY_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.amortisation_calc_way_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_AMORTISATION_CALC_WAY_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.amortisation_calc_way_list_elements.find(item => item.id === payload)
        const obj_id = state.amortisation_calc_way_list_elements.indexOf(obg)
        if (obg) {
            state.amortisation_calc_way_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_AMORTISATION_CALC_WAY_LIST_ELEMENTS]: (state, payload) => {
        state.amortisation_calc_way_list_elements_select = payload
    },


    [CREATE_CONTRACTOR_LIST_ELEMENTS]: (state, payload) => {
        state.contractor_list_elements.push(payload)
    },
    [UPDATE_CONTRACTOR_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.contractor_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_CONTRACTOR_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.contractor_list_elements.find(item => item.id === payload)
        const obj_id = state.contractor_list_elements.indexOf(obg)
        if (obg) {
            state.contractor_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_CONTRACTOR_LIST_ELEMENTS]: (state, payload) => {
        state.contractor_list_elements_select = payload
    },

    [CREATE_CONTRACTOR_CONTRACT_LIST_ELEMENTS]: (state, payload) => {
        state.contractor_contract_list_elements.push(payload)
    },
    [UPDATE_CONTRACTOR_CONTRACT_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.contractor_contract_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_CONTRACTOR_CONTRACT_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.contractor_contract_list_elements.find(item => item.id === payload)
        const obj_id = state.contractor_contract_list_elements.indexOf(obg)
        if (obg) {
            state.contractor_contract_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_CONTRACTOR_CONTRACT_LIST_ELEMENTS]: (state, payload) => {
        state.contractor_contract_list_elements_select = payload
    },

    [CREATE_POSITION_LIST_ELEMENTS]: (state, payload) => {
        state.position_list_elements.push(payload)
    },
    [UPDATE_POSITION_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.position_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_POSITION_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.position_list_elements.find(item => item.id === payload)
        const obj_id = state.position_list_elements.indexOf(obg)
        if (obg) {
            state.position_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_POSITION_LIST_ELEMENTS]: (state, payload) => {
        state.position_list_elements_select = payload
    },

    [CREATE_TAX_INSPECTION_LIST_ELEMENTS]: (state, payload) => {
        state.tax_inspection_list_elements.push(payload)
    },
    [UPDATE_TAX_INSPECTION_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.tax_inspection_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_TAX_INSPECTION_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.tax_inspection_list_elements.find(item => item.id === payload)
        const obj_id = state.tax_inspection_list_elements.indexOf(obg)
        if (obg) {
            state.tax_inspection_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_TAX_INSPECTION_LIST_ELEMENTS]: (state, payload) => {
        state.tax_inspection_list_elements_select = payload
    },

    [CREATE_SUBDIVISION_LIST_ELEMENTS]: (state, payload) => {
        state.subdivision_list_elements.push(payload)
    },
    [UPDATE_SUBDIVISION_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.subdivision_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_SUBDIVISION_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.subdivision_list_elements.find(item => item.id === payload)
        const obj_id = state.subdivision_list_elements.indexOf(obg)
        if (obg) {
            state.subdivision_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_SUBDIVISION_LIST_ELEMENTS]: (state, payload) => {
        state.subdivision_list_elements_select = payload
    },

    [CREATE_UNIT_LIST_ELEMENTS]: (state, payload) => {
        state.unit_list_elements.push(payload)
    },
    [UPDATE_UNIT_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.unit_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_UNIT_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.unit_list_elements.find(item => item.id === payload)
        const obj_id = state.unit_list_elements.indexOf(obg)
        if (obg) {
            state.unit_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_UNIT_LIST_ELEMENTS]: (state, payload) => {
        state.unit_list_elements_select = payload
    },

    [CREATE_NATURAL_PERSON_LIST_ELEMENTS]: (state, payload) => {
        state.natural_person_list_elements.push(payload)
    },
    [UPDATE_NATURAL_PERSON_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.natural_person_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_NATURAL_PERSON_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.natural_person_list_elements.find(item => item.id === payload)
        const obj_id = state.natural_person_list_elements.indexOf(obg)
        if (obg) {
            state.natural_person_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_NATURAL_PERSON_LIST_ELEMENTS]: (state, payload) => {
        state.natural_person_list_elements_select = payload
    },

    [CREATE_PERSONAL_LIST_ELEMENTS]: (state, payload) => {
        state.personal_list_elements.push(payload)
    },
    [UPDATE_PERSONAL_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.personal_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_PERSONAL_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.personal_list_elements.find(item => item.id === payload)
        const obj_id = state.personal_list_elements.indexOf(obg)
        if (obg) {
            state.personal_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_PERSONAL_LIST_ELEMENTS]: (state, payload) => {
        state.personal_list_elements_select = payload
    },

    [CREATE_BIOLOGICAL_ASSETS_LIST_ELEMENTS]: (state, payload) => {
        state.biological_assets_list_elements.push(payload)
    },
    [UPDATE_BIOLOGICAL_ASSETS_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.biological_assets_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_BIOLOGICAL_ASSETS_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.biological_assets_list_elements.find(item => item.id === payload)
        const obj_id = state.biological_assets_list_elements.indexOf(obg)
        if (obg) {
            state.biological_assets_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_BIOLOGICAL_ASSETS_LIST_ELEMENTS]: (state, payload) => {
        state.biological_assets_list_elements_select = payload
    },

    [CREATE_COST_ITEMS_LIST_ELEMENTS]: (state, payload) => {
        state.cost_items_list_elements.push(payload)
    },
    [UPDATE_COST_ITEMS_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.cost_items_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_COST_ITEMS_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.cost_items_list_elements.find(item => item.id === payload)
        const obj_id = state.cost_items_list_elements.indexOf(obg)
        if (obg) {
            state.cost_items_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_COST_ITEMS_LIST_ELEMENTS]: (state, payload) => {
        state.cost_items_list_elements_select = payload
    },

    [CREATE_RELEASE_REASON_LIST_ELEMENTS]: (state, payload) => {
        state.release_reason_list_elements.push(payload)
    },
    [UPDATE_RELEASE_REASON_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.release_reason_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_RELEASE_REASON_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.release_reason_list_elements.find(item => item.id === payload)
        const obj_id = state.release_reason_list_elements.indexOf(obg)
        if (obg) {
            state.release_reason_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_RELEASE_REASON_LIST_ELEMENTS]: (state, payload) => {
        state.release_reason_list_elements_select = payload
    },

    [CREATE_FIXED_ASSETS_LIST_ELEMENTS]: (state, payload) => {
        state.fixed_assets_list_elements.push(payload)
    },
    [UPDATE_FIXED_ASSETS_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.fixed_assets_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_FIXED_ASSETS_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.fixed_assets_list_elements.find(item => item.id === payload)
        const obj_id = state.fixed_assets_list_elements.indexOf(obg)
        if (obg) {
            state.fixed_assets_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_FIXED_ASSETS_LIST_ELEMENTS]: (state, payload) => {
        state.fixed_assets_list_elements_select = payload
    },

    [CREATE_INTANGIBLE_ASSETS_LIST_ELEMENTS]: (state, payload) => {
        state.intangible_assets_list_elements.push(payload)
    },
    [UPDATE_INTANGIBLE_ASSETS_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.intangible_assets_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_INTANGIBLE_ASSETS_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.intangible_assets_list_elements.find(item => item.id === payload)
        const obj_id = state.intangible_assets_list_elements.indexOf(obg)
        if (obg) {
            state.intangible_assets_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_INTANGIBLE_ASSETS_LIST_ELEMENTS]: (state, payload) => {
        state.intangible_assets_list_elements_select = payload
    },

    [CREATE_WORK_SCHEDULE_LIST_ELEMENTS]: (state, payload) => {
        state.work_schedule_list_elements.push(payload)
    },
    [UPDATE_WORK_SCHEDULE_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.work_schedule_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_WORK_SCHEDULE_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.work_schedule_list_elements.find(item => item.id === payload)
        const obj_id = state.work_schedule_list_elements.indexOf(obg)
        if (obg) {
            state.work_schedule_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_WORK_SCHEDULE_LIST_ELEMENTS]: (state, payload) => {
        state.work_schedule_list_elements_select = payload
    },

    [CREATE_ACCRUAL_LIST_ELEMENTS]: (state, payload) => {
        state.accrual_list_elements.push(payload)
    },
    [UPDATE_ACCRUAL_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.accrual_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_ACCRUAL_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.accrual_list_elements.find(item => item.id === payload)
        const obj_id = state.accrual_list_elements.indexOf(obg)
        if (obg) {
            state.accrual_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_ACCRUAL_LIST_ELEMENTS]: (state, payload) => {
        state.accrual_list_elements_select = payload
    },

    [CREATE_TIMESHEET_LETTER_LIST_ELEMENTS]: (state, payload) => {
        state.timesheet_letter_list_elements.push(payload)
    },
    [UPDATE_TIMESHEET_LETTER_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.timesheet_letter_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_TIMESHEET_LETTER_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.timesheet_letter_list_elements.find(item => item.id === payload)
        const obj_id = state.timesheet_letter_list_elements.indexOf(obg)
        if (obg) {
            state.timesheet_letter_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_TIMESHEET_LETTER_LIST_ELEMENTS]: (state, payload) => {
        state.timesheet_letter_list_elements_select = payload
    },

    [CREATE_INCOME_TYPE_PIX_LIST_ELEMENTS]: (state, payload) => {
        state.income_type_pix_list_elements.push(payload)
    },
    [UPDATE_INCOME_TYPE_PIX_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.income_type_pix_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_INCOME_TYPE_PIX_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.income_type_pix_list_elements.find(item => item.id === payload)
        const obj_id = state.income_type_pix_list_elements.indexOf(obg)
        if (obg) {
            state.income_type_pix_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_INCOME_TYPE_PIX_LIST_ELEMENTS]: (state, payload) => {
        state.income_type_pix_list_elements_select = payload
    },

    [CREATE_INCOME_ITEMS_LIST_ELEMENTS]: (state, payload) => {
        state.income_items_list_elements.push(payload)
    },
    [UPDATE_INCOME_ITEMS_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.income_items_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_INCOME_ITEMS_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.income_items_list_elements.find(item => item.id === payload)
        const obj_id = state.income_items_list_elements.indexOf(obg)
        if (obg) {
            state.income_items_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_INCOME_ITEMS_LIST_ELEMENTS]: (state, payload) => {
        state.income_items_list_elements_select = payload
    },

    [CREATE_NOMENCLATURE_GROUPS_LIST_ELEMENTS]: (state, payload) => {
        state.nomenclature_groups_list_elements.push(payload)
    },
    [UPDATE_NOMENCLATURE_GROUPS_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.nomenclature_groups_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_NOMENCLATURE_GROUPS_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.nomenclature_groups_list_elements.find(item => item.id === payload)
        const obj_id = state.nomenclature_groups_list_elements.indexOf(obg)
        if (obg) {
            state.nomenclature_groups_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_NOMENCLATURE_GROUPS_LIST_ELEMENTS]: (state, payload) => {
        state.nomenclature_groups_list_elements_select = payload
    },

    [CREATE_TAXES_LIST_ELEMENTS]: (state, payload) => {
        state.taxes_list_elements.push(payload)
    },
    [UPDATE_TAXES_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.taxes_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_TAXES_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.taxes_list_elements.find(item => item.id === payload)
        const obj_id = state.taxes_list_elements.indexOf(obg)
        if (obg) {
            state.taxes_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_TAXES_LIST_ELEMENTS]: (state, payload) => {
        state.taxes_list_elements_select = payload
    },

    [CREATE_MIN_AMOUNT_LIST_ELEMENTS]: (state, payload) => {
        state.min_amount_list_elements.push(payload)
    },
    [UPDATE_MIN_AMOUNT_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.min_amount_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_MIN_AMOUNT_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.min_amount_list_elements.find(item => item.id === payload)
        const obj_id = state.min_amount_list_elements.indexOf(obg)
        if (obg) {
            state.min_amount_list_elements.splice(obj_id, 1)
        }
    },

    [CREATE_INFLATION_INDEX_LIST_ELEMENTS]: (state, payload) => {
        state.inflation_index_list_elements.push(payload)
    },
    [UPDATE_MIN_AMOUNT_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.inflation_index_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_MIN_AMOUNT_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.inflation_index_list_elements.find(item => item.id === payload)
        const obj_id = state.inflation_index_list_elements.indexOf(obg)
        if (obg) {
            state.inflation_index_list_elements.splice(obj_id, 1)
        }
    },

    [CREATE_TAX_TARIFF_LIST_ELEMENTS]: (state, payload) => {
        state.tax_tariff_list_elements.push(payload)
    },
    [UPDATE_TAX_TARIFF_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.tax_tariff_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_TAX_TARIFF_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.tax_tariff_list_elements.find(item => item.id === payload)
        const obj_id = state.tax_tariff_list_elements.indexOf(obg)
        if (obg) {
            state.tax_tariff_list_elements.splice(obj_id, 1)
        }
    },

    [CREATE_NOMENCLATURE_LIST_ELEMENTS]: (state, payload) => {
        state.nomenclature_list_elements.push(payload)
    },
    [UPDATE_NOMENCLATURE_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.nomenclature_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_NOMENCLATURE_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.nomenclature_list_elements.find(item => item.id === payload)
        const obj_id = state.nomenclature_list_elements.indexOf(obg)
        if (obg) {
            state.nomenclature_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_NOMENCLATURE_LIST_ELEMENTS]: (state, payload) => {
        state.nomenclature_list_elements_select = payload
    },

    [GET_DISPATCHER_PROBLEM_LIST_GROUP]: (state, payload) => {
        state.dispatcher_problem_list_groups = payload
    },
    [GET_DISPATCHER_PROBLEM_LIST_ELEMENTS]: (state, payload) => {
        state.dispatcher_problem_list_elements = payload
    },
    [CREATE_DISPATCHER_PROBLEM_LIST_ELEMENTS]: (state, payload) => {
        state.dispatcher_problem_list_elements.push(payload)
    },
    [UPDATE_DISPATCHER_PROBLEM_LIST_ELEMENTS]: (state, payload) => {
        const obj = state.dispatcher_problem_list_elements.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_DISPATCHER_PROBLEM_LIST_ELEMENTS]: (state, payload) => {
        const obg = state.dispatcher_problem_list_elements.find(item => item.id === payload)
        const obj_id = state.dispatcher_problem_list_elements.indexOf(obg)
        if (obg) {
            state.dispatcher_problem_list_elements.splice(obj_id, 1)
        }
    },
    [SELECT_DISPATCHER_PROBLEM_LIST_ELEMENTS]: (state, payload) => {
        state.dispatcher_problem_list_elements_select = payload
    },


    ["RELOAD_NOMENCLATURE_SELECT"]: (state, payload) => {
        state.reload_nomenclature = payload
    }
}


export default {
    state,
    getters,
    actions,
    mutations
}