import {axios} from '@/utils/axios';

const RESOURCE_NAME = '/accounting/documents/entering-balance';

export default {
    get_document_by_id(id) {
        return axios.post(`${RESOURCE_NAME}/get-document-by-id`, {document_id: id})
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    conducted(payload) {
        return axios.post(`${RESOURCE_NAME}/conducted`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { "delete_id": payload })
    },
    select(payload) {
        return axios.get(`${RESOURCE_NAME}/select`, {params: payload})
    },
};