import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import { formatDate, formatNumber, formatToFixed } from "@/filters";
import NumericDirective from '@/utils/directives/numeric-directive'
import DateComponent from "@/components/DateComponent"
import {BASE_URL_WITHOUT_PROTOCOL} from "@/utils/axios";
import { io } from "socket.io-client";
import "vue-lazy-youtube-video/dist/style.simplified.css";
import VueSanitize from "vue-sanitize";

const defaultSanitizeOptions = {
  allowedTags: ['span', 'i', 'br', 'strong', 'b'],
  allowedAttributes: {
    'span': [ 'class' ]
  }
};

const WEBSOCKET_PORT = process.env.NODE_ENV === 'production' ? ':8090' : ''
const WEBSOCKET_URL = `wss://${BASE_URL_WITHOUT_PROTOCOL}${WEBSOCKET_PORT}/ws`

Vue.config.productionTip = false
Vue.filter('formatDate', formatDate)
Vue.filter('formatNumber', formatNumber)
Vue.filter('formatToFixed', formatToFixed)
Vue.use(NumericDirective)
Vue.use(VueSanitize, defaultSanitizeOptions);
Vue.component('date-component', DateComponent)

Vue.prototype.$ws = {
  connection: null,
  start() {
    if (!this.connection && process.env.NODE_ENV === 'production') {
      this.connection = io(WEBSOCKET_URL, {
        path: '/ws/socket.io',
        credentials: true,
        origin: ['https://wetoo.vinksoft.com.ua', 'https://www.wetoo.vinksoft.com.ua'],
        secure: true
      })
      this.connection.on('connect', () => {
        console.log('Have a nice day!')
      })
      this.connection.on('connect_failed ', () => {
        console.log('connect_failed')
      })
      this.connection.on('error ', (err) => {
        console.log('connect_failed', err)
        this.connection.on('disconnect', () => {
      })
      })
      this.connection.on('response', (data) => {
        store.commit("WS_MESSAGE", JSON.parse(data))
      })
    }
  },
  close() {
    if (this.connection) {
      this.connection.close()
      this.connection = null
    }
  },
  send_message(message) {
    this.connection.emit('message', JSON.stringify(message))
  }
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
