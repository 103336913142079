<template>
  <v-dialog :value="show_general_registration_of_changes"
            persistent
            max-width="600"
  >
    <RegistrationOfChangeLocal :item="item"
                               :dialog="show_general_registration_of_changes"
                               title="Реєстрація зміни для перерахунку"
                               @closeModal="closeModal"
    />
  </v-dialog>
</template>

<script>
import {GENERAL_REGISTRATION_HIDE, GENERAL_REGISTRATION_STATUS} from "@/store/actions/general_registration_of_changes";
import {mapGetters} from "vuex"

export default {
  name: "GeneralRegistrationOfChanges",
  components: {
    RegistrationOfChangeLocal: () => import("@/components/modal/HWP_Modal_RegistrationOfChanges.vue")
  },
  computed: {
    ...mapGetters({
      show_general_registration_of_changes: 'getGeneralRegistrationOfChangesShow',
      item: 'getGeneralRegistrationOfChangesItem'
    })
  },
  methods: {
    closeModal(payload) {
      this.$store.dispatch(GENERAL_REGISTRATION_STATUS, {id: this.item.modal_id, success: payload})
      this.$store.dispatch(GENERAL_REGISTRATION_HIDE)
    }
  }
}
</script>

<style scoped>

</style>