import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/registration-of-changes';

export default {
    get_all(person_hash) {
        return axios.get(`${RESOURCE_NAME}/`, {params: {person_hash: person_hash}})
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
};