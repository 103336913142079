import {
    CALC_BY_FLAT,
    CALC_BY_FLAT_LEGACY,
    CALC_BY_ALL,
    CALC_BY_ALL_LEGACY
} from "@/store/actions/calculation";

import calcAPI from "@/utils/axios/calculation"

import {ALERT_SHOW} from "@/store/actions/alert";

const state = {}

const getters = {
}

const actions = {
    [CALC_BY_FLAT]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            calcAPI.calc_by_flat(payload)
                .then(response => response.data )
                .then(() => {
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CALC_BY_FLAT_LEGACY]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            calcAPI.calc_by_flat_legacy(payload)
                .then(response => response.data )
                .then(() => {
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CALC_BY_ALL]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            calcAPI.calc_by_all()
                .then(response => response.data )
                .then(() => {
                    commit(ALERT_SHOW, { text: 'Розрахунок завершено успішно', color: 'success' })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CALC_BY_ALL_LEGACY]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            calcAPI.calc_by_all_legacy()
                .then(response => response.data )
                .then(() => {
                    commit(ALERT_SHOW, { text: 'Розрахунок завершено успішно', color: 'success' })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
}

const mutations = {
}


export default {
    state,
    getters,
    actions,
    mutations
}