import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/user-message-history';

export default {
    get_all() {
        return axios.get(`${RESOURCE_NAME}/`)
    },
    get_user_messages() {
        return axios.get(`${RESOURCE_NAME}/user-messages`)
    },
    set_looked(payload) {
        return axios.post(`${RESOURCE_NAME}/set-looked`, {item_id: payload})
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { "checker_id": payload })
    },

};