import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/calculation';

export default {
    calc_by_flat(person_hash) {
        return axios.post(`${RESOURCE_NAME}/by_flat`, { person_hash: person_hash })
    },
    calc_by_flat_legacy(person_hash) {
        return axios.post(`${RESOURCE_NAME}/by_flat_legacy`, { person_hash: person_hash })
    },
    calc_by_all() {
        return axios.post(`${RESOURCE_NAME}/by_all`)
    },
    calc_by_all_legacy() {
        return axios.post(`${RESOURCE_NAME}/by_all_legacy`)
    },
};