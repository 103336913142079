import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/organization-documents';

export default {
    get_documents(payload) {
        return axios.get(`${RESOURCE_NAME}/`, {params: payload})
    },
    create_document(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
    update_document(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
    remove_document(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { "document_id": payload })
    },
    get_group_type() {
        return axios.get(`${RESOURCE_NAME}/document-group-type`)
    },
    get_group_type_tree() {
        return axios.get(`${RESOURCE_NAME}/document-group-type-tree`)
    },
    create_group_type(payload) {
        return axios.post(`${RESOURCE_NAME}/document-group-type`, payload)
    },
    update_group_type(payload) {
        return axios.post(`${RESOURCE_NAME}/document-group-type/update`, payload)
    },
    remove_group_type(payload) {
        return axios.post(`${RESOURCE_NAME}/document-group-type/remove`, { group_type_id: payload })
    },
    get_document_type() {
        return axios.get(`${RESOURCE_NAME}/document-type`)
    },
    create_document_type(payload) {
        return axios.post(`${RESOURCE_NAME}/document-type`, payload)
    },
    update_document_type(payload) {
        return axios.post(`${RESOURCE_NAME}/document-type/update`, payload)
    },
    remove_document_type(payload) {
        return axios.post(`${RESOURCE_NAME}/document-type/remove`, { document_type_id: payload })
    },
};