import {ALERT_SHOW} from "@/store/actions/alert";

import legacyDocumentAPI from "@/utils/axios/legacy_documents"
import {
    GET_LEGACY_ACCOUNTING_DOCUMENTS_BY_FLAT,
    CREATE_LEGACY_ACCOUNTING_DOCUMENTS,
    REMOVE_LEGACY_ACCOUNTING_DOCUMENTS
} from "@/store/actions/legacy_documents";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";
// import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = {legacy_accounting_documents: []}

const getters = {
    getLegacyAccountingDocuments: state => state.legacy_accounting_documents,
}

const actions = {
    [GET_LEGACY_ACCOUNTING_DOCUMENTS_BY_FLAT]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            legacyDocumentAPI.get_by_flat(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_LEGACY_ACCOUNTING_DOCUMENTS_BY_FLAT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [CREATE_LEGACY_ACCOUNTING_DOCUMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            legacyDocumentAPI.create_documents(payload)
                .then(response => response.data)
                .then(() => {
                    dispatch(GET_LEGACY_ACCOUNTING_DOCUMENTS_BY_FLAT, {flat_id: payload.flat_id})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_LEGACY_ACCOUNTING_DOCUMENTS]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            legacyDocumentAPI.remove_documents(payload)
                .then(response => response.data)
                .then(data => {
                    dispatch(GET_LEGACY_ACCOUNTING_DOCUMENTS_BY_FLAT, {flat_id: data.flat_id})
                    resolve(data)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
}

const mutations = {
    [GET_LEGACY_ACCOUNTING_DOCUMENTS_BY_FLAT]: (state, payload) => {
        const local_payload = payload.slice()
        local_payload.forEach((item, idx) => {
            Object.assign(item, {row_num: idx + 1})
        })
        state.legacy_accounting_documents = local_payload
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}