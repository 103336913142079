import {
    FETCH_SERVICES,
    CREATE_SERVICE,
    UPDATE_SERVICE,
    DELETE_SERVICE,
    SELECT_SERVICES,
    SELECT_SERVICES_WITH_COUNTER,
    FETCH_REGULARS,
    CREATE_REGULARS,
    DELETE_REGULARS,
    UPDATE_REGULARS,
    SELECT_REGULARS,
    SELECT_SERVICES_WITHOUT_ALL_SERVICE,
    SELECT_SERVICES_ONE_TIME, SELECT_SOCIAL_REGULARS
} from "@/store/actions/services";

import serviceAPI from "@/utils/axios/service"

import {ALERT_SHOW} from "@/store/actions/alert";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = {
    services: [],
    services_select: [],
    services_select_without_all: [],
    services_select_with_counter: [],
    services_select_one_time: [],
    regulars: [],
    regulars_select: [],
    social_regulars_select: [],
    regulars_select_loaded: false,
    social_regulars_select_loaded: false
}

const getters = {
    getServices: state => state.services,
    getServicesSelect: state => state.services_select,
    getServicesSelectWithoutAll: state => state.services_select_without_all,
    getServicesSelectWithCounter: state => state.services_select_with_counter,
    getServicesSelectOneTime: state => state.services_select_one_time,
    getRegulars: state => state.regulars,
    getRegularsSelect: state => state.regulars_select,
    getSocialRegularsSelect: state => state.social_regulars_select
}

const actions = {
    [FETCH_SERVICES]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            serviceAPI.get()
                .then(response => response.data)
                .then(data => {
                    commit(FETCH_SERVICES, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [FETCH_REGULARS]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            serviceAPI.get_regular(payload)
                .then(response => response.data)
                .then(data => {
                    commit(FETCH_REGULARS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [SELECT_SERVICES]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            serviceAPI.select(true)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_SERVICES, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [SELECT_SERVICES_WITHOUT_ALL_SERVICE]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            serviceAPI.select(false)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_SERVICES_WITHOUT_ALL_SERVICE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [SELECT_SERVICES_WITH_COUNTER]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            serviceAPI.select_with_counter()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_SERVICES_WITH_COUNTER, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [SELECT_SERVICES_ONE_TIME]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            serviceAPI.select_one_time()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_SERVICES_ONE_TIME, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [SELECT_REGULARS]: ({ commit, payload }) => {
        return new Promise((resolve, reject) => {
            serviceAPI.select_regular(payload)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_REGULARS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [SELECT_SOCIAL_REGULARS]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            serviceAPI.select_social_regular()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_SOCIAL_REGULARS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_SERVICE]: ({ dispatch, commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            serviceAPI.create(payload)
                .then(response => { return response.data })
                .then(data => {
                    commit(CREATE_SERVICE, data)
                    dispatch(SELECT_SERVICES)
                    dispatch(SELECT_SERVICES_WITH_COUNTER)
                    dispatch(SELECT_SERVICES_WITHOUT_ALL_SERVICE)
                    dispatch(SELECT_SERVICES_ONE_TIME)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CREATE_REGULARS]: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            serviceAPI.create_regular(payload)
                .then(response => { return response.data })
                .then(data => {
                    commit(CREATE_REGULARS, data)
                    dispatch(SELECT_REGULARS)
                    dispatch(SELECT_SOCIAL_REGULARS)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_SERVICE]: ({ dispatch, commit }, payload) => {
        return new Promise(((resolve, reject) => {
            commit(START_MODAL_LOADING)
            serviceAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_SERVICE, data)
                    dispatch(SELECT_SERVICES_WITH_COUNTER)
                    dispatch(SELECT_SERVICES_WITHOUT_ALL_SERVICE)
                    dispatch(SELECT_SERVICES_ONE_TIME)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        }))
    },
    [UPDATE_REGULARS]: ({ commit, dispatch }, payload) => {
        return new Promise(((resolve, reject) => {
            commit(START_MODAL_LOADING)
            serviceAPI.update_regular(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_REGULARS, data)
                    dispatch(SELECT_REGULARS)
                    dispatch(SELECT_SOCIAL_REGULARS)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        }))
    },
    [DELETE_SERVICE]: ({ dispatch, commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            serviceAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(DELETE_SERVICE, data)
                    dispatch(SELECT_SERVICES_WITH_COUNTER)
                    dispatch(SELECT_SERVICES_WITHOUT_ALL_SERVICE)
                    dispatch(SELECT_SERVICES_ONE_TIME)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_REGULARS]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            serviceAPI.remove_regular(payload)
                .then(response => response.data)
                .then(data => {
                    commit(DELETE_REGULARS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
}

const mutations = {
    [FETCH_SERVICES]: (state, payload) => {
        state.services = payload
    },
    [FETCH_REGULARS]: (state, payload) => {
        state.regulars = payload
    },
    [SELECT_SERVICES]: (state, payload) => {
        state.services_select = payload
    },
    [SELECT_SERVICES_WITHOUT_ALL_SERVICE]: (state, payload) => {
        state.services_select_without_all = payload
    },
    [SELECT_SERVICES_WITH_COUNTER]: (state, payload) => {
        state.services_select_with_counter = payload
    },
    [SELECT_SERVICES_ONE_TIME]: (state, payload) => {
        state.services_select_one_time = payload
    },
    [SELECT_REGULARS]: (state, payload) => {
        state.regulars_select_loaded = true
        state.regulars_select = payload
    },
    [SELECT_SOCIAL_REGULARS]: (state, payload) => {
        state.social_regulars_select_loaded = true
        state.social_regulars_select = payload
    },
    [CREATE_SERVICE]: (state, payload) => {
        state.services.push(payload)
        state.services_select.push({
            text: payload.name,
            value: payload.id,
            type: payload.icon
        })
    },
    [CREATE_REGULARS]: (state, payload) => {
        state.regulars.push(payload)
        state.regulars_select.push({
            text: payload.name,
            value: payload.id,
        })
    },
    [UPDATE_SERVICE]: (state, payload) => {
        let obj = state.services.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })

        let s = state.services_select.find(item => item.value === payload.id)
        if (s) {
            s.text = payload.name
            s.value = payload.id
            s.type = payload.icon
        }
    },
    [UPDATE_REGULARS]: (state, payload) => {
        let obj = state.regulars.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })

        let s = state.regulars_select.find(item => item.value === payload.id)
        if (s) {
            s.text = payload.name
            s.value = payload.id
        }
    },
    [DELETE_SERVICE]: (state, payload) => {
        const obg = state.services.find(item => item.id === payload.id)
        const obj_id = state.services.indexOf(obg)
        if (obg) {
            state.services.splice(obj_id, 1)
        }

        const s = state.services_select.find(item => item.value === payload.id)
        const s_id = state.services_select.indexOf(s)
        if (s) {
            state.services_select.splice(s_id, 1)
        }
    },
    [DELETE_REGULARS]: (state, payload) => {
        const obg = state.regulars.find(item => item.id === payload.id)
        const obj_id = state.regulars.indexOf(obg)
        if (obg) {
            state.regulars.splice(obj_id, 1)
        }

        const s = state.regulars_select.find(item => item.value === payload.id)
        const s_id = state.regulars_select.indexOf(s)
        if (s) {
            state.regulars_select.splice(s_id, 1)
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}