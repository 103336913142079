import {ALERT_SHOW} from "@/store/actions/alert";

import appealReceiverGroupAPI from "@/utils/axios/appeal_receiver_group"
import {
    CREATE_APPEAL_RECEIVER_GROUP,
    DELETE_APPEAL_RECEIVER_GROUP,
    GET_ALL_APPEAL_RECEIVER_GROUP,
    SELECT_APPEAL_RECEIVER_GROUP,
    UPDATE_APPEAL_RECEIVER_GROUP
} from "@/store/actions/appeal_receiver_group";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = { appeal_receiver_groups: [], select_appeal_receiver_groups: [], select_loaded: false }

const getters = {
    getAppealReceiverGroups: state => state.appeal_receiver_groups,
    getSelectAppealReceiverGroups: state => state.select_appeal_receiver_groups,
}

const actions = {
    [GET_ALL_APPEAL_RECEIVER_GROUP]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            appealReceiverGroupAPI.get_all(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_ALL_APPEAL_RECEIVER_GROUP, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_APPEAL_RECEIVER_GROUP]: ({ dispatch, commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            appealReceiverGroupAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_APPEAL_RECEIVER_GROUP, data)
                    dispatch(SELECT_APPEAL_RECEIVER_GROUP)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_APPEAL_RECEIVER_GROUP]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            appealReceiverGroupAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_APPEAL_RECEIVER_GROUP, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_APPEAL_RECEIVER_GROUP]: ({ dispatch, commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            appealReceiverGroupAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(DELETE_APPEAL_RECEIVER_GROUP, data)
                    dispatch(SELECT_APPEAL_RECEIVER_GROUP)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_APPEAL_RECEIVER_GROUP]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            appealReceiverGroupAPI.select(payload)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_APPEAL_RECEIVER_GROUP, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
}

const mutations = {
    [GET_ALL_APPEAL_RECEIVER_GROUP]: (state, payload) => {
        state.appeal_receiver_groups = payload
    },
    [CREATE_APPEAL_RECEIVER_GROUP]: (state, payload) => {
        state.appeal_receiver_groups.push(payload)
    },
    [UPDATE_APPEAL_RECEIVER_GROUP]: (state, payload) => {
        let obj = state.appeal_receiver_groups.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [DELETE_APPEAL_RECEIVER_GROUP]: (state, payload) => {
        const obg = state.appeal_receiver_groups.find(item => item.id === payload.id)
        const obj_id = state.appeal_receiver_groups.indexOf(obg)
        if (obg) {
            state.appeal_receiver_groups.splice(obj_id, 1)
        }
    },
    [SELECT_APPEAL_RECEIVER_GROUP]: (state, payload) => {
        state.select_appeal_receiver_groups = payload
        state.select_loaded = true
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}