import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/contract';

export default {
    get_all() {
        return axios.get(`${RESOURCE_NAME}/`)
    },
    get_by_flat(payload) {
        return axios.get(`${RESOURCE_NAME}/by-flat`, { params: { flat_id: payload } })
    },
    get_by_flat_legacy(payload) {
        return axios.get(`${RESOURCE_NAME}/legacy/by-flat`, { params: { flat_id: payload } })
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    create_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    update_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/update`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { "contract_id": payload })
    },
    remove_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/remove`, { "contract_id": payload })
    },
};