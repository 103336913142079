import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/organizations';

export default {
    get() {
        return axios.get(`${RESOURCE_NAME}/`)
    },
    get_bases_send(payload) {
        return axios.get(`${RESOURCE_NAME}/bases-send`, {params: payload})
    },
    get_bases_by_service() {
        return axios.get(`${RESOURCE_NAME}/bases-by-service`)
    },
    get_payments_by_priority() {
        return axios.get(`${RESOURCE_NAME}/payments-by-priority`)
    },
    get_general_settings() {
        return axios.get(`${RESOURCE_NAME}/settings`)
    },
    get_accounting_general_settings() {
        return axios.get(`${RESOURCE_NAME}/accounting-settings`)
    },
    update_accounting_information_by_organization() {
        return axios.get(`${RESOURCE_NAME}/update-accounting-information`)
    },
    get_print_data() {
        return axios.get(`${RESOURCE_NAME}/print-details`)
    },
    get_site_admin_settings() {
        return axios.get(`${RESOURCE_NAME}/admin-site-settings`)
    },
    get_user_stats() {
        return axios.get(`${RESOURCE_NAME}/user-stats`)
    },
    save_general_settings(payload) {
        return axios.post(`${RESOURCE_NAME}/settings`, payload)
    },
    save_accounting_general_settings(payload) {
        return axios.post(`${RESOURCE_NAME}/accounting-settings`, payload)
    },
    save_site_admin_settings(payload) {
        return axios.post(`${RESOURCE_NAME}/admin-site-settings`, { schema: payload })
    },
    create_flats_from_eps_base(payload) {
        return axios.post(`${RESOURCE_NAME}/create-flats-from-eps-base`, payload)
    },
    create_first_site_id() {
        return axios.post(`${RESOURCE_NAME}/create-site-slug`)
    },
    update_site_id(payload) {
        return axios.post(`${RESOURCE_NAME}/update-site-slug`, payload)
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    create_bases_by_service(payload) {
        return axios.post(`${RESOURCE_NAME}/bases-by-service`, payload)
    },
    create_payments_by_priority(payload) {
        return axios.post(`${RESOURCE_NAME}/payments-by-priority`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    update_bases_by_service(payload) {
        return axios.post(`${RESOURCE_NAME}/update/bases-by-service`, payload)
    },
    update_payments_by_priority(payload) {
        return axios.post(`${RESOURCE_NAME}/update/payments-by-priority`, payload)
    },
    remove_bases_by_service(payload) {
        return axios.post(`${RESOURCE_NAME}/remove/bases-by-service`, { bases_by_service_id: payload })
    },
    remove_payments_by_priority(payload) {
        return axios.post(`${RESOURCE_NAME}/remove/payments-by-priority`, { bases_by_service_id: payload })
    },
    get_additional_information_for_organizations() {
        return axios.get(`${RESOURCE_NAME}/add-info`)
    },
    create_qr_code(payload) {
        return axios.post(`${RESOURCE_NAME}/create-qr-code`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
}
