import {ALERT_SHOW} from "@/store/actions/alert";

import paySystemAPI from "@/utils/axios/pay_system"
import {
    CREATE_PAY_SYSTEM,
    DELETE_PAY_SYSTEM,
    GET_ALL_PAY_SYSTEM,
    UPDATE_PAY_SYSTEM
} from "@/store/actions/pay_system";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = { pay_systems: [], }

const getters = {
    getPaySystems: state => state.pay_systems,
}

const actions = {
    [GET_ALL_PAY_SYSTEM]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            paySystemAPI.get_all(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_ALL_PAY_SYSTEM, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_PAY_SYSTEM]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            paySystemAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_PAY_SYSTEM, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_PAY_SYSTEM]: ({ commit }, payload) => {
        commit(START_MODAL_LOADING)
        return new Promise((resolve, reject) => {
            paySystemAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_PAY_SYSTEM, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_PAY_SYSTEM]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            paySystemAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(DELETE_PAY_SYSTEM, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
}

const mutations = {
    [GET_ALL_PAY_SYSTEM]: (state, payload) => {
        state.pay_systems = payload
    },
    [CREATE_PAY_SYSTEM]: (state, payload) => {
        state.pay_systems.push(payload)
    },
    [UPDATE_PAY_SYSTEM]: (state, payload) => {
        let obj = state.pay_systems.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [DELETE_PAY_SYSTEM]: (state, payload) => {
        const obg = state.pay_systems.find(item => item.id === payload.id)
        const obj_id = state.pay_systems.indexOf(obg)
        if (obg) {
            state.pay_systems.splice(obj_id, 1)
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}