import {axios} from '@/utils/axios';

const RESOURCE_NAME = '/accounting/documents/personal-timesheet';

export default {
    get_document_for_print(payload) {
        return axios.post(`${RESOURCE_NAME}/get-document-for-print`, {document_id: payload})
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, {"delete_id": payload})
    },
    select(payload) {
        return axios.get(`${RESOURCE_NAME}/select`, payload)
    },
    fill_all(payload) {
        return axios.post(`${RESOURCE_NAME}/fill-all`, payload)
    },
    fill_by_person(payload) {
        return axios.post(`${RESOURCE_NAME}/fill-by-person`, payload)
    },
    get_rows(payload) {
        return axios.get(`${RESOURCE_NAME}/rows`, { params: { header_id: payload } })
    },
    conducted(payload) {
        return axios.post(`${RESOURCE_NAME}/conducted`, payload)
    },
};