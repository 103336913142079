import {
    FETCH_FLATS,
    SELECT_FLATS,
    GET_FLAT,
    CREATE_FLAT,
    UPDATE_FLAT,
    SEARCH_FLAT_BY_TEXT,
    NEXT_ITEM,
    CLEAR_FLATS,
    CREATE_LEGACY_FLAT,
    FETCH_LEGACY_FLATS,
    SELECT_LEGACY_FLATS,
    GET_LEGACY_FLAT,
    SEARCH_LEGACY_FLAT_BY_TEXT,
    UPDATE_LEGACY_FLAT, NEXT_LEGACY_ITEM
} from "@/store/actions/flat";

import {ALERT_SHOW} from "@/store/actions/alert";
import flatAPI from "@/utils/axios/flat"
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";
import {FETCH_FLAT_INDICATORS} from "@/store/actions/flat_indicators";
import {FETCH_MAIN_CONTACTS} from "@/store/actions/contact";
import {FETCH_TARIFF_GROUP_BY_FLAT} from "@/store/actions/tariff_group";
import {GET_ALL_FLAT_TARIFF, GET_ALL_SERVICE, GET_ALL_WITH_BALANCE, GET_ARCHIVE} from "@/store/actions/flat_service";
import {FETCH_FAMILY_BY_FLAT, FETCH_FAMILY_BY_ONE, GET_OWNER_CHANGES} from "@/store/actions/family";
import {GET_ALL_ABSENT_PERSONS} from "@/store/actions/absent";
import {
    COUNTER_SELECT,
    GET_ALL_FLAT_COUNTER_OPERATION,
    GET_ALL_FLAT_COUNTER_SUB_ACCOUNT,
    GET_FLAT_COUNTER, GET_FLAT_COUNTER_READING
} from "@/store/actions/counter";
import {GET_ALL_RESTRUCTURING_AGREEMENT} from "@/store/actions/restructuring_agreement";
import {GET_FLAT_DOCUMENTS} from "@/store/actions/flat_documents";
import {GET_CONTRACTS_BY_FLAT} from "@/store/actions/contract";
import {GET_ALL_APPLIANCE_BY_FLAT, GET_ALL_APPLIANCE_OPERATION_JOURNAL} from "@/store/actions/appliance";

const state = {
    flats: [],
    legacy_flats: [],
    flat: {},
    legacy_flat: {},
    flats_select: [],
    legacy_flats_select: [],
    flat_select_status: false,
    legacy_flat_select_status: false
}

const getters = {
    getFlats: state => state.flats,
    getLegacyFlats: state => state.legacy_flats,
    getFlat: state => state.flat,
    getLegacyFlat: state => state.legacy_flat,
    getFlatsSelect: state => state.flats_select,
    getLegacyFlatsSelect: state => state.legacy_flats_select,
    getSelectStatus: state => state.flat_select_status,
}


const actions = {
    [FETCH_FLATS]: ({commit}, payload = {}) => {
        return new Promise((resolve, reject) => {
            flatAPI.all(payload)
                .then(response => {
                    return response.data
                })
                .then(data => {
                    commit(FETCH_FLATS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [FETCH_LEGACY_FLATS]: ({commit}, payload = {}) => {
        return new Promise((resolve, reject) => {
            flatAPI.legacy_all(payload)
                .then(response => {
                    return response.data
                })
                .then(data => {
                    commit(FETCH_LEGACY_FLATS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [SELECT_FLATS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            flatAPI.select()
                .then(response => {
                    return response.data
                })
                .then(data => {
                    commit(SELECT_FLATS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [SELECT_LEGACY_FLATS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            flatAPI.select_legacy()
                .then(response => {
                    return response.data
                })
                .then(data => {
                    commit(SELECT_LEGACY_FLATS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [GET_FLAT]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            flatAPI.one(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_FLAT, data)
                    resolve(true)
                }).catch(err => {
                const error = err.response.data.detail;
                commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                reject(false)
            })
        })
    },
    [GET_LEGACY_FLAT]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            flatAPI.legacy_one(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_LEGACY_FLAT, data)
                    resolve(true)
                }).catch(err => {
                const error = err.response.data.detail;
                commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                reject(false)
            })
        })
    },
    [NEXT_ITEM]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            flatAPI.next_item(payload.person_hash, payload.right, payload.city_id || null)
                .then(response => response.data)
                .then(data => {
                    resolve(data)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [NEXT_LEGACY_ITEM]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            flatAPI.next_item_legacy(payload.person_hash, payload.right)
                .then(response => response.data)
                .then(data => {
                    resolve(data)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [SEARCH_FLAT_BY_TEXT]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            flatAPI.search_by_text(payload)
                .then(response => response.data)
                .then(data => {
                    commit(FETCH_FLATS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [SEARCH_LEGACY_FLAT_BY_TEXT]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            flatAPI.legacy_search_by_text(payload)
                .then(response => response.data)
                .then(data => {
                    commit(FETCH_LEGACY_FLATS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [CREATE_FLAT]: async ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_FLAT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CREATE_LEGACY_FLAT]: async ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatAPI.legacy_create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_LEGACY_FLAT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_FLAT]: async ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_FLAT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_LEGACY_FLAT]: async ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatAPI.legacy_update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_LEGACY_FLAT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    ['CLEAR_WORKPLACE']: ({commit}) => {
        commit(FETCH_FLAT_INDICATORS, [])
        commit(FETCH_MAIN_CONTACTS, [])
        commit(FETCH_TARIFF_GROUP_BY_FLAT, [])
        commit(GET_ALL_SERVICE, [])
        commit(GET_ALL_FLAT_TARIFF, [])
        commit(GET_ALL_WITH_BALANCE, [])
        commit(FETCH_FAMILY_BY_FLAT, [])
        commit(FETCH_FAMILY_BY_ONE, null)
        commit(GET_ALL_ABSENT_PERSONS, [])
        commit(GET_OWNER_CHANGES, [])
        commit(GET_ARCHIVE, [])
        commit(GET_ALL_FLAT_COUNTER_OPERATION, [])
        commit(GET_FLAT_COUNTER, [])
        commit(GET_ALL_FLAT_COUNTER_SUB_ACCOUNT, [])
        commit(GET_FLAT_COUNTER_READING, [])
        commit(COUNTER_SELECT, {})
        commit(GET_ALL_RESTRUCTURING_AGREEMENT, [])
        commit(GET_FLAT_DOCUMENTS, [])
        commit(GET_CONTRACTS_BY_FLAT, [])
        commit(GET_ALL_APPLIANCE_BY_FLAT, [])
        commit(GET_ALL_APPLIANCE_OPERATION_JOURNAL, [])
    }
}

const mutations = {
    [CLEAR_FLATS]: (state) => {
        state.flats = []
    },
    [FETCH_FLATS]: (state, payload) => {
        state.flats = payload
    },
    [FETCH_LEGACY_FLATS]: (state, payload) => {
        state.legacy_flats = payload
    },
    [SELECT_FLATS]: (state, payload) => {
        state.flats_select = payload
    },
    [SELECT_LEGACY_FLATS]: (state, payload) => {
        state.legacy_flats_select = payload
    },
    [GET_FLAT]: (state, payload) => {
        state.flat = payload
    },
    [GET_LEGACY_FLAT]: (state, payload) => {
        state.legacy_flat = payload
    },
    [CREATE_FLAT]: (state, payload) => {
        state.flats.push(payload)
    },
    [CREATE_LEGACY_FLAT]: (state, payload) => {
        state.legacy_flats.push(payload)
    },
    [UPDATE_FLAT]: (state, payload) => {
        Object.keys(payload).forEach(item => {
            state.flats[item] = payload[item]
        })

        if (payload.person_hash === state.flat.person_hash) {
            Object.keys(payload).forEach(item => {
                state.flat[item] = payload[item]
            })
        }
    },
    [UPDATE_LEGACY_FLAT]: (state, payload) => {
        Object.keys(payload).forEach(item => {
            state.flats[item] = payload[item]
        })

        if (payload.person_hash === state.legacy_flat.person_hash) {
            Object.keys(payload).forEach(item => {
                state.legacy_flat[item] = payload[item]
            })
        }
    },
    ['CLEAR_FLATS']: (state) => {
        state.flats = []
        state.flat = {}
    },
    ['CLEAR_LEGACY_FLATS']: (state) => {
        state.legacy_flats = []
        state.legacy_flat = {}
    },
}


export default {
    state,
    getters,
    actions,
    mutations
}