import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/tariff_group';

export default {
    tariff_group_by_flat(person_hash) {
        return axios.get(`${RESOURCE_NAME}/flat/${person_hash}`)
    },
    tariff_group_by_legacy_flat(person_hash) {
        return axios.get(`${RESOURCE_NAME}/flat/legacy/${person_hash}`)
    },
    tariff_group_create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    tariff_group_legacy_create(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy`, payload)
    },
    tariff_group_update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    tariff_group_legacy_update(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/update`, payload)
    },
    tariff_group_type_select(payload) {
        return axios.get(`${RESOURCE_NAME}/type/select`, payload)
    },
    tariff_group_type(payload) {
        return axios.get(`${RESOURCE_NAME}/type`, payload)
    },
    tariff_group_type_create(payload) {
        return axios.post(`${RESOURCE_NAME}/type/create`, payload)
    },
    tariff_group_type_update(payload) {
        return axios.post(`${RESOURCE_NAME}/type/update`, payload)
    },
    tariff_group_type_remove(payload) {
        return axios.post(`${RESOURCE_NAME}/type/remove`, { payload_id: payload })
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { tariff_group_id: payload })
    },
    remove_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/remove`, { tariff_group_id: payload })
    },
}
