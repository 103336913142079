import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/accounting/documents/tax-bill-income';

export default {
    get_document_by_id(id) {
        return axios.post(`${RESOURCE_NAME}/get-document-by-id`, {document_id: id})
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    conducted(payload) {
        return axios.post(`${RESOURCE_NAME}/conducted`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { "delete_id": payload })
    },
    select() {
        return axios.get(`${RESOURCE_NAME}/select`)
    },
    get_document_sum(payload) {
        return axios.post(`${RESOURCE_NAME}/get-document-sum`, payload)
    },
};