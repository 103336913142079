import {
    FETCH_TARIFF_BY_ORGANIZATION,
    CREATE_TARIFF_BY_ORGANIZATION,
    UPDATE_TARIFF_BY_ORGANIZATION,
    DELETE_TARIFF_BY_ORGANIZATION
} from "@/store/actions/tariff_by_organization";

import tarByOrgAPI from "@/utils/axios/tariff_by_organization"

import {ALERT_SHOW} from "@/store/actions/alert";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = { tar_by_org: [] }

const getters = {
    getTarByOrg: state => state.tar_by_org,
}

const actions = {
    [FETCH_TARIFF_BY_ORGANIZATION]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            tarByOrgAPI.get(payload)
                .then(response => response.data)
                .then(data => {
                    commit(FETCH_TARIFF_BY_ORGANIZATION, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_TARIFF_BY_ORGANIZATION]: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            tarByOrgAPI.create(payload)
                .then(response => { return response.data })
                .then(data => {
                    commit(CREATE_TARIFF_BY_ORGANIZATION, data)
                    dispatch(FETCH_TARIFF_BY_ORGANIZATION, data?.is_legacy || false)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_TARIFF_BY_ORGANIZATION]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            tarByOrgAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_TARIFF_BY_ORGANIZATION, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_TARIFF_BY_ORGANIZATION]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            tarByOrgAPI.remove(payload)
                .then(resolve => resolve.data)
                .then(() => {
                    commit(DELETE_TARIFF_BY_ORGANIZATION, payload)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    }
}

const mutations = {
    [FETCH_TARIFF_BY_ORGANIZATION]: (state, payload) => {
        state.tar_by_org = payload
    },
    [CREATE_TARIFF_BY_ORGANIZATION]: (state, payload) => {
        state.tar_by_org.push(payload)
    },
    [UPDATE_TARIFF_BY_ORGANIZATION]: (state, payload) => {
        let obj = state.tar_by_org.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [DELETE_TARIFF_BY_ORGANIZATION]: (state, payload) => {
        const obg = state.tar_by_org.find(item => item.id === payload)
        if (obg) {
        const obj_id = state.tar_by_org.indexOf(obg)
            state.tar_by_org.splice(obj_id, 1)
        }
    },
}


export default {
    state,
    getters,
    actions,
    mutations
}