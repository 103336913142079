import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/accounting/documents/tax-bill-outcome';

export default {
    get_document_by_id(id) {
        return axios.post(`${RESOURCE_NAME}/get-document-by-id`, {document_id: id})
    },
    get_document_for_print(payload) {
        return axios.post(`${RESOURCE_NAME}/get-document-for-print`, {document_id: payload})
    },
    create_xml(payload) {
        return axios.post(`${RESOURCE_NAME}/create-xml`, {document_id: payload}, {responseType: 'blob'})
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    conducted(payload) {
        return axios.post(`${RESOURCE_NAME}/conducted`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { "delete_id": payload })
    },
    select() {
        return axios.get(`${RESOURCE_NAME}/select`)
    },
    get_document_sum(payload) {
        return axios.post(`${RESOURCE_NAME}/get-document-sum`, payload)
    },
    get_all_document_for_print(payload) {
        return axios.post(`${RESOURCE_NAME}/get-all-document-for-print`, payload)
    }
};