import {
    GET_ALL_SERVICE,
    GET_ALL_FLAT_TARIFF,
    GET_ALL_WITH_BALANCE,
    GET_ALL_WITH_BALANCE_LEGACY,
    GET_ARCHIVE,
    CREATE_FLAT_SERVICE,
    CREATE_FLAT_LEGACY_SERVICE,
    UPDATE_FLAT_SERVICE,
    UPDATE_FLAT_LEGACY_SERVICE,
    DELETE_FLAT_SERVICE,
    DELETE_FLAT_LEGACY_SERVICE,
    GET_ARCHIVE_LEGACY, GET_ALL_LEGACY_SERVICE, GET_ALL_LEGACY_FLAT_TARIFF
} from "@/store/actions/flat_service";

import flatServiceAPI from "@/utils/axios/flat_service"

import {ALERT_SHOW} from "@/store/actions/alert";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = {
    flat_services: [],
    flat_all_tariff: [],
    flat_services_with_balance: [],
    flat_balance_archive: [],
    flat_summary: {}
}

const getters = {
    getFlatServices: state => state.flat_services,
    getAllFlatTariff: state => state.flat_all_tariff,
    getFlatServiceWithBalance: state => state.flat_services_with_balance,
    getFlatServiceWithBalanceLegacy: state => state.flat_services_with_balance,
    getFlatArchive: state => state.flat_balance_archive,
    getFlatSummary: state => state.flat_summary
}

const actions = {
    [GET_ALL_SERVICE]: ({ commit }, person_hash) => {
        return new Promise((resolve, reject) => {
            flatServiceAPI.all(person_hash)
                .then(response => response.data)
                .then(data => {
                    commit(GET_ALL_SERVICE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [GET_ALL_LEGACY_SERVICE]: ({ commit }, person_hash) => {
        return new Promise((resolve, reject) => {
            flatServiceAPI.all_legacy(person_hash)
                .then(response => response.data)
                .then(data => {
                    commit(GET_ALL_LEGACY_SERVICE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [GET_ALL_FLAT_TARIFF]: ({ commit }, person_hash) => {
        return new Promise((resolve, reject) => {
            flatServiceAPI.all_tariff(person_hash)
                .then(response => response.data)
                .then(data => {
                    commit(GET_ALL_FLAT_TARIFF, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [GET_ALL_LEGACY_FLAT_TARIFF]: ({ commit }, person_hash) => {
        return new Promise((resolve, reject) => {
            flatServiceAPI.all_tariff_legacy(person_hash)
                .then(response => response.data)
                .then(data => {
                    commit(GET_ALL_LEGACY_FLAT_TARIFF, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [GET_ALL_WITH_BALANCE]: ({ commit }, person_hash) => {
        return new Promise((resolve, reject) => {
            flatServiceAPI.all_with_balance(person_hash)
                .then(response => response.data)
                .then(data => {
                    commit(GET_ALL_WITH_BALANCE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [GET_ALL_WITH_BALANCE_LEGACY]: ({ commit }, person_hash) => {
        return new Promise((resolve, reject) => {
            flatServiceAPI.all_with_balance_legacy(person_hash)
                .then(response => response.data)
                .then(data => {
                    commit(GET_ALL_WITH_BALANCE_LEGACY, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [GET_ARCHIVE]: ({ commit }, person_hash) => {
        return new Promise((resolve, reject) => {
            flatServiceAPI.get_archive(person_hash)
                .then(response => response.data)
                .then(data => {
                    commit(GET_ARCHIVE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [GET_ARCHIVE_LEGACY]: ({ commit }, person_hash) => {
        return new Promise((resolve, reject) => {
            flatServiceAPI.get_archive_legacy(person_hash)
                .then(response => response.data)
                .then(data => {
                    commit(GET_ARCHIVE_LEGACY, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_FLAT_SERVICE]: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatServiceAPI.create(payload)
                .then(response => response.data )
                .then(() => {
                    dispatch(GET_ALL_SERVICE, payload.person_hash)
                    dispatch(GET_ALL_FLAT_TARIFF, payload.person_hash)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CREATE_FLAT_LEGACY_SERVICE]: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatServiceAPI.create_legacy(payload)
                .then(response => response.data )
                .then(() => {
                    dispatch(GET_ALL_LEGACY_SERVICE, payload.person_hash)
                    dispatch(GET_ALL_LEGACY_FLAT_TARIFF, payload.person_hash)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_FLAT_SERVICE]: async ({ commit, dispatch }, payload) => {
        return new Promise(((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatServiceAPI.update(payload)
                .then(response => response.data)
                .then(() => {
                    dispatch(GET_ALL_SERVICE, payload.person_hash)
                    dispatch(GET_ALL_FLAT_TARIFF, payload.person_hash)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        }))
    },
    [UPDATE_FLAT_LEGACY_SERVICE]: async ({ commit, dispatch }, payload) => {
        return new Promise(((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatServiceAPI.update_legacy(payload)
                .then(response => response.data)
                .then(() => {
                    dispatch(GET_ALL_LEGACY_SERVICE, payload.person_hash)
                    dispatch(GET_ALL_LEGACY_FLAT_TARIFF, payload.person_hash)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        }))
    },
    [DELETE_FLAT_SERVICE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatServiceAPI.remove(payload)
                .then(response => response.data)
                .then(() => {
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_FLAT_LEGACY_SERVICE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatServiceAPI.remove_legacy(payload)
                .then(response => response.data)
                .then(() => {
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
}

const mutations = {
    [GET_ALL_SERVICE]: (state, payload) => {
        state.flat_services = payload
    },
    [GET_ALL_LEGACY_SERVICE]: (state, payload) => {
        state.flat_services = payload
    },
    [GET_ALL_FLAT_TARIFF]: (state, payload) => {
        state.flat_all_tariff = payload
    },
    [GET_ALL_LEGACY_FLAT_TARIFF]: (state, payload) => {
        state.flat_all_tariff = payload
    },
    [GET_ALL_WITH_BALANCE]: (state, payload) => {
        state.flat_services_with_balance = payload
        state.flat_summary = state.flat_services_with_balance.reduce((acc, item) =>  {
            acc.start_balance += item.start_balance
            acc.charge += item.charge
            acc.recalculation += item.recalculation
            acc.pay += item.pay
            acc.end_balance += item.end_balance
            return acc
        }, {
            start_balance: 0,
            charge: 0,
            recalculation: 0,
            pay: 0,
            end_balance: 0
        })
    },
    [GET_ALL_WITH_BALANCE_LEGACY]: (state, payload) => {
        let contracts = {}
        const contract_arr = []

        payload.sort((a, b) => {
            if (a.contract_name.toLowerCase() === b.contract_name.toLowerCase()){
                return a.service_name.toLowerCase() < b.service_name.toLowerCase() ? -1 : 1
            } else {
                return a.contract_name.toLowerCase() < b.contract_name.toLowerCase() ? -1 : 1
            }
        })
        payload.forEach((item, index) => {
            if (item.contract_id in contracts) {
                contracts[item.contract_id]['idx'] = index
                contracts[item.contract_id]['contract_name'] = item.contract_name
                contracts[item.contract_id]['start_balance'] += item.start_balance
                contracts[item.contract_id]['charge'] += item.charge
                contracts[item.contract_id]['recalculation'] += item.recalculation
                contracts[item.contract_id]['pay'] += item.pay
                contracts[item.contract_id]['end_balance'] += item.end_balance
                contracts[item.contract_id]['tax'] += item.tax || 0
                contracts[item.contract_id]['to_pay'] += item.to_pay || 0
            } else {
                contracts[item.contract_id] = {
                    idx: index,
                    contract_name: item.contract_name,
                    start_balance: item.start_balance,
                    charge: item.charge,
                    recalculation: item.recalculation,
                    pay: item.pay,
                    end_balance: item.end_balance,
                    tax: item.tax,
                    to_pay: item.to_pay
                }
            }
        })

        if (Object.keys(contracts).length > 1) {
            Object.keys(contracts).forEach(item => {
                contract_arr.push(contracts[item])
            })
            contract_arr.sort((a, b) => a.idx > b.idx ? 1 : -1)

            contract_arr.forEach((item, idx) => {
                const row = item
                row.is_total = true
                const prev_row = contract_arr[idx - 1]
                const add_idx = prev_row ? prev_row.idx : 0

                if (row.idx + 1 <= payload.length - 1) {
                    payload.splice(row.idx + 1 + add_idx, 0, row)
                }
            })
        }

        state.flat_services_with_balance = payload
        state.flat_summary = contract_arr.reduce((acc, item) =>  {
            acc.start_balance += item.start_balance
            acc.charge += item.charge
            acc.recalculation += item.recalculation
            acc.pay += item.pay
            acc.end_balance += item.end_balance
            return acc
        }, {
            start_balance: 0,
            charge: 0,
            recalculation: 0,
            pay: 0,
            end_balance: 0
        })
    },
    [GET_ARCHIVE]: (state, payload) => {
        state.flat_balance_archive = payload
    },
    [GET_ARCHIVE_LEGACY]: (state, payload) => {
        state.flat_balance_archive = payload
    }
}


export default {
    state,
    getters,
    actions,
    mutations
}