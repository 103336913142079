import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/flat';

export default {
    all(payload) {
        let params = {}
        if (payload.type) {
            params.type_q = payload.type
            params.value_q = payload.id
        }
        if (payload.add_id) {
            params.add_id = payload.add_id
        }

        return axios.get(`${RESOURCE_NAME}/`, { params })
    },
    legacy_all() {
        return axios.get(`${RESOURCE_NAME}/legacy`)
    },
    one(payload) {
        return axios.get(`${RESOURCE_NAME}/one/${payload}`)
    },
    legacy_one(payload) {
        return axios.get(`${RESOURCE_NAME}/legacy/one/${payload}`)
    },
    search_by_text(payload) {
        return axios.get(`${RESOURCE_NAME}/search`, {params: { search_text : payload }})
    },
    search_new({text, page}) {
        return axios.get(`${RESOURCE_NAME}/search-new`, {params: { search_text : text, page: page }})
    },
    search_new_legacy({text, page}) {
        return axios.get(`${RESOURCE_NAME}/search-new-legacy`, {params: { search_text : text, page: page }})
    },
    legacy_search_by_text(payload) {
        return axios.get(`${RESOURCE_NAME}/legacy/search`, {params: { search_text : payload }})
    },
    next_item(person_hash, right, city_id=null) {
        return axios.get(`${RESOURCE_NAME}/next-item`, {params: { person_hash : person_hash, right: right, city_id: city_id }})
    },
    next_item_legacy(person_hash, right) {
        return axios.get(`${RESOURCE_NAME}/legacy/next-item`, {params: { person_hash : person_hash, right: right }})
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    legacy_create(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    legacy_update(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/update`, payload)
    },
    select() {
        return axios.post(`${RESOURCE_NAME}/select`, {})
    },
    select_legacy() {
        return axios.post(`${RESOURCE_NAME}/legacy/select`, {})
    },
    get_max_person_id(payload) {
        return axios.post(`${RESOURCE_NAME}/get-max-person-id`, {...payload})
    },
    get_person_id_by_template(payload) {
        return axios.post(`${RESOURCE_NAME}/get-person-id-by-template`, {...payload})
    },
    get_postal_indexes(payload) {
        return axios.post(`${RESOURCE_NAME}/get-postal-index`, {...payload})
    },
    get_legacy_max_person_id(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/get-max-person-id`, {...payload})
    },
    check_person_id_id(payload) {
        return axios.post(`${RESOURCE_NAME}/check-person-id`, payload)
    },
    check_legacy_person_id_id(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/check-person-id`, payload)
    },
    get_person_id_by_flat_number(payload) {
        return axios.post(`${RESOURCE_NAME}/check-person-id`, payload)
    },
    get_legacy_person_id_by_flat_number(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/check-person-id`, payload)
    },
    fill_special() {
        return axios.post(`${RESOURCE_NAME}/fill-special`, {})
    },
    sendPersonalCabinetCode(payload) {
        return axios.post(`${RESOURCE_NAME}/send-personal-cabinet-code`, payload)
    },
    sendPersonalCabinetCodes(payload) {
        return axios.post(`${RESOURCE_NAME}/send-personal-cabinet-codes`, payload)
    },
};