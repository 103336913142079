export const GET_ORGANIZATION = 'GET_ORGANIZATION'
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION'
export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION'
export const GET_ORGANIZATION_PRINT_DATA = 'GET_ORGANIZATION_PRINT_DATA'
export const GET_GENERAL_SETTINGS = "GET_GENERAL_SETTINGS"
export const GET_ACCOUNTING_GENERAL_SETTINGS = "GET_ACCOUNTING_GENERAL_SETTINGS"
export const SET_CURRENT_ORGANIZATION = "SET_CURRENT_ORGANIZATION"

export const UPDATE_INFORMATION_BY_ORGANIZATION = 'UPDATE_INFORMATION_BY_ORGANIZATION'

export const SET_CURRENT_FLAT = "SET_CURRENT_FLAT"

export const FETCH_BASES_BY_SERVICE = "FETCH_BASES_BY_SERVICE"
export const CREATE_BASES_BY_SERVICE = "CREATE_BASES_BY_SERVICE"
export const UPDATE_BASES_BY_SERVICE = "UPDATE_BASES_BY_SERVICE"
export const REMOVE_BASES_BY_SERVICE = "REMOVE_BASES_BY_SERVICE"

export const FETCH_PAYMENTS_BY_PRIORITY = "FETCH_PAYMENTS_BY_PRIORITY"
export const CREATE_PAYMENTS_BY_PRIORITY = "CREATE_PAYMENTS_BY_PRIORITY"
export const UPDATE_PAYMENTS_BY_PRIORITY = "UPDATE_PAYMENTS_BY_PRIORITY"
export const REMOVE_PAYMENTS_BY_PRIORITY = "REMOVE_PAYMENTS_BY_PRIORITY"


