import {GET_PAYMENTS_BY_DOC_HEADER, SET_PAYMENTS_QUERY} from "@/store/actions/payments";

import {ALERT_SHOW} from "@/store/actions/alert";
import paymentPI from "@/utils/axios/payments"

const state = { payments_rows: [], payments_query: {} }

const getters = {
    getPaymentsRow: state => state.payments_rows,
    getPaymentsQuery: state => state.payments_query
}

const actions = {
    [GET_PAYMENTS_BY_DOC_HEADER]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            paymentPI.get_by_header(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_PAYMENTS_BY_DOC_HEADER, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
}

const mutations = {
    [GET_PAYMENTS_BY_DOC_HEADER]: (state, payload) => {
        if (payload) {
            state.payments_rows = payload.map((item, idx) => {
                return Object.assign(item, {row_num: idx + 1})
            })
        } else {
            state.payments_rows = null
        }
    },
    [SET_PAYMENTS_QUERY]: (state, payload) => {
        state.payments_query = payload || {}
    }
}


export default {
    state,
    getters,
    actions,
    mutations
}