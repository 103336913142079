import {QUESTION_SHOW, QUESTION_HIDE, QUESTION_ANSWER, RELOAD_LIST, START_ANSWER_LOADING, STOP_ANSWER_LOADING} from "@/store/actions/question";

const ICON = 'mdi-alert-outline'
const ICON_COLOR = 'error lighten-2'
const SHOW_ACCEPT_BUTTON = false
const TEXT = 'Підтвердіть дію...'

const state = {
    conf: {},
    active: false,
    answer: {},
    reload_list: {},
    global_watcher_loading: false
}

const getters = {
    question_conf: state => state.conf,
    question_status: state => state.active,
    question_answer: state => state.answer,
    reload_list: state => state.reload_list,
    question_watcher_loading: state => state.global_watcher_loading
}

const actions = {
    [QUESTION_SHOW]: ({ commit }, data) => {
        commit(QUESTION_ANSWER, {})
        commit(QUESTION_SHOW, data)
    },
    [QUESTION_HIDE]: ({ commit }) => {
        commit(QUESTION_HIDE)
    },
    [QUESTION_ANSWER]: ({ commit }, data) => {
        commit(QUESTION_ANSWER, data)
    },
    [RELOAD_LIST]: ({ commit }, data) => {
        commit(RELOAD_LIST, data)
    },
    [START_ANSWER_LOADING]: ({ commit }) => {
        commit(START_ANSWER_LOADING)
    },
    [STOP_ANSWER_LOADING]: ({ commit }) => {
        commit(STOP_ANSWER_LOADING)
    },
}

const mutations = {
    [QUESTION_SHOW]: (state, data) => {
        let conf = Object.assign({}, data)
        if (!conf.icon) conf.icon = ICON
        if (!conf.icon_color) conf.icon_color = ICON_COLOR
        if (!conf.accept_button) conf.accept_button = SHOW_ACCEPT_BUTTON
        if (!conf.text) conf.text = TEXT
        state.conf = conf
        state.active = true
    },
    [START_ANSWER_LOADING]: state => {
        state.global_watcher_loading = true
    },
    [STOP_ANSWER_LOADING]: state => {
        state.global_watcher_loading = false
    },
    [QUESTION_HIDE]: state => {
        state.active = false
    },
    [QUESTION_ANSWER]: (state, data) => {
        state.answer = data
    },
    [RELOAD_LIST]: (state, data) => {
        state.reload_list = data
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}