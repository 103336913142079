import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/personal-site-table';

export default {
    get_news() {
        return axios.get(`${RESOURCE_NAME}/news`)
    },
    get_notify() {
        return axios.get(`${RESOURCE_NAME}/notification`)
    },
    get_gallery() {
        return axios.get(`${RESOURCE_NAME}/gallery`)
    },
    create_news(payload) {
        return axios.post(`${RESOURCE_NAME}/news`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
    create_notify(payload) {
        return axios.post(`${RESOURCE_NAME}/notification`, payload)
    },
    create_gallery(payload) {
        return axios.post(`${RESOURCE_NAME}/gallery`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
    update_news(payload) {
        return axios.post(`${RESOURCE_NAME}/news/update`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
    update_notify(payload) {
        return axios.post(`${RESOURCE_NAME}/notification/update`, payload)
    },
    update_gallery(payload) {
        return axios.post(`${RESOURCE_NAME}/gallery/update`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
    remove_news(payload) {
        return axios.post(`${RESOURCE_NAME}/news/remove`, { news_id: payload })
    },
    remove_notify(payload) {
        return axios.post(`${RESOURCE_NAME}/notification/remove`, { notification_id: payload })
    },
    remove_gallery(payload) {
        return axios.post(`${RESOURCE_NAME}/gallery/remove`, { gallery_id: payload })
    },
};