import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/building';

export default {
    all(payload) {
        return axios.get(`${RESOURCE_NAME}/`, {params: payload})
    },
    flat_by_building(payload) {
        return axios.get(`${RESOURCE_NAME}/flats_by_building/${payload}`)
    },
    one(payload) {
        return axios.get(`${RESOURCE_NAME}/${payload}`)
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { building_id: payload })
    },
    search_new({text, parent_id, page}) {
        return axios.post(`${RESOURCE_NAME}/search-new`, { search_text : text, page: page, parent_id: parent_id })
    },
    get_by_id(payload) {
        return axios.post(`${RESOURCE_NAME}/get-by-id`, { elements: payload })
    },
    get_one_by_id(payload) {
        return axios.get(`${RESOURCE_NAME}/one-by-id`, { params: payload })
    },
};