<template>
  <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="date"
      transition="scale-transition"
      offset-y
      min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          v-model="computedDateFormatted"
          :label="label"
          :placeholder="placeholder"
          readonly
          hide-details
          :filled="filled"
          :required="req"
          :rules="required()"
          :disabled="disabled"
          v-bind="attrs"
          v-on="on"
          color="grey"
          :class="class_"
          :clearable="clearable"
          :solo="solo"
      />
    </template>
    <v-date-picker
        v-model="date"
        :type="monthly ? 'month' : 'date'"
        no-title
        scrollable
        :first-day-of-week="1"
        color="secondary"
        min="1910-01-01"
        locale="uk-UA"
    >
      <v-spacer/>
      <v-btn
          depressed text tile color="grey"
          @click="menu = false"
      >
        Відмінити
      </v-btn>
      <v-btn
          depressed text
          color="secondary darken-1"
          @click="saveDate"
      >
        <v-icon small>mdi-calendar-check-outline</v-icon>
        ОК
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>


<script>
import {formatDate} from "@/filters";
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    solo: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    req: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: true
    },
    class_: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    monthly: {
      type: Boolean,
      default: false
    },
    formatString: {
      type: String,
      default: 'DD.MM.YYYY'
    },
    clearable: {
      type: Boolean,
      default: true
    },
    first_day_of_month: {
      type: Boolean,
      default: false
    }
  },
  name: "DateComponent",
  data() {
    return {
      date: this.value,
      menu: false
    }
  },
  methods: {
    saveDate() {
      let date = this.date ?
          this.monthly ? this.date + '-01' :  this.date
          : this.date

      if (!this.monthly && this.first_day_of_month && date) {
        let spl = date.split('-')
        date = spl[0] + '-' + spl[1] + '-01'
      }

      this.$refs.menu.save(date)
      this.$emit('afterSaveDate', date)
      // this.$emit('input', date)
    },
    formatDate1 (date) {
      if (!date) return null
      if (date === undefined) return null

      // const [year, month, day] = date.split('-')
      return this.monthly ? formatDate(date, 'MMMM YYYY') : formatDate(date, this.formatString)
    },
    required() {
      if (this.req) {
        return [v => !!v || 'Це поле є обов’язковим']
      } else {
        return [true]
      }
    }
  },
  computed: {
    computedDateFormatted: {
      get() {
        return this.formatDate1(this.date)
      },
      set () {
        this.date = null
        return null
      }
    }
  },
  watch: {
    date(value) {
      this.$emit('input', value)
      this.date = value
      // this.$emit('change', value)
    },
    value() {
      this.date = this.value
    }
  },
}
</script>

<style scoped lang="scss">
.cs-date {
  flex: 0 0 180px;

  &:deep(.v-input__control) {
    min-height: 36px !important;
  }
}
</style>