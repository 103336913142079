import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/clock';

export default {
    block(payload) {
        return axios.post(`${RESOURCE_NAME}/block`, payload)
    },
    check(payload) {
        return axios.post(`${RESOURCE_NAME}/check`, payload)
    },
    unblock(payload) {
        return axios.post(`${RESOURCE_NAME}/unblock`, payload)
    },
    unblock_all() {
        return axios.post(`${RESOURCE_NAME}/unblock-all`)
    },
};