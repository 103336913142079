<template>
  <nav class="non-printable navigation" v-if="$route.meta.requiresAuth" v-resize="onResizeNew">
    <v-navigation-drawer
        v-model="drawer"
        app
        :temporary="$vuetify.breakpoint.xsOnly"
        :mini-variant="$vuetify.breakpoint.smAndUp"
        class="non-printable white"
        style="z-index: 200;"
        :disable-resize-watcher="true"
        :disable-route-watcher="true"
        :hide-overlay="$vuetify.breakpoint.smAndUp"
        :expand-on-hover="expand_on_hover"
    >

      <v-menu
          :close-on-content-click="true"
          :nudge-width="200"
          bottom
          nudge-bottom="54"
          min-width="300"
          max-width="300"
          style="min-width: 400px !important;"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-list-item style="cursor: pointer; height: 34px" @click.stop="" v-bind="attrs" v-on="on">
            <v-list-item-icon>
              <v-icon color="teal">mdi-account-circle-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle style="font-size: .8rem">
                {{ userName.substr(0, 20) }}
              </v-list-item-subtitle>
              <v-list-item-title style="font-size: .9rem">
                {{ roleName.substr(0, 20) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list nav dense>
          <v-list-item :to="{ name: 'UserProfile' }">
            <v-list-item-avatar color="error lighten-1" size="34">
              <v-icon color="white" size="20">mdi-badge-account-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-uppercase">Профіль користувача</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item :to="{ name: 'PersonalWorkPlace' }" v-if="userFlats.length">
            <v-list-item-avatar color="primary" size="34">
              <v-icon color="white" size="20">mdi-badge-account-outline</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="text-uppercase">Особистий кабінет</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="userFlats.length"></v-divider>
          <v-list-item @click="logout">
            <v-list-item-avatar color="teal" size="34">
              <v-icon color="white" size="20">mdi-location-exit</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="text-uppercase">Вийти із програми</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-divider></v-divider>

      <v-menu
          :close-on-content-click="true"
          :nudge-width="220"
          bottom
          nudge-bottom="54"
          min-width="320"
          max-width="320"
          class="elevation-0"
          style="box-shadow: none !important;"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-list-item style="cursor: pointer; height: 34px" @click.stop="" v-bind="attrs" v-on="on">
            <v-list-item-icon>
              <v-icon color="secondary">mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle style="font-size: .8rem">
                Організація
              </v-list-item-subtitle>
              <v-list-item-title style="font-size: .9rem;" v-if="organization.short_name">
                {{ organization.short_name.substr(0, 20) }}
              </v-list-item-title>
              <v-list-item-title v-else>
                Завантажується....
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list two-line nav dense style="overflow: hidden" class="px-2 pb-1">
          <div style="height: 96px;">
            <v-subheader inset class="ml-0"
                         style="font-size: 0.98rem;
                         text-align: center;
                         width: 100%;
                         justify-content: center;
                         height: 36px;
                         font-weight: 600;
                         padding-bottom: 4px; letter-spacing: 1px"
            >Доступні організації</v-subheader>
            <v-text-field clearable v-model="organization_search"
                          @click.stop="" hide-details color="grey"
                          single-line filled
                          label="Пошук організації"
            />
            <v-divider/>
          </div>
          <div style="height: calc(94vh - 96px); overflow: auto">
            <template v-for="(item, index) in organization_filtered">
              <v-list-item
                  link
                  :key="`org-${index}`"
                  @click="changeOrganization(item.id)"
                  :disabled="item.id === organization.id"
                  :class="item.id === organization.id ? 'organization-disabled' : ''"
                  class="grey lighten-5"
              >
                <v-list-item-avatar color="grey" size="34" class="my-1">
                  <v-icon color="white" size="20">
                    <template v-if="item.send_month">
                      <template v-if="item.current_month === item.send_month">
                        mdi-email-lock
                      </template>
                      <template v-else>
                        mdi-email
                      </template>
                    </template>
                    <template v-else>
                      mdi-subtitles-outline
                    </template>
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="py-1 my-0">
                  <v-list-item-title
                      class="text-uppercase text-wrap grey--text text--darken-3"
                      style="font-size: .8rem"
                  >
                    {{ `${item.short_name} (${item.code})` }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                      class="font-weight-regular"
                      v-if="item.current_month || item.send_month">
                    <div v-if="item.current_month">
                      <span class="font-weight-regular success--text text--darken-1">Місяць: </span>
                      <span class="grey--text text--darken-2">{{ formatDate(item.current_month, 'MMMM YYYY') }}</span>
                    </div>
                    <div v-if="item.send_month">
                      <span class="font-weight-regular success--text text--darken-1">Здано&nbsp;&nbsp;: </span>
                      <span class="grey--text text--darken-2">{{ formatDate(item.send_month, 'MMMM YYYY') }}</span>
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider :key="`div-${index}`"></v-divider>
            </template>
          </div>
        </v-list>
      </v-menu>

      <v-divider></v-divider>

      <v-menu
          :close-on-content-click="true"
          :nudge-width="200"
          bottom
          nudge-bottom="54"
          min-width="300"
          max-width="300"
          class="elevation-0"
          style="box-shadow: none !important;"
          v-if="organization.accounting || organization.dispatcher"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-list-item style="cursor: pointer; height: 34px" @click.stop="" v-bind="attrs" v-on="on">
            <v-list-item-icon>
              <v-icon color="secondary">mdi-format-section</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle style="font-size: .8rem">
                Розділ обліку
              </v-list-item-subtitle>
              <v-list-item-title style="font-size: .9rem">
                {{ panel.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list nav dense>
          <v-subheader inset class="ml-0" style="font-size: 0.85rem">Доступні розділи обліку</v-subheader>
          <v-divider/>
          <v-list-item link v-for="(item, index) in panels"
                       :key="`org-${index}`"
                       @click="changePanel(item)"
                       :disabled="getDisablePanel(item)"
          >
            <v-list-item-avatar color="grey" size="34">
              <v-icon color="white" size="20">{{ item.icon }}</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="text-uppercase">{{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-divider v-if="organization.accounting"></v-divider>

      <v-list nav style="position: relative;">
        <template v-for="(link, index) in links">
          <template v-if="!link.routes">
            <v-list-item
                :key="`nav-${index}`"
                :to="link.route"
                color="success darken-2"
                active-class="nav-active-cls"
            >
              <v-list-item-action>
                <v-badge
                    :content="vote_stats.votes"
                    offset-y="9"
                    offset-x="5"
                    color="teal"
                    class="custom-badge"
                    left
                    overlap
                    v-if="vote_stats.votes && link.badge"
                >
                  <v-icon color="success" size="23">{{ link.icon }}</v-icon>
                </v-badge>
                <v-icon v-else color="success" size="23">{{ link.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title
                    style="font-size: .72rem !important; text-transform: uppercase; letter-spacing: 1px; font-weight: 500"
                >

                  {{ link.short_title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-else>
            <v-list-group
                :key="`s_item-${index}`"
                :prepend-icon="link.icon"
                no-action
                active-class="nav-active-cls"
                color="grey darken-4"
                class="custom-group"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title style="font-size: .8625rem !important;" v-text="link.title"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                  link
                  v-for="(child, index) in link.routes"
                  :key="`child-${index}`"
                  :to="child.route"
                  active-class="nav-active-cls"
              >
                <v-list-item-content>
                  <v-list-item-title style="font-size: .8625rem !important;" v-text="child.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </template>
        </template>
        <v-list-item
            to="/admin/organizations"
            color="success darken-2"
            active-class="nav-active-cls"
            v-if="isSuperUser"
        >
          <v-list-item-action>
            <v-icon color="success" size="23">mdi-layers-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title
                style="font-size: .72rem !important; text-transform: uppercase; letter-spacing: 1px; font-weight: 500"
            >
              Усі організації
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app v-if="$vuetify.breakpoint.xs" dense class="non-printable">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>WeToo - автоматизація обліку</v-toolbar-title>
    </v-app-bar>
  </nav>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {AUTH_LOGOUT, AUTO_LOGIN} from "@/store/actions/auth";
import {USER_SET_ACCOUNTING, USER_SET_DISPATCHER, USER_SET_LEGACY} from "@/store/actions/user"
import {SET_CURRENT_ORGANIZATION} from "@/store/actions/organization";
import {CLEAR_FLATS, FETCH_FLATS} from "@/store/actions/flat";
import {GET_TREE} from "@/store/actions/city";
import {accounting_links} from "@/utils/accounting"
import userAPI from "@/utils/axios/user"
import {GET_CURRENT_LEGACY_MONTH, GET_CURRENT_MONTH} from "@/store/actions/work_month";
import {BALANCE_SHOW} from "@/store/actions/balance_soap";
import {formatDate} from "@/filters";
import {CLEAR_ALL_ACCOUNTING_LOADING} from "@/store/actions/accounting/list";
// import {requestPermission, SERVER_PUBLIC_KEY, subscribeToPush} from "@/utils/icons";
// import pushNotification from "@/utils/axios/puhs_notification";


export default {
  name: "NavDrawer",
  data() {
    return {
      organization_search: '',
      windowSize: {
        x: 0,
        y: 0,
      },
      organization_loading: false,
      panel: null,
      brk: '',
      drawer: !this.$vuetify.breakpoint.xs,
      temporary: this.$vuetify.breakpoint.xs,
      mini: false,
      mini_change_pressed: null,
      custom: false,
      house_pay_links: [
        {title: 'Робочий стіл', short_title: 'Робочий стіл', icon: 'mdi-monitor-dashboard', route: '/', roles: ['all']},
        {
          title: 'Статистика',
          short_title: 'Статистика',
          icon: 'mdi-chart-timeline',
          route: '/dashboard',
          roles: ['all']
        },
        {
          title: 'Послуги',
          short_title: 'Послуги',
          icon: 'mdi-view-grid-outline',
          route: '/services',
          roles: ['admin', 'superuser']
        },
        {
          title: 'Геоніми',
          short_title: 'Геоніми',
          icon: 'mdi-city-variant-outline',
          route: '/streets',
          roles: ['admin', 'superuser']
        },
        {
          title: 'Тарифи',
          short_title: 'Тарифи',
          icon: 'mdi-currency-usd',
          route: '/tariff-organization',
          roles: ['admin', 'superuser']
        },
        {
          title: 'Нормативи',
          short_title: 'Нормативи',
          icon: 'mdi-card-bulleted-settings-outline',
          route: '/regulars',
          roles: ['admin', 'superuser']
        },
        {
          title: 'Розсилка',
          short_title: 'Розсилка',
          icon: 'mdi-message-text-clock-outline',
          route: '/send-media',
          roles: ['all']
        },
        {
          title: 'Оповіщення',
          short_title: 'Оповіщення',
          icon: 'mdi-vote-outline',
          route: '/voting',
          roles: ['all'],
          badge: true
        },
        {title: 'Звернення', short_title: 'Звернення', icon: 'mdi-archive-outline', route: '/appeal', roles: ['all']},
        {
          title: 'Планування',
          short_title: 'Планування',
          icon: 'mdi-office-building-cog-outline',
          route: '/estimate',
          roles: ['all']
        },
        {
          title: 'Адміністрування',
          short_title: 'Адміністрування',
          icon: 'mdi-newspaper-variant',
          route: '/site-profile',
          roles: ['admin', 'superuser']
        },
        {
          title: this.simple_user ? 'Профіль' : 'Користувачі',
          short_title: this.simple_user ? 'Профіль' : 'Користувачі',
          icon: 'mdi-account-cog-outline',
          route: '/users-management',
          roles: ['admin', 'superuser']
        },
      ],
      dispatcher_links: [
        {title: 'Робочий стіл', short_title: 'Робочий стіл', icon: 'mdi-monitor-dashboard', route: '/dispatcher-call', roles: ['all']},
      ],
      legacy_links: [
        {
          title: 'Робочий стіл',
          short_title: 'Робочий стіл',
          icon: 'mdi-monitor-dashboard',
          route: '/legacy-desktop',
          roles: ['all']
        },
        {
          title: 'Послуги',
          short_title: 'Послуги',
          icon: 'mdi-view-grid-outline',
          route: '/services',
          roles: ['admin', 'superuser']
        },
        {
          title: 'Геоніми',
          short_title: 'Геоніми',
          icon: 'mdi-city-variant-outline',
          route: '/streets',
          roles: ['admin', 'superuser']
        },
        {
          title: 'Тарифи',
          short_title: 'Тарифи',
          icon: 'mdi-currency-usd',
          route: '/tariff-organization',
          roles: ['admin', 'superuser']
        },
        {
          title: 'Нормативи',
          short_title: 'Нормативи',
          icon: 'mdi-card-bulleted-settings-outline',
          route: '/regulars',
          roles: ['admin', 'superuser']
        },
        {
          title: this.simple_user ? 'Профіль' : 'Користувачі',
          short_title: this.simple_user ? 'Профіль' : 'Користувачі',
          icon: 'mdi-account-cog-outline',
          route: '/users-management',
          roles: ['admin', 'superuser']
        },
      ],
      accounting_links,
      panels: [
        {id: 1, icon: 'mdi-office-building-outline', name: 'Квартирний облік', color: 'teal'},
        {id: 3, icon: 'mdi-file-document-multiple-outline', name: 'Юридичні особи', color: 'teal'},
        {id: 2, icon: 'mdi-bookshelf', name: 'Бухгалтерський облік', color: 'teal'},
        {id: 4, icon: 'mdi-phone-incoming-outgoing', name: 'Диспетчерська', color: 'teal'},
      ]
    }
  },
  methods: {
    ...mapActions({
      fetchFlats: FETCH_FLATS,
      fetchTree: GET_TREE,
      setOrganization: SET_CURRENT_ORGANIZATION,
    }),
    formatDate,
    // afterServiceWorkerRegistered() {
    //   navigator.serviceWorker.getRegistration()
    //       .then(registration => {
    //         requestPermission()
    //             .then(permission => {
    //               if (permission === 'granted') {
    //                 subscribeToPush(registration, SERVER_PUBLIC_KEY, pushNotification);
    //               } else {
    //                 console.log('permission not granted')
    //               }
    //             })
    //       })
    //
    //
    // },
    changeOrganization(payload) {
      this.organization_loading = true
      this.setOrganization(payload)
          .then(response => {
            if (response) {
              this.$store.dispatch(AUTO_LOGIN)
                  .then(() => {
                    this.$store.dispatch(CLEAR_ALL_ACCOUNTING_LOADING)
                    this.organization_loading = false
                    if (this.$route.name !== 'Desktop') {
                      this.$router.push({name: 'Desktop'})
                    } else {
                      this.fetchTree()
                          .then(() => {
                            const first = this.tree[0] || undefined
                            if (!first) {
                              this.$store.state.tree = []
                              this.$store.commit(CLEAR_FLATS)
                            } else {
                              const payload = {
                                id: first.obj_id,
                                type: first.type
                              }
                              this.fetchFlats(payload)
                              this.$store.dispatch(BALANCE_SHOW)
                            }

                            this.afterServiceWorkerRegistered()
                          })
                    }
                  })
            }
          })
          .catch(() => {
            this.organization_loading = false
          })
    },
    onResizeNew() {
      if (this.$vuetify.breakpoint.xs) {
        if (this.$vuetify.breakpoint.name !== this.brk) {
          this.drawer = false
        }
      } else {
        this.drawer = true
      }
      this.brk = this.$vuetify.breakpoint.name
      this.$store.dispatch("SET_SCREEN_DATA", {width: window.innerWidth, height: window.innerHeight})
    },
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight}

      if (this.$vuetify.breakpoint.name !== this.brk) {
        if (this.windowSize.x >= 100 && this.windowSize.x <= 640) {
          this.mini = false
          this.mini_change_pressed = undefined
          this.temporary = true
        }

        if (this.windowSize.x >= 641 && this.windowSize.x <= 1151) {
          if (this.mini_change_pressed === false) {
            this.mini = true
          }

          if (this.mini_change_pressed === true) {
            this.mini = true
          }

          if (this.mini_change_pressed === null) {
            this.mini = true
          }

          this.temporary = false
          this.drawer = true
        }

        if (this.windowSize.x >= 1152 && this.windowSize.x <= 999999) {

          if (this.mini_change_pressed === false) {
            this.mini = false
          }

          if (this.mini_change_pressed === true) {
            this.mini = true
          }

          if (this.mini_change_pressed === null) {
            this.mini = false
          }

          this.temporary = false
          this.drawer = true
        }

        this.brk = this.$vuetify.breakpoint.name
      }


      //
      // switch (this.$vuetify.breakpoint.name) {
      //   case "xs":
      //     this.mini = false
      //     this.drawer = false
      //     this.temporary = true
      //     break
      //   case "sm":
      //     this.mini = true
      //     this.drawer = true
      //     this.temporary = false
      //     break
      //   case "md":
      //     this.mini = false
      //     this.drawer = true
      //     this.temporary = false
      //     break
      //   case "lg":
      //     this.mini = false
      //     this.drawer = true
      //     this.temporary = false
      //     break
      //   case "xl":
      //     this.mini = false
      //     this.drawer = true
      //     this.temporary = false
      //     break
      // }
    },
    changePanel(item) {
      const accounting = item.id === 2
      const legacy = item.id === 3
      const dispatcher = item.id === 4

      if (legacy || accounting) {
        if (legacy) {
          userAPI.set_legacy(legacy)
              .then(() => {
                this.panel = item
                this.$store.dispatch(GET_CURRENT_LEGACY_MONTH)
                    .then(() => {
                      this.$store.commit(USER_SET_LEGACY, legacy)
                      this.$store.commit(USER_SET_ACCOUNTING, false)
                      this.$store.commit(USER_SET_DISPATCHER, false)
                      this.$router.push({name: 'afterLogin'}).catch(() => {
                      })
                    })
              })
        }
        if (accounting) {
          userAPI.set_accounting(accounting)
              .then(() => {
                this.panel = item
                this.$store.commit(USER_SET_ACCOUNTING, accounting)
                this.$store.commit(USER_SET_LEGACY, false)
                this.$store.commit(USER_SET_DISPATCHER, false)
                this.$router.push({name: 'afterLogin'}).catch(() => {
                })
              })
        }
      } else {
        if (dispatcher) {
          userAPI.set_dispatcher(dispatcher)
              .then(() => {
                this.panel = item
                this.$store.dispatch(GET_CURRENT_MONTH)
                    .then(() => {
                      this.$store.commit(USER_SET_DISPATCHER, dispatcher)
                      this.$store.commit(USER_SET_ACCOUNTING, false)
                      this.$store.commit(USER_SET_LEGACY, false)
                      this.$router.push({name: 'afterLogin'}).catch(() => {
                      })
                    })
              })
        } else {
          userAPI.set_accounting(false)
              .then(() => {
                this.panel = item
                this.$store.dispatch(GET_CURRENT_MONTH)
                    .then(() => {
                      this.$store.commit(USER_SET_ACCOUNTING, false)
                      this.$store.commit(USER_SET_LEGACY, false)
                      this.$store.commit(USER_SET_DISPATCHER, false)
                      this.$router.push({name: 'afterLogin'}).catch(() => {
                      })
                    })
              })
        }

      }

    },
    logout() {
      this.$store.dispatch(AUTH_LOGOUT)
          .then(() => {
            this.$router.push({name: 'Login'})
          })
    },
    getDisablePanel(item) {
      if (item.id === 2) {
        return !this.organization.accounting
      }
      if (item.id === 3) {
        return !this.organization.legacy
      }
      if (item.id === 4) {
        return !this.organization.dispatcher
      }
      return false
    }
  },
  computed: {
    ...mapGetters({
      organizations: 'getOrganizations',
      organization: 'getCurrentOrganization',
      loading: 'get_loading',
      tree: 'getTree',
      isSuperUser: 'isSuperUser',
      isAdmin: 'isAdmin',
      accounting: 'isAccounting',
      dispatcher: 'isDispatcher',
      legacy: 'isLegacy',
      simple_user: 'simple_user',
      roleName: 'getProfileName',
      userName: 'getUserName',
      userFlats: 'getUserFlats',
      vote_stats: 'getVoteStats',
      settings: 'getGeneralSettings',
    }),
    expand_on_hover() {
      return this.$vuetify.breakpoint.smAndUp
    },
    organization_filtered() {
      if (this.organization_search) {
        return (this.organizations || [])
            .filter(i => i.short_name.toLowerCase().indexOf(this.organization_search.toLowerCase()) !== -1)
      } else {
        return (this.organizations || []).slice(0, 9)
      }
    },
    // panels() {
    //   const local_panels = [
    //     {id: 1, icon: 'mdi-office-building-outline', name: 'Квартирний облік', color: 'teal'}
    //   ]
    //   if (this.organization.legacy) {
    //     local_panels.push({id: 3, icon: 'mdi-file-document-multiple-outline', name: 'Юридичні особи', color: 'teal'})
    //   }
    //   if (this.organization.accounting) {
    //     local_panels.push({id: 2, icon: 'mdi-bookshelf', name: 'Бухгалтерський облік', color: 'teal'})
    //   }
    //   if (this.organization.dispatcher) {
    //     local_panels.push({id: 4, icon: 'mdi-phone-incoming-outgoing', name: 'Диспетчерська', color: 'teal'})
    //   }
    //   return local_panels
    // },
    links() {
      if (this.accounting) {
        return accounting_links
      } else {
        const superuser = this.isSuperUser ? 'superuser' : ''
        const simple_user = this.simple_user ? 'simple_user' : ''
        const admin_user = this.isAdmin ? 'admin' : ''
        const local_links = this.house_pay_links.filter(item =>
            item.roles.includes('all')
            || item.roles.includes(superuser)
            || item.roles.includes(admin_user)
            || item.roles.includes(simple_user))
        const local_legacy_links = this.legacy_links.filter(item =>
            item.roles.includes('all')
            || item.roles.includes(superuser)
            || item.roles.includes(admin_user)
            || item.roles.includes(simple_user))
        const local_dispatcher_links = this.dispatcher_links.filter(item =>
            item.roles.includes('all')
            || item.roles.includes(superuser)
            || item.roles.includes(admin_user)
            || item.roles.includes(simple_user))


        const exclude = []
        if (this.legacy) {
          return local_legacy_links.filter(item => {
            return !exclude.includes(item.route)
          })
        } else {
          if (this.dispatcher) {
            return local_dispatcher_links.filter(item => {
              return !exclude.includes(item.route)
            })
          } else {
            const desktop_show_media_send = (this.settings?.desktop_show_media_send || {}).value || null
            const desktop_show_voting = (this.settings?.desktop_show_voting || {}).value || null
            const desktop_show_appeal = (this.settings?.desktop_show_appeal || {}).value || null
            const desktop_show_stats = (this.settings?.desktop_show_stats || {}).value || null
            const desktop_show_planing = (this.settings?.desktop_show_planing || {}).value || null
            const desktop_show_administration = (this.settings?.desktop_show_administration || {}).value || null
            if (!desktop_show_stats) {
              exclude.push('/dashboard')
            }
            if (!desktop_show_media_send) {
              exclude.push('/send-media')
            }
            if (!desktop_show_voting) {
              exclude.push('/voting')
            }
            if (!desktop_show_appeal) {
              exclude.push('/appeal')
            }
            if (!desktop_show_planing) {
              exclude.push('/estimate')
            }
            if (!desktop_show_administration) {
              exclude.push('/site-profile')
            }
            return local_links.filter(item => {
              return !exclude.includes(item.route)
            })
          }
        }
      }
    }
  },
  watch: {
    accounting: {
      immediate: true,
      handler(payload) {
        if (payload) {
          this.panel = this.panels[2]
        } else {
          if (this.legacy) {
            this.panel = this.panels[1]
          } else {
            if (this.dispatcher) {
              this.panel = this.panels[3]
            } else {
              this.panel = this.panels[0]
            }
          }
        }
      }
    },
    legacy: {
      immediate: true,
      handler(payload) {
        if (payload) {
          this.panel = this.panels[1]
        } else {
          if (this.accounting) {
            this.panel = this.panels[2]
          } else {
            if (this.dispatcher) {
              this.panel = this.panels[3]
            } else {
              this.panel = this.panels[0]
            }
          }
        }
      }
    },
    dispatcher: {
      immediate: true,
      handler(payload) {
        if (payload) {
          this.panel = this.panels[3]
        } else {
          if (this.accounting) {
            this.panel = this.panels[2]
          } else {
            if (this.legacy) {
              this.panel = this.panels[1]
            } else {
              this.panel = this.panels[0]
            }
          }
        }
      }
    }
  },
  created() {
    this.onResizeNew()
  },
  mounted() {
    this.onResize()
  }
}
</script>

<style scoped lang="scss">
.organization-disabled {
  background: #efefef !important;

  .l-title {
    color: #2d2d2d;
  }
}

.navigation {
  &:deep(.v-list-item__icon) {
    margin: 10px 0 !important;
  }

  &:deep(.v-list-item__icon:first-child) {
    margin-right: 32px !important;
  }

  &:deep(.v-list-item__action) {
    margin: 10px 0 !important;
  }

  &:deep(.v-list-item__action:first-child) {
    margin-right: 32px !important;
  }

  &:deep(.v-list-item__content) {
    padding: 6px 0 !important;
  }

  &:deep(.v-list-item) {
    min-height: 43px !important;
  }
}
</style>
