import {axios} from '@/utils/axios';

const RESOURCE_NAME = '/accounting/documents/work-transfer';

export default {
    get_document_by_id(id) {
        return axios.post(`${RESOURCE_NAME}/get-document-by-id`, {document_id: id})
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, {"delete_id": payload})
    },
    select(payload) {
        return axios.get(`${RESOURCE_NAME}/select`, payload)
    },
    get_accruals(payload) {
        return axios.get(`${RESOURCE_NAME}/accruals`, { params: { header_id: payload } })
    },
    last_accrual(payload) {
        return axios.get(`${RESOURCE_NAME}/last-accrual`, { params: payload })
    },
    previous_data(payload) {
        return axios.get(`${RESOURCE_NAME}/previous-data`, { params: payload })
    },
    conducted(payload) {
        return axios.post(`${RESOURCE_NAME}/conducted`, payload)
    },
};