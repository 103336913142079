<template>
  <v-dialog :value="show_question"
            persistent
            max-width="500"
  >
    <v-card>
      <v-card-text class="pt-6 mb-3 py-0">
        <v-row>
          <v-col cols="3" md="3" class="text-center">
            <v-icon :color="conf.icon_color" size="74">
              {{ conf.icon }}
            </v-icon>
          </v-col>
          <v-col cols="9" md="9" class="subtitle-1">
            <h4 class="font-weight-bold text-uppercase" style="line-height: 1.24rem; font-size: 1.2rem; letter-spacing: 1px;">Увага!</h4>
            <p class="py-0 mb-0 mt-4" style="line-height: 1.36;">{{ conf.text }}</p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer/>
        <v-btn v-if="conf.accept_button" small text @click="closeModal(true)">Підтвердити</v-btn>
        <v-btn small text @click="closeModal(false)">Закрити</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {QUESTION_ANSWER, QUESTION_HIDE} from "@/store/actions/question";
import {mapGetters} from "vuex"

export default {
  name: "GeneralQuestion",
  computed: {
    ...mapGetters({
      show_question: 'question_status',
      conf: 'question_conf'
    })
  },
  methods: {
    closeModal(accept) {
      this.$store.dispatch(QUESTION_HIDE)
      this.$store.dispatch(QUESTION_ANSWER, { id: this.conf.id, answer: accept, payload: this.conf.payload })
    }
  }
}
</script>

<style scoped>

</style>