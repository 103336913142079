import {axios} from '@/utils/axios';

const RESOURCE_NAME = '/flat-counter';

export default {
    get_counter_model(payload=false) {
        return axios.get(`${RESOURCE_NAME}/model`, {params: {building: payload}})
    },
    get_flat_counter(payload) {
        return axios.get(`${RESOURCE_NAME}/`, {params: {flat_id: payload}})
    },
    get_flat_counter_operation(payload) {
        return axios.get(`${RESOURCE_NAME}/counter-operation`, {params: {flat_id: payload}})
    },
    get_flat_counter_by_id(payload) {
        return axios.get(`${RESOURCE_NAME}/counter-current-reading`, {
            params: {
                flat_id: payload.flat_id,
                flat_counter_id: payload.flat_counter_id,
                month: payload.month
            }
        })
    },
    get_flat_counter_sub_account(payload) {
        return axios.get(`${RESOURCE_NAME}/sub-account`, {params: payload})
    },
    get_flat_counter_reading(payload) {
        return axios.get(`${RESOURCE_NAME}/counter-reading`, {params: {flat_id: payload}})
    },
    create_counter_model(payload) {
        return axios.post(`${RESOURCE_NAME}/model`, payload)
    },
    create_flat_counter(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    create_counter_operation(payload) {
        return axios.post(`${RESOURCE_NAME}/counter-operation`, payload)
    },
    create_counter_sub_account(payload) {
        return axios.post(`${RESOURCE_NAME}/sub-account`, payload)
    },
    create_flat_counter_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy`, payload)
    },
    create_flat_counter_reading(payload) {
        return axios.post(`${RESOURCE_NAME}/counter-reading`, payload)
    },
    create_flat_counter_reading_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/counter-reading`, payload)
    },
    update_counter_model(payload) {
        return axios.post(`${RESOURCE_NAME}/model/update`, payload)
    },
    update_flat_counter(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    update_counter_operation(payload) {
        return axios.post(`${RESOURCE_NAME}/counter-operation/update`, payload)
    },
    update_counter_sub_account(payload) {
        return axios.post(`${RESOURCE_NAME}/sub-account/update`, payload)
    },
    update_flat_counter_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/update`, payload)
    },
    remove_counter_model(payload) {
        return axios.post(`${RESOURCE_NAME}/model/remove`, {"model_id": payload})
    },
    remove_flat_counter(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, {"flat_counter_id": payload})
    },
    remove_counter_operation(payload) {
        return axios.post(`${RESOURCE_NAME}/counter-operation/remove`, {"flat_counter_operation_id": payload})
    },
    remove_counter_sub_account(payload) {
        return axios.post(`${RESOURCE_NAME}/sub-account/remove`, {"flat_counter_sub_account_id": payload})
    },
    remove_flat_counter_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/remove`, {"flat_counter_id": payload})
    },
    remove_flat_counter_reading(payload, calc, cabinet) {
        return axios.post(`${RESOURCE_NAME}/counter-reading/remove`, {"flat_counter_value_id": payload, "calc": calc, "cabinet": cabinet})
    },
    remove_flat_counter_reading_legacy(payload, calc) {
        return axios.post(`${RESOURCE_NAME}/legacy/counter-reading/remove`, {"flat_counter_value_id": payload, "calc": calc})
    },
    select_counter_model(payload) {
        return axios.post(`${RESOURCE_NAME}/model/select`, { building: payload })
    }
};