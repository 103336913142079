import {
    FETCH_TARIFF_BY_BUILDING,
    CREATE_TARIFF_BY_BUILDING,
    UPDATE_TARIFF_BY_BUILDING,
    DELETE_TARIFF_BY_BUILDING,
} from "@/store/actions/tariff_by_building";

import tarByBuildAPI from "@/utils/axios/tariff_by_building"

import {ALERT_SHOW} from "@/store/actions/alert";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = {tar_by_building: []}

const getters = {
    getTarByBuild: state => state.tar_by_building,
}

const transform_data = (data) => {
    let group_data = []

    if (!data) return undefined

    if (Array.isArray(data)) {
        data.map(item => {
            let f = group_data.find(i => i.building_id === item.building_id)
            if (!f) {
                group_data.push(
                    {
                        building_id: item.building_id,
                        address: item.full_address,
                        services: [item.icon],
                        data: [item]
                    }
                )
            } else {
                f.data.push(item)
                if (f.services.indexOf(item.icon) === -1) {
                    f.services.push(item.icon)
                }
            }
        })
    } else {
        group_data.push(
            {
                building_id: data.building_id,
                address: data.full_address,
                services: [data.icon],
                data: [data]
            }
        )
    }


    return group_data
}

const actions = {
    [FETCH_TARIFF_BY_BUILDING]: ({commit}, payload = {}) => {
        return new Promise((resolve, reject) => {
            tarByBuildAPI.get(payload)
                .then(response => response.data)
                .then(data => {
                    const group_data = transform_data(data)
                    commit(FETCH_TARIFF_BY_BUILDING, group_data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [CREATE_TARIFF_BY_BUILDING]: ({commit, dispatch}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            tarByBuildAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    dispatch(FETCH_TARIFF_BY_BUILDING, {is_legacy: data?.is_legacy || false})
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_TARIFF_BY_BUILDING]: async ({commit}, payload) => {
        return new Promise(((resolve, reject) => {
            commit(START_MODAL_LOADING)
            tarByBuildAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    // const group_data = transform_data(data)
                    commit(UPDATE_TARIFF_BY_BUILDING, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        }))
    },
    [DELETE_TARIFF_BY_BUILDING]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            tarByBuildAPI.remove(payload.id)
                .then(response => response.data)
                .then(() => {
                    commit(DELETE_TARIFF_BY_BUILDING, payload)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
}

const mutations = {
    [FETCH_TARIFF_BY_BUILDING]: (state, payload) => {
        state.tar_by_building = payload
    },
    [CREATE_TARIFF_BY_BUILDING]: (state, payload) => {
        state.tar_by_building.push(payload)
    },
    [UPDATE_TARIFF_BY_BUILDING]: (state, payload) => {
        let obj = state.tar_by_building.find(item => item.building_id === payload.building_id)
        console.log(payload)
        if (obj) {
            let service_item = obj.data.find(s_item => s_item.id === payload.id)
            Object.keys(payload).forEach(item => {
                service_item[item] = payload[item]
            })
        }
    },
    [DELETE_TARIFF_BY_BUILDING]: (state, payload) => {
        let obj = state.tar_by_building.find(item => item.building_id === payload.building_id)
        if (obj) {
            let service_item = obj.data.find(s_item => s_item.id === payload.id)
            const service_item_id = obj.data.indexOf(service_item)
            if (service_item) {
                obj.data.splice(service_item_id, 1)
            }
            if (obj.data.length === 0) {
                const obj_id = state.tar_by_building.indexOf(obj)
                state.tar_by_building.splice(obj_id, 1)
            }
        }

    },
}


export default {
    state,
    getters,
    actions,
    mutations
}