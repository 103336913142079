import {START_LOADING, STOP_LOADING, START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = {
    global_loading: false,
    global_modal_loading: false
}

const getters = {
    get_loading: state => state.global_loading,
    get_modal_loading: state => state.global_modal_loading
}

const actions = {
    [START_LOADING]: ({ commit }) => {
        commit(START_LOADING)
    },
    [STOP_LOADING]: ({ commit }) => {
        commit(STOP_LOADING)
    },
    [START_MODAL_LOADING]: ({ commit }) => {
        commit(START_MODAL_LOADING)
    },
    [STOP_MODAL_LOADING]: ({ commit }) => {
        commit(STOP_MODAL_LOADING)
    },
}

const mutations = {
    [START_LOADING]: (state) => {
        state.global_loading = true
    },
    [STOP_LOADING]: state => {
        state.global_loading = false
    },
    [START_MODAL_LOADING]: (state) => {
        state.global_modal_loading = true
    },
    [STOP_MODAL_LOADING]: state => {
        state.global_modal_loading = false
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}