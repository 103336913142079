import {
    FETCH_PRIVILEGE_OWNER,
    FETCH_PRIVILEGE_PERSONS,
    FETCH_PRIVILEGE_CATEGORIES,
    CREATE_PRIVILEGE_OWNER,
    CREATE_PRIVILEGE_PERSONS,
    CREATE_PRIVILEGE_CATEGORIES,
    UPDATE_PRIVILEGE_PERSONS,
    UPDATE_PRIVILEGE_OWNER,
    UPDATE_PRIVILEGE_CATEGORIES,
    REMOVE_PRIVILEGE_OWNER,
    REMOVE_PRIVILEGE_PERSONS,
    REMOVE_PRIVILEGE_CATEGORIES, SET_SELECTED_PRIVILEGE, CLEAR_SELECTED_PRIVILEGE
} from "@/store/actions/privilege";

import {ALERT_SHOW} from "@/store/actions/alert";
import privilegeAPI from "@/utils/axios/privilege"
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = {privilege_categories: [], privilege_owners: [], privilege_persons: [], selected_privilege: {}}

const getters = {
    getPrivilegeCategories: state => state.privilege_categories,
    getPrivilegeOwners: state => state.privilege_owners,
    getPrivilegePersons: state => state.privilege_persons,
    getPrivilegeSelected: state => state.selected_privilege
}

const actions = {
    [FETCH_PRIVILEGE_CATEGORIES]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            privilegeAPI.get_categories(payload)
                .then(response => response.data)
                .then(data => {
                    commit(FETCH_PRIVILEGE_CATEGORIES, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [FETCH_PRIVILEGE_OWNER]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            privilegeAPI.get_privilege_owners(payload)
                .then(response => response.data)
                .then(data => {
                    commit(FETCH_PRIVILEGE_OWNER, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [FETCH_PRIVILEGE_PERSONS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            privilegeAPI.get_privilege_persons(payload)
                .then(response => response.data)
                .then(data => {
                    commit(FETCH_PRIVILEGE_PERSONS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [CREATE_PRIVILEGE_CATEGORIES]: ({commit}, payload) => {
        commit(START_MODAL_LOADING)
        return new Promise((resolve, reject) => {
            privilegeAPI.create_category(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_PRIVILEGE_CATEGORIES, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CREATE_PRIVILEGE_OWNER]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            privilegeAPI.create_privilege_owner(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_PRIVILEGE_OWNER, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CREATE_PRIVILEGE_PERSONS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            privilegeAPI.create_privilege_persons(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_PRIVILEGE_PERSONS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_PRIVILEGE_CATEGORIES]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            privilegeAPI.update_category(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_PRIVILEGE_CATEGORIES, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_PRIVILEGE_OWNER]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            privilegeAPI.update_privilege_owner(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_PRIVILEGE_OWNER, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_PRIVILEGE_PERSONS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            privilegeAPI.update_persons(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_PRIVILEGE_PERSONS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_PRIVILEGE_CATEGORIES]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            privilegeAPI.remove_category(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_PRIVILEGE_CATEGORIES, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_PRIVILEGE_OWNER]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            privilegeAPI.remove_privilege_owner(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_PRIVILEGE_OWNER, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_PRIVILEGE_PERSONS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            privilegeAPI.remove_persons(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_PRIVILEGE_PERSONS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
}

const mutations = {
    [FETCH_PRIVILEGE_CATEGORIES]: (state, payload) => {
        state.privilege_categories = payload
    },
    [FETCH_PRIVILEGE_OWNER]: (state, payload) => {
        state.privilege_owners = payload
    },
    [FETCH_PRIVILEGE_PERSONS]: (state, payload) => {
        state.privilege_persons = payload
    },
    [CREATE_PRIVILEGE_CATEGORIES]: (state, payload) => {
        state.privilege_categories.push(payload)
    },
    [CREATE_PRIVILEGE_OWNER]: (state, payload) => {
        state.privilege_owners.push(payload)
    },
    [CREATE_PRIVILEGE_PERSONS]: (state, payload) => {
        state.privilege_persons.push(payload)
    },
    [UPDATE_PRIVILEGE_CATEGORIES]: (state, payload) => {
        const obj = state.privilege_categories.find(item => item.id === payload.id)

        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [UPDATE_PRIVILEGE_OWNER]: (state, payload) => {
        const obj = state.privilege_owners.find(item => item.id === payload.id)

        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [UPDATE_PRIVILEGE_PERSONS]: (state, payload) => {
        const obj = state.privilege_persons.find(item => item.id === payload.id)

        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_PRIVILEGE_CATEGORIES]: (state, payload) => {
        const obg = state.privilege_categories.find(item => item.id === payload)
        const obj_id = state.privilege_categories.indexOf(obg)
        if (obg) {
            state.privilege_categories.splice(obj_id, 1)
        }
    },
    [REMOVE_PRIVILEGE_OWNER]: (state, payload) => {
        const obg = state.privilege_owners.find(item => item.id === payload)
        const obj_id = state.privilege_owners.indexOf(obg)
        if (obg) {
            state.privilege_owners.splice(obj_id, 1)
        }
    },
    [REMOVE_PRIVILEGE_PERSONS]: (state, payload) => {
        const obg = state.privilege_persons.find(item => item.id === payload)
        const obj_id = state.privilege_persons.indexOf(obg)
        if (obg) {
            state.privilege_persons.splice(obj_id, 1)
        }
    },
    [SET_SELECTED_PRIVILEGE]: (state, payload) => {
        state.selected_privilege = payload
    },
    [CLEAR_SELECTED_PRIVILEGE]: (state) => {
        state.selected_privilege = {}
        state.privilege_persons = []
    }
}


export default {
    state,
    getters,
    actions,
    mutations
}
