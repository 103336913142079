export const GET_WAREHOUSE_LIST_ELEMENTS = "GET_WAREHOUSE_LIST_ELEMENTS"
export const CREATE_WAREHOUSE_LIST_ELEMENTS = "CREATE_WAREHOUSE_LIST_ELEMENTS"
export const UPDATE_WAREHOUSE_LIST_ELEMENTS = "UPDATE_WAREHOUSE_LIST_ELEMENTS"
export const REMOVE_WAREHOUSE_LIST_ELEMENTS = "REMOVE_WAREHOUSE_LIST_ELEMENTS"
export const SELECT_WAREHOUSE_LIST_ELEMENTS = "SELECT_WAREHOUSE_LIST_ELEMENTS"

export const CLEAR_ALL_ACCOUNTING_LOADING = 'CLEAR_ALL_ACCOUNTING_LOADING'

export const GET_NOMENCLATURE_LIST_GROUP = "GET_NOMENCLATURE_LIST_GROUP"
export const GET_NOMENCLATURE_LIST_ELEMENTS = "GET_NOMENCLATURE_LIST_ELEMENTS"
export const CREATE_NOMENCLATURE_LIST_GROUP = "CREATE_NOMENCLATURE_LIST_GROUP"
export const CREATE_NOMENCLATURE_LIST_ELEMENTS = "CREATE_NOMENCLATURE_LIST_ELEMENTS"
export const UPDATE_NOMENCLATURE_LIST_GROUP = "UPDATE_NOMENCLATURE_LIST_GROUP"
export const UPDATE_NOMENCLATURE_LIST_ELEMENTS = "UPDATE_NOMENCLATURE_LIST_ELEMENTS"
export const REMOVE_NOMENCLATURE_LIST_GROUP = "REMOVE_NOMENCLATURE_LIST_GROUP"
export const REMOVE_NOMENCLATURE_LIST_ELEMENTS = "REMOVE_NOMENCLATURE_LIST_ELEMENTS"
export const SELECT_NOMENCLATURE_LIST_ELEMENTS = "SELECT_NOMENCLATURE_LIST_ELEMENTS"

export const GET_DISPATCHER_PROBLEM_LIST_GROUP = "GET_DISPATCHER_PROBLEM_LIST_GROUP"
export const GET_DISPATCHER_PROBLEM_LIST_ELEMENTS = "GET_DISPATCHER_PROBLEM_LIST_ELEMENTS"
export const CREATE_DISPATCHER_PROBLEM_LIST_GROUP = "CREATE_DISPATCHER_PROBLEM_LIST_GROUP"
export const CREATE_DISPATCHER_PROBLEM_LIST_ELEMENTS = "CREATE_DISPATCHER_PROBLEM_LIST_ELEMENTS"
export const UPDATE_DISPATCHER_PROBLEM_LIST_GROUP = "UPDATE_DISPATCHER_PROBLEM_LIST_GROUP"
export const UPDATE_DISPATCHER_PROBLEM_LIST_ELEMENTS = "UPDATE_DISPATCHER_PROBLEM_LIST_ELEMENTS"
export const REMOVE_DISPATCHER_PROBLEM_LIST_GROUP = "REMOVE_DISPATCHER_PROBLEM_LIST_GROUP"
export const REMOVE_DISPATCHER_PROBLEM_LIST_ELEMENTS = "REMOVE_DISPATCHER_PROBLEM_LIST_ELEMENTS"
export const SELECT_DISPATCHER_PROBLEM_LIST_ELEMENTS = "SELECT_DISPATCHER_PROBLEM_LIST_ELEMENTS"


export const GET_NOMENCLATURE_GROUPS_LIST_ELEMENTS = "GET_NOMENCLATURE_GROUPS_LIST_ELEMENTS"
export const CREATE_NOMENCLATURE_GROUPS_LIST_ELEMENTS  = "CREATE_NOMENCLATURE_GROUPS_LIST_ELEMENTS"
export const UPDATE_NOMENCLATURE_GROUPS_LIST_ELEMENTS  = "UPDATE_NOMENCLATURE_GROUPS_LIST_ELEMENTS"
export const REMOVE_NOMENCLATURE_GROUPS_LIST_ELEMENTS  = "REMOVE_NOMENCLATURE_GROUPS_LIST_ELEMENTS"
export const SELECT_NOMENCLATURE_GROUPS_LIST_ELEMENTS  = "SELECT_NOMENCLATURE_GROUPS_LIST_ELEMENTS"


export const GET_CURRENCY_LIST_ELEMENTS = "GET_CURRENCY_LIST_ELEMENTS"
export const CREATE_CURRENCY_LIST_ELEMENTS  = "CREATE_CURRENCY_LIST_ELEMENTS"
export const UPDATE_CURRENCY_LIST_ELEMENTS  = "UPDATE_CURRENCY_LIST_ELEMENTS"
export const REMOVE_CURRENCY_LIST_ELEMENTS  = "REMOVE_CURRENCY_LIST_ELEMENTS"
export const SELECT_CURRENCY_LIST_ELEMENTS  = "SELECT_CURRENCY_LIST_ELEMENTS"


export const GET_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS = "GET_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS"
export const CREATE_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS  = "CREATE_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS"
export const UPDATE_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS  = "UPDATE_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS"
export const REMOVE_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS  = "REMOVE_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS"


export const GET_HOUSE_ACCOUNTING_CHARGE_SETTINGS_LIST_ELEMENTS = "GET_HOUSE_ACCOUNTING_CHARGE_SETTINGS_LIST_ELEMENTS"
export const CREATE_HOUSE_ACCOUNTING_CHARGE_SETTINGS_LIST_ELEMENTS  = "CREATE_HOUSE_ACCOUNTING_CHARGE_SETTINGS_LIST_ELEMENTS"
export const UPDATE_HOUSE_ACCOUNTING_CHARGE_SETTINGS_LIST_ELEMENTS  = "UPDATE_HOUSE_ACCOUNTING_CHARGE_SETTINGS_LIST_ELEMENTS"
export const REMOVE_HOUSE_ACCOUNTING_CHARGE_SETTINGS_LIST_ELEMENTS  = "REMOVE_HOUSE_ACCOUNTING_CHARGE_SETTINGS_LIST_ELEMENTS"


export const GET_AMORTISATION_CALC_WAY_LIST_ELEMENTS = "GET_AMORTISATION_CALC_WAY_LIST_ELEMENTS"
export const CREATE_AMORTISATION_CALC_WAY_LIST_ELEMENTS  = "CREATE_AMORTISATION_CALC_WAY_LIST_ELEMENTS"
export const UPDATE_AMORTISATION_CALC_WAY_LIST_ELEMENTS  = "UPDATE_AMORTISATION_CALC_WAY_LIST_ELEMENTS"
export const REMOVE_AMORTISATION_CALC_WAY_LIST_ELEMENTS  = "REMOVE_AMORTISATION_CALC_WAY_LIST_ELEMENTS"
export const SELECT_AMORTISATION_CALC_WAY_LIST_ELEMENTS  = "SELECT_AMORTISATION_CALC_WAY_LIST_ELEMENTS"


export const GET_CONTRACTOR_LIST_ELEMENTS = "GET_CONTRACTOR_LIST_ELEMENTS"
export const CREATE_CONTRACTOR_LIST_ELEMENTS  = "CREATE_CONTRACTOR_LIST_ELEMENTS"
export const UPDATE_CONTRACTOR_LIST_ELEMENTS  = "UPDATE_CONTRACTOR_LIST_ELEMENTS"
export const REMOVE_CONTRACTOR_LIST_ELEMENTS  = "REMOVE_CONTRACTOR_LIST_ELEMENTS"
export const SELECT_CONTRACTOR_LIST_ELEMENTS  = "SELECT_CONTRACTOR_LIST_ELEMENTS"


export const GET_CONTRACTOR_CONTRACT_LIST_ELEMENTS = "GET_CONTRACTOR_CONTRACT_LIST_ELEMENTS"
export const CREATE_CONTRACTOR_CONTRACT_LIST_ELEMENTS  = "CREATE_CONTRACTOR_CONTRACT_LIST_ELEMENTS"
export const UPDATE_CONTRACTOR_CONTRACT_LIST_ELEMENTS  = "UPDATE_CONTRACTOR_CONTRACT_LIST_ELEMENTS"
export const REMOVE_CONTRACTOR_CONTRACT_LIST_ELEMENTS  = "REMOVE_CONTRACTOR_CONTRACT_LIST_ELEMENTS"
export const SELECT_CONTRACTOR_CONTRACT_LIST_ELEMENTS  = "SELECT_CONTRACTOR_CONTRACT_LIST_ELEMENTS"


export const GET_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS = "GET_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS"
export const CREATE_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS  = "CREATE_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS"
export const UPDATE_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS  = "UPDATE_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS"
export const REMOVE_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS  = "REMOVE_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS"
export const SELECT_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS  = "SELECT_CONTRACTOR_CONTACT_PERSON_LIST_ELEMENTS"


export const GET_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS = "GET_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS"
export const CREATE_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS  = "CREATE_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS"
export const UPDATE_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS  = "UPDATE_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS"
export const REMOVE_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS  = "REMOVE_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS"


export const GET_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS = "GET_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS"
export const CREATE_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS  = "CREATE_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS"
export const UPDATE_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS  = "UPDATE_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS"
export const REMOVE_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS  = "REMOVE_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS"
export const SELECT_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS  = "SELECT_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS"

export const GET_POSITION_LIST_ELEMENTS = "GET_POSITION_LIST_ELEMENTS"
export const CREATE_POSITION_LIST_ELEMENTS  = "CREATE_POSITION_LIST_ELEMENTS"
export const UPDATE_POSITION_LIST_ELEMENTS  = "UPDATE_POSITION_LIST_ELEMENTS"
export const REMOVE_POSITION_LIST_ELEMENTS  = "REMOVE_POSITION_LIST_ELEMENTS"
export const SELECT_POSITION_LIST_ELEMENTS  = "SELECT_POSITION_LIST_ELEMENTS"


export const GET_TAX_INSPECTION_LIST_ELEMENTS = "GET_TAX_INSPECTION_LIST_ELEMENTS"
export const CREATE_TAX_INSPECTION_LIST_ELEMENTS  = "CREATE_TAX_INSPECTION_LIST_ELEMENTS"
export const UPDATE_TAX_INSPECTION_LIST_ELEMENTS  = "UPDATE_TAX_INSPECTION_LIST_ELEMENTS"
export const REMOVE_TAX_INSPECTION_LIST_ELEMENTS  = "REMOVE_TAX_INSPECTION_LIST_ELEMENTS"
export const SELECT_TAX_INSPECTION_LIST_ELEMENTS  = "SELECT_TAX_INSPECTION_LIST_ELEMENTS"

export const GET_NATURAL_PERSON_LIST_ELEMENTS = "GET_NATURAL_PERSON_LIST_ELEMENTS"
export const CREATE_NATURAL_PERSON_LIST_ELEMENTS  = "CREATE_NATURAL_PERSON_LIST_ELEMENTS"
export const UPDATE_NATURAL_PERSON_LIST_ELEMENTS  = "UPDATE_NATURAL_PERSON_LIST_ELEMENTS"
export const REMOVE_NATURAL_PERSON_LIST_ELEMENTS  = "REMOVE_NATURAL_PERSON_LIST_ELEMENTS"
export const SELECT_NATURAL_PERSON_LIST_ELEMENTS  = "SELECT_NATURAL_PERSON_LIST_ELEMENTS"


export const GET_UNIT_LIST_ELEMENTS = "GET_UNIT_LIST_ELEMENTS"
export const CREATE_UNIT_LIST_ELEMENTS  = "CREATE_UNIT_LIST_ELEMENTS"
export const UPDATE_UNIT_LIST_ELEMENTS  = "UPDATE_UNIT_LIST_ELEMENTS"
export const REMOVE_UNIT_LIST_ELEMENTS  = "REMOVE_UNIT_LIST_ELEMENTS"
export const SELECT_UNIT_LIST_ELEMENTS  = "SELECT_UNIT_LIST_ELEMENTS"


export const GET_SUBDIVISION_LIST_ELEMENTS = "GET_SUBDIVISION_LIST_ELEMENTS"
export const CREATE_SUBDIVISION_LIST_ELEMENTS  = "CREATE_SUBDIVISION_LIST_ELEMENTS"
export const UPDATE_SUBDIVISION_LIST_ELEMENTS  = "UPDATE_SUBDIVISION_LIST_ELEMENTS"
export const REMOVE_SUBDIVISION_LIST_ELEMENTS  = "REMOVE_SUBDIVISION_LIST_ELEMENTS"
export const SELECT_SUBDIVISION_LIST_ELEMENTS  = "SELECT_SUBDIVISION_LIST_ELEMENTS"


export const GET_PERSONAL_LIST_ELEMENTS = "GET_PERSONAL_LIST_ELEMENTS"
export const CREATE_PERSONAL_LIST_ELEMENTS  = "CREATE_PERSONAL_LIST_ELEMENTS"
export const UPDATE_PERSONAL_LIST_ELEMENTS  = "UPDATE_PERSONAL_LIST_ELEMENTS"
export const REMOVE_PERSONAL_LIST_ELEMENTS  = "REMOVE_PERSONAL_LIST_ELEMENTS"
export const SELECT_PERSONAL_LIST_ELEMENTS  = "SELECT_PERSONAL_LIST_ELEMENTS"


export const GET_BANK_ACCOUNT_LIST_ELEMENTS = "GET_BANK_ACCOUNT_LIST_ELEMENTS"
export const CREATE_BANK_ACCOUNT_LIST_ELEMENTS  = "CREATE_BANK_ACCOUNT_LIST_ELEMENTS"
export const UPDATE_BANK_ACCOUNT_LIST_ELEMENTS  = "UPDATE_BANK_ACCOUNT_LIST_ELEMENTS"
export const REMOVE_BANK_ACCOUNT_LIST_ELEMENTS  = "REMOVE_BANK_ACCOUNT_LIST_ELEMENTS"
export const SELECT_BANK_ACCOUNT_LIST_ELEMENTS  = "SELECT_BANK_ACCOUNT_LIST_ELEMENTS"


export const GET_CASH_FLOW_ITEM_LIST_ELEMENTS = "GET_CASH_FLOW_ITEM_LIST_ELEMENTS"
export const CREATE_CASH_FLOW_ITEM_LIST_ELEMENTS  = "CREATE_CASH_FLOW_ITEM_LIST_ELEMENTS"
export const UPDATE_CASH_FLOW_ITEM_LIST_ELEMENTS  = "UPDATE_CASH_FLOW_ITEM_LIST_ELEMENTS"
export const REMOVE_CASH_FLOW_ITEM_LIST_ELEMENTS  = "REMOVE_CASH_FLOW_ITEM_LIST_ELEMENTS"
export const SELECT_CASH_FLOW_ITEM_LIST_ELEMENTS  = "SELECT_CASH_FLOW_ITEM_LIST_ELEMENTS"


export const GET_CHART_OF_ACCOUNTS_LIST_ELEMENTS = "GET_CHART_OF_ACCOUNTS_LIST_ELEMENTS"
export const CREATE_CHART_OF_ACCOUNTS_LIST_ELEMENTS  = "CREATE_CHART_OF_ACCOUNTS_LIST_ELEMENTS"
export const UPDATE_CHART_OF_ACCOUNTS_LIST_ELEMENTS  = "UPDATE_CHART_OF_ACCOUNTS_LIST_ELEMENTS"
export const REMOVE_CHART_OF_ACCOUNTS_LIST_ELEMENTS  = "REMOVE_CHART_OF_ACCOUNTS_LIST_ELEMENTS"
export const SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS  = "SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS"


export const GET_BIOLOGICAL_ASSETS_LIST_ELEMENTS = "GET_BIOLOGICAL_ASSETS_LIST_ELEMENTS"
export const CREATE_BIOLOGICAL_ASSETS_LIST_ELEMENTS  = "CREATE_BIOLOGICAL_ASSETS_LIST_ELEMENTS"
export const UPDATE_BIOLOGICAL_ASSETS_LIST_ELEMENTS  = "UPDATE_BIOLOGICAL_ASSETS_LIST_ELEMENTS"
export const REMOVE_BIOLOGICAL_ASSETS_LIST_ELEMENTS  = "REMOVE_BIOLOGICAL_ASSETS_LIST_ELEMENTS"
export const SELECT_BIOLOGICAL_ASSETS_LIST_ELEMENTS  = "SELECT_BIOLOGICAL_ASSETS_LIST_ELEMENTS"


export const GET_COST_ITEMS_LIST_ELEMENTS = "GET_COST_ITEMS_LIST_ELEMENTS"
export const GET_COST_ITEMS_LIST_GROUP = "GET_COST_ITEMS_LIST_GROUP"
export const CREATE_COST_ITEMS_LIST_ELEMENTS  = "CREATE_COST_ITEMS_LIST_ELEMENTS"
export const CREATE_COST_ITEMS_LIST_GROUP  = "CREATE_COST_ITEMS_LIST_GROUP"
export const UPDATE_COST_ITEMS_LIST_ELEMENTS  = "UPDATE_COST_ITEMS_LIST_ELEMENTS"
export const UPDATE_COST_ITEMS_LIST_GROUP  = "UPDATE_COST_ITEMS_LIST_GROUP"
export const REMOVE_COST_ITEMS_LIST_ELEMENTS  = "REMOVE_COST_ITEMS_LIST_ELEMENTS"
export const REMOVE_COST_ITEMS_LIST_GROUP  = "REMOVE_COST_ITEMS_LIST_GROUP"
export const SELECT_COST_ITEMS_LIST_ELEMENTS  = "SELECT_COST_ITEMS_LIST_ELEMENTS"


export const GET_RELEASE_REASON_LIST_ELEMENTS = "GET_RELEASE_REASON_LIST_ELEMENTS"
export const CREATE_RELEASE_REASON_LIST_ELEMENTS  = "CREATE_RELEASE_REASON_LIST_ELEMENTS"
export const UPDATE_RELEASE_REASON_LIST_ELEMENTS  = "UPDATE_RELEASE_REASON_LIST_ELEMENTS"
export const REMOVE_RELEASE_REASON_LIST_ELEMENTS  = "REMOVE_RELEASE_REASON_LIST_ELEMENTS"
export const SELECT_RELEASE_REASON_LIST_ELEMENTS  = "SELECT_RELEASE_REASON_LIST_ELEMENTS"

export const GET_INTANGIBLE_ASSETS_LIST_ELEMENTS = "GET_INTANGIBLE_ASSETS_LIST_ELEMENTS"
export const CREATE_INTANGIBLE_ASSETS_LIST_ELEMENTS  = "CREATE_INTANGIBLE_ASSETS_LIST_ELEMENTS"
export const UPDATE_INTANGIBLE_ASSETS_LIST_ELEMENTS  = "UPDATE_INTANGIBLE_ASSETS_LIST_ELEMENTS"
export const REMOVE_INTANGIBLE_ASSETS_LIST_ELEMENTS  = "REMOVE_INTANGIBLE_ASSETS_LIST_ELEMENTS"
export const SELECT_INTANGIBLE_ASSETS_LIST_ELEMENTS  = "SELECT_INTANGIBLE_ASSETS_LIST_ELEMENTS"


export const GET_FIXED_ASSETS_LIST_ELEMENTS = "GET_FIXED_ASSETS_LIST_ELEMENTS"
export const CREATE_FIXED_ASSETS_LIST_ELEMENTS  = "CREATE_FIXED_ASSETS_LIST_ELEMENTS"
export const UPDATE_FIXED_ASSETS_LIST_ELEMENTS  = "UPDATE_FIXED_ASSETS_LIST_ELEMENTS"
export const REMOVE_FIXED_ASSETS_LIST_ELEMENTS  = "REMOVE_FIXED_ASSETS_LIST_ELEMENTS"
export const SELECT_FIXED_ASSETS_LIST_ELEMENTS  = "SELECT_FIXED_ASSETS_LIST_ELEMENTS"


export const GET_INCOME_ITEMS_LIST_ELEMENTS = "GET_INCOME_ITEMS_LIST_ELEMENTS"
export const CREATE_INCOME_ITEMS_LIST_ELEMENTS  = "CREATE_INCOME_ITEMS_LIST_ELEMENTS"
export const UPDATE_INCOME_ITEMS_LIST_ELEMENTS  = "UPDATE_INCOME_ITEMS_LIST_ELEMENTS"
export const REMOVE_INCOME_ITEMS_LIST_ELEMENTS  = "REMOVE_INCOME_ITEMS_LIST_ELEMENTS"
export const SELECT_INCOME_ITEMS_LIST_ELEMENTS  = "SELECT_INCOME_ITEMS_LIST_ELEMENTS"

export const GET_WORK_SCHEDULE_LIST_ELEMENTS = "GET_WORK_SCHEDULE_LIST_ELEMENTS"
export const CREATE_WORK_SCHEDULE_LIST_ELEMENTS  = "CREATE_WORK_SCHEDULE_LIST_ELEMENTS"
export const UPDATE_WORK_SCHEDULE_LIST_ELEMENTS  = "UPDATE_WORK_SCHEDULE_LIST_ELEMENTS"
export const REMOVE_WORK_SCHEDULE_LIST_ELEMENTS  = "REMOVE_WORK_SCHEDULE_LIST_ELEMENTS"
export const SELECT_WORK_SCHEDULE_LIST_ELEMENTS  = "SELECT_WORK_SCHEDULE_LIST_ELEMENTS"


export const GET_ACCRUAL_LIST_ELEMENTS = "GET_ACCRUAL_LIST_ELEMENTS"
export const CREATE_ACCRUAL_LIST_ELEMENTS  = "CREATE_ACCRUAL_LIST_ELEMENTS"
export const UPDATE_ACCRUAL_LIST_ELEMENTS  = "UPDATE_ACCRUAL_LIST_ELEMENTS"
export const REMOVE_ACCRUAL_LIST_ELEMENTS  = "REMOVE_ACCRUAL_LIST_ELEMENTS"
export const SELECT_ACCRUAL_LIST_ELEMENTS  = "SELECT_ACCRUAL_LIST_ELEMENTS"


export const GET_TIMESHEET_LETTER_ELEMENTS = "GET_TIMESHEET_LETTER_ELEMENTS"
export const CREATE_TIMESHEET_LETTER_LIST_ELEMENTS  = "CREATE_TIMESHEET_LETTER_LIST_ELEMENTS"
export const UPDATE_TIMESHEET_LETTER_LIST_ELEMENTS  = "UPDATE_TIMESHEET_LETTER_LIST_ELEMENTS"
export const REMOVE_TIMESHEET_LETTER_LIST_ELEMENTS  = "REMOVE_TIMESHEET_LETTER_LIST_ELEMENTS"
export const SELECT_TIMESHEET_LETTER_LIST_ELEMENTS  = "SELECT_TIMESHEET_LETTER_LIST_ELEMENTS"


export const GET_INCOME_TYPE_PIX_ELEMENTS = "GET_INCOME_TYPE_PIX_ELEMENTS"
export const CREATE_INCOME_TYPE_PIX_LIST_ELEMENTS  = "CREATE_INCOME_TYPE_PIX_LIST_ELEMENTS"
export const UPDATE_INCOME_TYPE_PIX_LIST_ELEMENTS  = "UPDATE_INCOME_TYPE_PIX_LIST_ELEMENTS"
export const REMOVE_INCOME_TYPE_PIX_LIST_ELEMENTS  = "REMOVE_INCOME_TYPE_PIX_LIST_ELEMENTS"
export const SELECT_INCOME_TYPE_PIX_LIST_ELEMENTS  = "SELECT_INCOME_TYPE_PIX_LIST_ELEMENTS"


export const GET_TAXES_ELEMENTS = "GET_TAXES_ELEMENTS"
export const CREATE_TAXES_LIST_ELEMENTS  = "CREATE_TAXES_LIST_ELEMENTS"
export const UPDATE_TAXES_LIST_ELEMENTS  = "UPDATE_TAXES_LIST_ELEMENTS"
export const REMOVE_TAXES_LIST_ELEMENTS  = "REMOVE_TAXES_LIST_ELEMENTS"
export const SELECT_TAXES_LIST_ELEMENTS  = "SELECT_TAXES_LIST_ELEMENTS"


export const GET_MIN_AMOUNT_ELEMENTS = "GET_MIN_AMOUNT_ELEMENTS"
export const CREATE_MIN_AMOUNT_LIST_ELEMENTS  = "CREATE_MIN_AMOUNT_LIST_ELEMENTS"
export const UPDATE_MIN_AMOUNT_LIST_ELEMENTS  = "UPDATE_MIN_AMOUNT_LIST_ELEMENTS"
export const REMOVE_MIN_AMOUNT_LIST_ELEMENTS  = "REMOVE_MIN_AMOUNT_LIST_ELEMENTS"
export const SELECT_MIN_AMOUNT_LIST_ELEMENTS  = "SELECT_MIN_AMOUNT_LIST_ELEMENTS"


export const GET_INFLATION_INDEX_ELEMENTS = "GET_INFLATION_INDEX_ELEMENTS"
export const CREATE_INFLATION_INDEX_LIST_ELEMENTS  = "CREATE_INFLATION_INDEX_LIST_ELEMENTS"
export const UPDATE_INFLATION_INDEX_LIST_ELEMENTS  = "UPDATE_INFLATION_INDEX_LIST_ELEMENTS"
export const REMOVE_INFLATION_INDEX_LIST_ELEMENTS  = "REMOVE_INFLATION_INDEX_LIST_ELEMENTS"
export const SELECT_INFLATION_INDEX_LIST_ELEMENTS  = "SELECT_INFLATION_INDEX_LIST_ELEMENTS"


export const GET_TAX_TARIFF_ELEMENTS = "GET_TAX_TARIFF_ELEMENTS"
export const CREATE_TAX_TARIFF_LIST_ELEMENTS  = "CREATE_TAX_TARIFF_LIST_ELEMENTS"
export const UPDATE_TAX_TARIFF_LIST_ELEMENTS  = "UPDATE_TAX_TARIFF_LIST_ELEMENTS"
export const REMOVE_TAX_TARIFF_LIST_ELEMENTS  = "REMOVE_TAX_TARIFF_LIST_ELEMENTS"
export const SELECT_TAX_TARIFF_LIST_ELEMENTS  = "SELECT_TAX_TARIFF_LIST_ELEMENTS"

export const GET_DOCUMENT_ENTERING_BALANCES_ELEMENTS = 'GET_DOCUMENT_ENTERING_BALANCES_ELEMENTS'
export const CREATE_DOCUMENT_ENTERING_BALANCES_ELEMENTS = 'CREATE_DOCUMENT_ENTERING_BALANCES_ELEMENTS'
export const UPDATE_DOCUMENT_ENTERING_BALANCES_ELEMENTS = 'UPDATE_DOCUMENT_ENTERING_BALANCES_ELEMENTS'
export const REMOVE_DOCUMENT_ENTERING_BALANCES_ELEMENTS = 'REMOVE_DOCUMENT_ENTERING_BALANCES_ELEMENTS'
export const SELECT_DOCUMENT_ENTERING_BALANCES_ELEMENTS = 'SELECT_DOCUMENT_ENTERING_BALANCES_ELEMENTS'
export const CONDUCTED_DOCUMENT_ENTERING_BALANCES_ELEMENTS = 'CONDUCTED_DOCUMENT_ENTERING_BALANCES_ELEMENTS'

export const GET_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS = "GET_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS"
export const CREATE_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS = "CREATE_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS"
export const UPDATE_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS = "UPDATE_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS"
export const REMOVE_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS = "REMOVE_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS"
export const SELECT_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS = "SELECT_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS"
export const CONDUCTED_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS = 'CONDUCTED_DOCUMENT_CLOSE_FINANCIAL_MONTH_ELEMENTS'

export const GET_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS = "GET_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS"
export const CREATE_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS = "CREATE_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS"
export const UPDATE_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS = "UPDATE_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS"
export const REMOVE_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS = "REMOVE_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS"
export const SELECT_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS = "SELECT_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS"
export const CONDUCTED_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS = 'CONDUCTED_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS'

export const GET_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS = "GET_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS"
export const CREATE_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS  = "CREATE_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS"
export const UPDATE_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS  = "UPDATE_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS"
export const REMOVE_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS  = "REMOVE_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS"
export const SELECT_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS  = "SELECT_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS"
export const CONDUCTED_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS = 'CONDUCTED_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS'

export const GET_DOCUMENT_BANK_INCOME_ELEMENTS = 'GET_DOCUMENT_BANK_INCOME_ELEMENTS'
export const CREATE_DOCUMENT_BANK_INCOME_ELEMENTS = 'CREATE_DOCUMENT_BANK_INCOME_ELEMENTS'
export const UPDATE_DOCUMENT_BANK_INCOME_ELEMENTS = 'UPDATE_DOCUMENT_BANK_INCOME_ELEMENTS'
export const REMOVE_DOCUMENT_BANK_INCOME_ELEMENTS = 'REMOVE_DOCUMENT_BANK_INCOME_ELEMENTS'
export const SELECT_DOCUMENT_BANK_INCOME_ELEMENTS = 'SELECT_DOCUMENT_BANK_INCOME_ELEMENTS'
export const CONDUCTED_DOCUMENT_BANK_INCOME_ELEMENTS = 'CONDUCTED_DOCUMENT_BANK_INCOME_ELEMENTS'


export const GET_DOCUMENT_BANK_OUTCOME_ELEMENTS = 'GET_DOCUMENT_BANK_OUTCOME_ELEMENTS'
export const CREATE_DOCUMENT_BANK_OUTCOME_ELEMENTS = 'CREATE_DOCUMENT_BANK_OUTCOME_ELEMENTS'
export const UPDATE_DOCUMENT_BANK_OUTCOME_ELEMENTS = 'UPDATE_DOCUMENT_BANK_OUTCOME_ELEMENTS'
export const REMOVE_DOCUMENT_BANK_OUTCOME_ELEMENTS = 'REMOVE_DOCUMENT_BANK_OUTCOME_ELEMENTS'
export const SELECT_DOCUMENT_BANK_OUTCOME_ELEMENTS = 'SELECT_DOCUMENT_BANK_OUTCOME_ELEMENTS'
export const CONDUCTED_DOCUMENT_BANK_OUTCOME_ELEMENTS = 'CONDUCTED_DOCUMENT_BANK_OUTCOME_ELEMENTS'


export const GET_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS = 'GET_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS'
export const CREATE_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS = 'CREATE_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS'
export const UPDATE_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS = 'UPDATE_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS'
export const REMOVE_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS = 'REMOVE_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS'
export const SELECT_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS = 'SELECT_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS'
export const CONDUCTED_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS = 'CONDUCTED_DOCUMENT_CASH_ORDER_OUTCOME_ELEMENTS'


export const GET_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS = 'GET_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS'
export const CREATE_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS = 'CREATE_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS'
export const UPDATE_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS = 'UPDATE_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS'
export const REMOVE_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS = 'REMOVE_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS'
export const SELECT_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS = 'SELECT_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS'
export const CONDUCTED_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS = 'CONDUCTED_DOCUMENT_CASH_ORDER_INCOME_ELEMENTS'


export const GET_DOCUMENT_COMMISSIONING_ELEMENTS = 'GET_DOCUMENT_COMMISSIONING_ELEMENTS'
export const CREATE_DOCUMENT_COMMISSIONING_ELEMENTS = 'CREATE_DOCUMENT_COMMISSIONING_ELEMENTS'
export const UPDATE_DOCUMENT_COMMISSIONING_ELEMENTS = 'UPDATE_DOCUMENT_COMMISSIONING_ELEMENTS'
export const REMOVE_DOCUMENT_COMMISSIONING_ELEMENTS = 'REMOVE_DOCUMENT_COMMISSIONING_ELEMENTS'
export const SELECT_DOCUMENT_COMMISSIONING_ELEMENTS = 'SELECT_DOCUMENT_COMMISSIONING_ELEMENTS'
export const CONDUCTED_DOCUMENT_COMMISSIONING_ELEMENTS = 'CONDUCTED_DOCUMENT_COMMISSIONING_ELEMENTS'


export const GET_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS = 'GET_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS'
export const CREATE_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS = 'CREATE_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS'
export const UPDATE_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS = 'UPDATE_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS'
export const REMOVE_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS = 'REMOVE_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS'
export const SELECT_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS = 'SELECT_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS'
export const CONDUCTED_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS = 'CONDUCTED_DOCUMENT_PERSONAL_TIMESHEET_ELEMENTS'


export const GET_DOCUMENT_WORK_TRANSFER_ELEMENTS = 'GET_DOCUMENT_WORK_TRANSFER_ELEMENTS'
export const CREATE_DOCUMENT_WORK_TRANSFER_ELEMENTS = 'CREATE_DOCUMENT_WORK_TRANSFER_ELEMENTS'
export const UPDATE_DOCUMENT_WORK_TRANSFER_ELEMENTS = 'UPDATE_DOCUMENT_WORK_TRANSFER_ELEMENTS'
export const REMOVE_DOCUMENT_WORK_TRANSFER_ELEMENTS = 'REMOVE_DOCUMENT_WORK_TRANSFER_ELEMENTS'
export const SELECT_DOCUMENT_WORK_TRANSFER_ELEMENTS = 'SELECT_DOCUMENT_WORK_TRANSFER_ELEMENTS'
export const CONDUCTED_DOCUMENT_WORK_TRANSFER_ELEMENTS = 'CONDUCTED_DOCUMENT_WORK_TRANSFER_ELEMENTS'


export const GET_DOCUMENT_RELEASE_WORK_ELEMENTS = 'GET_DOCUMENT_RELEASE_WORK_ELEMENTS'
export const CREATE_DOCUMENT_RELEASE_WORK_ELEMENTS = 'CREATE_DOCUMENT_RELEASE_WORK_ELEMENTS'
export const UPDATE_DOCUMENT_RELEASE_WORK_ELEMENTS = 'UPDATE_DOCUMENT_RELEASE_WORK_ELEMENTS'
export const REMOVE_DOCUMENT_RELEASE_WORK_ELEMENTS = 'REMOVE_DOCUMENT_RELEASE_WORK_ELEMENTS'
export const SELECT_DOCUMENT_RELEASE_WORK_ELEMENTS = 'SELECT_DOCUMENT_RELEASE_WORK_ELEMENTS'
export const CONDUCTED_DOCUMENT_RELEASE_WORK_ELEMENTS = 'CONDUCTED_DOCUMENT_RELEASE_WORK_ELEMENTS'

export const GET_DOCUMENT_EMPLOYMENT_ELEMENTS = 'GET_DOCUMENT_EMPLOYMENT_ELEMENTS'
export const CREATE_DOCUMENT_EMPLOYMENT_ELEMENTS = 'CREATE_DOCUMENT_EMPLOYMENT_ELEMENTS'
export const UPDATE_DOCUMENT_EMPLOYMENT_ELEMENTS = 'UPDATE_DOCUMENT_EMPLOYMENT_ELEMENTS'
export const REMOVE_DOCUMENT_EMPLOYMENT_ELEMENTS = 'REMOVE_DOCUMENT_EMPLOYMENT_ELEMENTS'
export const SELECT_DOCUMENT_EMPLOYMENT_ELEMENTS = 'SELECT_DOCUMENT_EMPLOYMENT_ELEMENTS'
export const CONDUCTED_DOCUMENT_EMPLOYMENT_ELEMENTS = 'CONDUCTED_DOCUMENT_EMPLOYMENT_ELEMENTS'


export const GET_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS = 'GET_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS'
export const CREATE_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS = 'CREATE_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS'
export const UPDATE_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS = 'UPDATE_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS'
export const REMOVE_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS = 'REMOVE_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS'
export const SELECT_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS = 'SELECT_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS'
export const CONDUCTED_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS = 'CONDUCTED_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS'


export const GET_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS = 'GET_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS'
export const CREATE_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS = 'CREATE_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS'
export const UPDATE_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS = 'UPDATE_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS'
export const REMOVE_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS = 'REMOVE_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS'
export const SELECT_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS = 'SELECT_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS'
export const CONDUCTED_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS = 'CONDUCTED_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS'


export const GET_DOCUMENT_SALARY_CHARGE_ELEMENTS = 'GET_DOCUMENT_SALARY_CHARGE_ELEMENTS'
export const CREATE_DOCUMENT_SALARY_CHARGE_ELEMENTS = 'CREATE_DOCUMENT_SALARY_CHARGE_ELEMENTS'
export const UPDATE_DOCUMENT_SALARY_CHARGE_ELEMENTS = 'UPDATE_DOCUMENT_SALARY_CHARGE_ELEMENTS'
export const REMOVE_DOCUMENT_SALARY_CHARGE_ELEMENTS = 'REMOVE_DOCUMENT_SALARY_CHARGE_ELEMENTS'
export const SELECT_DOCUMENT_SALARY_CHARGE_ELEMENTS = 'SELECT_DOCUMENT_SALARY_CHARGE_ELEMENTS'
export const CONDUCTED_DOCUMENT_SALARY_CHARGE_ELEMENTS = 'CONDUCTED_DOCUMENT_SALARY_CHARGE_ELEMENTS'


export const GET_DOCUMENT_SALARY_TO_PAY_ELEMENTS = 'GET_DOCUMENT_SALARY_TO_PAY_ELEMENTS'
export const CREATE_DOCUMENT_SALARY_TO_PAY_ELEMENTS = 'CREATE_DOCUMENT_SALARY_TO_PAY_ELEMENTS'
export const UPDATE_DOCUMENT_SALARY_TO_PAY_ELEMENTS = 'UPDATE_DOCUMENT_SALARY_TO_PAY_ELEMENTS'
export const REMOVE_DOCUMENT_SALARY_TO_PAY_ELEMENTS = 'REMOVE_DOCUMENT_SALARY_TO_PAY_ELEMENTS'
export const SELECT_DOCUMENT_SALARY_TO_PAY_ELEMENTS = 'SELECT_DOCUMENT_SALARY_TO_PAY_ELEMENTS'
export const CONDUCTED_DOCUMENT_SALARY_TO_PAY_ELEMENTS = 'CONDUCTED_DOCUMENT_SALARY_TO_PAY_ELEMENTS'


export const GET_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS = 'GET_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS'
export const CREATE_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS = 'CREATE_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS'
export const UPDATE_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS = 'UPDATE_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS'
export const REMOVE_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS = 'REMOVE_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS'
export const SELECT_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS = 'SELECT_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS'
export const CONDUCTED_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS = 'CONDUCTED_DOCUMENT_HOSPITAL_CHARGE_ELEMENTS'


export const GET_DOCUMENT_VOCATION_CHARGE_ELEMENTS = 'GET_DOCUMENT_VOCATION_CHARGE_ELEMENTS'
export const CREATE_DOCUMENT_VOCATION_CHARGE_ELEMENTS = 'CREATE_DOCUMENT_VOCATION_CHARGE_ELEMENTS'
export const UPDATE_DOCUMENT_VOCATION_CHARGE_ELEMENTS = 'UPDATE_DOCUMENT_VOCATION_CHARGE_ELEMENTS'
export const REMOVE_DOCUMENT_VOCATION_CHARGE_ELEMENTS = 'REMOVE_DOCUMENT_VOCATION_CHARGE_ELEMENTS'
export const SELECT_DOCUMENT_VOCATION_CHARGE_ELEMENTS = 'SELECT_DOCUMENT_VOCATION_CHARGE_ELEMENTS'
export const CONDUCTED_DOCUMENT_VOCATION_CHARGE_ELEMENTS = 'CONDUCTED_DOCUMENT_VOCATION_CHARGE_ELEMENTS'

export const GET_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS = 'GET_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS'
export const CREATE_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS = 'CREATE_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS'
export const UPDATE_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS = 'UPDATE_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS'
export const REMOVE_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS = 'REMOVE_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS'
export const SELECT_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS = 'SELECT_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS'
export const CONDUCTED_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS = 'CONDUCTED_DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_ELEMENTS'


export const GET_DOCUMENT_BILL_OUTCOME_ELEMENTS = 'GET_DOCUMENT_BILL_OUTCOME_ELEMENTS'
export const CREATE_DOCUMENT_BILL_OUTCOME_ELEMENTS = 'CREATE_DOCUMENT_BILL_OUTCOME_ELEMENTS'
export const UPDATE_DOCUMENT_BILL_OUTCOME_ELEMENTS = 'UPDATE_DOCUMENT_BILL_OUTCOME_ELEMENTS'
export const REMOVE_DOCUMENT_BILL_OUTCOME_ELEMENTS = 'REMOVE_DOCUMENT_BILL_OUTCOME_ELEMENTS'
export const SELECT_DOCUMENT_BILL_OUTCOME_ELEMENTS = 'SELECT_DOCUMENT_BILL_OUTCOME_ELEMENTS'
export const CONDUCTED_DOCUMENT_BILL_OUTCOME_ELEMENTS = 'CONDUCTED_DOCUMENT_BILL_OUTCOME_ELEMENTS'


export const GET_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS = 'GET_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS'
export const CREATE_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS = 'CREATE_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS'
export const UPDATE_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS = 'UPDATE_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS'
export const REMOVE_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS = 'REMOVE_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS'
export const SELECT_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS = 'SELECT_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS'
export const CONDUCTED_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS = 'CONDUCTED_DOCUMENT_ACT_WORK_PERFORMED_INCOME_ELEMENTS'


export const GET_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS = 'GET_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS'
export const CREATE_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS = 'CREATE_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS'
export const UPDATE_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS = 'UPDATE_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS'
export const REMOVE_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS = 'REMOVE_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS'
export const SELECT_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS = 'SELECT_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS'
export const CONDUCTED_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS = 'CONDUCTED_DOCUMENT_INVOICE_WAREHOUSE_INCOME_ELEMENTS'


export const GET_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS = 'GET_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS'
export const CREATE_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS = 'CREATE_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS'
export const UPDATE_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS = 'UPDATE_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS'
export const REMOVE_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS = 'REMOVE_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS'
export const SELECT_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS = 'SELECT_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS'
export const CONDUCTED_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS = 'CONDUCTED_DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_ELEMENTS'


export const GET_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS = 'GET_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS'
export const CREATE_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS = 'CREATE_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS'
export const UPDATE_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS = 'UPDATE_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS'
export const REMOVE_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS = 'REMOVE_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS'
export const SELECT_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS = 'SELECT_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS'
export const CONDUCTED_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS = 'CONDUCTED_DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_ELEMENTS'


export const GET_DOCUMENT_ADVANCE_REPORT_ELEMENTS = 'GET_DOCUMENT_ADVANCE_REPORT_ELEMENTS'
export const CREATE_DOCUMENT_ADVANCE_REPORT_ELEMENTS = 'CREATE_DOCUMENT_ADVANCE_REPORT_ELEMENTS'
export const UPDATE_DOCUMENT_ADVANCE_REPORT_ELEMENTS = 'UPDATE_DOCUMENT_ADVANCE_REPORT_ELEMENTS'
export const REMOVE_DOCUMENT_ADVANCE_REPORT_ELEMENTS = 'REMOVE_DOCUMENT_ADVANCE_REPORT_ELEMENTS'
export const SELECT_DOCUMENT_ADVANCE_REPORT_ELEMENTS = 'SELECT_DOCUMENT_ADVANCE_REPORT_ELEMENTS'
export const CONDUCTED_DOCUMENT_ADVANCE_REPORT_ELEMENTS = 'CONDUCTED_DOCUMENT_ADVANCE_REPORT_ELEMENTS'


export const GET_DOCUMENT_MANUAL_OPERATION_ELEMENTS = 'GET_DOCUMENT_MANUAL_OPERATION_ELEMENTS'
export const CREATE_DOCUMENT_MANUAL_OPERATION_ELEMENTS = 'CREATE_DOCUMENT_MANUAL_OPERATION_ELEMENTS'
export const UPDATE_DOCUMENT_MANUAL_OPERATION_ELEMENTS = 'UPDATE_DOCUMENT_MANUAL_OPERATION_ELEMENTS'
export const REMOVE_DOCUMENT_MANUAL_OPERATION_ELEMENTS = 'REMOVE_DOCUMENT_MANUAL_OPERATION_ELEMENTS'
export const SELECT_DOCUMENT_MANUAL_OPERATION_ELEMENTS = 'SELECT_DOCUMENT_MANUAL_OPERATION_ELEMENTS'
export const CONDUCTED_DOCUMENT_MANUAL_OPERATION_ELEMENTS = 'CONDUCTED_DOCUMENT_MANUAL_OPERATION_ELEMENTS'


export const GET_DOCUMENT_TAX_BILL_INCOME_ELEMENTS = 'GET_DOCUMENT_TAX_BILL_INCOME_ELEMENTS'
export const CREATE_DOCUMENT_TAX_BILL_INCOME_ELEMENTS = 'CREATE_DOCUMENT_TAX_BILL_INCOME_ELEMENTS'
export const UPDATE_DOCUMENT_TAX_BILL_INCOME_ELEMENTS = 'UPDATE_DOCUMENT_TAX_BILL_INCOME_ELEMENTS'
export const REMOVE_DOCUMENT_TAX_BILL_INCOME_ELEMENTS = 'REMOVE_DOCUMENT_TAX_BILL_INCOME_ELEMENTS'
export const SELECT_DOCUMENT_TAX_BILL_INCOME_ELEMENTS = 'SELECT_DOCUMENT_TAX_BILL_INCOME_ELEMENTS'
export const CONDUCTED_DOCUMENT_TAX_BILL_INCOME_ELEMENTS = 'CONDUCTED_DOCUMENT_TAX_BILL_INCOME_ELEMENTS'


export const GET_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS = 'GET_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS'
export const CREATE_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS = 'CREATE_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS'
export const UPDATE_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS = 'UPDATE_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS'
export const REMOVE_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS = 'REMOVE_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS'
export const SELECT_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS = 'SELECT_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS'
export const CONDUCTED_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS = 'CONDUCTED_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS'
