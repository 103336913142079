export const USER_REQUEST = "USER_REQUEST";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_ERROR = "USER_ERROR";
export const USER_SET_ORGANIZATIONS = "USER_SET_ORGANIZATIONS";
export const USER_SET_CURRENT_ORGANIZATION = "USER_SET_CURRENT_ORGANIZATION";
export const USER_SET_FLATS = "USER_SET_FLATS";
export const USER_SET_CURRENT_FLAT = "USER_SET_CURRENT_FLAT"
export const USER_GET_ALL_BY_ORG = "USER_GET_ALL_BY_ORG"
export const USER_UPDATE = "USER_UPDATE"
export const USER_PROFILE_UPDATE = "USER_PROFILE_UPDATE"
export const USER_SET_NEW_PASSWORD = "USER_SET_NEW_PASSWORD"
export const USER_CREATE = "USER_CREATE"
export const USER_REGISTER_FOR_PERSONAL = "USER_REGISTER_FOR_PERSONAL"
export const USER_SET_ACCOUNTING = "USER_SET_ACCOUNTING"
export const USER_SET_DISPATCHER = "USER_SET_DISPATCHER"
export const USER_SET_LEGACY = "USER_SET_LEGACY"
export const USER_RANDOM_FLAT = "USER_RANDOM_FLAT"
