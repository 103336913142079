import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/appeal-receiver-group';

export default {
    get_all() {
        return axios.get(`${RESOURCE_NAME}/`)
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { "appeal_receiver_group_id": payload })
    },
    select() {
        return axios.post(`${RESOURCE_NAME}/select`)
    },
};