<template>
  <v-app>
    <v-main class="grey lighten-5" v-cloak>
      <slot/>
    </v-main>
    <v-snackbar
        :timeout="4000"
        :color="alert_conf.color"
        :value="show_alert"
        @input="hide_alert"
    >
      {{ alert_conf.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
            dark
            icon
            v-bind="attrs"
            @click="hide_alert"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <GeneralQuestion/>
  </v-app>
</template>

<script>
import GeneralQuestion from "@/components/modal/GeneralQuestion";
import {ALERT_HIDE} from "@/store/actions/alert";

export default {
  name: "CleanLayout",
  components: {
    GeneralQuestion
  },
  computed: {
    show_alert() {
      return this.$store.getters.alert_status
    },
    alert_conf() {
      return this.$store.getters.alert_conf
    },
  },
  methods: {
    hide_alert() {
      this.$store.commit(ALERT_HIDE, this.alert_conf);
    }
  },
}
</script>

<style scoped>

</style>