import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/sms-send-config';

export default {
    get_all() {
        return axios.get(`${RESOURCE_NAME}/`)
    },
    get_base_data(payload) {
        return axios.get(`${RESOURCE_NAME}/base-data`, {params: {item_id: payload}})
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { "sms_send_config_id": payload })
    },
    select() {
        return axios.post(`${RESOURCE_NAME}/select`)
    },
    send_sms_with_send_config(payload) {
        return axios.post(`${RESOURCE_NAME}/send-sms-with-send-config`, payload)
    },
};