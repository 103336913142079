import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/appliance';

export default {
    get_all_models(appliance_type=null) {
        return axios.get(`${RESOURCE_NAME}/model`, {params: {appliance_type}})
    },
    get_all_appliance_by_flat(flat_id) {
        return axios.get(`${RESOURCE_NAME}/`, {params: {flat_id}})
    },
    get_all_operation_journal_by_flat(flat_id) {
        return axios.get(`${RESOURCE_NAME}/operation-journal`, {params: {flat_id}})
    },
    create_model(payload) {
        return axios.post(`${RESOURCE_NAME}/model`, payload)
    },
    create_appliance_by_flat(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    create_operation_journal(payload) {
        return axios.post(`${RESOURCE_NAME}/operation-journal`, payload)
    },
    update_model(payload) {
        return axios.post(`${RESOURCE_NAME}/update/model`, payload)
    },
    update_appliance_by_flat(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    update_operation_journal(payload) {
        return axios.post(`${RESOURCE_NAME}/update/operation-journal`, payload)
    },
    remove_model(payload) {
        return axios.post(`${RESOURCE_NAME}/remove/model`, { "appliance_model_id": payload })
    },
    remove_appliance_by_flat(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { "appliance_by_flat_id": payload })
    },
    remove_operation_journal(payload) {
        return axios.post(`${RESOURCE_NAME}/remove/operation-journal`, { "appliance_operation_journal_id": payload })
    },
    select_model() {
        return axios.post(`${RESOURCE_NAME}/select/model`)
    },
};