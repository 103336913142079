import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/work-month';

export default {
    get_current_month() {
        return axios.get(`${RESOURCE_NAME}/`)
    },
    get_current_legacy_month() {
        return axios.get(`${RESOURCE_NAME}/legacy`)
    },
    close_month() {
        return axios.post(`${RESOURCE_NAME}/close-month`)
    },
    close_legacy_month() {
        return axios.post(`${RESOURCE_NAME}/legacy/close-month`)
    },
    open_month() {
        return axios.post(`${RESOURCE_NAME}/open-month`)
    },
    open_legacy_month() {
        return axios.post(`${RESOURCE_NAME}/legacy/open-month`)
    },
}
