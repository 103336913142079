import {ALERT_CLEAN, ALERT_HIDE, ALERT_SHOW} from "@/store/actions/alert";

const state = {
    alert: {},
    active: false,
    before_install_prompt: null
}

const getters = {
    alert_conf: state => state.alert,
    alert_status: state => state.active,
    get_install_prompt: state => state.before_install_prompt
}

const actions = {
    [ALERT_SHOW]: ({ commit }, data) => {
        commit(ALERT_SHOW, data)
    },
    [ALERT_HIDE]: ({ commit }) => {
        commit(ALERT_HIDE)
    }
}

const mutations = {
    [ALERT_SHOW]: (state, data) => {
        state.alert = data
        state.active = true
    },
    [ALERT_HIDE]: state => {
        state.active = false
    },
    [ALERT_CLEAN]: state => {
        state.alert = {}
    },
    'BEFORE_INSTALL_PROMPT': (state, data) => {
        state.before_install_prompt = data
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}