export const FETCH_FLAT_INDICATORS = 'FETCH_FLAT_INDICATORS'
export const FETCH_FLAT_INDICATOR_TYPES = 'FETCH_FLAT_INDICATOR_TYPES'
export const CREATE_FLAT_INDICATOR = 'CREATE_FLAT_INDICATOR'
export const UPDATE_FLAT_INDICATOR = 'UPDATE_FLAT_INDICATOR'
export const DELETE_FLAT_INDICATOR = 'DELETE_FLAT_INDICATOR'

export const GET_FLAT_INDICATOR_TYPE = 'GET_FLAT_INDICATOR_TYPE'
export const CREATE_FLAT_INDICATOR_TYPE = 'CREATE_FLAT_INDICATOR_TYPE'
export const UPDATE_FLAT_INDICATOR_TYPE = 'UPDATE_FLAT_INDICATOR_TYPE'
export const REMOVE_FLAT_INDICATOR_TYPE = 'REMOVE_FLAT_INDICATOR_TYPE'

export const GET_UNIT_TYPES = 'GET_UNIT_TYPES'
export const CREATE_UNIT_TYPE = 'CREATE_UNIT_TYPE'
export const UPDATE_UNIT_TYPE = 'UPDATE_UNIT_TYPE'
export const REMOVE_UNIT_TYPE = 'REMOVE_UNIT_TYPE'
export const SELECT_UNIT_TYPE = 'SELECT_UNIT_TYPE'

export const FETCH_LEGACY_FLAT_INDICATORS = 'FETCH_LEGACY_FLAT_INDICATORS'
export const CREATE_LEGACY_FLAT_INDICATOR = 'CREATE_LEGACY_FLAT_INDICATOR'
export const UPDATE_LEGACY_FLAT_INDICATOR = 'UPDATE_LEGACY_FLAT_INDICATOR'
export const DELETE_LEGACY_FLAT_INDICATOR = 'DELETE_LEGACY_FLAT_INDICATOR'