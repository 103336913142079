const state = {
    socket_data: null
}

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //Включно з мінімальним та виключаючи максимальне значення

}

const getters = {
    get_socket: state => state.socket_data
}

const mutations = {
    ["WS_MESSAGE"]: (state, payload) => {
        state.socket_data = Object.assign({}, { data: payload, rid: getRandomInt(1, 99999) })
    },
}

export default {
    state,
    getters,
    mutations
}