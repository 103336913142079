import Vue from 'vue'
import api from "@/utils/axios/user"
import orgAPI from "@/utils/axios/organization"

import {
    USER_REQUEST,
    USER_ERROR,
    USER_SUCCESS,
    USER_SET_CURRENT_ORGANIZATION,
    USER_SET_ORGANIZATIONS,
    USER_SET_FLATS,
    USER_SET_CURRENT_FLAT,
    USER_GET_ALL_BY_ORG,
    USER_SET_NEW_PASSWORD,
    USER_UPDATE,
    USER_CREATE,
    USER_SET_ACCOUNTING,
    USER_PROFILE_UPDATE,
    USER_SET_LEGACY,
    USER_RANDOM_FLAT, USER_SET_DISPATCHER,
} from "../actions/user";
import {AUTH_LOGOUT} from "../actions/auth";
import {GET_CURRENT_MONTH, GET_CURRENT_LEGACY_MONTH} from "@/store/actions/work_month";
import {
    SELECT_SERVICES,
    SELECT_SERVICES_WITH_COUNTER,
    SELECT_SERVICES_WITHOUT_ALL_SERVICE
} from "@/store/actions/services";
import {SELECT_BANK} from "@/store/actions/bank";
import {GET_ACCOUNTING_GENERAL_SETTINGS, GET_GENERAL_SETTINGS} from "@/store/actions/organization";
import {ALERT_SHOW} from "@/store/actions/alert";
import {VOTE_GET_STATS} from "@/store/actions/vote";
import {BALANCE_SHOW} from "@/store/actions/balance_soap";
import {GET_ALL_USER_MESSAGE} from "@/store/actions/user_message_history";

const state = {
    status: "",
    profile: {},
    organizations: [],
    organization: {},
    user_flats: [],
    user_flat: {},
    cab: false,
    users: [],
    flat_accounting: false,
    role: '',
    accounting: false,
    dispatcher: false,
    is_legacy: false,
    desktop_condition: {accounting: false, is_legacy: false}
};

const getters = {
    getProfile: state => state.profile,
    getProfileName: state => {
        const profile = state.profile || {}
        const roles = profile.scoped || []

        if (roles.includes('superuser')) {
            return 'Суперкористувач'
        }
        if (roles.includes('admin')) {
            return 'Адміністратор'
        }
        if (roles.includes('user')) {
            return 'Користувач'
        }
        return 'Обмежені права'
    },
    getUserName: state => state.profile.name || '',
    getUserCheckerId: state => state.profile.checker_id || null,
    getUserId: state => state.profile.id || null,
    isProfileLoaded: state => !!state.profile.name,
    getOrganizations: state => state.organizations,
    getCurrentOrganization: state => state.organization,
    getUserFlats: state => state.user_flats || [],
    getCurrentUserFlat: state => state.user_flat,
    getPersonalCabinetHome: state => state.cab && !state.flat_accounting,
    getUsers: state => state.users,
    isSuperUser: state => (state.profile.scoped || []).includes('superuser'),
    isPaysUser: state => (state.profile.scoped || []).includes('pays'),
    isAccounting: state => state.accounting,
    isDispatcher: state => state.dispatcher,
    isLegacy: state => state.is_legacy,
    desktopCondition: state => state.desktop_condition,
    isAdmin: state => (state.profile.scoped || []).includes('admin'),
    simple_user: state => !(state.profile.scoped || []).includes('admin') && !(state.profile.scoped || []).includes('superuser'),
};

const actions = {
    // eslint-disable-next-line no-unused-vars
    [USER_REQUEST]: ({commit, dispatch}) => {
        commit(USER_REQUEST);
        api.get_user()
            .then(resp => {
                const data = resp.data;
                delete data.roles;
                const legacy = data?.accounting_db?.legacy || false
                // const cabinet_only = data.organization.cabinet_only
                // const its_cabinet_user = data.scoped.includes('cabinet')

                commit(USER_SUCCESS, data);
                commit(USER_SET_ORGANIZATIONS)
                commit(USER_SET_CURRENT_ORGANIZATION)
                commit(USER_SET_FLATS)
                commit(USER_SET_CURRENT_FLAT)
                if (legacy) {
                    dispatch(GET_CURRENT_LEGACY_MONTH)
                    // if (!cabinet_only && !its_cabinet_user) {
                    //     dispatch(SELECT_LEGACY_FLATS)
                    // }
                    dispatch(GET_ACCOUNTING_GENERAL_SETTINGS)
                } else {
                    dispatch(GET_CURRENT_MONTH)
                    // if (!cabinet_only && !its_cabinet_user) {
                    //     dispatch(SELECT_FLATS)
                    // }
                }
                dispatch(SELECT_SERVICES)
                dispatch(SELECT_SERVICES_WITHOUT_ALL_SERVICE)
                dispatch(SELECT_SERVICES_WITH_COUNTER)
                dispatch(SELECT_BANK)
                dispatch(GET_GENERAL_SETTINGS)
                dispatch(VOTE_GET_STATS)
                dispatch(GET_ALL_USER_MESSAGE)
                dispatch(BALANCE_SHOW)
            })
            .catch(() => {
                commit(USER_ERROR);
                dispatch(AUTH_LOGOUT);
            });
    },
    [USER_GET_ALL_BY_ORG]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            api.get_all_by_org(payload)
                .then(response => response.data)
                .then(data => {
                    commit(USER_GET_ALL_BY_ORG, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [USER_CREATE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            api.create_user(payload)
                .then(response => response.data)
                .then(data => {
                    commit(USER_CREATE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })

    },
    [USER_UPDATE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            api.update_user(payload)
                .then(response => response.data)
                .then(data => {
                    commit(USER_UPDATE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })

    },
    [USER_PROFILE_UPDATE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            api.update_profile(payload)
                .then(response => response.data)
                .then(data => {
                    commit(USER_PROFILE_UPDATE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })

    },
    [USER_SET_NEW_PASSWORD]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            api.set_new_password(payload)
                .then(response => response.data)
                .then(() => {
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })

    },
    [USER_RANDOM_FLAT]: ({commit}) => {
        return new Promise((resolve, reject) => {
            api.get_random_flat()
                .then(response => response.data)
                .then((data) => {
                    commit(USER_RANDOM_FLAT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    }
};

const mutations = {
    [USER_SET_ACCOUNTING]: (state, payload) => {
        state.accounting = payload
        state.desktop_condition.accounting = payload
    },
    [USER_SET_LEGACY]: (state, payload) => {
        state.is_legacy = payload
        state.desktop_condition.is_legacy = payload
    },
    [USER_SET_DISPATCHER]: (state, payload) => {
        state.dispatcher = payload
        state.desktop_condition.dispatcher = payload
    },
    [USER_GET_ALL_BY_ORG]: (state, payload) => {
        state.users = payload;
    },
    [USER_REQUEST]: state => {
        state.status = "loading";
    },
    [USER_SUCCESS]: (state, resp) => {
        state.status = "success";
        const data = Object.assign({}, resp)

        state.accounting = data?.accounting_db?.accounting || false
        state.is_legacy = data?.accounting_db?.legacy || false
        state.dispatcher = data?.accounting_db?.dispatcher || false
        state.desktop_condition = {accounting: state.accounting, is_legacy: state.is_legacy, dispatcher: state.dispatcher}

        if (data.scoped) {
            state.cab = data.scoped.includes('cabinet')
            state.flat_accounting = (data.scoped.includes('admin') || data.scoped.includes('flat_manager')) || false
            state.role = data.scoped.length ? data.scoped[0] : 'without-role'
        } else {
            state.cab = false
            state.role = ''
            state.flat_accounting = false
        }
        Vue.set(state, "profile", data);

    },
    [USER_ERROR]: state => {
        state.status = "error";
    },
    [AUTH_LOGOUT]: state => {
        state.profile = {};
        state.organizations = [];
        state.organization = {};
    },
    [USER_SET_ORGANIZATIONS]: state => {
        Vue.set(state, "organizations", state.profile.organizations || []);
        orgAPI.get_additional_information_for_organizations()
            .then(response => response.data)
            .then(data => {
                if (data) {
                    Object.keys(data).forEach(key => {
                        const key_numeric = +key
                        const find = state.profile.organizations.find(i => i.id === key_numeric)
                        if (find) {
                            find.current_month = data[key].current_month
                            find.send_month = data[key].send_month
                        }
                    })
                }
            })
    },
    [USER_SET_FLATS]: state => {
        Vue.set(state, "user_flats", state.profile.flats || []);
    },
    [USER_SET_CURRENT_ORGANIZATION]: (state, payload) => {
        if (!payload) {
            if (!state.profile.organization) {
                Vue.set(state, "organization", state.profile.organizations[0] || {});
            } else {
                Vue.set(state, "organization", state.profile.organization || {});
            }
        } else {
            Vue.set(state, "organization", payload || {});
        }
    },
    [USER_SET_CURRENT_FLAT]: (state, payload) => {
        if (!payload) {
            if (!state.profile.flat) {
                Vue.set(state, "user_flat", state.profile.flats[0] || {});
            } else {
                Vue.set(state, "user_flat", state.profile.flat || {});
            }
        } else {
            Vue.set(state, "user_flat", payload || {});
        }
    },
    [USER_RANDOM_FLAT]: (state, payload) => {
        Vue.set(state, "user_flat", payload || {});
        if (payload) {
            state.user_flats = [payload]
        } else {
            state.user_flats = []
        }
    },
    [USER_UPDATE]: (state, payload) => {
        let obj = state.users.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [USER_PROFILE_UPDATE]: (state, payload) => {
        let obj = state.users.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
        state.profile.name = payload.name
        state.profile.email = payload.email
        state.profile.checker_id = payload.checker_id
        state.profile.position_id = payload.position_id
    },
    [USER_CREATE]: (state, payload) => {
        if (payload) {
            state.users.push(payload)
        }
    },
    ['SET_NEW_ORGANIZATION_IN_PROFILE']: (state, payload) => {
        Object.assign({}, state.profile.organization, payload.organization)
        Object.assign({}, state.organization, payload.organization)

        state.profile.organizations = payload.organizations
        state.organizations = payload.organizations
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};