import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/bank';

export default {
    get_banks() {
        return axios.get(`${RESOURCE_NAME}/`)
    },
    get_bank_accounts(payload) {
        if (payload) {
            return axios.get(`${RESOURCE_NAME}/accounts`, {params: {organization_id: payload}})
        } else {
            return axios.get(`${RESOURCE_NAME}/accounts`)
        }
    },
    get_bank_accounts_by_building(payload) {
        return axios.get(`${RESOURCE_NAME}/accounts/by-building`, {params: {building_id: payload}})
    },
    create_bank(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    create_bank_account(payload) {
        return axios.post(`${RESOURCE_NAME}/accounts/create`, payload)
    },
    update_bank(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    update_bank_account(payload) {
        return axios.post(`${RESOURCE_NAME}/accounts/update`, payload)
    },
    remove_bank(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { "bank_id": payload })
    },
    remove_bank_account(payload) {
        return axios.post(`${RESOURCE_NAME}/accounts/remove`, { "bank_account_id": payload })
    },
    select_bank() {
        return axios.post(`${RESOURCE_NAME}/select`)
    },
    select_bank_accounts() {
        return axios.post(`${RESOURCE_NAME}/accounts/select`)
    }
};