export const APPEAL_ITEM_GET_ALL = "APPEAL_ITEM_GET_ALL"
export const APPEAL_ITEM_CREATE = "APPEAL_ITEM_CREATE"
export const APPEAL_ITEM_UPDATE = "APPEAL_ITEM_UPDATE"
export const APPEAL_ITEM_REMOVE = "APPEAL_ITEM_REMOVE"
export const APPEAL_ITEM_READ = "APPEAL_ITEM_READ"
export const APPEAL_ITEM_RATE = "APPEAL_ITEM_RATE"

export const APPEAL_FILE_UPDATE = "APPEAL_FILE_UPDATE"

export const APPEAL_ADD = "APPEAL_ADD"
export const APPEAL_UPDATE_OPTION = "APPEAL_UPDATE_OPTION"

export const APPEAL_COMMENT_GET_ALL = "APPEAL_COMMENT_GET_ALL"
export const APPEAL_COMMENT_CREATE = "APPEAL_COMMENT_CREATE"
export const APPEAL_CLEAR_COMMENTS = "APPEAL_CLEAR_COMMENTS"
export const APPEAL_UPDATE_COMMENT_COUNT = "APPEAL_UPDATE_COMMENT_COUNT"
