export const GET_ORGANIZATION_DOCUMENTS = "GET_ORGANIZATION_DOCUMENTS"
export const CREATE_ORGANIZATION_DOCUMENTS = "CREATE_ORGANIZATION_DOCUMENTS"
export const UPDATE_ORGANIZATION_DOCUMENTS = "UPDATE_ORGANIZATION_DOCUMENTS"
export const REMOVE_ORGANIZATION_DOCUMENTS = "REMOVE_ORGANIZATION_DOCUMENTS"
export const GET_GROUP_DOCUMENT_TYPE = "GET_GROUP_DOCUMENT_TYPE"
export const CREATE_GROUP_DOCUMENT_TYPE = "CREATE_GROUP_DOCUMENT_TYPE"
export const UPDATE_GROUP_DOCUMENT_TYPE = "UPDATE_GROUP_DOCUMENT_TYPE"
export const REMOVE_GROUP_DOCUMENT_TYPE = "REMOVE_GROUP_DOCUMENT_TYPE"
export const GET_DOCUMENT_TYPE = "GET_DOCUMENT_TYPE"
export const CREATE_DOCUMENT_TYPE = "CREATE_DOCUMENT_TYPE"
export const UPDATE_DOCUMENT_TYPE = "UPDATE_DOCUMENT_TYPE"
export const REMOVE_DOCUMENT_TYPE = "REMOVE_DOCUMENT_TYPE"