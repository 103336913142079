import {axios} from '@/utils/axios';

const RESOURCE_NAME = '/accounting/personal';

export default {
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, {"delete_id": payload})
    },
    getHistory(payload) {
        return axios.get(`${RESOURCE_NAME}/document-history`, {params: {person_id: payload}})
    },
    select_elements() {
        return axios.get(`${RESOURCE_NAME}/select`)
    },
    select(payload) {
        return axios.get(`${RESOURCE_NAME}/select`, payload)
    },
    get_main_actual(payload) {
        return axios.get(`${RESOURCE_NAME}/main-actual`, {
            params: {
                date: payload,
            }
        })
    },
};