import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/counter-transfer-coefficient';

export default {
    get_all() {
        return axios.get(`${RESOURCE_NAME}/`)
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { "counter_transfer_coefficient_id": payload })
    },
};