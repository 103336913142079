import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/vote';

export default {
    get_all(payload) {
        return axios.post(`${RESOURCE_NAME}/all`, payload)
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
    update_read(payload) {
        return axios.post(`${RESOURCE_NAME}/update-read`, { vote_id: payload })
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { vote_id: payload })
    },
    vote_add(payload) {
        return axios.post(`${RESOURCE_NAME}/vote`, { question_id: payload })
    },
    vote_comment(payload) {
        return axios.get(`${RESOURCE_NAME}/vote-comment`, {params: {vote_id: payload}})
    },
    vote_comment_create(payload) {
        return axios.post(`${RESOURCE_NAME}/vote-comment`, payload)
    },
    vote_stats() {
        return axios.post(`${RESOURCE_NAME}/vote-stats`)
    },
    vote_last_date() {
        return axios.post(`${RESOURCE_NAME}/vote-date-last`)
    },
    vote_result(payload) {
        return axios.post(`${RESOURCE_NAME}/vote-result`, { vote_id: payload })
    }
}
