import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/flat-documents';

export default {
    get_flat_documents(payload) {
        return axios.get(`${RESOURCE_NAME}/`, { params: { flat_id: payload } })
    },
    create_flat_document(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
    update_flat_document(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
    remove_flat_document(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { "document_id": payload })
    },
};