import {BALANCE_CLEAN, BALANCE_SHOW, BALANCE_HIDE} from "@/store/actions/balance_soap";
import soapAPI from "@/utils/axios/soap"
import {ALERT_SHOW} from "@/store/actions/alert";

const state = {
    balance: {},
    active: false
}

const getters = {
    balance_conf: state => state.balance,
    balance_status: state => state.active,
    balance_is_payed: state => ((state.balance || {}).actual || true)
}

const actions = {
    [BALANCE_SHOW]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            soapAPI.get_balance()
                .then(response => response.data)
                .then(data => {
                    commit(BALANCE_SHOW, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    reject(false)
                })
        })
    },
    [BALANCE_HIDE]: ({ commit }) => {
        commit(BALANCE_HIDE)
    }
}

const mutations = {
    [BALANCE_SHOW]: (state, data) => {
        state.balance = data
        state.active = !((state.balance || {}).actual)
    },
    [BALANCE_HIDE]: state => {
        state.active = false
    },
    [BALANCE_CLEAN]: state => {
        state.balance = {}
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}