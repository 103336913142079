import {
    FETCH_TARIFF_GROUP_BY_FLAT,
    FETCH_TARIFF_GROUP_BY_LEGACY_FLAT,
    FETCH_TARIFF_GROUP_SELECT,
    CREATE_TARIFF_GROUP,
    CREATE_TARIFF_LEGACY_GROUP,
    UPDATE_TARIFF_GROUP,
    UPDATE_TARIFF_LEGACY_GROUP,
    DELETE_TARIFF_GROUP,
    DELETE_TARIFF_LEGACY_GROUP,
    FETCH_TARIFF_GROUP_TYPE,
    CREATE_TARIFF_GROUP_TYPE,
    UPDATE_TARIFF_GROUP_TYPE,
    DELETE_TARIFF_GROUP_TYPE
} from "@/store/actions/tariff_group";

import tariffGroupAPI from "@/utils/axios/tariff_group"

import {ALERT_SHOW} from "@/store/actions/alert";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = { tariff_groups: [], tariff_group_status: false, tariff_group_select: [], tariff_group_types: [] }

const getters = {
    getTariffGroup: state => state.tariff_groups,
    getTariffGroupActive: state => {
        if (state.tariff_groups.length === 1) {
            return state.tariff_groups
        }
        return state.tariff_groups.filter(i => i.date_end === null)
    },
    getTariffLegacyGroup: state => state.tariff_groups,
    getTariffGroupSelect: state => state.tariff_group_select,
    getTariffGroupType: state => state.tariff_group_types
}

const actions = {
    [FETCH_TARIFF_GROUP_BY_FLAT]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            tariffGroupAPI.tariff_group_by_flat(payload)
                .then(response => response.data)
                .then(data => {
                    commit(FETCH_TARIFF_GROUP_BY_FLAT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [FETCH_TARIFF_GROUP_BY_LEGACY_FLAT]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            tariffGroupAPI.tariff_group_by_legacy_flat(payload)
                .then(response => response.data)
                .then(data => {
                    commit(FETCH_TARIFF_GROUP_BY_LEGACY_FLAT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [FETCH_TARIFF_GROUP_TYPE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            tariffGroupAPI.tariff_group_type(payload)
                .then(response => response.data)
                .then(data => {
                    commit(FETCH_TARIFF_GROUP_TYPE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [FETCH_TARIFF_GROUP_SELECT]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            tariffGroupAPI.tariff_group_type_select()
                .then(response => response.data)
                .then(data => {
                    commit(FETCH_TARIFF_GROUP_SELECT, data)
                    resolve(true)
                }).catch(err => {
                const error = err.response.data.detail;
                commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                reject(false)
            })
        })
    },
    [CREATE_TARIFF_GROUP]: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            tariffGroupAPI.tariff_group_create(payload)
                .then(response => response.data)
                .then(() => {
                    dispatch(FETCH_TARIFF_GROUP_BY_FLAT, payload.person_hash)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CREATE_TARIFF_LEGACY_GROUP]: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            tariffGroupAPI.tariff_group_legacy_create(payload)
                .then(response => response.data)
                .then(() => {
                    dispatch(FETCH_TARIFF_GROUP_BY_LEGACY_FLAT, payload.person_hash)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CREATE_TARIFF_GROUP_TYPE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            tariffGroupAPI.tariff_group_type_create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_TARIFF_GROUP_TYPE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_TARIFF_GROUP]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            tariffGroupAPI.tariff_group_update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_TARIFF_GROUP, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_TARIFF_LEGACY_GROUP]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            tariffGroupAPI.tariff_group_legacy_update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_TARIFF_LEGACY_GROUP, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_TARIFF_GROUP_TYPE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            tariffGroupAPI.tariff_group_type_update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_TARIFF_GROUP_TYPE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_TARIFF_GROUP]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            tariffGroupAPI.remove(payload)
                .then(response => response.data)
                .then(() => {
                    commit(DELETE_TARIFF_GROUP, payload)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_TARIFF_LEGACY_GROUP]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            tariffGroupAPI.remove_legacy(payload)
                .then(response => response.data)
                .then(() => {
                    commit(DELETE_TARIFF_LEGACY_GROUP, payload)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_TARIFF_GROUP_TYPE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            tariffGroupAPI.tariff_group_type_remove(payload)
                .then(response => response.data)
                .then(() => {
                    commit(DELETE_TARIFF_GROUP_TYPE, payload)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    }
}

const mutations = {
    [FETCH_TARIFF_GROUP_BY_FLAT]: (state, payload) => {
        state.tariff_groups = payload
    },
    [FETCH_TARIFF_GROUP_BY_LEGACY_FLAT]: (state, payload) => {
        state.tariff_groups = payload
    },
    [FETCH_TARIFF_GROUP_TYPE]: (state, payload) => {
        state.tariff_group_types = payload
    },
    [FETCH_TARIFF_GROUP_SELECT]: (state, payload) => {
        state.tariff_group_select = payload
    },
    [CREATE_TARIFF_GROUP]: (state, payload) => {
        state.tariff_groups.push(payload)
    },
    [CREATE_TARIFF_LEGACY_GROUP]: (state, payload) => {
        state.tariff_groups.push(payload)
    },
    [CREATE_TARIFF_GROUP_TYPE]: (state, payload) => {
        state.tariff_group_types.push(payload)
        state.tariff_group_select.push(
            {
                text: payload.name,
                value: payload.id
            }
        )
    },
    [UPDATE_TARIFF_GROUP]: (state, payload) => {
        let obj = state.tariff_groups.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [UPDATE_TARIFF_LEGACY_GROUP]: (state, payload) => {
        let obj = state.tariff_groups.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [UPDATE_TARIFF_GROUP_TYPE]: (state, payload) => {
        let obj = state.tariff_group_types.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })

        let select_obj = state.tariff_group_select.find(item => item.id === payload.id)
        if (select_obj) {
            select_obj.text = payload.name
            select_obj.value = payload.id
        }
    },
    [DELETE_TARIFF_GROUP]: (state, payload) => {
        const obg = state.tariff_groups.find(item => item.id === payload)
        const obj_id = state.tariff_groups.indexOf(obg)
        if (obg) {
            state.tariff_groups.splice(obj_id, 1)
        }
    },
    [DELETE_TARIFF_LEGACY_GROUP]: (state, payload) => {
        const obg = state.tariff_groups.find(item => item.id === payload)
        const obj_id = state.tariff_groups.indexOf(obg)
        if (obg) {
            state.tariff_groups.splice(obj_id, 1)
        }
    },
    [DELETE_TARIFF_GROUP_TYPE]: (state, payload) => {
        const obg = state.tariff_group_types.find(item => item.id === payload)
        const obj_id = state.tariff_group_types.indexOf(obg)
        if (obg) {
            state.tariff_group_types.splice(obj_id, 1)
        }

        let select_obj = state.tariff_group_select.find(item => item.id === payload)
        if (select_obj) {
            const select_ob_idx = state.tariff_group_select.indexOf(select_obj)
            if (select_ob_idx !== -1) {
                state.tariff_group_select.splice(select_ob_idx, 1)
            }
        }
    },
}


export default {
    state,
    getters,
    actions,
    mutations
}