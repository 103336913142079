import {ALERT_SHOW} from "@/store/actions/alert";

import spreedSheetAPI from "@/utils/axios/spreed_sheet"
import {
    CREATE_SPREED_SHEET,
    DELETE_SPREED_SHEET,
    GET_ALL_SPREED_SHEET,
    SELECT_SPREED_SHEET,
    UPDATE_SPREED_SHEET
} from "@/store/actions/spreed_sheet";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = { spreed_sheets: [], select_spreed_sheets: [], select_loaded: false }

const getters = {
    getSpreedSheets: state => state.spreed_sheets,
    getSelectSpreedSheets: state => state.select_spreed_sheets,
}

const actions = {
    [GET_ALL_SPREED_SHEET]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            spreedSheetAPI.get_all(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_ALL_SPREED_SHEET, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_SPREED_SHEET]: ({ dispatch, commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            spreedSheetAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_SPREED_SHEET, data)
                    dispatch(SELECT_SPREED_SHEET)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_SPREED_SHEET]: ({ commit }, payload) => {
        commit(START_MODAL_LOADING)
        return new Promise((resolve, reject) => {
            spreedSheetAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_SPREED_SHEET, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_SPREED_SHEET]: ({ dispatch, commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            spreedSheetAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(DELETE_SPREED_SHEET, data)
                    dispatch(SELECT_SPREED_SHEET)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_SPREED_SHEET]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            spreedSheetAPI.select(payload)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_SPREED_SHEET, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
}

const mutations = {
    [GET_ALL_SPREED_SHEET]: (state, payload) => {
        state.spreed_sheets = payload
    },
    [CREATE_SPREED_SHEET]: (state, payload) => {
        state.spreed_sheets.push(payload)
    },
    [UPDATE_SPREED_SHEET]: (state, payload) => {
        let obj = state.spreed_sheets.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [DELETE_SPREED_SHEET]: (state, payload) => {
        const obg = state.spreed_sheets.find(item => item.id === payload.id)
        const obj_id = state.spreed_sheets.indexOf(obg)
        if (obg) {
            state.spreed_sheets.splice(obj_id, 1)
        }
    },
    [SELECT_SPREED_SHEET]: (state, payload) => {
        state.select_spreed_sheets = payload
        state.select_loaded = true
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}