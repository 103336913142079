import {
    GENERAL_REGISTRATION_SHOW,
    GENERAL_REGISTRATION_HIDE,
    GENERAL_REGISTRATION_STATUS
} from "@/store/actions/general_registration_of_changes";

const state = {
    general_registration_of_changes_show: false,
    general_registration_of_changes_item: {},
    general_registration_of_changes_status: {}
}

const getters = {
    getGeneralRegistrationOfChangesShow: state => state.general_registration_of_changes_show,
    getGeneralRegistrationOfChangesItem: state => state.general_registration_of_changes_item,
    getGeneralRegistrationOfChangesStatus: state => state.general_registration_of_changes_status,
}

const actions = {
    [GENERAL_REGISTRATION_SHOW]: ({ commit }, data) => {
        commit(GENERAL_REGISTRATION_STATUS, {})
        commit(GENERAL_REGISTRATION_SHOW, data)
    },
    [GENERAL_REGISTRATION_HIDE]: ({ commit }) => {
        commit(GENERAL_REGISTRATION_HIDE)
    },
    [GENERAL_REGISTRATION_STATUS]: ({ commit }, data) => {
        commit(GENERAL_REGISTRATION_STATUS, data)
    },
}

const mutations = {
    [GENERAL_REGISTRATION_SHOW]: (state, data) => {
        state.general_registration_of_changes_item = Object.assign({}, data)
        state.general_registration_of_changes_show = true
    },
    [GENERAL_REGISTRATION_HIDE]: state => {
        state.general_registration_of_changes_item = Object.assign({}, {})
        state.general_registration_of_changes_show = false
    },
    [GENERAL_REGISTRATION_STATUS]: (state, data) => {
        state.general_registration_of_changes_status = data
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}