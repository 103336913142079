<template>
  <v-app v-cloak>
    <template v-if="!data_loading">
      <Navigation/>
      <v-main class="grey lighten-5">
        <div class="site-section-wrapper" id="bg-1">
          <div class="site-section">
            <slot/>
          </div>
        </div>
      </v-main>
    </template>
    <v-snackbar
        :timeout="4000"
        :color="alert_conf.color"
        :value="show_alert"
        @input="hide_alert"
    >
      {{ alert_conf.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
            dark
            icon
            v-bind="attrs"
            @click="hide_alert"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <GeneralQuestion/>
  </v-app>
</template>

<script>
import GeneralQuestion from "@/components/modal/GeneralQuestion";
import {ALERT_HIDE} from "@/store/actions/alert";
import {mapActions, mapGetters} from "vuex";
import {GET_SITE_CONFIG, GET_SITE_ORGANIZATION_DATA} from "@/store/actions/personalSite/site";

export default {
  name: "SiteLayout",
  components: {
    GeneralQuestion,
    Navigation: () => import("@/components/PersonalSite/Navigation"),
  },
  computed: {
    ...mapGetters({
      site_organization: 'getSiteOrganization',
      site_config: 'getSiteConfig'
    }),
    show_alert() {
      return this.$store.getters.alert_status
    },
    alert_conf() {
      return this.$store.getters.alert_conf
    },
  },
  data() {
    return {
      data_loading: true,
    }
  },
  methods: {
    ...mapActions({
      getSiteOrganization: GET_SITE_ORGANIZATION_DATA,
    }),
    hide_alert() {
      this.$store.commit(ALERT_HIDE, this.alert_conf);
    }
  },
  created() {
    const organization_slug = this.$route.params.organization || ''
    if (organization_slug) {
      this.getSiteOrganization(organization_slug)
        .then(() => {
          this.$store.dispatch(GET_SITE_CONFIG, organization_slug)
            .then(site_enable => {
              if (site_enable) {
                this.data_loading = false
              } else {
                this.data_loading = true
                this.$router.push({ name: 'SiteBlocked' })
              }
            })
        })
        .catch(() => {
          this.data_loading = false
          this.$router.push({ name: 'NotFoundSite' })
        })
    }
  }
}
</script>

<style scoped lang="scss">
  .site-section {
    max-width: 1400px;
    margin: 0 auto;
    padding: 60px 10px;
  }
</style>