import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/soap-wsdl';

export default {
    get_balance() {
        return axios.post(`${RESOURCE_NAME}/get-balance`)
    },
    get_documents(payload) {
        return axios.post(`${RESOURCE_NAME}/get-documents`, payload)
    },
    get_in_out(payload) {
        return axios.post(`${RESOURCE_NAME}/get-in-out`, payload)
    },
    download_document(payload) {
        return axios.post(`${RESOURCE_NAME}/download-document`, payload, {responseType: 'blob'})
    },
    get_person_id() {
        return axios.post(`${RESOURCE_NAME}/get-person-id`)
    },
    create_person_id(payload) {
        return axios.post(`${RESOURCE_NAME}/create-person-id`, payload)
    }
};