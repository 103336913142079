export const GET_ALL_APPLIANCE_MODEL = "GET_ALL_APPLIANCE_MODEL"
export const CREATE_APPLIANCE_MODEL = "CREATE_APPLIANCE_MODEL"
export const UPDATE_APPLIANCE_MODEL = "UPDATE_APPLIANCE_MODEL"
export const DELETE_APPLIANCE_MODEL = "DELETE_APPLIANCE_MODEL"
export const SELECT_APPLIANCE_MODEL = "SELECT_APPLIANCE_MODEL"

export const GET_ALL_APPLIANCE_BY_FLAT = "GET_ALL_APPLIANCE_BY_FLAT"
export const CREATE_APPLIANCE_BY_FLAT = "CREATE_APPLIANCE_BY_FLAT"
export const UPDATE_APPLIANCE_BY_FLAT = "UPDATE_APPLIANCE_BY_FLAT"
export const DELETE_APPLIANCE_BY_FLAT = "DELETE_APPLIANCE_BY_FLAT"

export const APPLIANCE_BY_FLAT_CURRENT_SELECT = 'APPLIANCE_BY_FLAT_CURRENT_SELECT'

export const GET_ALL_APPLIANCE_OPERATION_JOURNAL = "GET_ALL_APPLIANCE_OPERATION_JOURNAL"
export const CREATE_APPLIANCE_OPERATION_JOURNAL = "CREATE_APPLIANCE_OPERATION_JOURNAL"
export const UPDATE_APPLIANCE_OPERATION_JOURNAL = "UPDATE_APPLIANCE_OPERATION_JOURNAL"
export const DELETE_APPLIANCE_OPERATION_JOURNAL = "DELETE_APPLIANCE_OPERATION_JOURNAL"