export const GET_SITE_ORGANIZATION_DATA = "GET_SITE_ORGANIZATION_DATA"
export const GET_SITE_CONFIG = "GET_SITE_CONFIG"
export const GET_SITE_NOTIFY = "GET_SITE_NOTIFY"
export const GET_SITE_NEWS = "GET_SITE_NEWS"
export const GET_SITE_GALLERY = "GET_GALLERY"
export const CREATE_SITE_NOTIFY = "CREATE_SITE_NOTIFY"
export const CREATE_SITE_NEWS = "CREATE_SITE_NEWS"
export const CREATE_SITE_GALLERY = "CREATE_SITE_GALLERY"
export const UPDATE_SITE_NEWS = "UPDATE_SITE_NEWS"
export const UPDATE_SITE_NOTIFY = "UPDATE_SITE_NOTIFY"
export const UPDATE_SITE_GALLERY = "UPDATE_SITE_GALLERY"
export const REMOVE_SITE_NOTIFY = "REMOVE_SITE_NOTIFY"
export const REMOVE_SITE_NEWS = "REMOVE_SITE_NEWS"
export const REMOVE_SITE_GALLERY = "REMOVE_SITE_GALLERY"
