import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/contact';

export default {
    main(person_hash) {
        return axios.get(`${RESOURCE_NAME}/main`, { params: { flat_hash: person_hash } })
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { "contact_id": payload })
    },
};