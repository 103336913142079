import {
    VOTE_ITEM_GET_ALL,
    VOTE_ITEM_UPDATE,
    VOTE_ITEM_CREATE,
    VOTE_ITEM_REMOVE,
    VOTE_ADD, VOTE_COMMENT_GET_ALL, VOTE_COMMENT_CREATE, VOTE_UPDATE_OPTION, VOTE_ITEM_READ,
    VOTE_COMMENT_UPDATE_LAST,
    VOTE_UPDATE_USER_VOTED,
    VOTE_ITEM_SHOW_FULL_TEXT,
    VOTE_GET_STATS,
    VOTE_SET_LAST_DATE
} from "@/store/actions/vote";

import voteAPI from "@/utils/axios/vote"

import {ALERT_SHOW} from "@/store/actions/alert";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = {
    votes: [],
    comments: [],
    stats: {
        votes: 0,
        informing: 0,
    }
}

const getters = {
    getVotes: state => state.votes,
    getComments: state => state.comments,
    getVoteStats: state => state.stats
}

const actions = {
    [VOTE_ITEM_GET_ALL]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            voteAPI.get_all(payload)
                .then(response => response.data)
                .then(data => {
                    commit(VOTE_ITEM_GET_ALL, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [VOTE_COMMENT_GET_ALL]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            voteAPI.vote_comment(payload)
                .then(response => response.data)
                .then(data => {
                    commit(VOTE_COMMENT_GET_ALL, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [VOTE_ITEM_CREATE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            voteAPI.create(payload)
                .then(response => { return response.data })
                .then(data => {
                    commit(VOTE_ITEM_CREATE, data)
                    resolve(data)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [VOTE_COMMENT_CREATE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            voteAPI.vote_comment_create(payload)
                .then(response => { return response.data })
                .then(data => {
                    commit(VOTE_COMMENT_CREATE, data)
                    resolve(data)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [VOTE_ITEM_UPDATE]: ({ commit }, payload) => {
        return new Promise(((resolve, reject) => {
            commit(START_MODAL_LOADING)
            voteAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(VOTE_ITEM_UPDATE, data)
                    resolve(data)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        }))
    },
    [VOTE_ITEM_READ]: ({ commit }, payload) => {
        return new Promise(((resolve, reject) => {
            voteAPI.update_read(payload)
                .then(response => response.data)
                .then(data => {
                    commit(VOTE_ITEM_READ, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        }))
    },
    [VOTE_ITEM_REMOVE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            voteAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(VOTE_ITEM_REMOVE, data)
                    resolve(payload)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [VOTE_ADD]: ({ commit }, payload) => {
        return new Promise(((resolve, reject) => {
            voteAPI.vote_add(payload)
                .then(response => response.data)
                .then(data => {
                    commit(VOTE_ADD, data)
                    resolve(data)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        }))
    },
    [VOTE_GET_STATS]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            voteAPI.vote_stats(payload)
                .then(response => response.data)
                .then(data => {
                    commit(VOTE_GET_STATS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [VOTE_SET_LAST_DATE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            voteAPI.vote_last_date(payload)
                .then(response => response.data)
                .then(data => {
                    commit(VOTE_SET_LAST_DATE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
}

const mutations = {
    [VOTE_ITEM_GET_ALL]: (state, payload) => {
        state.votes = payload.map(item => {
            const short_text = item.text.substr(0, 300) + '...'
            return Object.assign({}, item, {short_text: short_text, show_full: item.text.length < 300})
        })
    },
    [VOTE_COMMENT_GET_ALL]: (state, payload) => {
        state.comments = payload
    },
    [VOTE_ITEM_CREATE]: (state, payload) => {
        payload.short_text = payload.text.substr(0, 300) + '...'
        payload.show_full = payload.text.length < 300
        state.votes.unshift(payload)
    },
    [VOTE_COMMENT_CREATE]: (state, payload) => {
        state.comments.unshift(payload)
    },
    [VOTE_COMMENT_UPDATE_LAST]: (state, payload) => {
       const item = state.votes.find(i => i.id === payload.vote_id)
        if (item) {
            item.last_comment_date = payload.create_date
            item.last_comment_text = payload.text
            item.message_count = payload.message_count
        }
    },
    [VOTE_ITEM_UPDATE]: (state, payload) => {
        let obj = state.votes.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
        obj.short_text = payload.text.substr(0, 300) + '...'
        obj.show_full = payload.text.length < 300
    },
    [VOTE_ITEM_READ]: (state, payload) => {
        let obj = state.votes.find(item => item.id === payload.id)
        if (obj) {
            obj.read = true
        }

    },
    [VOTE_ITEM_REMOVE]: (state, payload) => {
        const obg = state.votes.find(item => item.id === payload.id)
        if (obg) {
            state.votes.splice(state.votes.indexOf(obg), 1)
        }
    },
    [VOTE_ADD]: (state, payload) => {
        let obj = state.votes.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [VOTE_UPDATE_OPTION]: (state, payload) => {
        let obj = state.votes.find(item => item.id === payload.id)
        if (obj) {
            obj.options = payload.options
        }
    },
    [VOTE_UPDATE_USER_VOTED]: (state, payload) => {
        let obj = state.votes.find(item => item.id === payload.id)
        if (obj) {
            obj.voted = true
        }
    },
    [VOTE_ITEM_SHOW_FULL_TEXT]: (state, payload) => {
        let obj = state.votes.find(item => item.id === payload.id)
        if (obj) {
            obj.show_full = true
        }
    },
    [VOTE_GET_STATS]: (state, payload) => {
        state.stats = payload
    },
    [VOTE_SET_LAST_DATE]: (state, payload) => {
        state.stats = payload
    },
}


export default {
    state,
    getters,
    actions,
    mutations
}