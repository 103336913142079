export const GET_FLAT_MODEL_COUNTER = "GET_FLAT_MODEL_COUNTER"
export const CREATE_FLAT_MODEL_COUNTER = "CREATE_FLAT_MODEL_COUNTER"
export const UPDATE_FLAT_MODEL_COUNTER = "UPDATE_FLAT_MODEL_COUNTER"
export const SELECT_FLAT_MODEL_COUNTER = "SELECT_FLAT_MODEL_COUNTER"
export const REMOVE_FLAT_MODEL_COUNTER = "REMOVE_FLAT_MODEL_COUNTER"

export const GET_FLAT_COUNTER = "GET_FLAT_COUNTER"
export const GET_FLAT_COUNTER_BY_ID = "GET_FLAT_COUNTER_BY_ID"
export const CREATE_FLAT_COUNTER = "CREATE_FLAT_COUNTER"
export const UPDATE_FLAT_COUNTER = "UPDATE_FLAT_COUNTER"
export const REMOVE_FLAT_COUNTER = "REMOVE_FLAT_COUNTER"

export const CREATE_FLAT_COUNTER_LEGACY = "CREATE_FLAT_COUNTER_LEGACY"
export const UPDATE_FLAT_COUNTER_LEGACY = "UPDATE_FLAT_COUNTER_LEGACY"
export const REMOVE_FLAT_COUNTER_LEGACY = "REMOVE_FLAT_COUNTER_LEGACY"

export const GET_BUILDING_COUNTER = "GET_BUILDING_COUNTER"
export const GET_BUILDING_COUNTER_BY_ID = "GET_BUILDING_COUNTER_BY_ID"
export const CREATE_BUILDING_COUNTER = "CREATE_BUILDING_COUNTER"
export const UPDATE_BUILDING_COUNTER = "UPDATE_BUILDING_COUNTER"
export const REMOVE_BUILDING_COUNTER = "REMOVE_BUILDING_COUNTER"

export const COUNTER_SELECT = "COUNTER_SELECT"
export const BUILDING_COUNTER_SELECT = "BUILDING_COUNTER_SELECT"

export const GET_FLAT_COUNTER_READING = "GET_FLAT_COUNTER_READING"
export const GET_BUILDING_COUNTER_READING = "GET_BUILDING_COUNTER_READING"
export const CREATE_FLAT_COUNTER_READING = "CREATE_FLAT_COUNTER_READING"
export const REMOVE_FLAT_COUNTER_READING = "REMOVE_FLAT_COUNTER_READING"

export const CREATE_BUILDING_COUNTER_READING = "CREATE_BUILDING_COUNTER_READING"
export const REMOVE_BUILDING_COUNTER_READING = "REMOVE_BUILDING_COUNTER_READING"
export const RECALCULATION_BUILDING_COUNTER = "RECALCULATION_BUILDING_COUNTER"

export const CREATE_FLAT_COUNTER_LEGACY_READING = "CREATE_FLAT_COUNTER_LEGACY_READING"
export const REMOVE_FLAT_COUNTER_LEGACY_READING = "REMOVE_FLAT_COUNTER_LEGACY_READING"

export const GET_ALL_FLAT_COUNTER_OPERATION = "GET_ALL_FLAT_COUNTER_OPERATION"
export const CREATE_FLAT_COUNTER_OPERATION = "CREATE_FLAT_COUNTER_OPERATION"
export const UPDATE_FLAT_COUNTER_OPERATION = "UPDATE_FLAT_COUNTER_OPERATION"
export const DELETE_FLAT_COUNTER_OPERATION = "DELETE_FLAT_COUNTER_OPERATION"

export const GET_ALL_FLAT_COUNTER_SUB_ACCOUNT = "GET_ALL_FLAT_COUNTER_SUB_ACCOUNT"
export const CREATE_FLAT_COUNTER_SUB_ACCOUNT = "CREATE_FLAT_COUNTER_SUB_ACCOUNT"
export const UPDATE_FLAT_COUNTER_SUB_ACCOUNT = "UPDATE_FLAT_COUNTER_SUB_ACCOUNT"
export const DELETE_FLAT_COUNTER_SUB_ACCOUNT = "DELETE_FLAT_COUNTER_SUB_ACCOUNT"

