import {
    GET_SITE_CONFIG,
    GET_SITE_ORGANIZATION_DATA,
} from "@/store/actions/personalSite/site";

import SiteAPI from "@/utils/axios/personalSite/site"
import {ALERT_SHOW} from "@/store/actions/alert";


const state = { site_organization: {}, site_notify: [], site_news: [], site_config: {} }

const getters = {
    getSiteOrganization: state => state.site_organization,
    getSiteConfig: state => state.site_config
}

const actions = {
    [GET_SITE_ORGANIZATION_DATA]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            SiteAPI.get_organization_data(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_SITE_ORGANIZATION_DATA, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [GET_SITE_CONFIG]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            SiteAPI.get_site_config(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_SITE_CONFIG, data)
                    resolve(data.site_enable || false)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
}

const mutations = {
    [GET_SITE_ORGANIZATION_DATA]: (state, payload) => {
        state.site_organization = payload
    },
    [GET_SITE_CONFIG]: (state, payload) => {
        state.site_config = payload
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}