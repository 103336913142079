import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/users';

export default {
    authenticate_user(email, password) {
        const formData = new FormData();
        formData.append("username", email);
        formData.append("password", password)

        return axios.post(
            '/token',
            formData
        )
    },
    google_auth(payload) {
        return axios.post('/google-token', payload)
    },
    // facebook_auth(payload) {
    //     return axios.post('/facebook-token', payload)
    // },
    get_user() {
        return axios.get(`${RESOURCE_NAME}/me`)
    },
    get_all_by_org(payload) {
        return axios.get(`${RESOURCE_NAME}/`, {params: payload})
    },
    get_profile() {
        return axios.get(`${RESOURCE_NAME}/profile`)
    },
    get_roles() {
        return axios.get(`${RESOURCE_NAME}/roles`)
    },
    send_reset_code(payload) {
        return axios.post(`${RESOURCE_NAME}/send-reset`, payload)
    },
    reset_user_password(payload) {
        return axios.post(`${RESOURCE_NAME}/reset-password`, payload)
    },
    create_user(payload) {
        return axios.post(`${RESOURCE_NAME}/create-from`, payload)
    },
    update_user(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    update_profile(payload) {
        return axios.post(`${RESOURCE_NAME}/update-profile`, payload)
    },
    activate_user(payload) {
        return axios.post(`${RESOURCE_NAME}/activate`, { user_hash: payload })
    },
    register_user_in_personal_cabinet(payload) {
        return axios.post(`${RESOURCE_NAME}/register-for-personal-cabinet`, payload)
    },
    set_current(payload) {
        return axios.post(`${RESOURCE_NAME}/set`, { organization_id: payload })
    },
    set_current_flat(payload) {
        return axios.post(`${RESOURCE_NAME}/set-flat`, { flat_id: payload })
    },
    set_accounting(payload) {
        return axios.post(`${RESOURCE_NAME}/set-accounting`, { accounting: payload })
    },
    set_dispatcher(payload) {
        return axios.post(`${RESOURCE_NAME}/set-dispatcher`, { dispatcher: payload })
    },
    set_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/set-legacy`, { legacy: payload })
    },
    get_random_flat() {
        return axios.get(`${RESOURCE_NAME}/get-random-flat`)
    }
};
