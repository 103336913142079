export const FETCH_TARIFF_GROUP_BY_FLAT = "FETCH_TARIFF_GROUP_BY_FLAT"
export const FETCH_TARIFF_GROUP_BY_LEGACY_FLAT = "FETCH_TARIFF_GROUP_BY_LEGACY_FLAT"
export const FETCH_TARIFF_GROUP_SELECT = "FETCH_TARIFF_GROUP_SELECT"
export const FETCH_TARIFF_GROUP_TYPE = "FETCH_TARIFF_GROUP_TYPE"
export const CREATE_TARIFF_GROUP = "CREATE_TARIFF_GROUP"
export const CREATE_TARIFF_LEGACY_GROUP = "CREATE_TARIFF_LEGACY_GROUP"
export const CREATE_TARIFF_GROUP_TYPE = "CREATE_TARIFF_GROUP_TYPE"
export const UPDATE_TARIFF_GROUP = "UPDATE_TARIFF_GROUP"
export const UPDATE_TARIFF_LEGACY_GROUP = "UPDATE_TARIFF_LEGACY_GROUP"
export const UPDATE_TARIFF_GROUP_TYPE = "UPDATE_TARIFF_GROUP_TYPE"
export const DELETE_TARIFF_GROUP = "DELETE_TARIFF_GROUP"
export const DELETE_TARIFF_LEGACY_GROUP = "DELETE_TARIFF_LEGACY_GROUP"
export const DELETE_TARIFF_GROUP_TYPE = "DELETE_TARIFF_GROUP_TYPE"
