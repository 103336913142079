import moment from 'moment'
import numeral from 'numeral'

moment.locale('uk')
moment.updateLocale('uk', {
    weekdays : {
        standalone: 'Неділя_Понеділок_Вівторок_Середа_Четвер_П’ятниця_Субота'.split('_'),
        format: 'Неділю_Понеділок_Вівторок_Середу_Четвер_П’ятницю_Суботу'.split('_'),
        isFormat: /\[ ?[Вв] ?(?:минулу|наступну|цю)? ?\] ?dddd/
    }
});

const formatDate = (value, formatString = 'DD.MM.YYYY') => {
    if (value) {
        if (value === '0001-01-01') return null
        if (value === '0001-01-01T00:00:00') return null
        return moment(String(value)).format(formatString)
    } else {
        return ''
    }
}

const formatNumber = (value, formatString = '0.00', int=1) => {
    if (value) {
        let val = numeral(value/int).format(formatString);
        return isNaN(val) ? formatString : val

    } else {
        return formatString
    }
}

const formatToFixed = (value, digits=2) => {
    if (value) {
        if (typeof value == 'number') {
            let str = value.toString()
            const last_dot = str.lastIndexOf('.')
            let dot_add = last_dot === -1 ? digits : digits - (str.length - last_dot - 1)
            dot_add = dot_add > 0 ? dot_add : 0
            const dot = last_dot === -1 ? '.' : ''
            str = str + dot + ('0'.repeat(dot_add))
            return str
        }
    } else {
        return '0.' + ('0'.repeat(digits))
    }
}


export { formatDate, formatNumber, formatToFixed }