import {axios} from '@/utils/axios';

const RESOURCE_NAME = '/payments';

export default {
    get_headers(payload) {
        return axios.get(`${RESOURCE_NAME}/header`, {params: payload})
    },
    get_by_header(payload) {
        return axios.get(`${RESOURCE_NAME}/`, {params: {header_id: payload}})
    },
    get_by_header_legacy(payload) {
        return axios.get(`${RESOURCE_NAME}/legacy`, {params: {header_id: payload}})
    },
    get_by_flat_archive(payload) {
        return axios.get(`${RESOURCE_NAME}/archive`, {
            params: {
                person_hash: payload.person_hash,
                date_start: payload.date_start
            }
        })
    },
    get_by_flat_archive_legacy(payload) {
        return axios.get(`${RESOURCE_NAME}/legacy/archive`, {
            params: {
                person_hash: payload.person_hash,
                date_start: payload.date_start,
            }
        })
    },
    get_services_for_sub_item(payload) {
        return axios.get(`${RESOURCE_NAME}/services`, {
            params: {
                header_id: payload.header_id,
                flat_id: payload.flat_id
            }
        })
    },
    get_services_for_sub_item_legacy(payload) {
        return axios.get(`${RESOURCE_NAME}/legacy/services`, {
            params: {
                header_id: payload.header_id,
                flat_id: payload.flat_id,
                contract_id: payload.contract_id
            }
        })
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    update_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/update`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, {header_id: payload})
    },
    remove_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/remove`, {header_id: payload})
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/create`, payload)
    },
    create_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/create`, payload)
    },
    create_row(payload) {
        return axios.post(`${RESOURCE_NAME}/create/row`, payload)
    },
    create_row_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/create/row`, payload)
    },
    update_row(payload) {
        return axios.post(`${RESOURCE_NAME}/update/row`, payload)
    },
    update_row_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/update/row`, payload)
    },
    remove_row(payload) {
        return axios.post(`${RESOURCE_NAME}/remove/row`, payload)
    },
    remove_row_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/remove/row`, payload)
    },
    remove_downloaded_payments(payload) {
        return axios.post(`${RESOURCE_NAME}/remove-downloaded`, payload)
    }
}
