import {axios} from '@/utils/axios';

const WAREHOUSE_RESOURCE = '/accounting/list/warehouse';
const CURRENCY_RESOURCE = '/accounting/list/currency';
const HOUSE_ACCOUNTING_PAYMENTS_RESOURCE = '/accounting/list/home-accounting-payments-settings';
const HOUSE_ACCOUNTING_CHARGE_RESOURCE = '/accounting/list/home-accounting-charge-settings';
const AMORTISATION_CALC_WAY_RESOURCE = '/accounting/list/amortisation-calc-way';
const CONTRACTOR_RESOURCE = '/accounting/list/contractor';
const CONTRACTOR_CONTRACT_RESOURCE = '/accounting/list/contractor-contract';
const POSITION_RESOURCE = '/accounting/list/position';
const TAX_INSPECTION_RESOURCE = '/accounting/list/tax-inspection';
const SUBDIVISION_RESOURCE = '/accounting/list/subdivision';
const UNIT_RESOURCE = '/accounting/list/unit';
const NATURAL_PERSON_RESOURCE = '/accounting/list/natural_person';
const PERSONAL_RESOURCE = '/accounting/list/personal';
const NOMENCLATURE_RESOURCE = '/accounting/list/nomenclature';
const DISPATCHER_PROBLEM_RESOURCE = '/accounting/list/dispatcher-problem';
const CASH_FLOW_ITEM_RESOURCE = '/accounting/list/cash_flow_item';
const BANK_ACCOUNT_RESOURCE = '/accounting/list/bank_account';
const BIOLOGICAL_ASSETS_RESOURCE = '/accounting/list/biological_assets'
const COST_ITEMS_RESOURCE = '/accounting/list/cost_items'
const RELEASE_REASON_RESOURCE = '/accounting/list/release_reason'
const FIXED_ASSETS_RESOURCE = '/accounting/list/fixed_assets'
const INTANGIBLE_ASSETS_RESOURCE = '/accounting/list/intangible_assets'
const WORK_SCHEDULE_RESOURCE = '/accounting/list/work-schedule'
const ACCRUAL_RESOURCE = '/accounting/list/accrual'
const TIMESHEET_LETTER_RESOURCE = '/accounting/list/timesheet-letter'
const INCOME_TYPE_PIX_RESOURCE = '/accounting/list/income-type-pix'
const INCOME_ITEMS_RESOURCE = '/accounting/list/income_items'
const TAXES_RESOURCE = '/accounting/list/taxes'
const MIN_AMOUNT_RESOURCE = '/accounting/list/min-amount'
const INFLATION_INDEX_RESOURCE = '/accounting/list/inflation-index'
const TAX_TARIFF_RESOURCE = '/accounting/list/tax-tariff'
const NOMENCLATURE_GROUPS_RESOURCE = '/accounting/list/nomenclature_groups'
const CONTRACTOR_CONTACT_PERSON_RESOURCE = '/accounting/list/contractor-contact-person'
const CONTRACTOR_RESPONSE_PERSON_RESOURCE = '/accounting/list/contractor-response-person'
const CONTRACTOR_BANK_ACCOUNT_RESOURCE = '/accounting/list/contractor-bank-account'

export default {
    fetch_warehouse_elements(payload) {
        return axios.get(`${WAREHOUSE_RESOURCE}/elements`, {params: {text: payload.text || null}})
    },
    fetch_currency_elements(payload) {
        return axios.get(`${CURRENCY_RESOURCE}/elements`, {params: {text: payload.text || null}})
    },
    fetch_house_accounting_payments_settings_elements(payload) {
        return axios.get(`${HOUSE_ACCOUNTING_PAYMENTS_RESOURCE}/elements`, {params: {text: payload.text || null}})
    },
    fetch_house_accounting_charge_settings_elements(payload) {
        return axios.get(`${HOUSE_ACCOUNTING_CHARGE_RESOURCE}/elements`, {params: {text: payload.text || null}})
    },
    fetch_amortisation_calc_way_elements(payload) {
        return axios.get(`${AMORTISATION_CALC_WAY_RESOURCE}/elements`, {params: {text: payload.text || null}})
    },
    fetch_contractor_elements({text, currency_id, date}) {
        return axios.get(`${CONTRACTOR_RESOURCE}/elements`, {
            params: {
                currency_id: currency_id,
                date: date,
                text: text || null
            }
        })
    },
    fetch_contractor_contract_elements(payload) {
        return axios.get(`${CONTRACTOR_CONTRACT_RESOURCE}/elements`,
            {
                params: {
                    text: payload.text || null,
                    owner_id: payload.owner_id || null,
                    person_hash: payload.person_hash || null
                }
            }
        )
    },
    fetch_contractor_contact_person_elements(payload) {
        return axios.get(`${CONTRACTOR_CONTACT_PERSON_RESOURCE}/elements`,
            {
                params: {
                    text: payload.text || null,
                    contractor_id: payload.contractor_id || null,
                    person_hash: payload.person_hash || null
                }
            }
        )
    },
    fetch_contractor_response_person_elements(payload) {
        return axios.get(`${CONTRACTOR_RESPONSE_PERSON_RESOURCE}/elements`,
            {
                params: {
                    text: payload.text || null,
                    contractor_id: payload.contractor_id || null,
                    person_hash: payload.person_hash || null
                }
            }
        )
    },
    fetch_contractor_bank_account_elements(payload) {
        return axios.get(`${CONTRACTOR_BANK_ACCOUNT_RESOURCE}/elements`,
            {
                params: {
                    text: payload.text || null,
                    contractor_id: payload.contractor_id || null,
                    person_hash: payload.person_hash || null
                }
            }
        )
    },
    fetch_position_elements(payload) {
        return axios.get(`${POSITION_RESOURCE}/elements`, {params: {text: payload.text || null}})
    },
    fetch_tax_inspection_elements(payload) {
        return axios.get(`${TAX_INSPECTION_RESOURCE}/elements`, {params: {text: payload.text || null}})
    },
    fetch_subdivision_elements(payload) {
        return axios.get(`${SUBDIVISION_RESOURCE}/elements`, {params: {text: payload.text || null}})
    },
    fetch_unit_elements(payload) {
        return axios.get(`${UNIT_RESOURCE}/elements`, {params: {text: payload.text || null}})
    },
    fetch_natural_person_elements(payload) {
        return axios.get(`${NATURAL_PERSON_RESOURCE}/elements`, {params: {text: payload.text || null}})
    },
    fetch_personal_elements(payload) {
        return axios.get(`${PERSONAL_RESOURCE}/elements`, {params: {text: payload.text || null}})
    },
    fetch_cash_flow_item_elements(payload) {
        return axios.get(`${CASH_FLOW_ITEM_RESOURCE}/elements`, {params: {text: payload.text || null}})
    },
    fetch_bank_account_elements(payload) {
        return axios.get(`${BANK_ACCOUNT_RESOURCE}/elements`, {params: {text: payload.text || null}})
    },
    fetch_biological_assets_elements(payload) {
        return axios.get(`${BIOLOGICAL_ASSETS_RESOURCE}/elements`, {params: {text: payload.text || null}})
    },
    fetch_cost_items_groups() {
        return axios.get(`${COST_ITEMS_RESOURCE}/groups`)
    },
    fetch_cost_items_elements(payload) {
        return axios.get(`${COST_ITEMS_RESOURCE}/elements`,
            {params: {text: payload.text || null, parent_id: payload.parent_id || null}}

        )
    },
    fetch_release_reason_elements(payload) {
        return axios.get(`${RELEASE_REASON_RESOURCE}/elements`, {params: {text: payload.text || null}})
    },
    fetch_fixed_assets_elements(payload) {
        return axios.get(`${FIXED_ASSETS_RESOURCE}/elements`, {params: {text: payload.text || null}})
    },
    fetch_intangible_assets_elements(payload) {
        return axios.get(`${INTANGIBLE_ASSETS_RESOURCE}/elements`, {params: {text: payload.text || null}})
    },
    fetch_work_schedule_elements(payload) {
        return axios.get(`${WORK_SCHEDULE_RESOURCE}/elements`, {params: {text: payload.text || null}})
    },
    fetch_accrual_elements(payload) {
        return axios.get(`${ACCRUAL_RESOURCE}/elements`, {
            params: {
                text: payload.text || null,
                is_vocation: payload.is_vocation || null,
                is_hospital: payload.is_hospital || null
            }
        })
    },
    fetch_timesheet_letter_elements(payload) {
        return axios.get(`${TIMESHEET_LETTER_RESOURCE}/elements`, {params: {text: payload.text || null}})
    },
    fetch_income_type_pix_elements(payload) {
        return axios.get(`${INCOME_TYPE_PIX_RESOURCE}/elements`, {params: {text: payload.text || null}})
    },
    fetch_income_items_elements(payload) {
        return axios.get(`${INCOME_ITEMS_RESOURCE}/elements`, {params: {text: payload.text || null}})
    },
    fetch_taxes_elements(payload) {
        return axios.get(`${TAXES_RESOURCE}/elements`, {params: {text: payload.text || null}})
    },
    fetch_min_amount_elements(payload) {
        return axios.get(`${MIN_AMOUNT_RESOURCE}/elements`, {params: {text: payload.text || null}})
    },
    fetch_inflation_index_elements(payload) {
        return axios.get(`${INFLATION_INDEX_RESOURCE}/elements`, {params: {text: payload.text || null}})
    },
    fetch_tax_tariff_elements(payload) {
        return axios.get(`${TAX_TARIFF_RESOURCE}/elements`, {params: {text: payload.text || null}})
    },
    fetch_nomenclature_groups() {
        return axios.get(`${NOMENCLATURE_RESOURCE}/groups`)
    },
    fetch_nomenclature_elements({parent_id, text, is_service, warehouse_id, date}) {
        return axios.get(`${NOMENCLATURE_RESOURCE}/elements`, {
            params: {
                parent_id: parent_id || null,
                is_service: is_service,
                warehouse_id: warehouse_id,
                date: date,
                text: text || null
            }
        })
    },
    fetch_dispatcher_problem_groups() {
        return axios.get(`${DISPATCHER_PROBLEM_RESOURCE}/groups`)
    },
    fetch_dispatcher_problem_elements({parent_id, text, pay_service}) {
        return axios.get(`${DISPATCHER_PROBLEM_RESOURCE}/elements`, {
            params: {
                parent_id: parent_id || null,
                pay_service: pay_service,
                text: text || null
            }
        })
    },
    fetch_nomenclature_groups_elements(payload) {
        return axios.get(`${NOMENCLATURE_GROUPS_RESOURCE}/elements`, {params: {text: payload.text || null}})
    },
}