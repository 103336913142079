import {
    AUTH_REQUEST,
    AUTH_GOOGLE_REQUEST,
    AUTH_ERROR,
    AUTH_SUCCESS,
    AUTH_LOGOUT,
    AUTO_LOGIN
} from "../actions/auth";
import {
    USER_ERROR,
    USER_REQUEST,
    USER_SUCCESS,
    USER_SET_CURRENT_ORGANIZATION,
    USER_SET_ORGANIZATIONS,
    USER_SET_FLATS,
    USER_SET_CURRENT_FLAT
} from "../actions/user";
import api from "@/utils/axios/user"
import {axios} from "@/utils/axios";
import {GET_CURRENT_MONTH, GET_CURRENT_LEGACY_MONTH} from "@/store/actions/work_month";
import {
    SELECT_SERVICES,
    SELECT_SERVICES_WITH_COUNTER,
    SELECT_SERVICES_WITHOUT_ALL_SERVICE
} from "@/store/actions/services";
import {SELECT_BANK} from "@/store/actions/bank";
import {GET_ACCOUNTING_GENERAL_SETTINGS, GET_GENERAL_SETTINGS} from "@/store/actions/organization";
import {ALERT_SHOW} from "@/store/actions/alert";
import {VOTE_GET_STATS} from "@/store/actions/vote";
import {BALANCE_SHOW} from "@/store/actions/balance_soap";
import {GET_ALL_USER_MESSAGE} from "@/store/actions/user_message_history";


const state = {
    token: localStorage.getItem("user-token") || "",
    status: "",
    hasLoadedOnce: false
};

const getters = {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status
};

const actions = {
    [AUTH_REQUEST]: ({commit, dispatch}, user) => {
        return new Promise((resolve, reject) => {
            commit(AUTH_REQUEST);
            api.authenticate_user(user.email, user.password)
                .then(resp => {
                    let data = resp.data
                    localStorage.setItem("user-token", data.access_token);
                    axios.defaults.headers.common['Authorization'] = `${data.token_type} ${data.access_token}`
                    commit(AUTH_SUCCESS, data);
                    dispatch(USER_REQUEST).then(() => resolve(resp));
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(AUTH_ERROR, err);
                    localStorage.removeItem("user-token");
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(err);
                });
        });
    },
    [AUTH_GOOGLE_REQUEST]: ({commit, dispatch}, data) => {
        return new Promise((resolve) => {
            commit(AUTH_REQUEST);
            localStorage.setItem("user-token", data.access_token);
            axios.defaults.headers.common['Authorization'] = `${data.token_type} ${data.access_token}`
            commit(AUTH_SUCCESS, data);
            dispatch(USER_REQUEST).then(() => resolve(true));
        });
    },
    [AUTH_LOGOUT]: ({commit}) => {
        return new Promise(resolve => {
            commit(AUTH_LOGOUT);
            localStorage.removeItem("user-token");
            resolve();
        });
    },
    [AUTO_LOGIN]: ({commit, dispatch}) => {
        const token = localStorage.getItem('user-token') || '';
        if (token) {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = `bearer ${token}`
                api.get_user()
                    .then(response => response.data)
                    .then(data => {
                        delete data.roles;
                        const legacy = data?.accounting_db?.legacy || false
                        // const cabinet_only = data.organization.cabinet_only
                        // const its_cabinet_user = data.scoped.includes('cabinet')

                        commit(USER_SUCCESS, data);
                        commit(AUTH_SUCCESS, {access_token: token})
                        commit(USER_SET_ORGANIZATIONS)
                        commit(USER_SET_CURRENT_ORGANIZATION)
                        commit(USER_SET_FLATS)
                        commit(USER_SET_CURRENT_FLAT)
                        if (legacy) {
                            dispatch(GET_CURRENT_LEGACY_MONTH)
                            // if (!cabinet_only && !its_cabinet_user) {
                            //     dispatch(SELECT_LEGACY_FLATS)
                            // }
                            dispatch(GET_ACCOUNTING_GENERAL_SETTINGS)
                        } else {
                            dispatch(GET_CURRENT_MONTH)
                            // if (!cabinet_only && !its_cabinet_user) {
                            //     dispatch(SELECT_FLATS)
                            // }
                        }
                        dispatch(SELECT_SERVICES)
                        dispatch(SELECT_SERVICES_WITHOUT_ALL_SERVICE)
                        dispatch(SELECT_SERVICES_WITH_COUNTER)
                        dispatch(SELECT_BANK)
                        dispatch(GET_GENERAL_SETTINGS)
                        dispatch(VOTE_GET_STATS)
                        dispatch(GET_ALL_USER_MESSAGE)
                        dispatch(BALANCE_SHOW)
                        resolve(true)
                    })
                    .catch(() => {
                        commit(USER_ERROR);
                        dispatch(AUTH_LOGOUT)
                            .then(() => {
                                axios.defaults.headers.common['Authorization'] = '';
                                reject(false)
                            });
                    });
            })
        } else {
            delete axios.defaults.headers.common['Authorization']
        }
    }
};

const mutations = {
    [AUTH_REQUEST]: state => {
        state.status = "loading";
    },
    [AUTH_SUCCESS]: (state, resp) => {
        state.status = "success";
        state.token = resp.access_token;
        state.hasLoadedOnce = true;
    },
    [AUTH_ERROR]: state => {
        state.status = "error";
        state.hasLoadedOnce = true;
    },
    [AUTH_LOGOUT]: state => {
        state.token = "";
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
