import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/media-send';

export default {
    get_header() {
        return axios.get(`${RESOURCE_NAME}/header`)
    },
    get_bue() {
        return axios.get(`${RESOURCE_NAME}/bue`)
    },
    get_sms_templates() {
        return axios.get(`${RESOURCE_NAME}/sms-templates`)
    },
    get_sms_balance() {
        return axios.get(`${RESOURCE_NAME}/sms-balance`)
    },
    create_header(payload) {
        return axios.post(`${RESOURCE_NAME}/header`, payload)
    },
    get_rows(payload) {
        return axios.post(`${RESOURCE_NAME}/header/get-rows`, payload)
    },
    create_bue(payload) {
        return axios.post(`${RESOURCE_NAME}/bue`, payload)
    },
    update_header(payload) {
        return axios.post(`${RESOURCE_NAME}/header/update`, payload)
    },
    header_start_sending(payload) {
        return axios.post(`${RESOURCE_NAME}/header/start-sending`, {document_id: payload})
    },
    remove_header(payload) {
        return axios.post(`${RESOURCE_NAME}/header/remove`, { "delete_id": payload })
    },
};