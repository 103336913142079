import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/accounting/timesheet-letter';

export default {
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { "delete_id": payload })
    },
    select(payload) {
        return axios.get(`${RESOURCE_NAME}/select`, payload)
    }
};