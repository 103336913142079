import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/privilege';

export default {
    get_categories() {
        return axios.get(`${RESOURCE_NAME}/get-categories`)
    },
    get_privilege_owners(payload) {
        return axios.get(`${RESOURCE_NAME}/get-privilege-owners`,
            { params: { person_hash: payload } }
        )
    },
    get_privilege_persons(payload) {
        return axios.get(`${RESOURCE_NAME}/get-privilege-persons`,
            { params: { flat_id: payload.flat_id, privilege_owner_id: payload.privilege_owner_id } }
        )
    },
    create_category(payload) {
        return axios.post(`${RESOURCE_NAME}/create-category`, payload)
    },
    create_privilege_owner(payload) {
        return axios.post(`${RESOURCE_NAME}/create-privilege-owner`, payload)
    },
    create_privilege_persons(payload) {
        return axios.post(`${RESOURCE_NAME}/create-person`, payload)
    },
    update_category(payload) {
        return axios.post(`${RESOURCE_NAME}/update-category`, payload)
    },
    update_privilege_owner(payload) {
        return axios.post(`${RESOURCE_NAME}/update-privilege-owner`, payload)
    },
    update_persons(payload) {
        return axios.post(`${RESOURCE_NAME}/update-person`, payload)
    },
    remove_category(payload) {
        return axios.post(`${RESOURCE_NAME}/remove-category`, { category_id: payload })
    },
    remove_privilege_owner(payload) {
        return axios.post(`${RESOURCE_NAME}/remove-privilege-owner`, { privilege_owner_id: payload })
    },
    remove_persons(payload) {
        return axios.post(`${RESOURCE_NAME}/remove-person`, { person_id: payload })
    },
    select_privilege_with_owner(payload) {
        return axios.post(`${RESOURCE_NAME}/select-privilege-owner`, { flat_id: payload })
    }
};