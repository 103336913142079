import {ALERT_SHOW} from "@/store/actions/alert";

import flatCounterAvgAPI from "@/utils/axios/flat_counter_avg"
import {
    CREATE_FLAT_COUNTER_AVG,
    CREATE_FLAT_COUNTER_AVG_LEGACY,
    DELETE_FLAT_COUNTER_AVG,
    DELETE_FLAT_COUNTER_AVG_LEGACY,
    GET_ALL_FLAT_COUNTER_AVG,
    UPDATE_FLAT_COUNTER_AVG,
    UPDATE_FLAT_COUNTER_AVG_LEGACY
} from "@/store/actions/flat_counter_avg";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = { flat_counter_avg_items: [] }

const getters = {
    getFlatCountersAvg: state => state.flat_counter_avg_items,
}

const actions = {
    [GET_ALL_FLAT_COUNTER_AVG]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            flatCounterAvgAPI.get_all(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_ALL_FLAT_COUNTER_AVG, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_FLAT_COUNTER_AVG]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatCounterAvgAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_FLAT_COUNTER_AVG, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CREATE_FLAT_COUNTER_AVG_LEGACY]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatCounterAvgAPI.create_legacy(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_FLAT_COUNTER_AVG_LEGACY, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_FLAT_COUNTER_AVG]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatCounterAvgAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_FLAT_COUNTER_AVG, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_FLAT_COUNTER_AVG_LEGACY]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatCounterAvgAPI.update_legacy(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_FLAT_COUNTER_AVG_LEGACY, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_FLAT_COUNTER_AVG]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatCounterAvgAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(DELETE_FLAT_COUNTER_AVG, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_FLAT_COUNTER_AVG_LEGACY]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatCounterAvgAPI.remove_legacy(payload)
                .then(response => response.data)
                .then(data => {
                    commit(DELETE_FLAT_COUNTER_AVG_LEGACY, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
}

const mutations = {
    [GET_ALL_FLAT_COUNTER_AVG]: (state, payload) => {
        state.flat_counter_avg_items = payload
    },
    [CREATE_FLAT_COUNTER_AVG]: (state, payload) => {
        state.flat_counter_avg_items.push(payload)
    },
    [CREATE_FLAT_COUNTER_AVG_LEGACY]: (state, payload) => {
        state.flat_counter_avg_items.push(payload)
    },
    [UPDATE_FLAT_COUNTER_AVG]: (state, payload) => {
        let obj = state.flat_counter_avg_items.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [UPDATE_FLAT_COUNTER_AVG_LEGACY]: (state, payload) => {
        let obj = state.flat_counter_avg_items.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [DELETE_FLAT_COUNTER_AVG]: (state, payload) => {
        const obg = state.flat_counter_avg_items.find(item => item.id === payload.id)
        const obj_id = state.flat_counter_avg_items.indexOf(obg)
        if (obg) {
            state.flat_counter_avg_items.splice(obj_id, 1)
        }
    },
    [DELETE_FLAT_COUNTER_AVG_LEGACY]: (state, payload) => {
        const obg = state.flat_counter_avg_items.find(item => item.id === payload.id)
        const obj_id = state.flat_counter_avg_items.indexOf(obg)
        if (obg) {
            state.flat_counter_avg_items.splice(obj_id, 1)
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}