import {
    GET_NATURAL_PERSON,
    GET_POSITION,
    GET_RESPONSE_PERSON,
    CREATE_NATURAL_PERSON,
    CREATE_POSITION,
    CREATE_RESPONSE_PERSON,
    UPDATE_NATURAL_PERSON,
    UPDATE_POSITION,
    UPDATE_RESPONSE_PERSON,
    REMOVE_RESPONSE_PERSON,
    REMOVE_NATURAL_PERSON,
    REMOVE_POSITION,
    SELECT_NATURAL_PERSON,
    SELECT_POSITION
} from "@/store/actions/response_people";

import {ALERT_SHOW} from "@/store/actions/alert";
import responsePeopleAPI from "@/utils/axios/response_people"
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = { positions: [], position_select: [], natural_peoples: [], natural_peoples_select: [], response_people: [] }

const getters = {
    getPosition: state => state.positions,
    getPositionSelect: state => state.position_select,
    getNaturalPeople: state => state.natural_peoples,
    getNaturalPeopleSelect: state => state.natural_peoples_select,
    getResponsePeople: state => state.response_people
}

const actions = {
    [GET_POSITION]: ({commit}) => {
        return new Promise((resolve, reject) => {
            responsePeopleAPI.get_position()
                .then(response => response.data)
                .then(data => {
                    commit(GET_POSITION, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [GET_NATURAL_PERSON]: ({commit}) => {
        return new Promise((resolve, reject) => {
            responsePeopleAPI.get_natural_person()
                .then(response => response.data)
                .then(data => {
                    commit(GET_NATURAL_PERSON, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [GET_RESPONSE_PERSON]: ({commit}) => {
        return new Promise((resolve, reject) => {
            responsePeopleAPI.get_response_person()
                .then(response => response.data)
                .then(data => {
                    commit(GET_RESPONSE_PERSON, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_POSITION]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            responsePeopleAPI.create_position(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_POSITION, data)
                    dispatch(SELECT_POSITION)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CREATE_NATURAL_PERSON]: ({dispatch, commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            responsePeopleAPI.create_natural_person(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_NATURAL_PERSON, data)
                    dispatch(SELECT_NATURAL_PERSON)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CREATE_RESPONSE_PERSON]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            responsePeopleAPI.create_response_person(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_RESPONSE_PERSON, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_POSITION]: ({ dispatch, commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            responsePeopleAPI.update_position(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_POSITION, data)
                    dispatch(SELECT_POSITION)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_NATURAL_PERSON]: ({ dispatch, commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            responsePeopleAPI.update_natural_person(payload)
                .then(response => response.data)
                .then((data) => {
                    commit(UPDATE_NATURAL_PERSON, data)
                    dispatch(SELECT_NATURAL_PERSON)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_RESPONSE_PERSON]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            responsePeopleAPI.update_response_person(payload)
                .then(response => response.data)
                .then((data) => {
                    commit(UPDATE_RESPONSE_PERSON, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_POSITION]: ({ dispatch, commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            responsePeopleAPI.remove_position(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_POSITION, data)
                    dispatch(SELECT_POSITION)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_NATURAL_PERSON]: ({ dispatch, commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            responsePeopleAPI.remove_natural_person(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_NATURAL_PERSON, data)
                    dispatch(SELECT_NATURAL_PERSON)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_RESPONSE_PERSON]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            responsePeopleAPI.remove_response_person(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_RESPONSE_PERSON, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_POSITION]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            responsePeopleAPI.select_position()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_POSITION, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [SELECT_NATURAL_PERSON]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            responsePeopleAPI.select_natural_person()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_NATURAL_PERSON, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    }
}

const mutations = {
    [GET_POSITION]: (state, payload) => {
        state.positions = payload
    },
    [GET_NATURAL_PERSON]: (state, payload) => {
        state.natural_peoples = payload
    },
    [GET_RESPONSE_PERSON]: (state, payload) => {
        state.response_people = payload
    },
    [SELECT_POSITION]: (state, payload) => {
        state.position_select = payload
    },
    [SELECT_NATURAL_PERSON]: (state, payload) => {
        state.natural_peoples_select = payload
    },
    [CREATE_POSITION]: (state, payload) => {
        state.positions.push(payload)
    },
    [CREATE_NATURAL_PERSON]: (state, payload) => {
        state.natural_peoples.push(payload)
    },
    [CREATE_RESPONSE_PERSON]: (state, payload) => {
        state.response_people.push(payload)
    },
    [UPDATE_POSITION]: (state, payload) => {
        let obj = state.positions.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [UPDATE_NATURAL_PERSON]: (state, payload) => {
        let obj = state.natural_peoples.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [UPDATE_RESPONSE_PERSON]: (state, payload) => {
        let obj = state.response_people.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [REMOVE_POSITION]: (state, payload) => {
        const obg = state.positions.find(item => item.id === payload.id)
        const obj_id = state.positions.indexOf(obg)
        if (obg) {
            state.positions.splice(obj_id, 1)
        }
    },
    [REMOVE_NATURAL_PERSON]: (state, payload) => {
        const obg = state.natural_peoples.find(item => item.id === payload.id)
        const obj_id = state.natural_peoples.indexOf(obg)
        if (obg) {
            state.natural_peoples.splice(obj_id, 1)
        }
    },
    [REMOVE_RESPONSE_PERSON]: (state, payload) => {
        const obg = state.response_people.find(item => item.id === payload.id)
        const obj_id = state.response_people.indexOf(obg)
        if (obg) {
            state.response_people.splice(obj_id, 1)
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}