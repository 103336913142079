<template>
  <v-dialog :value="showUserMessages"
            persistent
            max-width="600"
  >
    <v-card>
      <v-toolbar :color="current_color"
                 extended
                 extension-height="42"
                 dark elevation="1">
        <template v-slot:extension>
          <v-chip :color="current_chip_color" small>
            <template v-if="current_item_object.message_level === 1">
              Звичайне
            </template>
            <template v-if="current_item_object.message_level === 2">
              Важливе
            </template>
            <template v-if="current_item_object.message_level === 3">
              Надважливе
            </template>
          </v-chip>
          <v-chip :color="current_chip_color" small class="ml-2">
            {{ organization.short_name }}
          </v-chip>
        </template>
        <v-toolbar-title style="font-size: 1.4rem">
          Нові оголошення
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeModal">
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-6 pt-3 py-0 px-4">
        <v-carousel
            :continuous="false"
            :cycle="false"
            :show-arrows="true"
            hide-delimiters
            height="450"
            class="user-message-carousel"
            v-model="current_item"
        >
          <template v-slot:prev="{ on, attrs }">
            <v-btn
                icon small
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-arrow-left-thin</v-icon>
            </v-btn>
          </template>
          <template v-slot:next="{ on, attrs }">
            <v-btn
                small icon
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-arrow-right-thin</v-icon>
            </v-btn>
          </template>
          <v-carousel-item
              v-for="message in messages"
              :key="message.id"
          >
            <div style="height: 340px; text-align: center; font-size: 1.05rem; padding-top: 10px; overflow: auto">
              <h2 style="font-size: 1.5rem; line-height: 1.64rem">{{ message.title }}</h2>
<!--              <p class="mt-3" style="font-size: .96rem; line-height: 1.3rem;">-->
<!--                {{ message.message }}-->
<!--              </p>-->
              <p class="mt-3" style="font-size: .96rem; line-height: 1.3rem; text-align: justify" v-html="sanitize(message.message)">
              </p>
            </div>

            <div class="carousel-pages">
              <v-chip small style="height: 26px !important; font-weight: 500">
                {{ current_item + 1 }} із {{ messages.length }}
              </v-chip>
            </div>
            <div class="carousel-accept-button; pt-3" style="height: 110px" v-if="!message.looked">
              <v-btn depressed class="ma-auto d-flex" :class="current_color" dark @click="messageLooked(message)">
                <v-icon size="24" left class="mr-3">mdi-eye-check-outline</v-icon>
                Ознайомлений(-на)
              </v-btn>
            </div>
            <div class="carousel-accept-button; pt-3" style="height: 110px" v-else>
              <v-btn depressed class="ma-auto d-flex" :class="current_color" disabled>
                <v-icon size="24" left class="mr-3">mdi-eye-lock-outline</v-icon>
                Ви вже ознайомились
              </v-btn>
            </div>
          </v-carousel-item>
        </v-carousel>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import {HIDE_USER_MESSAGE, LOOK_USER_MESSAGE} from "@/store/actions/user_message_history";

export default {
  name: "GeneralUserMessage",
  data() {
    return {
      current_item: null
    }
  },
  computed: {
    ...mapGetters({
      messages: 'getUserMessages',
      showUserMessages: 'showUserMessages',
      organization: 'getCurrentOrganization'
    }),
    current_item_object() {
      return this.current_item === null ? {} : this.messages[this.current_item]
    },
    current_color() {
      if (this.current_item === null) {
        return 'grey darken-3'
      } else {
        const item = this.messages[this.current_item] || {}
        switch (item.message_level || 0) {
          case 1:
            return 'teal lighten-1'
          case 2:
            return 'success lighten-1'
          case 3:
            return 'error lighten-1'
          default:
            return 'grey darken-3'
        }
      }
    },
    current_chip_color() {
      if (this.current_item === null) {
        return 'grey darken-3'
      } else {
        const item = this.messages[this.current_item] || {}
        switch (item.message_level || 0) {
          case 1:
            return 'teal darken-1'
          case 2:
            return 'success darken-2'
          case 3:
            return 'error darken-1'
          default:
            return 'grey darken-3'
        }
      }
    }
  },
  methods: {
    messageLooked(payload) {
      this.$store.dispatch(LOOK_USER_MESSAGE, payload)
    },
    closeModal() {
      this.$store.commit(HIDE_USER_MESSAGE, false)
    },
    sanitize(text) {
      return this.$sanitize(text)
    }
  },
  created() {
  }
}
</script>

<style scoped>

</style>