import {
    FETCH_BUILDINGS,
    GET_BUILDING,
    CREATE_BUILDING,
    UPDATE_BUILDING,
    DELETE_BUILDING,
    FLAT_BY_BUILDING
} from "@/store/actions/building";

import {ALERT_SHOW} from "@/store/actions/alert";
import buildingAPI from "@/utils/axios/bilding"
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = {buildings: [], building_status: false, building: {}, flats_building: []}

const getters = {
    getBuildings: state => state.buildings,
    getBuildingStatus: state => state.building_status,
    getBuilding: state => state.building,
    getFlatsByBuilding: state => state.flats_building
}

const actions = {
    [FETCH_BUILDINGS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            buildingAPI.all(payload)
                .then(response => response.data)
                .then(data => {
                    commit(FETCH_BUILDINGS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [GET_BUILDING]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            buildingAPI.one(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_BUILDING, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_BUILDING]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            buildingAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_BUILDING, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_BUILDING]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            buildingAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_BUILDING, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_BUILDING]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            buildingAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(DELETE_BUILDING, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [FLAT_BY_BUILDING]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            buildingAPI.flat_by_building(payload)
                .then(response => response.data)
                .then(data => {
                    commit(FLAT_BY_BUILDING, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    }
}

const mutations = {
    [FETCH_BUILDINGS]: (state, payload) => {
        state.buildings = payload
    },
    [GET_BUILDING]: (state, payload) => {
        state.building = payload
    },
    [CREATE_BUILDING]: (state, payload) => {
        state.buildings.push(payload)
    },
    [UPDATE_BUILDING]: (state, payload) => {
        const obj = state.buildings.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        } else {
            Object.keys(payload).forEach(item => {
                state.building[item] = payload[item]
            })
        }
    },
    [DELETE_BUILDING]: (state, payload) => {
        const obg = state.buildings.find(item => item.id === payload.id)
        const obj_id = state.buildings.indexOf(obg)
        if (obg) {
            state.buildings.splice(obj_id, 1)
            state.building = null
        }
    },
    [FLAT_BY_BUILDING]: (state, payload) => {
        state.flats_building = payload
    },
}


export default {
    state,
    getters,
    actions,
    mutations
}