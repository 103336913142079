import {ALERT_SHOW} from "@/store/actions/alert";

import userMessageHistoryAPI from "@/utils/axios/user_message_history"
import {
    CREATE_USER_MESSAGE_HISTORY,
    DELETE_USER_MESSAGE_HISTORY,
    GET_ALL_USER_MESSAGE_HISTORY,
    UPDATE_USER_MESSAGE_HISTORY,
    GET_ALL_USER_MESSAGE,
    HIDE_USER_MESSAGE,
    LOOK_USER_MESSAGE
} from "@/store/actions/user_message_history";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = { user_message_history: [], user_messages: [], show_user_messages: false }

const getters = {
    getUserMessageHistory: state => state.user_message_history,
    getUserMessages: state => state.user_messages,
    showUserMessages: state => state.show_user_messages
}

const actions = {
    [GET_ALL_USER_MESSAGE_HISTORY]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            userMessageHistoryAPI.get_all(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_ALL_USER_MESSAGE_HISTORY, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [GET_ALL_USER_MESSAGE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            userMessageHistoryAPI.get_user_messages(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_ALL_USER_MESSAGE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_USER_MESSAGE_HISTORY]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            userMessageHistoryAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_USER_MESSAGE_HISTORY, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [LOOK_USER_MESSAGE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            userMessageHistoryAPI.set_looked(payload.id)
                .then(response => response.data)
                .then(data => {
                    commit(LOOK_USER_MESSAGE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_USER_MESSAGE_HISTORY]: ({ commit }, payload) => {
        commit(START_MODAL_LOADING)
        return new Promise((resolve, reject) => {
            userMessageHistoryAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_USER_MESSAGE_HISTORY, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_USER_MESSAGE_HISTORY]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            userMessageHistoryAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(DELETE_USER_MESSAGE_HISTORY, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    }
}

const mutations = {
    [GET_ALL_USER_MESSAGE_HISTORY]: (state, payload) => {
        state.user_message_history = payload
    },
    [GET_ALL_USER_MESSAGE]: (state, payload) => {
        state.user_messages = payload
        state.show_user_messages = state.user_messages.length
    },
    [HIDE_USER_MESSAGE]: (state, payload) => {
        state.show_user_messages = payload
    },
    [LOOK_USER_MESSAGE]: (state, payload) => {
        const item = state.user_messages.find(i => i.id === payload.id)
        if (item) {
            item.looked = true
        }
    },
    [CREATE_USER_MESSAGE_HISTORY]: (state, payload) => {
        state.user_message_history.push(payload)
    },
    [UPDATE_USER_MESSAGE_HISTORY]: (state, payload) => {
        let obj = state.user_message_history.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [DELETE_USER_MESSAGE_HISTORY]: (state, payload) => {
        const obg = state.user_message_history.find(item => item.id === payload.id)
        const obj_id = state.user_message_history.indexOf(obg)
        if (obg) {
            state.user_message_history.splice(obj_id, 1)
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}