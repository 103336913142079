import {
    FETCH_CITIES,
    CREATE_CITY,
    UPDATE_CITY,
    DELETE_CITY,
    FETCH_STREETS,
    CREATE_STREET,
    UPDATE_STREET,
    DELETE_STREET,
    GET_TREE
} from "@/store/actions/city";

import {ALERT_SHOW} from "@/store/actions/alert";

import cityAPI from "@/utils/axios/city"
import streetAPI from "@/utils/axios/street"
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = { cities: [], city_status: false, streets: [], street_status: false, tree: [] }

const getters = {
    getCities: state => state.cities,
    getCityStatus: state => state.city_status,
    getStreets: state => state.streets,
    getStreetStatus: state => state.street_status,
    getTree: state => state.tree
}

const actions = {
    [FETCH_CITIES]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            cityAPI.all(payload)
                .then(response => response.data)
                .then(data => {
                    commit(FETCH_CITIES, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [FETCH_STREETS]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            streetAPI.all(payload)
                .then(response => response.data)
                .then(data => {
                    commit(FETCH_STREETS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [GET_TREE]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            cityAPI.get_tree()
                .then(response => response.data)
                .then(data => {
                    commit(GET_TREE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })

    },
    [CREATE_CITY]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            cityAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_CITY, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CREATE_STREET]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            streetAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_STREET, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_CITY]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            cityAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_CITY, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_STREET]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            streetAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_STREET, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_CITY]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            cityAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(DELETE_CITY, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_STREET]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            streetAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(DELETE_STREET, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    }
}

const mutations = {
    [FETCH_CITIES]: (state, payload) => {
        state.cities = payload
    },
    [FETCH_STREETS]: (state, payload) => {
        state.streets = payload
    },
    [GET_TREE]: (state, payload) => {
        state.tree = payload
    },
    [CREATE_CITY]: (state, payload) => {
        state.cities.push(payload)
    },
    [CREATE_STREET]: (state, payload) => {
        state.streets.push(payload)
    },
    [UPDATE_CITY]: (state, payload) => {
        let obj = state.cities.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [UPDATE_STREET]: (state, payload) => {
        let obj = state.streets.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [DELETE_CITY]: (state, payload) => {
        const obg = state.cities.find(item => item.id === payload.id)
        const obj_id = state.cities.indexOf(obg)
        if (obg) {
            state.cities.splice(obj_id, 1)
        }
    },
    [DELETE_STREET]: (state, payload) => {
        const obg = state.streets.find(item => item.id === payload.id)
        const obj_id = state.streets.indexOf(obg)
        if (obg) {
            state.streets.splice(obj_id, 1)
        }
    },
    ['CLEAR_TREE']: (state) => {
        state.tree = []
    },
    ['CLEAR_GEO_DATA']: (state) => {
        state.amalgamated_hromadas = []
        state.cities = []
        state.city_areas = []
        state.streets = []
        state.buildings = []
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}