import {ALERT_SHOW} from "@/store/actions/alert";

import registrationOfChangesAPI from "@/utils/axios/registration_of_changes"
import {
    GET_ALL_REGISTRATION_OF_CHANGES,
    CREATE_REGISTRATION_OF_CHANGES
} from "@/store/actions/registration_of_changes";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = { registration_of_changes: [] }

const getters = {
    getRegistrationOfChanges: state => state.registration_of_changes,
}

const actions = {
    [GET_ALL_REGISTRATION_OF_CHANGES]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            registrationOfChangesAPI.get_all(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_ALL_REGISTRATION_OF_CHANGES, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_REGISTRATION_OF_CHANGES]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            registrationOfChangesAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_REGISTRATION_OF_CHANGES, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
}

const mutations = {
    [GET_ALL_REGISTRATION_OF_CHANGES]: (state, payload) => {
        state.registration_of_changes = payload
    },
    [CREATE_REGISTRATION_OF_CHANGES]: (state, payload) => {
        state.registration_of_changes.push(payload)
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}