import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/personal-site';

export default {
    get_organization_data(payload) {
        return axios.get(`${RESOURCE_NAME}/`, {params: { organization_slug: payload }})
    },
    get_top_debt(payload) {
        return axios.get(`${RESOURCE_NAME}/top-debt`, {params: { organization_slug: payload }})
    },
    get_site_config(payload) {
        return axios.get(`${RESOURCE_NAME}/site-config`, {params: { organization_slug: payload }})
    },
    get_notification(payload) {
        return axios.get(`${RESOURCE_NAME}/notification`, {params: { organization_slug: payload }})
    },
    get_news(payload) {
        return axios.get(`${RESOURCE_NAME}/news`, {params: { organization_slug: payload }})
    },
    get_news_by_id({organization_slug, news_id}) {
        return axios.get(`${RESOURCE_NAME}/news-one`, {params: { organization_slug: organization_slug, news_id: news_id }})
    },
    get_files(payload) {
        return axios.get(`${RESOURCE_NAME}/documents`, {params: { organization_slug: payload }})
    },
    get_documents_new(payload) {
        return axios.get(`${RESOURCE_NAME}/documents-new`, {params: payload})
    },
    get_gallery(payload) {
        return axios.get(`${RESOURCE_NAME}/gallery`, {params: { organization_slug: payload }})
    },
};