import {
    FETCH_FAMILY_BY_FLAT,
    FETCH_FAMILY_BY_ONE,
    CREATE_FAMILY_MEMBER,
    GET_RELATION_TYPE_SELECT,
    UPDATE_FAMILY_MEMBER,
    DELETE_FAMILY_MEMBER,
    GET_OWNER_CHANGES,
} from "@/store/actions/family";

import familyAPI from "@/utils/axios/family"

import {ALERT_SHOW} from "@/store/actions/alert";
import {GET_FLAT} from "@/store/actions/flat";
import {FETCH_MAIN_CONTACTS} from "@/store/actions/contact";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = { family: [], family_member: null, relation_type_select: [], owner_changes: [] }

const getters = {
    getFamily: state => state.family,
    getFamilyMember: state => state.family_member,
    getRelationTypeSelect: state => state.relation_type_select,
    getOwnerChanges: state => state.owner_changes
}

const actions = {
    [FETCH_FAMILY_BY_FLAT]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            familyAPI.by_flat(payload)
                .then(response => response.data)
                .then(data => {
                    commit(FETCH_FAMILY_BY_FLAT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [FETCH_FAMILY_BY_ONE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            familyAPI.all(payload)
                .then(response => response.data)
                .then(data => {
                    commit(FETCH_FAMILY_BY_ONE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [GET_RELATION_TYPE_SELECT]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            familyAPI.relation_select()
                .then(response => response.data)
                .then(data => {
                    commit(GET_RELATION_TYPE_SELECT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [GET_OWNER_CHANGES]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            familyAPI.get_owner_changes(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_OWNER_CHANGES, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_FAMILY_MEMBER]: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            familyAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_FAMILY_MEMBER, data)
                    if (data.flat_owner) {
                        dispatch(GET_FLAT, payload.flat_hash)
                        dispatch(FETCH_FAMILY_BY_FLAT, payload.flat_hash)
                        dispatch(FETCH_MAIN_CONTACTS, payload.flat_hash)
                        dispatch(GET_OWNER_CHANGES, payload.flat_hash)
                    }
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_FAMILY_MEMBER]: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            familyAPI.update(payload)
                .then(response => response.data)
                .then(() => {
                    dispatch(GET_FLAT, payload.flat_hash)
                    dispatch(FETCH_FAMILY_BY_FLAT, payload.flat_hash)
                    dispatch(FETCH_MAIN_CONTACTS, payload.flat_hash)
                    dispatch(GET_OWNER_CHANGES, payload.flat_hash)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))

        })
    },
    [DELETE_FAMILY_MEMBER]: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            familyAPI.remove(payload.person_hash)
                .then(response => response.data)
                .then(() => {
                    dispatch(GET_FLAT, payload.flat_hash)
                        .then(res => {
                            if (res) {
                                dispatch(GET_FLAT, payload.flat_hash)
                                dispatch(FETCH_MAIN_CONTACTS, payload.flat_hash)
                                dispatch(FETCH_FAMILY_BY_FLAT, payload.flat_hash)
                                dispatch(GET_OWNER_CHANGES, payload.flat_hash)
                            }
                        })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
}

const mutations = {
    [FETCH_FAMILY_BY_FLAT]: (state, payload) => {
        state.family = payload
    },
    [FETCH_FAMILY_BY_ONE]: (state, payload) => {
        state.family_member = payload
    },
    [GET_RELATION_TYPE_SELECT]: (state, payload) => {
        state.relation_type_select = payload
    },
    [GET_OWNER_CHANGES]: (state, payload) => {
        state.owner_changes = payload
    },
    [CREATE_FAMILY_MEMBER]: (state, payload) => {
        state.family.push(payload)
    },
    [UPDATE_FAMILY_MEMBER]: (state, payload) => {
        let obj = state.family.find(item => item.person_hash === payload.person_hash)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [DELETE_FAMILY_MEMBER]: (state, payload) => {
        const obg = state.family.find(item => item.person_hash === payload.person_hash)
        const obj_id = state.family.indexOf(obg)
        if (obg) {
            state.family.splice(obj_id, 1)
        }
    },
}


export default {
    state,
    getters,
    actions,
    mutations
}