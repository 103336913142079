import {ALERT_SHOW} from "@/store/actions/alert";

import appliancePI from "@/utils/axios/appliance"
import {
    GET_ALL_APPLIANCE_MODEL,
    CREATE_APPLIANCE_MODEL,
    UPDATE_APPLIANCE_MODEL,
    DELETE_APPLIANCE_MODEL,
    SELECT_APPLIANCE_MODEL,

    GET_ALL_APPLIANCE_BY_FLAT,
    CREATE_APPLIANCE_BY_FLAT,
    UPDATE_APPLIANCE_BY_FLAT,
    DELETE_APPLIANCE_BY_FLAT,
    APPLIANCE_BY_FLAT_CURRENT_SELECT,

    GET_ALL_APPLIANCE_OPERATION_JOURNAL,
    CREATE_APPLIANCE_OPERATION_JOURNAL,
    UPDATE_APPLIANCE_OPERATION_JOURNAL,
    DELETE_APPLIANCE_OPERATION_JOURNAL
} from "@/store/actions/appliance";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";
import {maxBy} from "lodash";

const state = {
    appliance_models: [],
    select_appliance_models: [],
    appliance_by_flat: [],
    selected_appliance_by_flat: [],
    appliance_operation_journal: [],
    appliance_operation_journal_filtered: [],
    select_loaded: false
}

const getters = {
    getApplianceModels: state => state.appliance_models,
    getSelectApplianceModels: state => state.select_appliance_models,
    getApplianceByFlat: state => state.appliance_by_flat,
    getApplianceOperationJournal: state => state.appliance_operation_journal_filtered,
    getCurrentSelectedApplianceByFlat: state => state.selected_appliance_by_flat,
}

const actions = {
    [GET_ALL_APPLIANCE_MODEL]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            appliancePI.get_all_models(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_ALL_APPLIANCE_MODEL, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_APPLIANCE_MODEL]: ({ dispatch, commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            appliancePI.create_model(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_APPLIANCE_MODEL, data)
                    dispatch(SELECT_APPLIANCE_MODEL)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_APPLIANCE_MODEL]: ({ commit }, payload) => {
        commit(START_MODAL_LOADING)
        return new Promise((resolve, reject) => {
            appliancePI.update_model(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_APPLIANCE_MODEL, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_APPLIANCE_MODEL]: ({ dispatch, commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            appliancePI.remove_model(payload)
                .then(response => response.data)
                .then(data => {
                    commit(DELETE_APPLIANCE_MODEL, data)
                    dispatch(DELETE_APPLIANCE_MODEL)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_APPLIANCE_MODEL]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            appliancePI.select_model(payload)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_APPLIANCE_MODEL, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },

    [GET_ALL_APPLIANCE_BY_FLAT]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            appliancePI.get_all_appliance_by_flat(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_ALL_APPLIANCE_BY_FLAT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_APPLIANCE_BY_FLAT]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            appliancePI.create_appliance_by_flat(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_APPLIANCE_BY_FLAT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_APPLIANCE_BY_FLAT]: ({ commit }, payload) => {
        commit(START_MODAL_LOADING)
        return new Promise((resolve, reject) => {
            appliancePI.update_appliance_by_flat(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_APPLIANCE_BY_FLAT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_APPLIANCE_BY_FLAT]: ({ dispatch, commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            appliancePI.remove_appliance_by_flat(payload)
                .then(response => response.data)
                .then(data => {
                    commit(DELETE_APPLIANCE_BY_FLAT, data)
                    dispatch(SELECT_APPLIANCE_MODEL)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [APPLIANCE_BY_FLAT_CURRENT_SELECT]: ({commit}, payload) => {
        commit(APPLIANCE_BY_FLAT_CURRENT_SELECT, payload)
    },

    [GET_ALL_APPLIANCE_OPERATION_JOURNAL]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            appliancePI.get_all_operation_journal_by_flat(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_ALL_APPLIANCE_OPERATION_JOURNAL, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_APPLIANCE_OPERATION_JOURNAL]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            appliancePI.create_operation_journal(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_APPLIANCE_OPERATION_JOURNAL, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_APPLIANCE_OPERATION_JOURNAL]: ({ commit }, payload) => {
        commit(START_MODAL_LOADING)
        return new Promise((resolve, reject) => {
            appliancePI.update_operation_journal(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_APPLIANCE_OPERATION_JOURNAL, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_APPLIANCE_OPERATION_JOURNAL]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            appliancePI.remove_operation_journal(payload)
                .then(response => response.data)
                .then(data => {
                    commit(DELETE_APPLIANCE_OPERATION_JOURNAL, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
}

const mutations = {
    [GET_ALL_APPLIANCE_MODEL]: (state, payload) => {
        state.appliance_models = payload
    },
    [CREATE_APPLIANCE_MODEL]: (state, payload) => {
        state.appliance_models.push(payload)
    },
    [UPDATE_APPLIANCE_MODEL]: (state, payload) => {
        let obj = state.appliance_models.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [DELETE_APPLIANCE_MODEL]: (state, payload) => {
        const obg = state.appliance_models.find(item => item.id === payload.id)
        const obj_id = state.appliance_models.indexOf(obg)
        if (obg) {
            state.appliance_models.splice(obj_id, 1)
        }
    },
    [SELECT_APPLIANCE_MODEL]: (state, payload) => {
        state.select_appliance_models = payload
        state.select_loaded = true
    },

    [GET_ALL_APPLIANCE_BY_FLAT]: (state, payload) => {
        const local_payload = payload.slice()
        local_payload.forEach((item, idx) => {
            Object.assign(item, {row_num: idx + 1})
        })
        state.appliance_by_flat = local_payload
    },
    [CREATE_APPLIANCE_BY_FLAT]: (state, payload) => {
        const max_row_item = maxBy(payload, 'row_num')
        let max_row = 0
        if (max_row_item) {
            max_row = max_row_item.row_num
        }
        state.appliance_by_flat.push(Object.assign({}, payload, {row_num: max_row + 1}))
    },
    [UPDATE_APPLIANCE_BY_FLAT]: (state, payload) => {
        let obj = state.appliance_by_flat.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [DELETE_APPLIANCE_BY_FLAT]: (state, payload) => {
        const obg = state.appliance_by_flat.find(item => item.id === payload.id)
        const obj_id = state.appliance_by_flat.indexOf(obg)
        if (obg) {
            state.appliance_by_flat.splice(obj_id, 1)
        }
    },

    [GET_ALL_APPLIANCE_OPERATION_JOURNAL]: (state, payload) => {
        state.appliance_operation_journal = payload
    },
    [CREATE_APPLIANCE_OPERATION_JOURNAL]: (state, payload) => {
        state.appliance_operation_journal.push(payload)

        if (state.selected_appliance_by_flat.id) {
            state.appliance_operation_journal_filtered = state.appliance_operation_journal.filter(
                item => item.appliance_id === state.selected_appliance_by_flat.id)
        } else {
            state.appliance_operation_journal_filtered = []
        }
    },
    [UPDATE_APPLIANCE_OPERATION_JOURNAL]: (state, payload) => {
        let obj = state.appliance_operation_journal.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })

        if (state.selected_appliance_by_flat.id) {
            state.appliance_operation_journal_filtered = state.appliance_operation_journal.filter(
                item => item.appliance_id === state.selected_appliance_by_flat.id)
        } else {
            state.appliance_operation_journal_filtered = []
        }
    },
    [DELETE_APPLIANCE_OPERATION_JOURNAL]: (state, payload) => {
        state.appliance_operation_journal = state.appliance_operation_journal.filter(item => item.id !== payload.id)

        if (state.selected_appliance_by_flat.id) {
            state.appliance_operation_journal_filtered = state.appliance_operation_journal.filter(
                item => item.appliance_id === state.selected_appliance_by_flat.id)
        } else {
            state.appliance_operation_journal_filtered = []
        }
    },
    [APPLIANCE_BY_FLAT_CURRENT_SELECT]: (state, payload) => {
        state.selected_appliance_by_flat = payload
        if (!payload) state.appliance_operation_journal_filtered = []
        if (payload.id) {
            state.appliance_operation_journal_filtered = state.appliance_operation_journal.filter(
                item => item.appliance_id === payload.id)
        } else {
            state.appliance_operation_journal_filtered = []
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}