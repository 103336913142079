import {axios} from '@/utils/axios';

const RESOURCE_NAME = '/building-counter';

export default {
    get_counter() {
        return axios.get(`${RESOURCE_NAME}/`)
    },
    get_counter_by_id(payload) {
        return axios.get(`${RESOURCE_NAME}/counter-current-reading`, {
            params: {
                building_counter_id: payload.building_counter_id,
                month: payload.month
            }
        })
    },
    get_counter_reading(payload) {
        return axios.get(`${RESOURCE_NAME}/counter-reading`, {params: {building_counter_id: payload}})
    },
    get_counter_reading_values(payload) {
        return axios.get(`${RESOURCE_NAME}/counter-reading-values`, {params: {reading_id: payload}})
    },
    create_counter(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    create_counter_reading(payload) {
        return axios.post(`${RESOURCE_NAME}/counter-reading`, payload)
    },
    update_counter(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    remove_counter(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, {"building_counter_id": payload})
    },
    remove_counter_reading(payload) {
        return axios.post(`${RESOURCE_NAME}/counter-reading/remove`, {"building_counter_value_id": payload})
    },
    recalculation_counter_reading(payload) {
        return axios.post(`${RESOURCE_NAME}/counter-reading/recalculation`, payload)
    },
    select_counter_model() {
        return axios.post(`${RESOURCE_NAME}/model/select`, { building: true })
    },
    get_counter_user(payload) {
        return axios.get(`${RESOURCE_NAME}/counter-user`, {params: {building_counter_id: payload}})
    },
    create_or_update_counter_user(payload) {
        return axios.post(`${RESOURCE_NAME}/counter-user`, payload)
    },
    fill_counter_user(payload) {
        return axios.post(`${RESOURCE_NAME}/counter-user/fill`, payload)
    },
    remove_counter_user(payload) {
        return axios.post(`${RESOURCE_NAME}/counter-user/remove`, {"building_counter_id": payload})
    },
};