import {ALERT_SHOW} from "@/store/actions/alert";

import removalItemAPI from "@/utils/axios/removal"
import removalItemsAPI from "@/utils/axios/removals"

import {
    FETCH_REMOVAL_ITEM_ALL,
    CREATE_REMOVAL_ITEM,
    UPDATE_REMOVAL_ITEM,
    REMOVE_REMOVAL_ITEM,
    SELECT_REMOVAL_ITEM,
    FETCH_REMOVAL_ITEMS_ALL,
    CREATE_REMOVAL_ITEMS,
    UPDATE_REMOVAL_ITEMS,
    REMOVE_REMOVAL_ITEMS
} from "@/store/actions/removal";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = { removal_items: [], removal_items_select: [], removal_headers: [], select_loaded: false }

const getters = {
    getRemovalItem: state => state.removal_items,
    getRemovalItemSelect: state => state.removal_items_select,
    getRemovalHeaders: state => state.removal_headers,
}

const actions = {
    [FETCH_REMOVAL_ITEM_ALL]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            removalItemAPI.get_all(payload)
                .then(response => response.data)
                .then(data => {
                    commit(FETCH_REMOVAL_ITEM_ALL, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_REMOVAL_ITEM]: ({ dispatch, commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            removalItemAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_REMOVAL_ITEM, data)
                    dispatch(SELECT_REMOVAL_ITEM)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_REMOVAL_ITEM]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            removalItemAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_REMOVAL_ITEM, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_REMOVAL_ITEM]: ({ dispatch, commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            removalItemAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_REMOVAL_ITEM, data)
                    dispatch(SELECT_REMOVAL_ITEM)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_REMOVAL_ITEM]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            removalItemAPI.select(payload)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_REMOVAL_ITEM, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },

    [FETCH_REMOVAL_ITEMS_ALL]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            removalItemsAPI.get_all(payload)
                .then(response => response.data)
                .then(data => {
                    commit(FETCH_REMOVAL_ITEMS_ALL, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_REMOVAL_ITEMS]: ({ dispatch, commit }, payload) => {
        return new Promise((resolve, reject) => {
            removalItemsAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_REMOVAL_ITEMS, data)
                    dispatch(SELECT_REMOVAL_ITEM)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [UPDATE_REMOVAL_ITEMS]: ({ dispatch, commit }, payload) => {
        return new Promise((resolve, reject) => {
            removalItemsAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_REMOVAL_ITEMS, data)
                    dispatch(SELECT_REMOVAL_ITEM)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [REMOVE_REMOVAL_ITEMS]: ({ dispatch, commit }, payload) => {
        return new Promise((resolve, reject) => {
            removalItemsAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_REMOVAL_ITEMS, data)
                    dispatch(SELECT_REMOVAL_ITEM)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
}

const mutations = {
    [FETCH_REMOVAL_ITEM_ALL]: (state, payload) => {
        state.removal_items = payload
    },
    [CREATE_REMOVAL_ITEM]: (state, payload) => {
        state.removal_items.push(payload)
    },
    [UPDATE_REMOVAL_ITEM]: (state, payload) => {
        let obj = state.removal_items.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [REMOVE_REMOVAL_ITEM]: (state, payload) => {
        const obg = state.removal_items.find(item => item.id === payload.id)
        const obj_id = state.removal_items.indexOf(obg)
        if (obg) {
            state.removal_items.splice(obj_id, 1)
        }
    },
    [SELECT_REMOVAL_ITEM]: (state, payload) => {
        state.removal_items_select = payload
        state.select_loaded = true
    },

    [FETCH_REMOVAL_ITEMS_ALL]: (state, payload) => {
        state.removal_headers = payload
    },
    [CREATE_REMOVAL_ITEMS]: (state, payload) => {
        state.removal_headers.push(payload)
    },
    [UPDATE_REMOVAL_ITEMS]: (state, payload) => {
        let obj = state.removal_headers.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [REMOVE_REMOVAL_ITEMS]: (state, payload) => {
        const obg = state.removal_headers.find(item => item.id === payload)
        const obj_id = state.removal_headers.indexOf(obg)
        if (obg) {
            state.removal_headers.splice(obj_id, 1)
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}