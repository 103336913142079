import {ALERT_SHOW} from "@/store/actions/alert";

import amalgamatedHromadaAPI from "@/utils/axios/amalgamanted_hromada"
import {
    CREATE_AMALGAMATED_HROMADA,
    DELETE_AMALGAMATED_HROMADA,
    FETCH_AMALGAMATED_HROMADA,
    UPDATE_AMALGAMATED_HROMADA
} from "@/store/actions/amalgamated_hromada";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = {
    amalgamated_hromadas: [],
    select_amalgamated_hromadas: [],
    select_loaded: false
}

const getters = {
    getAmalgamatedHromadas: state => state.amalgamated_hromadas,
    getSelectAmalgamatedHromadas: state => state.select_amalgamated_hromadas,
}

const actions = {
    [FETCH_AMALGAMATED_HROMADA]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            amalgamatedHromadaAPI.get_all(payload)
                .then(response => response.data)
                .then(data => {
                    commit(FETCH_AMALGAMATED_HROMADA, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_AMALGAMATED_HROMADA]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            amalgamatedHromadaAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_AMALGAMATED_HROMADA, data)
                    // dispatch(SELECT_CHECKER)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_AMALGAMATED_HROMADA]: ({ commit }, payload) => {
        commit(START_MODAL_LOADING)
        return new Promise((resolve, reject) => {
            amalgamatedHromadaAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_AMALGAMATED_HROMADA, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_AMALGAMATED_HROMADA]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            amalgamatedHromadaAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(DELETE_AMALGAMATED_HROMADA, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    }
}

const mutations = {
    [FETCH_AMALGAMATED_HROMADA]: (state, payload) => {
        state.amalgamated_hromadas = payload
    },
    [CREATE_AMALGAMATED_HROMADA]: (state, payload) => {
        state.amalgamated_hromadas.push(payload)
    },
    [UPDATE_AMALGAMATED_HROMADA]: (state, payload) => {
        let obj = state.amalgamated_hromadas.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [DELETE_AMALGAMATED_HROMADA]: (state, payload) => {
        const obg = state.amalgamated_hromadas.find(item => item.id === payload.id)
        const obj_id = state.amalgamated_hromadas.indexOf(obg)
        if (obg) {
            state.amalgamated_hromadas.splice(obj_id, 1)
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}