import {ALERT_SHOW} from "@/store/actions/alert";

import cityAreaAPI from "@/utils/axios/city_area"
import {
    CREATE_CITY_AREA,
    DELETE_CITY_AREA,
    FETCH_CITY_AREA,
    UPDATE_CITY_AREA
} from "@/store/actions/city_area";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = {
    city_areas: [],
    select_city_areas: [],
    select_loaded: false
}

const getters = {
    getCityAreas: state => state.city_areas,
    getSelectCityAreas: state => state.select_city_areas,
}

const actions = {
    [FETCH_CITY_AREA]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            cityAreaAPI.get_all(payload)
                .then(response => response.data)
                .then(data => {
                    commit(FETCH_CITY_AREA, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_CITY_AREA]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            cityAreaAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_CITY_AREA, data)
                    // dispatch(SELECT_CHECKER)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_CITY_AREA]: ({ commit }, payload) => {
        commit(START_MODAL_LOADING)
        return new Promise((resolve, reject) => {
            cityAreaAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_CITY_AREA, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_CITY_AREA]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            cityAreaAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(DELETE_CITY_AREA, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    }
}

const mutations = {
    [FETCH_CITY_AREA]: (state, payload) => {
        state.city_areas = payload
    },
    [CREATE_CITY_AREA]: (state, payload) => {
        state.city_areas.push(payload)
    },
    [UPDATE_CITY_AREA]: (state, payload) => {
        let obj = state.city_areas.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [DELETE_CITY_AREA]: (state, payload) => {
        const obg = state.city_areas.find(item => item.id === payload.id)
        const obj_id = state.city_areas.indexOf(obg)
        if (obg) {
            state.city_areas.splice(obj_id, 1)
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}