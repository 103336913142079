export const GET_ALL_SERVICE = "GET_ALL_SERVICE"
export const GET_ALL_FLAT_TARIFF = "GET_ALL_FLAT_TARIFF"
export const GET_ALL_WITH_BALANCE = "GET_ALL_WITH_BALANCE"
export const GET_ARCHIVE = "GET_ARCHIVE"
export const GET_ARCHIVE_LEGACY = "GET_ARCHIVE_LEGACY"
export const CREATE_FLAT_SERVICE = "CREATE_FLAT_SERVICE"
export const UPDATE_FLAT_SERVICE = "UPDATE_FLAT_SERVICE"
export const DELETE_FLAT_SERVICE = "DELETE_FLAT_SERVICE"

export const GET_ALL_WITH_BALANCE_LEGACY = "GET_ALL_WITH_BALANCE_LEGACY"
export const GET_ALL_LEGACY_SERVICE = "GET_ALL_LEGACY_SERVICE"
export const GET_ALL_LEGACY_FLAT_TARIFF = "GET_ALL_LEGACY_FLAT_TARIFF"
export const CREATE_FLAT_LEGACY_SERVICE = "CREATE_FLAT_LEGACY_SERVICE"
export const UPDATE_FLAT_LEGACY_SERVICE = "UPDATE_FLAT_LEGACY_SERVICE"
export const DELETE_FLAT_LEGACY_SERVICE = "DELETE_FLAT_LEGACY_SERVICE"
