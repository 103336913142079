import Vue from "vue";
import Vuex from "vuex";
import user from "@/store/modules/user"
import auth from "@/store/modules/auth";
import alert from "@/store/modules/alert";
import loading from "@/store/modules/loading";
import counter from "@/store/modules/counter";
import cities from "@/store/modules/city";
import city_area from "@/store/modules/city_area";
import amalgamated_hromadas from "@/store/modules/amalgamated_hromada"
import building from "@/store/modules/building";
import flat from "@/store/modules/flats";
import building_indicator from "@/store/modules/building_indicator";
import contact from "@/store/modules/contact";
import flat_indicator from "@/store/modules/flat_indicator";
import family from "@/store/modules/family";
import tariff_group from "@/store/modules/tariff_group";
import service from "@/store/modules/service";
import tariff_by_organization from "@/store/modules/tariff_by_organization";
import tariff_by_building from "@/store/modules/tariff_by_building";
import flat_service from "@/store/modules/flat_service";
import question from "@/store/modules/question";
import calculation from "@/store/modules/calculation";
import work_month from "@/store/modules/work_month";
import organization from "@/store/modules/organization";
import bank from "@/store/modules/bank";
import response_people from "@/store/modules/response_people";
import organization_contact from "@/store/modules/organization_contact";
import payments from "@/store/modules/payments";
import regular_by_organization from "@/store/modules/regular_by_organization";
import flat_documents from "@/store/modules/flat_documents";
import vote from "@/store/modules/vote"
import appeal from "@/store/modules/appeal"
import socket from "@/store/modules/websockets"
import contract from "@/store/modules/contract";
import privilege from "@/store/modules/privilege";
import chart_of_account from "@/store/modules/accounting/chart_of_account"
import personal_site from "@/store/modules/personalSite/site"
import organization_documents from "@/store/modules/organization_documents";
import site_tables from "@/store/modules/site_tables";
import list from "@/store/modules/accounting/list";
import doc_list from "@/store/modules/accounting/documents";
import media_send from "@/store/modules/media_send";
import checker from "@/store/modules/checker";
import counter_contractor from "@/store/modules/counter_contractor";
import counter_installation_place from "@/store/modules/counter_installation_place";
import pay_system from "@/store/modules/pay_system";
import appeal_receiver_group from "@/store/modules/appeal_receiver_group";
import screen_data from "@/store/modules/screen_data"
import flat_counter_avg from "@/store/modules/flat_counter_avg";
import removal from "@/store/modules/removal";
import absent from "@/store/modules/absent";
import legacy_accounting_documents from "@/store/modules/legacy_documents";
import balance from "@/store/modules/balance"
import registration_of_changes from "@/store/modules/registration_of_changes"
import general_registration_of_changes from "@/store/modules/general_registration_of_changes"
import appliance from "@/store/modules/appliance";
import counter_transfer_coefficient from "@/store/modules/counter_transfer_coefficient";
import flat_counter_fmc from "@/store/modules/flat_counter_fmc";
import penalty_tariff from "@/store/modules/penalty_tariff"
import restructuring_agreement from "@/store/modules/restructuring_agreement"
import spreed_sheet from "@/store/modules/spreed_sheet"
import dispatcher_calls from "@/store/modules/dispatcher_call"
import sms_send_config from "@/store/modules/sms_send_config"
import sms_send_template from "@/store/modules/sms_send_template"
import user_message_history from "@/store/modules/user_message_history"

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
    modules: {
        user,
        auth,
        alert,
        screen_data,
        question,
        loading,
        counter,
        amalgamated_hromadas,
        city_area,
        cities,
        building,
        building_indicator,
        flat,
        flat_indicator,
        contact,
        family,
        tariff_group,
        service,
        tariff_by_organization,
        tariff_by_building,
        flat_service,
        calculation,
        work_month,
        organization,
        bank,
        checker,
        counter_contractor,
        counter_installation_place,
        flat_counter_avg,
        response_people,
        organization_contact,
        payments,
        regular_by_organization,
        flat_documents,
        vote,
        appeal,
        socket,
        contract,
        privilege,
        chart_of_account,
        personal_site,
        organization_documents,
        site_tables,
        list,
        doc_list,
        media_send,
        appeal_receiver_group,
        removal,
        absent,
        legacy_accounting_documents,
        balance,
        pay_system,
        registration_of_changes,
        general_registration_of_changes,
        appliance,
        counter_transfer_coefficient,
        flat_counter_fmc,
        penalty_tariff,
        restructuring_agreement,
        spreed_sheet,
        dispatcher_calls,
        sms_send_config,
        sms_send_template,
        user_message_history
    },
    strict: debug
});