export const GET_ALL_CONTRACTS = "GET_ALL_CONTRACTS"
export const GET_CONTRACTS_BY_FLAT = "GET_CONTRACTS_BY_FLAT"
export const CREATE_CONTRACT = "CREATE_CONTRACT"
export const UPDATE_CONTRACT = "UPDATE_CONTRACT"
export const DELETE_CONTRACT = "DELETE_CONTRACT"

export const GET_CONTRACT_LEGACY_BY_FLAT = "GET_CONTRACT_LEGACY_BY_FLAT"
export const CREATE_LEGACY_CONTRACT = "CREATE_LEGACY_CONTRACT"
export const UPDATE_LEGACY_CONTRACT = "UPDATE_LEGACY_CONTRACT"
export const DELETE_LEGACY_CONTRACT = "DELETE_LEGACY_CONTRACT"
