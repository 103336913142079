import {ALERT_SHOW} from "@/store/actions/alert";

import counterContractorAPI from "@/utils/axios/counter_contractor"
import {
    CREATE_COUNTER_CONTRACTOR,
    DELETE_COUNTER_CONTRACTOR,
    GET_ALL_COUNTER_CONTRACTORS,
    SELECT_COUNTER_CONTRACTOR,
    UPDATE_COUNTER_CONTRACTOR
} from "@/store/actions/counter_contractor";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = {
    counter_contractors: [],
    select_counter_contractors: [],
    select_loaded: false
}

const getters = {
    getCounterContractors: state => state.counter_contractors,
    getSelectCounterContractors: state => state.select_counter_contractors,
}

const actions = {
    [GET_ALL_COUNTER_CONTRACTORS]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            counterContractorAPI.get_all(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_ALL_COUNTER_CONTRACTORS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_COUNTER_CONTRACTOR]: ({ dispatch, commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            counterContractorAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_COUNTER_CONTRACTOR, data)
                    dispatch(SELECT_COUNTER_CONTRACTOR)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_COUNTER_CONTRACTOR]: ({ commit }, payload) => {
        commit(START_MODAL_LOADING)
        return new Promise((resolve, reject) => {
            counterContractorAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_COUNTER_CONTRACTOR, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_COUNTER_CONTRACTOR]: ({ dispatch, commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            counterContractorAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(DELETE_COUNTER_CONTRACTOR, data)
                    dispatch(SELECT_COUNTER_CONTRACTOR)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_COUNTER_CONTRACTOR]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            counterContractorAPI.select(payload)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_COUNTER_CONTRACTOR, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
}

const mutations = {
    [GET_ALL_COUNTER_CONTRACTORS]: (state, payload) => {
        state.counter_contractors = payload
    },
    [CREATE_COUNTER_CONTRACTOR]: (state, payload) => {
        state.counter_contractors.push(payload)
    },
    [UPDATE_COUNTER_CONTRACTOR]: (state, payload) => {
        let obj = state.counter_contractors.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [DELETE_COUNTER_CONTRACTOR]: (state, payload) => {
        const obg = state.counter_contractors.find(item => item.id === payload.id)
        const obj_id = state.counter_contractors.indexOf(obg)
        if (obg) {
            state.counter_contractors.splice(obj_id, 1)
        }
    },
    [SELECT_COUNTER_CONTRACTOR]: (state, payload) => {
        state.select_counter_contractors = payload
        state.select_loaded = true
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}