import {axios} from '@/utils/axios';

const BANK_INCOME_RESOURCE = '/accounting/documents/list/bank-income';
const BANK_OUTCOME_RESOURCE = '/accounting/documents/list/bank-outcome';
const CASH_ORDER_OUTCOME_RESOURCE = '/accounting/documents/list/cash-order-outcome';
const CASH_ORDER_INCOME_RESOURCE = '/accounting/documents/list/cash-order-income';
const PERSONAL_TIMESHEET_RESOURCE = '/accounting/documents/list/personal-timesheet';
const WORK_TRANSFER_RESOURCE = '/accounting/documents/list/work-transfer';
const EMPLOYMENT_RESOURCE = '/accounting/documents/list/employment';
const CONTRACT_BY_CLN_RESOURCE = '/accounting/documents/list/contract-by-cln';
const SOCIAL_PRIVILEGE_RESOURCE = '/accounting/documents/list/social-privilege';
const SALARY_CHARGE_RESOURCE = '/accounting/documents/list/salary-charge';
const SALARY_TO_PAY_RESOURCE = '/accounting/documents/list/salary-to-pay';
const HOSPITAL_CHARGE_RESOURCE = '/accounting/documents/list/hospital-charge';
const VOCATION_CHARGE_RESOURCE = '/accounting/documents/list/vocation-charge';
const ACT_WORK_PERFORMED_OUTCOME = '/accounting/documents/list/act-work-performed-outcome';
const ACT_WORK_PERFORMED_INCOME = '/accounting/documents/list/act-work-performed-income';
const INVOICE_WAREHOUSE_INCOME = '/accounting/documents/list/invoice-warehouse-income';
const INVOICE_WAREHOUSE_OUTCOME = '/accounting/documents/list/invoice-warehouse-outcome';
const BILL_OUTCOME = '/accounting/documents/list/bill-outcome';
const WAREHOUSE_INVENTORY_OUTCOME = '/accounting/documents/list/warehouse-inventory-outcome';
const ADVANCE_REPORT = '/accounting/documents/list/advance-report';
const COMMISSIONING_RESOURCE = '/accounting/documents/list/commissioning-assets';
const LOW_COST_COMMISSIONING_RESOURCE = '/accounting/documents/list/low-cost-commissioning';
const CLOSE_FINANCIAL_MONTH_RESOURCE = '/accounting/documents/list/close-financial-month';
const CLOSE_HOME_ACCOUNTING_RESOURCE = '/accounting/documents/list/close-home-accounting';
const ENTERING_BALANCE_RESOURCE = '/accounting/documents/list/entering-balance';
const MANUAL_OPERATION_RESOURCE = '/accounting/documents/list/manual-operation';
const TAX_BILL_INCOME_OPERATION_RESOURCE = '/accounting/documents/list/tax-bill-income';
const TAX_BILL_OUTCOME_OPERATION_RESOURCE = '/accounting/documents/list/tax-bill-outcome';
const RELEASE_WORK_OPERATION_RESOURCE = '/accounting/documents/list/release-work';

export default {
    get_accounting(payload) {
        return axios.post('/accounting/documents/list/get-accounting', payload)
    },
    fetch_doc_commissioning_elements(payload) {
        return axios.get(`${COMMISSIONING_RESOURCE}/elements`, {params: payload})
    },
    fetch_doc_bank_income_elements(payload) {
        return axios.get(`${BANK_INCOME_RESOURCE}/elements`, {params: payload})
    },
    fetch_doc_bank_outcome_elements(payload) {
        return axios.get(`${BANK_OUTCOME_RESOURCE}/elements`, {params: payload})
    },
    fetch_doc_cash_order_income_elements(payload) {
        return axios.get(`${CASH_ORDER_INCOME_RESOURCE}/elements`, {params: payload})
    },
    fetch_doc_cash_order_outcome_elements(payload) {
        return axios.get(`${CASH_ORDER_OUTCOME_RESOURCE}/elements`, {params: payload})
    },
    fetch_doc_personal_timesheet_elements(payload) {
        return axios.get(`${PERSONAL_TIMESHEET_RESOURCE}/elements`, {params: payload})
    },
    fetch_doc_work_transfer_elements(payload) {
        return axios.get(`${WORK_TRANSFER_RESOURCE}/elements`, {params: payload})
    },
    fetch_doc_employment_elements(payload) {
        return axios.get(`${EMPLOYMENT_RESOURCE}/elements`, {params: payload})
    },
    fetch_doc_contract_by_cln_elements(payload) {
        return axios.get(`${CONTRACT_BY_CLN_RESOURCE}/elements`, {params: payload})
    },
    fetch_doc_social_privilege_elements(payload) {
        return axios.get(`${SOCIAL_PRIVILEGE_RESOURCE}/elements`, {params: payload})
    },
    fetch_doc_salary_charge_elements(payload) {
        return axios.get(`${SALARY_CHARGE_RESOURCE}/elements`, {params: payload})
    },
    fetch_doc_salary_to_pay_elements(payload) {
        return axios.get(`${SALARY_TO_PAY_RESOURCE}/elements`, {params: payload})
    },
    fetch_doc_hospital_charge_elements(payload) {
        return axios.get(`${HOSPITAL_CHARGE_RESOURCE}/elements`, {params: payload})
    },
    fetch_doc_vocation_charge_elements(payload) {
        return axios.get(`${VOCATION_CHARGE_RESOURCE}/elements`, {params: payload})
    },
    fetch_doc_act_work_performed_outcome_elements(payload) {
        return axios.get(`${ACT_WORK_PERFORMED_OUTCOME}/elements`, {params: payload})
    },
    fetch_doc_bill_outcome_elements(payload) {
        return axios.get(`${BILL_OUTCOME}/elements`, {params: payload})
    },
    fetch_doc_act_work_performed_income_elements(payload) {
        return axios.get(`${ACT_WORK_PERFORMED_INCOME}/elements`, {params: payload})
    },
    fetch_doc_invoice_warehouse_income_elements(payload) {
        return axios.get(`${INVOICE_WAREHOUSE_INCOME}/elements`, {params: payload})
    },
    fetch_doc_invoice_warehouse_outcome_elements(payload) {
        return axios.get(`${INVOICE_WAREHOUSE_OUTCOME}/elements`, {params: payload})
    },
    fetch_doc_warehouse_inventory_outcome_elements(payload) {
        return axios.get(`${WAREHOUSE_INVENTORY_OUTCOME}/elements`, {params: payload})
    },
    fetch_doc_advance_report_elements(payload) {
        return axios.get(`${ADVANCE_REPORT}/elements`, {params: payload})
    },
    fetch_doc_low_cost_commissioning_elements(payload) {
        return axios.get(`${LOW_COST_COMMISSIONING_RESOURCE}/elements`, {params: payload})
    },
    fetch_doc_close_financial_month_elements(payload) {
        return axios.get(`${CLOSE_FINANCIAL_MONTH_RESOURCE}/elements`, {params: payload})
    },
    fetch_doc_close_home_accounting_elements(payload) {
        return axios.get(`${CLOSE_HOME_ACCOUNTING_RESOURCE}/elements`, {params: payload})
    },
    fetch_doc_entering_balance_elements(payload) {
        return axios.get(`${ENTERING_BALANCE_RESOURCE}/elements`, {params: payload})
    },
    fetch_doc_manual_operation_elements(payload) {
        return axios.get(`${MANUAL_OPERATION_RESOURCE}/elements`, {params: payload})
    },
    fetch_doc_tax_bill_income_elements(payload) {
        return axios.get(`${TAX_BILL_INCOME_OPERATION_RESOURCE}/elements`, {params: payload})
    },
    fetch_doc_tax_bill_outcome_elements(payload) {
        return axios.get(`${TAX_BILL_OUTCOME_OPERATION_RESOURCE}/elements`, {params: payload})
    },
    fetch_doc_release_work_elements(payload) {
        return axios.get(`${RELEASE_WORK_OPERATION_RESOURCE}/elements`, {params: payload})
    },
}