import {
    FETCH_CHART_OF_ACCOUNT,
    CREATE_CHART_OF_ACCOUNT,
    UPDATE_CHART_OF_ACCOUNT,
    REMOVE_CHART_OF_ACCOUNT,
    SELECT_CHART_OF_ACCOUNT
} from "@/store/actions/accounting/chart_of_account";

import {ALERT_SHOW} from "@/store/actions/alert";
import chartOfAccountAPI from "@/utils/axios/accounting/chart_of_account"
import {RELOAD_LIST} from "@/store/actions/question";

const state = { charts_of_account: [], chart_of_account_select: []}

const getters = {
    getChartsOfAccount: state => state.charts_of_account,
    getChartOfAccountSelect: state => state.chart_of_account_select,
}

const actions = {
    [FETCH_CHART_OF_ACCOUNT]: ({commit}) => {
        return new Promise((resolve, reject) => {
            chartOfAccountAPI.get_all()
                .then(response => response.data)
                .then(data => {
                    commit(FETCH_CHART_OF_ACCOUNT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_CHART_OF_ACCOUNT]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            chartOfAccountAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_CHART_OF_ACCOUNT, data)
                    commit(RELOAD_LIST, { getter: 'get_chart_of_accounts_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [UPDATE_CHART_OF_ACCOUNT]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            chartOfAccountAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_CHART_OF_ACCOUNT, data)
                    commit(RELOAD_LIST, { getter: 'get_chart_of_accounts_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [REMOVE_CHART_OF_ACCOUNT]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            chartOfAccountAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_CHART_OF_ACCOUNT, data)
                    commit(RELOAD_LIST, { getter: 'get_chart_of_accounts_list', id: data.id })
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [SELECT_CHART_OF_ACCOUNT]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            chartOfAccountAPI.select()
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_CHART_OF_ACCOUNT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
}

const mutations = {
    [FETCH_CHART_OF_ACCOUNT]: (state, payload) => {
        state.charts_of_account = payload
    },
    [SELECT_CHART_OF_ACCOUNT]: (state, payload) => {
        state.chart_of_account_select = payload
    },
    [CREATE_CHART_OF_ACCOUNT]: (state, payload) => {
        state.charts_of_account.push(payload)
    },
    [UPDATE_CHART_OF_ACCOUNT]: (state, payload) => {
        const obj = state.charts_of_account.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }
    },
    [REMOVE_CHART_OF_ACCOUNT]: (state, payload) => {
        const obg = state.charts_of_account.find(item => item.id === payload.id)
        const obj_id = state.charts_of_account.indexOf(obg)
        if (obg) {
            state.charts_of_account.splice(obj_id, 1)
        }
    },
}


export default {
    state,
    getters,
    actions,
    mutations
}