import {
    GET_ORGANIZATION_DOCUMENTS,
    CREATE_ORGANIZATION_DOCUMENTS,
    UPDATE_ORGANIZATION_DOCUMENTS,
    REMOVE_ORGANIZATION_DOCUMENTS,
    GET_GROUP_DOCUMENT_TYPE,
    CREATE_GROUP_DOCUMENT_TYPE,
    UPDATE_GROUP_DOCUMENT_TYPE,
    REMOVE_GROUP_DOCUMENT_TYPE,
    GET_DOCUMENT_TYPE,
    CREATE_DOCUMENT_TYPE,
    UPDATE_DOCUMENT_TYPE,
    REMOVE_DOCUMENT_TYPE
} from "@/store/actions/organization_documents";

import {ALERT_SHOW} from "@/store/actions/alert";
import organizationDocumentAPI from "@/utils/axios/organization_douments"
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = {organization_documents: [], document_group_type: [], document_type: []}

const getters = {
    getOrganizationDocuments: state => state.organization_documents,
    getDocumentGroupType: state => state.document_group_type,
    getDocumentType: state => state.document_type,
}

const actions = {
    [GET_ORGANIZATION_DOCUMENTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            organizationDocumentAPI.get_documents()
                .then(response => response.data)
                .then(data => {
                    commit(GET_ORGANIZATION_DOCUMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [GET_GROUP_DOCUMENT_TYPE]: ({commit}) => {
        return new Promise((resolve, reject) => {
            organizationDocumentAPI.get_group_type()
                .then(response => response.data)
                .then(data => {
                    commit(GET_GROUP_DOCUMENT_TYPE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [GET_DOCUMENT_TYPE]: ({commit}) => {
        return new Promise((resolve, reject) => {
            organizationDocumentAPI.get_document_type()
                .then(response => response.data)
                .then(data => {
                    commit(GET_DOCUMENT_TYPE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_ORGANIZATION_DOCUMENTS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            organizationDocumentAPI.create_document(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_ORGANIZATION_DOCUMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CREATE_GROUP_DOCUMENT_TYPE]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            organizationDocumentAPI.create_group_type(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_GROUP_DOCUMENT_TYPE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [CREATE_DOCUMENT_TYPE]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            organizationDocumentAPI.create_document_type(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_DOCUMENT_TYPE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_ORGANIZATION_DOCUMENTS]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            organizationDocumentAPI.update_document(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_ORGANIZATION_DOCUMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_GROUP_DOCUMENT_TYPE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            organizationDocumentAPI.update_group_type(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_GROUP_DOCUMENT_TYPE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_DOCUMENT_TYPE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            organizationDocumentAPI.update_document_type(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_DOCUMENT_TYPE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_ORGANIZATION_DOCUMENTS]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            organizationDocumentAPI.remove_document(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_ORGANIZATION_DOCUMENTS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_GROUP_DOCUMENT_TYPE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            organizationDocumentAPI.remove_group_type(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_GROUP_DOCUMENT_TYPE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [REMOVE_DOCUMENT_TYPE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            organizationDocumentAPI.remove_document_type(payload)
                .then(response => response.data)
                .then(data => {
                    commit(REMOVE_DOCUMENT_TYPE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
}

const mutations = {
    [GET_ORGANIZATION_DOCUMENTS]: (state, payload) => {
        state.organization_documents = payload
    },
    [GET_GROUP_DOCUMENT_TYPE]: (state, payload) => {
        state.document_group_type = payload
    },
    [GET_DOCUMENT_TYPE]: (state, payload) => {
        state.document_type = payload
    },
    [CREATE_ORGANIZATION_DOCUMENTS]: (state, payload) => {
        state.organization_documents.push(payload)
    },
    [CREATE_GROUP_DOCUMENT_TYPE]: (state, payload) => {
        state.document_group_type.push(payload)
    },
    [CREATE_DOCUMENT_TYPE]: (state, payload) => {
        state.document_type.push(payload)
    },
    [UPDATE_ORGANIZATION_DOCUMENTS]: (state, payload) => {
        const doc_item = state.organization_documents.find(item => item.id === payload.id)
        if (doc_item) {
            Object.keys(payload).forEach(item => {
                doc_item[item] = payload[item]
            })
        }
    },
    [UPDATE_GROUP_DOCUMENT_TYPE]: (state, payload) => {
        const doc_item = state.document_group_type.find(item => item.id === payload.id)
        if (doc_item) {
            Object.keys(payload).forEach(item => {
                doc_item[item] = payload[item]
            })
        }
    },
    [UPDATE_DOCUMENT_TYPE]: (state, payload) => {
        const doc_item = state.document_type.find(item => item.id === payload.id)
        if (doc_item) {
            Object.keys(payload).forEach(item => {
                doc_item[item] = payload[item]
            })
        }
    },
    [REMOVE_ORGANIZATION_DOCUMENTS]: (state, payload) => {
        const obg = state.organization_documents.find(item => item.id === payload.id)
        const obj_id = state.organization_documents.indexOf(obg)
        if (obg) {
            state.organization_documents.splice(obj_id, 1)
        }
    },
    [REMOVE_GROUP_DOCUMENT_TYPE]: (state, payload) => {
        const obg = state.document_group_type.find(item => item.id === payload.id)
        const obj_id = state.document_group_type.indexOf(obg)
        if (obg) {
            state.document_group_type.splice(obj_id, 1)
        }
    },
    [REMOVE_DOCUMENT_TYPE]: (state, payload) => {
        const obg = state.document_type.find(item => item.id === payload.id)
        const obj_id = state.document_type.indexOf(obg)
        if (obg) {
            state.document_type.splice(obj_id, 1)
        }
    },
}


export default {
    state,
    getters,
    actions,
    mutations
}