import {ALERT_SHOW} from "@/store/actions/alert";

import counterTransferCoefficientAPI from "@/utils/axios/counter_transfer_coefficient"
import {
    GET_ALL_COUNTER_TRANSFER_COEFFICIENT,
    CREATE_COUNTER_TRANSFER_COEFFICIENT,
    UPDATE_COUNTER_TRANSFER_COEFFICIENT,
    DELETE_COUNTER_TRANSFER_COEFFICIENT
} from "@/store/actions/counter_transfer_coefficient";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = { counter_transfer_coefficients: [] }

const getters = {
    getCounterTransferCoefficients: state => state.counter_transfer_coefficients,
}

const actions = {
    [GET_ALL_COUNTER_TRANSFER_COEFFICIENT]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            counterTransferCoefficientAPI.get_all(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_ALL_COUNTER_TRANSFER_COEFFICIENT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_COUNTER_TRANSFER_COEFFICIENT]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            counterTransferCoefficientAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_COUNTER_TRANSFER_COEFFICIENT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_COUNTER_TRANSFER_COEFFICIENT]: ({ commit }, payload) => {
        commit(START_MODAL_LOADING)
        return new Promise((resolve, reject) => {
            counterTransferCoefficientAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_COUNTER_TRANSFER_COEFFICIENT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_COUNTER_TRANSFER_COEFFICIENT]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            counterTransferCoefficientAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(DELETE_COUNTER_TRANSFER_COEFFICIENT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    }
}

const mutations = {
    [GET_ALL_COUNTER_TRANSFER_COEFFICIENT]: (state, payload) => {
        state.counter_transfer_coefficients = payload
    },
    [CREATE_COUNTER_TRANSFER_COEFFICIENT]: (state, payload) => {
        state.counter_transfer_coefficients.push(payload)
    },
    [UPDATE_COUNTER_TRANSFER_COEFFICIENT]: (state, payload) => {
        let obj = state.counter_transfer_coefficients.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [DELETE_COUNTER_TRANSFER_COEFFICIENT]: (state, payload) => {
        const obg = state.counter_transfer_coefficients.find(item => item.id === payload.id)
        const obj_id = state.counter_transfer_coefficients.indexOf(obg)
        if (obg) {
            state.counter_transfer_coefficients.splice(obj_id, 1)
        }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}