import {
    GET_CURRENT_MONTH,
    GET_CURRENT_LEGACY_MONTH,
    CLOSE_MONTH,
    CLOSE_LEGACY_MONTH,
    OPEN_MONTH,
    OPEN_LEGACY_MONTH
} from "@/store/actions/work_month";

import monthAPI from "@/utils/axios/work_month"

import {ALERT_SHOW} from "@/store/actions/alert";

import moment from "moment";

const state = {
    current_month: null,
    prev_month: null,
    next_month: null,
    current_month_legacy: null,
    prev_month_legacy: null,
    next_month_legacy: null
}

const getters = {
    getCurrentMonth: state => state.current_month,
    getPrevMonth: state => state.prev_month,
    getNextMonth: state => state.next_month,
    getCurrentMonthLegacy: state => state.current_month_legacy,
    getPrevMonthLegacy: state => state.prev_month_legacy,
    getNextMonthLegacy: state => state.next_month_legacy
}

const getMonths = (mdate) => {
    return {
        next_month: moment(mdate).add(1, 'months').format('YYYY-MM-DD'),
        prev_month: moment(mdate).add(-1, 'months').format('YYYY-MM-DD')
    };
}

const actions = {
    [GET_CURRENT_MONTH]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            monthAPI.get_current_month()
                .then(response => response.data )
                .then(data => {
                    commit(GET_CURRENT_MONTH, data.month)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [GET_CURRENT_LEGACY_MONTH]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            monthAPI.get_current_legacy_month()
                .then(response => response.data )
                .then(data => {
                    commit(GET_CURRENT_LEGACY_MONTH, data.month)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [OPEN_MONTH]: async ({ commit }) => {
        return new Promise(((resolve, reject) => {
            monthAPI.open_month()
                .then(response => response.data)
                .then(data => {
                    commit(OPEN_MONTH, data.month)
                    resolve(data.month)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        }))
    },
    [OPEN_LEGACY_MONTH]: async ({ commit }) => {
        return new Promise(((resolve, reject) => {
            monthAPI.open_legacy_month()
                .then(response => response.data)
                .then(data => {
                    commit(OPEN_LEGACY_MONTH, data.month)
                    resolve(data.month)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        }))
    },
    [CLOSE_MONTH]: async ({ commit }) => {
        return new Promise(((resolve, reject) => {
            monthAPI.close_month()
                .then(response => response.data)
                .then(data => {
                    commit(CLOSE_MONTH, data.month)
                    resolve(data.month)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        }))
    },
    [CLOSE_LEGACY_MONTH]: async ({ commit }) => {
        return new Promise(((resolve, reject) => {
            monthAPI.close_legacy_month()
                .then(response => response.data)
                .then(data => {
                    commit(CLOSE_LEGACY_MONTH, data.month)
                    resolve(data.month)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        }))
    },
}

const mutations = {
    [GET_CURRENT_MONTH]: (state, payload) => {
        const months = getMonths(payload)
        state.current_month = payload
        state.prev_month = months.prev_month
        state.next_month = months.next_month
    },
    [GET_CURRENT_LEGACY_MONTH]: (state, payload) => {
        const months = getMonths(payload)
        state.current_month_legacy = payload
        state.prev_month_legacy = months.prev_month
        state.next_month_legacy = months.next_month
    },
    [OPEN_MONTH]: (state, payload) => {
        const months = getMonths(payload)
        state.current_month = payload
        state.prev_month = months.prev_month
        state.next_month = months.next_month
    },
    [OPEN_LEGACY_MONTH]: (state, payload) => {
        const months = getMonths(payload)
        state.current_month_legacy = payload
        state.prev_month_legacy = months.prev_month
        state.next_month_legacy = months.next_month
    },
    [CLOSE_MONTH]: (state, payload) => {
        const months = getMonths(payload)
        state.current_month = payload
        state.prev_month = months.prev_month
        state.next_month = months.next_month
    },
    [CLOSE_LEGACY_MONTH]: (state, payload) => {
        const months = getMonths(payload)
        state.current_month_legacy = payload
        state.prev_month_legacy = months.prev_month
        state.next_month_legacy = months.next_month
    }
}


export default {
    state,
    getters,
    actions,
    mutations
}