import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/organization-pay-system';

export default {
    get_all() {
        return axios.get(`${RESOURCE_NAME}/`)
    },
    get_all_enable() {
        return axios.get(`${RESOURCE_NAME}/enable`)
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { "item_id": payload })
    },

};