const state = {
    screen_data: {width: window.innerWidth, height: window.innerHeight},
    bottom_sheet_width: 80
}

const getters = {
    getScreenData: state => state.screen_data,
    getBottomSheetWidth: state => state.bottom_sheet_width
}

const actions = {
    "SET_SCREEN_DATA": ({ commit }, data) => {
        commit("SET_SCREEN_DATA", data)

        if (data.width >= 240 && data.width <= 560) {
            commit("SET_BOTTOM_SHEET_WIDTH", 94)
        } else if (data.width >= 561 && data.width <= 700) {
            commit("SET_BOTTOM_SHEET_WIDTH", 92)
        } else if (data.width >= 701 && data.width <= 900) {
            commit("SET_BOTTOM_SHEET_WIDTH", 88)
        } else if (data.width >= 901 && data.width <= 1000) {
            commit("SET_BOTTOM_SHEET_WIDTH", 86)
        } else if (data.width >= 1001 && data.width <= 1200) {
            commit("SET_BOTTOM_SHEET_WIDTH", 84)
        } else if (data.width >= 1201 && data.width <= 1400) {
            commit("SET_BOTTOM_SHEET_WIDTH", 82)
        } else if (data.width >= 1401 && data.width <= 1600) {
            commit("SET_BOTTOM_SHEET_WIDTH", 76)
        } else {
            commit("SET_BOTTOM_SHEET_WIDTH", 70)
        }
    },
}

const mutations = {
    "SET_SCREEN_DATA": (state, payload) => {
        state.screen_data = payload
    },
    "SET_BOTTOM_SHEET_WIDTH": (state, payload) => {
        state.bottom_sheet_width = payload
    },
}


export default {
    state,
    getters,
    actions,
    mutations
}