import {
    MEDIA_SEND_HEADER_FETCH_ALL,
    MEDIA_SEND_HEADER_CREATE,
    MEDIA_SEND_HEADER_UPDATE,
    MEDIA_SEND_HEADER_REMOVE,
    MEDIA_BUE_FETCH_ALL,
    MEDIA_BUE_CREATE,
} from "@/store/actions/media_send";

import mediaSendAPI from "@/utils/axios/media_send"

import {ALERT_SHOW} from "@/store/actions/alert";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = { media_send_header: [], media_bue: [] }

const getters = {
    getMediaSendHeader: state => state.media_send_header,
    getMediaBue: state => state.media_bue,
}

const actions = {
    [MEDIA_SEND_HEADER_FETCH_ALL]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            mediaSendAPI.get_header(payload)
                .then(response => response.data)
                .then(data => {
                    commit(MEDIA_SEND_HEADER_FETCH_ALL, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [MEDIA_BUE_FETCH_ALL]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            mediaSendAPI.get_bue(payload)
                .then(response => response.data)
                .then(data => {
                    commit(MEDIA_BUE_FETCH_ALL, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [MEDIA_SEND_HEADER_CREATE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            mediaSendAPI.create_header(payload)
                .then(response => response.data)
                .then(data => {
                    commit(MEDIA_SEND_HEADER_CREATE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [MEDIA_BUE_CREATE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            mediaSendAPI.create_bue(payload)
                .then(response => response.data)
                .then(data => {
                    commit(MEDIA_BUE_CREATE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [MEDIA_SEND_HEADER_UPDATE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            mediaSendAPI.update_header(payload)
                .then(response => response.data)
                .then(data => {
                    commit(MEDIA_SEND_HEADER_UPDATE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [MEDIA_SEND_HEADER_REMOVE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            mediaSendAPI.remove_header(payload)
                .then(response => response.data)
                .then(data => {
                    commit(MEDIA_SEND_HEADER_REMOVE, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
}

const mutations = {
    [MEDIA_SEND_HEADER_FETCH_ALL]: (state, payload) => {
        state.media_send_header = payload
    },
    [MEDIA_BUE_FETCH_ALL]: (state, payload) => {
        state.media_bue = payload
    },
    [MEDIA_SEND_HEADER_CREATE]: (state, payload) => {
        state.media_send_header.push(payload)
    },
    [MEDIA_BUE_CREATE]: (state, payload) => {
        state.media_bue.push(payload)
    },
    [MEDIA_SEND_HEADER_UPDATE]: (state, payload) => {
        let obj = state.media_send_header.find(item => item.id === payload.id)
        if (obj) {
            Object.keys(payload).forEach(item => {
                obj[item] = payload[item]
            })
        }

    },
    [MEDIA_SEND_HEADER_REMOVE]: (state, payload) => {
        const obg = state.media_send_header.find(item => item.id === payload.id)
        const obj_id = state.media_send_header.indexOf(obg)
        if (obg) {
            state.media_send_header.splice(obj_id, 1)
        }
    },
}


export default {
    state,
    getters,
    actions,
    mutations
}