import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/response-people';

export default {
    get_position() {
        return axios.get(`${RESOURCE_NAME}/position`)
    },
    get_natural_person() {
        return axios.get(`${RESOURCE_NAME}/natural`)
    },
    get_response_person() {
        return axios.get(`${RESOURCE_NAME}/response`)
    },
    select_position() {
        return axios.get(`${RESOURCE_NAME}/position/select`)
    },
    select_natural_person() {
        return axios.post(`${RESOURCE_NAME}/natural/select`)
    },
    create_position(payload) {
        return axios.post(`${RESOURCE_NAME}/position`, payload)
    },
    create_natural_person(payload) {
        return axios.post(`${RESOURCE_NAME}/natural`, payload)
    },
    create_response_person(payload) {
        return axios.post(`${RESOURCE_NAME}/response`, payload)
    },
    update_position(payload) {
        return axios.post(`${RESOURCE_NAME}/position/update`, payload)
    },
    update_natural_person(payload) {
        return axios.post(`${RESOURCE_NAME}/natural/update`, payload)
    },
    update_response_person(payload) {
        return axios.post(`${RESOURCE_NAME}/response/update`, payload)
    },
    remove_position(payload) {
        return axios.post(`${RESOURCE_NAME}/position/remove`, { position_id: payload })
    },
    remove_natural_person(payload) {
        return axios.post(`${RESOURCE_NAME}/natural/remove`, { natural_person_id: payload })
    },
    remove_response_person(payload) {
        return axios.post(`${RESOURCE_NAME}/response/remove`, { response_person_id: payload })
    },
}