import {ALERT_SHOW} from "@/store/actions/alert";

import restructuringAgreementAPI from "@/utils/axios/restructuring_agreement"
import {
    CREATE_RESTRUCTURING_AGREEMENT,
    DELETE_RESTRUCTURING_AGREEMENT,
    GET_ALL_RESTRUCTURING_AGREEMENT,
    UPDATE_RESTRUCTURING_AGREEMENT
} from "@/store/actions/restructuring_agreement";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = { restructuring_agreements: []}

const getters = {
    getRestructuringAgreements: state => state.restructuring_agreements,
}

const actions = {
    [GET_ALL_RESTRUCTURING_AGREEMENT]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            restructuringAgreementAPI.get_all(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_ALL_RESTRUCTURING_AGREEMENT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_RESTRUCTURING_AGREEMENT]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            restructuringAgreementAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_RESTRUCTURING_AGREEMENT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_RESTRUCTURING_AGREEMENT]: ({ commit }, payload) => {
        commit(START_MODAL_LOADING)
        return new Promise((resolve, reject) => {
            restructuringAgreementAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_RESTRUCTURING_AGREEMENT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_RESTRUCTURING_AGREEMENT]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            restructuringAgreementAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(DELETE_RESTRUCTURING_AGREEMENT, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
}

const mutations = {
    [GET_ALL_RESTRUCTURING_AGREEMENT]: (state, payload) => {
        state.restructuring_agreements = payload
    },
    [CREATE_RESTRUCTURING_AGREEMENT]: (state, payload) => {
        state.restructuring_agreements.push(payload)
    },
    [UPDATE_RESTRUCTURING_AGREEMENT]: (state, payload) => {
        let obj = state.restructuring_agreements.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [DELETE_RESTRUCTURING_AGREEMENT]: (state, payload) => {
        const obg = state.restructuring_agreements.find(item => item.id === payload.id)
        const obj_id = state.restructuring_agreements.indexOf(obg)
        if (obg) {
            state.restructuring_agreements.splice(obj_id, 1)
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}